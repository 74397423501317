import React, { Component } from 'react';
import {Auth, Logger} from "aws-amplify";
import {withRouter, Redirect, Link} from "react-router-dom";
import {connect} from "react-redux";
import {setLoggedInUser} from "../../redux/actions";
import AuthMessage from "../../models/AuthMessage";

const logger = new Logger('LoginScreen');

class LoginScreen extends Component {

  static ERR_CODE_UserNotConfirmedException = "UserNotConfirmedException";

  constructor(props) {
    super(props);

    this.state = {
      usernameFieldValue: "",
      messages: [],
    };

    // This binding is necessary to make `this` work in the callback
    this.submit = this.submit.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);

  }

  submit = async (e) => {
    e.preventDefault();
    logger.debug('The submit link was clicked.');

    this.setState({
      messages: [],
    });


    let username = this.state.usernameFieldValue.trim()

    if (username === "") {
      let messages = this.state.messages;
      messages.push(new AuthMessage("You must enter a user name", AuthMessage.TYPE_DANGER));
      this.setState({
        messages: messages,
      });
      return;
    }


    try {
      Auth.resendSignUp(username)

      let messages = this.state.messages;
      messages.push(new AuthMessage("Verification email has been sent", AuthMessage.TYPE_SUCCESS));
      this.setState({
        messages: messages,
      });

    } catch(err) {
        logger.error(err);
        let messages = this.state.messages;
        messages.push(new AuthMessage(err, AuthMessage.TYPE_DANGER, err.errorCode));
        this.setState({
          messages: messages,
        });
    }
  };



  handleUserNameChange(event) {
    this.setState({usernameFieldValue: event.target.value});
  }

  renderMessages() {

    if (this.state.messages.length === 0) {
      return null;
    }

    return this.state.messages.map( (item, idx) =>  {
        return (<div key={item.getKey()} className={"alert alert-dismissible " + item.getType() + " fade show"}>
          <div>
            {item.getMessage()}&nbsp;&nbsp;
            {
              (item.getErrorCode() === LoginScreen.ERR_CODE_UserNotConfirmedException) ?
              <Link className="alert-link" to="/verify">Click here to re-send the verification email</Link>
                : null
            }
          </div>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        );
    });
  }

  render() {
    if (this.props.loggedInUser) {
      return (
        <Redirect to="/"/>
      );
    }

    return (
      <>
        <div className="row justify-content-center" style={{marginTop: 40}}>
          <div className="col-md-8">
            <div className="card">
              <div className="card-header">Verify Email</div>

              <div className="card-body">
                {this.renderMessages()}
                <form>

                  <div className="form-group row">
                    <label htmlFor="username" className="col-md-4 col-form-label text-md-right">User Name</label>

                    <div className="col-md-6">
                      <input id="username"
                             type="username"
                             className="form-control"
                             name="username"
                             value={this.state.userNameFieldValue}
                             onChange={this.handleUserNameChange}
                             autoComplete="username"
                             required
                             autoFocus/>
                    </div>
                  </div>


                  <div className="form-group row mb-0">
                    <div className="col-md-8 offset-md-4">
                      <button className="btn btn-primary" onClick={this.submit}>
                        Login
                      </button>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


      </>
    );
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  logger.debug("Nav.mapStateToProps");
  return {
    loggedInUser: state.users.loggedInUser,
  }
};

export default withRouter(connect(
  mapStateToProps,
  {setLoggedInUser}
)(LoginScreen));
