import React, { Component } from 'react';
import {API, Auth, Logger} from "aws-amplify";
import {Link, withRouter, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {setLoggedInUser} from "../../redux/actions";
import User from "../../models/User";
import AuthMessage from "../../models/AuthMessage";
import BusyModal from "../../components/modals/BusyModal";

import "../../css/AuthScreens.css"

const logger = new Logger('LoginScreen');


class LoginScreen extends Component {

  static ERR_CODE_UserNotConfirmedException = "UserNotConfirmedException";
  static ERR_CODE_UserNotFoundException = "UserNotFoundException";

  constructor(props) {
    super(props);

    this.state = {
      emailFieldValue: "",
      passwordFieldValue: "",
      rememberMeFieldValue: true,
      loggedIn: false,
      admin: false,
      user: null,
      messages: [],
      loggingIn: false,
    };

    this.modalElement = null;

    // This binding is necessary to make `this` work in the callback
    this.submitLogin = this.submitLogin.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordNameChange = this.handlePasswordNameChange.bind(this);
    this.handleRememberMeChange = this.handleRememberMeChange.bind(this);
    this.renderMessages = this.renderMessages.bind(this);
    this.resendVerification = this.resendVerification.bind(this);

  }


  componentDidMount = async() => {

    let waitingToVerify = localStorage.getItem('epiqueue.confirmation');
    if (waitingToVerify) {
      this.props.history.replace("/register");
    }
  };


  componentWillMount = async() => {



    // Auth.signOut()
    //   .then(data => {
    //     this.props.setLoggedInUser(null);
    //     this.props.history.push('/');
    //   })
    //   .catch(err => logger.debug(err));



    // Auth.currentAuthenticatedUser({
    //   bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    //   bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    // }).then(
    //   (user) => {
    //     logger.debug(user)
    //     this.setState({
    //       user: user,
    //     });
    //   }).catch(err => {
    //   logger.debug(err)
    // });
  };

  submitLogin = async (e) => {
    e.preventDefault();
    logger.debug('The submitLogin link was clicked.');

    this.setState({
      messages: [],
      loggingIn: true,
    });


    let email = this.state.emailFieldValue.trim()
    let password = this.state.passwordFieldValue.trim()

    if (email === "") {
      let messages = this.state.messages;
      messages.push(new AuthMessage("You must enter an email address", AuthMessage.TYPE_DANGER));
      this.setState({
        messages: messages,
        loggingIn: false,
      });
      return;
    }



    if (password === "") {
      let messages = this.state.messages;
      messages.push(new AuthMessage("You must enter a password.", AuthMessage.TYPE_DANGER));
      this.setState({
        messages: messages,
        loggingIn: false,
      });
      return;
    }



    try {

      logger.debug("about to try to log in");
      await Auth.signIn(email, password);

      const cognitoUser = await Auth.currentAuthenticatedUser();

      window.gtag('event', 'login');

      let apiName = 'epiqueueapi';
      let path = '/user/ensure';
      let postData = {
        body: {
          email: cognitoUser.attributes.email,
          identifier: cognitoUser.username,
          username: cognitoUser.attributes.preferred_username,
        }
      };
      var response = await API.post(apiName, path, postData);
      if (response.success) {
        logger.debug('ok')
      } else {
        await Auth.signOut();
        throw new Error(response.errorMessage);
      }

      logger.debug("going to try to load user");
      let result = await User.load(cognitoUser);
      let user = result.user;
      logger.debug(result.user);

      if (user && !user.identityId) {
        let credentials = await Auth.currentCredentials();
        user.identityId = credentials.identityId;
        await user.save();
        logger.debug("saved: " + JSON.stringify(user));
      }

      this.props.setLoggedInUser(user);
      this.props.history.replace('/');
    } catch(err) {
      if (err.code === LoginScreen.ERR_CODE_UserNotConfirmedException) {
        logger.error("UserNotConfirmedException");
        logger.error(err);
        // let messages = this.state.messages;
        // messages.push(new AuthMessage("You have not yet confirmed your email.<p/>Please enter the code sent in the email.", AuthMessage.TYPE_DANGER, LoginScreen.ERR_CODE_UserNotConfirmedException));
        // this.setState({
        //   messages: messages,
        //   loggingIn: false,
        // });

        // need to enter code
        localStorage.setItem('epiqueue.confirmation', email);
        this.props.history.replace("/register");



        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === LoginScreen.ERR_CODE_UserNotFoundException) {


        logger.error(err);
        let messages = this.state.messages;
        messages.push(new AuthMessage("Sign in failed.<br/>Please check that the username and password are correct.", AuthMessage.TYPE_DANGER, err.code));
        this.setState({
          messages: messages,
          loggingIn: false,
        });


      } else if (err.code === 'PasswordResetRequiredException') {

        logger.error("PasswordResetRequiredException");
        logger.error(err);
        let messages = this.state.messages;
        messages.push(new AuthMessage("You are required to reset your password.", AuthMessage.TYPE_DANGER, err.code));
        this.setState({
          messages: messages,
          loggingIn: false,
        });
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else {
        logger.error(err);
        let messages = this.state.messages;
        messages.push(new AuthMessage(err.message, AuthMessage.TYPE_DANGER, err.code));
        this.setState({
          messages: messages,
          loggingIn: false,
        });
      }
    }
  };

  resendVerification = async() => {
    logger.debug('The submit link was clicked.');

    await this.setState({
      messages: [],
    });


    let email = this.state.emailFieldValue.trim()

    if (email === "") {
      let messages = this.state.messages;
      messages.push(new AuthMessage("You must enter an email address", AuthMessage.TYPE_DANGER));
      this.setState({
        messages: messages,
      });
      return;
    }


    try {
      await Auth.resendSignUp(email);

      let messages = this.state.messages;
      messages.push(new AuthMessage("Verification email has been send, please log in after verifying", AuthMessage.TYPE_SUCCESS));
      this.setState({
        messages: messages,
      });

    } catch(err) {
      logger.error(err);
      let messages = this.state.messages;
      messages.push(new AuthMessage(err, AuthMessage.TYPE_DANGER, err.errorCode));
      this.setState({
        messages: messages,
      });
    }
  }


  handleEmailChange(event) {
    this.setState({emailFieldValue: event.target.value});
  }

  handlePasswordNameChange(event) {
    this.setState({passwordFieldValue: event.target.value});
  }

  handleRememberMeChange(event) {
   this.setState({rememberMeFieldValue: event.target.checked});
  }

  renderMessages() {

    if (this.state.messages.length === 0) {
      return null;
    }

    //return null;

    return this.state.messages.map( (item, idx) =>  {
        return (<div key={item.getKey()} className={"alert alert-dismissible " + item.getType() + " fade show"}>
          <div>
            <div dangerouslySetInnerHTML={{__html: item.getMessage()}}/>
            {
              (item.getErrorCode() === LoginScreen.ERR_CODE_UserNotConfirmedException) ?
                <button className="btn btn-link alert-link pl-0" onClick={() => this.resendVerification()}>Click here to re-send the verification email</button>
                : null
            }
          </div>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        );
    });
  }

  render() {
    if (this.props.loggedInUser) {
      return (
        <Redirect to="/"/>
      );
    }

    return (
      <>

        <BusyModal id="login_busy_modal" show={this.state.loggingIn} setRef={el => this.modalElement = el} message="Welcome, we are loggin you in..."/>

        <div className="row justify-content-center" style={{marginTop: 40}}>
          <div className="col-sm-9 col-md-9 col-lg-7 mx-auto">
            <div className="card">
              <div className="card-body">
                {this.renderMessages()}
                <h5 className="card-title text-center">Sign In</h5>

                <form>

                  <div className="form-group row">
                    <label htmlFor="email" className="col-md-4 col-form-label text-md-right">Email</label>

                    <div className="col-md-6">
                      <input id="email"
                             type="email"
                             className="form-control"
                             name="email"
                             value={this.state.emailNameFieldValue}
                             onChange={this.handleEmailChange}
                             autoComplete="email"
                             required
                             autoFocus/>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="password" className="col-md-4 col-form-label text-md-right">Password</label>

                    <div className="col-md-6">
                      <input id="password"
                             type="password"
                             className="form-control"
                             name="password"
                             onChange={this.handlePasswordNameChange}
                             autoComplete="current-password"
                             required/>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-md-6 offset-md-4">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="remember" id="remember"  onChange={this.handleRememberMeChange} checked={ this.state.rememberMeFieldValue }/>
                          <label className="form-check-label" htmlFor="remember">
                            Remember Me
                          </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-0">
                    <div className="col-md-6 offset-md-4">
                      <button className="btn btn-lg btn-primary w-100 epiqueue-darkblue" onClick={this.submitLogin}>
                        Login
                      </button>
                    </div>

                    <div className="col-md-8 offset-md-4 mt-3">
                      <Link className="epiqueue-darkblue-text" to="/password_request">Forgot Your Password?</Link>
                    </div>
                    <div className="col-md-8 offset-md-4 mt-3">
                      <Link className="epiqueue-darkblue-text" to="/register">Don't have an account? Register Here</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        </>
    );
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  logger.debug("Nav.mapStateToProps");
  return {
    loggedInUser: state.users.loggedInUser,
  }
};

export default withRouter(connect(
  mapStateToProps,
  {setLoggedInUser}
)(LoginScreen));
