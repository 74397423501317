import React, { Component } from 'react';
import {withRouter, Link} from "react-router-dom";
import PropTypes from 'prop-types';

class BackLink extends Component {

  render() {
    return (
        <div className="mb-4 mt-2">
          <Link className="epiqueue-darkblue-text" to={this.props.link}><i className="fas fa-chevron-left"/> {this.props.text}</Link>
        </div>
      )
  };
}

BackLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default withRouter(BackLink);

