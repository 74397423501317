import React, { Component } from 'react';
import PropTypes from 'prop-types';
import User from "../models/User";
import {withRouter, Link} from "react-router-dom";

import "../css/SellerCard.css"
import ProfilePic from "./ProfilePic";

class SellerCard extends Component {

  render() {
    let {seller} = this.props;

    let picSize =130;
    if (this.props.size === 'small') {
      picSize = 100;
    }

    let sellerCardClassName = "seller-card";
    if (this.props.size === 'small') {
      sellerCardClassName = "seller-card-small";
    }

    let imageClassName = "seller-card-image";
    if (this.props.size === 'small') {
      imageClassName = "seller-card-image-small";
    }

    return (
    <Link to={seller.getProfileUrl()} style={{textDecoration: 'none' }}>
      <div className={"d-flex flex-column " + sellerCardClassName}>
        <div className={imageClassName}>
          <ProfilePic size={picSize} user={seller}/>
        </div>
        <div className="d-flex seller-card-body justify-content-center align-items-start">
          <div className="seller-card-seller-name">
            {seller.username}
          </div>
        </div>
      </div>
    </Link>
    );
  };
}

SellerCard.propTypes = {
  seller: PropTypes.instanceOf(User).isRequired,
  size: PropTypes.string
};

export default withRouter(SellerCard);

