import React, {} from 'react';
import RootScreen from "./RootScreen";
import BlankBox from "../components/BlankBox";


class SellersTermsScreen extends RootScreen {


  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'SellersTermsScreen'
    });

    this.state = {
      loggedIn: false,
      admin: false,
      user: null
    }

  }

  document() {
    return (
      <div className='legal'>



        <div className='text-center'>
          <div className="font-weight-bold font-150">
            EPIQUEUE, INC SELLERS POLICY
          </div>
          (Updated on June 1, 2019)
        </div>
          <p/>

          Welcome to www.epiqueue.com!  These Terms of Use describe the terms and conditions applicable to your access and use of the websites, mobile sites, mobile applications and other portals owned, operated, branded or made available by Epiqueue.com relate to the Epiqueue e-commerce platform.  This document is a legally binding agreement between you as the user(s) of the Sites (referred to as 'you', 'your' or 'User' hereinafter) and Epiqueue.com (referred to as 'we', 'our' or 'Epiqueue' hereinafter).
          <p/>

        <strong>1.Application and Acceptance of the Terms</strong>
          <p/>

        1.1 A User's access to and use of the Epiqueue's services, software and products through Epiqueue's services, software and products (collectively referred to as the <strong>'Services'</strong> hereinafter) is subject to the terms and conditions contained in this document. This document and such other rules and policies of Epiqueue are collectively referred to below as the <strong>'Terms'</strong>.  By accessing and use of the Services, you agree to accept and be bound by the Terms.  Please do not access or use the Services or the Sites if you do not accept all Terms.
          <p/>

          1.2 Epiqueue may amend any Terms at any time by posting the relevant amended and restated Terms on the Sites.  By continuing to access or use the Services or the Sites, user agrees that the amended and restated Terms will apply.
          <p/>

          1.3 The Terms may not otherwise be modified except in writing by an authorized officer of Epiqueue
          <p/>

        <strong>2. Sellers' Accounts</strong>
          <p/>

          2.1 In order to sell products and goods on Epiqueue, a seller must create an account on Epiqueue.com.
          <p/>

          2.2. Seller must be 18 years or older to use Epiqueue's Services.  Minors under 18 and at least 13 years of age are only permitted to use Epiqueue's Services through an account owned by a parent or legal guardian with their appropriate permission and under their direct supervision. Children under 13 years are not permitted to use Epiqueue or the Services. Seller is responsible for any and all account activity conducted by a minor on their account.
        <p/>

          2.3 Sellers must be transparent in representing themselves and their goods on Epiqueue.com.  Fake identities, misrepresentation and impersonation of another person or company is prohibited.
        <p/>

          a) A seller should include the names and roles of people involved in the creation of hand-made goods
        <p/>

          b) Sellers should use their own words and photographs (not stock images) to describe items
        <p/>

          2.4 Sellers must use appropriate names. If not using their own full name for the account, then Seller may not use language that is offensive, vulgar, or infringes another's intellectual property rights.
        <p/>

          2.5 Seller is solely responsible for Seller's account and any activity on it.  If an account is shared by multiple people, then the person whose financial information is on the account will ultimately be responsible for all activity. If Seller is registering as a business entity, Seller personally guarantees that Seller has the authority to agree to the Terms on behalf of the business. Epiqueue's accounts are not transferable.
        <p/>

          2.6 Members of the Epiqueue community must be respectful towards other members. Members may not harass other members, publish or post threats of violence against others or promote or encourage others to engage in violence or illegal activity.
        <p/>

          2.7 Sellers may not interfere with another member's business.
        <p/>

          2.8 Sellers may not coordinate pricing with other sellers. Seller may take into account the pricing of other sellers that they observe on the Epiqueue site, but sellers may not contact other sellers to coordinate pricing in any way.
        <p/>

          2.9 Users may not engage in illegal activity or activity that infringes another's intellectual property or encourage others to engage in that type of activity.
        <p/>

          2.10 Epiqueue reserves the right to take action against any account or content that violates this policy. For example, Epiqueue may remove content, temporarily or permanently suspend community posting privileges, or close accounts.
        <p/>

          2.11 These Terms don't create any agency, partnership, joint venture, employment, or franchisee relationship between Seller and Epiqueue.
        <p/>

          2.12 Epiqueue may provide Seller with certain legal information in writing. By using Epiqueue's Services, Seller agrees that Epiqueue may send it information electronically (such as by email) instead of mailing paper copies and that Seller's electronic agreement is the same as its signature on paper.
        <p/>

          2.13 If Seller creates or uses an account on behalf of a business entity, Seller represents that Seller is authorized to act on behalf of such business and bind the business to this User Agreement. Such account is owned and controlled by the business entity. No agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by this User Agreement.
        <p/>

        <strong>3. Sellers' Rights and Responsibilities</strong>
        <p/>

          3.1 Epiqueue expects sellers to provide quality service that results in a high level of buyer satisfaction. To meet these expectations, Sellers must promptly resolve customer issues, ship items on time, specify shipping costs and handling time in the listing and be reasonable in the charges.  Sellers are expected to be helpful, friendly, and professional.
        <p/>

          3.2 Sellers who fail to meet the minimum performance standards may be restricted from selling items on Epiqueue and may be prohibited from opening a new account.
        <p/>

          3.3 Epiqueue reserves the right to decline transactions that are believed to be high risk, fraudulent, or in violation of Epiqueue's Terms of Use.
        <p/>

        <strong>4. Seller's Content</strong>
        <p/>

          4.1. Epiqueue makes no claims to the content that Seller's post on Epiqueue.com. Seller is solely responsible for its own content and represents that it has all necessary rights and that it is not infringing or violating any third party's rights by posting.
        <p/>

          4.2. By posting content to Epiqueue, Seller grants Epiqueue a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use, display, edit, modify, reproduce, distribute, store, and
          prepare derivative works of the content to promote Epiqueue, Seller's shop, or the Services in general, in any formats and through any channels, including across any Epiqueue services, Epiqueue's partners, or third-party website or advertising medium. Seller agrees not to assert any moral rights or rights of publicity against us for using its content. Seller also recognize Epiqueue's legitimate interest in using it, in accordance with the scope of this license, to the extent its content contains any personal information.  These rights allow Epiqueue to display images of content to buyers online, resize photos that are posted to fit in different areas of the website, or promote items in other spaces to promote Epiqueue's business.
        <p/>

          4.3. If content is alleged to infringe another person's intellectual property, Epiqueue will take appropriate action, such as disabling it if we receive proper notice or terminating the Seller's account if the Seller is found to be a repeat infringer. Seller will be notified when such actions occur.
        <p/>

          4.4. Inappropriate, False, or Misleading Content. Seller agrees not to not post any content that is abusive, threatening, defamatory, obscene, vulgar, or otherwise offensive or in violation of Epiqueue's Prohibited Items Policy or any part of Epiqueue's Terms. Seller also agrees not to post any content that is false and misleading or uses the Services in a manner that is fraudulent or deceptive.
        <p/>

          4.5 Seller fees don't purchase exclusive rights to item exposure on Epiqueue, whether on a web page, mobile app, or otherwise. Epiqueue may display third-party advertisements (including links and references thereto) or other content in any part of Epiqueue's Services, in Epiqueue's sole discretion and without consent from, or payment, fee reduction, or other credit to, sellers.
        <p/>

        <strong>5. Seller's Use of Epiqueue's Services</strong>
        <p/>

          5.1 Epiqueue grants Seller a limited, non-exclusive, non-transferable, and revocable license to use Epiqueue's Services'subject to the Terms and the following restrictions.
        <p/>

          5.2. Seller may not use Epiqueue's Services to break the law. Seller agrees that it will not violate any laws in connection with its use of the services. This includes any local, state, federal, and international laws that may apply to Seller.
        <p/>

          5.3. Sellers are responsible for paying all fees that they owe to Epiqueue. Except as set forth below, Seller is also solely responsible for collecting and/or paying any applicable taxes for any purchases or sales that Seller makes through Epiqueue's services.
        <p/>

          5.4 Seller agrees not to crawl, scrape, or spider any page of the Services or to reverse engineer or attempt to obtain the source code of the Services. Seller agrees not to interfere with or try to disrupt Epiqueue's Services.
        <p/>

          5.5 If Epiqueue provides Seller with information about another user, Seller agrees that it will use the information only for the purposes that it is provided to Seller. Seller may not disclose, sell, rent, or distribute a user's information to a third party for purposes unrelated to the Services. Additionally, Seller may not use user information for marketing purposes, via electronic or other means, unless Seller obtain the consent of the specific user to do so.
        <p/>

          5.6 The name "Epiqueue" and the other Epiqueue marks, phrases, logos, and designs that we use in connection with Epiqueue's Services, are trademarks, service marks, or trade dress of Epiqueue in the US.
        <p/>

          5.6. Any unsolicited ideas or other materials Seller submits to Epiqueue (not including Seller's content or items it sells through Epiqueue's Services) are considered non-confidential and non-proprietary to Seller.
          Seller grants us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to Seller.
        <p/>

        <strong>6. Termination</strong>
        <p/>

          6.1.  Seller may terminate its account with Epiqueue at any time. Terminating the account will not affect the availability of some of its content that Seller posted through the Services prior to termination. Seller will still be responsible for fulfillment of any outstanding bills.  Upon termination, seller may lose information associated with the account, including content.
        <p/>

          6.2 Epiqueue may terminate or suspend Seller's account (and any related accounts) and Seller's access to the Services at any time, for any reason, and without advance notice. Seller does not have a contractual or legal right to continue to use Epiqueue's Services. Epiqueue may refuse service to anyone, at any time, for any reason.
        <p/>

          6.3. Epiqueue reserves the right to change, suspend, or discontinue any of the Services at any time, for any reason. Epiqueue will not be liable to Seller for the effect that any changes to the Services may have on Seller, including income or ability to generate revenue through the Services.
        <p/>

          6.4 These terms remain in effect after termination and/or end of services.
        <p/>

        <strong>7. Fees</strong>
        <p/>

          7.1 Seller has the option to select from two Epiqueue fee structures detailed below.<br/>
          7.2 Transaction Fee Options<br/>
          When Seller makes a sale through Epiqueue.com, Seller will be charged a transaction fee of 10% of the price Seller displays for each listing. The transaction fee will not apply to sales tax, Goods and Services Tax ("GST"), or Harmonized Sales Tax ("HST"), unless Seller has included those charges in the listing price. If Seller offers optional personalization for an additional fee, the additional fee for personalization is added to the listing price Seller displays and the total display price is subject to the same transaction fees described in this Section.<br/>
          7.3 Subscription Fee Option<br/>
          Epiqueue sellers may elect to subscribe to Epiqueue Pro to have a sub a subscription fee schedule instead of a transaction fee schedule. The fee for an Epiqueue Pro subscription is $29 USD per month. Epiqueue Pro subscription fees are deducted from Sellers each month.<br/>
          Subscription fees will be added to Seller's payment account on the same day every month. Seller can cancel an Epiqueue Pro subscription anytime. Seller will have access to an Epiqueue Pro subscription until the end of that subscription cycle. After the Epiqueue Pro subscription has ended, Seller's shop will return to Epiqueue Free, and Seller will no longer have a subscription fee added to its payment account.
        <p/>

          Epiqueue Pro subscribers pay no transaction fees for items that they sell.  Pro subscribers gain access to additional queues as well.
        <p/>

          7.4 Epiqueue may change seller fees from time to time by posting the changes on the Epiqueue site 14 days in advance, but with no advance notice required for temporary promotions or any changes that result in the reduction of fees.
        <p/>

          7.5 Seller must have a payment method on file when selling through Epiqueue and pay all fees and applicable taxes associated with Epiqueue's services by the payment due date. If Seller's payment method fails or Seller's account is past due, Epiqueue may collect amounts owed by charging other payment methods on file, retain collection agencies and legal counsel. Epiqueue, or the collection agencies we retain, may also report information about the account to credit bureaus, and as a result, late payments, missed payments, or other defaults on the account may be reflected in Seller's credit report.
          7.6 Epiqueue has engaged third-party service providers to perform many of the services related to payment processing, including card processing, currency exchange, identity verification, fraud analysis, and regulatory compliance.
        <p/>

        <strong>8. Shipping Policy and Seller Protection Benefits</strong>
        <p/>

          8.1. Sellers must provide an accurate "ships from" address and must specify shipping costs and processing times in their listings. Sellers must ship items promptly after they are sold and comply with all local and international shipping and customs regulations. Sellers must ship to the address listed on the Epiqueue receipt and mark the order as shipped when Seller ships it. Sellers must charge an appropriate amount for shipping
        <p/>

          8.2 If the Seller's shop and the order in question satisfy the eligibility requirements, Epiqueue will try to help Seller resolve Non-Delivery cases, Not as Described cases, and chargebacks. For Non-Delivery or Not as Described cases, Seller must respond to Epiqueue's inquiries within 3 calendar days or the time frame noted by Epiqueue in the case. If a chargeback is filed against Seller, the Seller must to respond to any inquiries within 3 calendar days or the time frame indicated by Epiqueue. If Seller hasn't provided the necessary information in time, Epiqueue may not be able to help resolve the dispute. Epiqueue can't guarantee a resolution in the Seller's favor.
        <p/>

        <strong>9. Returns and Cancellations</strong>
        <p/>

          9.1 When an item is returned, to refund the buyer, Seller authorizes Epiqueue to request that Stripe remove the refund amount from Seller's account, place the amount on Seller's invoice, and/or charge the Seller's payment method on file.
        <p/>

          9.2 The cost of return shipping for an item that is not as described is the Seller's responsibility.
        <p/>

          9.3 Seller is not required to accept returns, but if Seller does, Seller must clearly state the criteria under which Seller will accept a return such as the time period that returns are valid in, who pays for return shipping and how the refund will be issued
        <p/>

          9.4 Seller may need to cancel a transaction because the item is broken, Seller made a mistake in the listing, or the item is out of stock. In these cases, Seller must first contact the buyer and let them know that Seller is canceling the transaction and the reason why. If a transaction is cancelled, to refund the buyer, Seller authorizes Epiqueue to remove the refund amount from Seller's account.
        <p/>

        <strong>10. Taxes</strong>
        <p/>

          10.1 Aside from the limited circumstances set out below, Seller is responsible for collecting and paying any taxes associated with using and making sales through Epiqueue's services. Epiqueue will issue 1099-K forms to certain sellers to comply with IRS and state requirements.
        <p/>

          10.2 Internal Revenue Service regulations require that Epiqueue file a Form 1099-K to report unadjusted annual gross sales information for sellers located in the United States or being paid in USD in certain circumstances. To comply with these regulations, sellers who approach 150 transactions in a calendar year, regardless of sales volume and aggregated across his or her Shops, will be required to provide taxpayer-identification information to Epiqueue.
        <p/>

        <strong>11. Listing Conditions:</strong>
        <p/>

          11.1 Seller is responsible for the accuracy and content of the listing and item offered,
        <p/>

          11.2 Content that violates any of Epiqueue's policies may be modified deleted at Epiqueue's discretion.
        <p/>

          11.3 Epiqueue may revise product data associated with listings to supplement, remove, or correct information
        <p/>

          11.4 Seller may not offer different brands or models of an item in a single listing. Sellers may not sell batches of items that would have different item conditions (i.e. one item is New and the other is Re-furbished) in the same listing.
        <p/>

        <strong>12. Prohibited Items Policy</strong>
        <p/>

          12.1The following types of items are prohibited or restricted on Epiqueue:
        <p/>

          a) Alcohol, Tobacco, Drugs, Drug Paraphernalia, and Medical Drugs
        <p/>



          b) Live Animals, Animal Products and Human Remains
        <p/>



          c) Dangerous Items: Hazardous Materials, Recalled Items, and Weapons
        <p/>



          d) Hate Items: Items that Promote, Support, or Glorify Hatred
        <p/>



          e) Illegal Items, Items Promoting Illegal Activity, and Highly Regulated Items
        <p/>



          f) Internationally Regulated Items
        <p/>



          g) Pornography
        <p/>



          h) Violent Items: Items that Promote, Support, or Glorify Violence
        <p/>



          i) No real estate, housing, or motor vehicles
        <p/>



        <strong>13. Content</strong>
        <p/>

          13.1 Epiqueue responds to notices of alleged copyright infringement under the United States Digital Millennium Copyright Act. Epiqueue works to ensure that listed items and content on the site do not infringe upon the copyright, trademark, or certain other intellectual property rights of third parties. When Epiqueue removes or disables access to content in response to a notice, Epiqueue makes a reasonable attempt to contact the allegedly infringing party, provide information about the notice and removal, and, in cases of alleged copyright infringement, provide information about counter notification.
        <p/>

          a)If Epiqueue receives a DMCA counter notification, the removed material may be replaced or access to it may be restored 10 business days after receipt of the counter notice, unless the copyright owner files an action seeking a court order against the allegedly infringing party and informs Epiqueue of this action. Epiqueue sends a copy of the counter notice to the original complaining party.
        <p/>

          b) Epiqueue terminates account privileges of members that are subject to repeat notices of intellectual property infringement in appropriate circumstances and at Epiqueue's discretion.
          13.2 Seller represents and warrants that, for all such content Seller provides, Seller owns or otherwise controls all necessary rights to do so and to meet Seller's obligations under this User Agreement. Seller represents and warrants that such content is accurate. Seller represents and warrants that use of any such content (including derivative works) by Epiqueue, its users, or others in contract with it, and in compliance with this User Agreement, does not and will not infringe any Intellectual Property Rights of any third party. Epiqueue takes no responsibility and assumes no liability for any content provided by Seller or any third party.
        <p/>

          13.3 The name "Epiqueue" and other Epiqueue marks, logos, designs, and phrases that we use in connection with Epiqueue's Services are trademarks, service marks, or trade dress of Epiqueue in the U.S. They may not be used without the express written prior permission of Epiqueue.
        <p/>

        <strong>14. Disclaimer of Warranties; Limitation of Liability</strong>
        <p/>

          14.1 Epiqueue cannot guarantee the continuous operation of or access to these services, but will try to keep its services safe, secure, and functioning properly.
        <p/>

          14.2 Seller agrees that Seller is making use of Epiqueue's services at its own risk, and that services are being provided to Seller on an "AS IS" and "AS AVAILABLE" basis. Accordingly, to the extent permitted by applicable law, Epiqueue excludes all express or implied warranties, terms and conditions including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
        <p/>

          14.3 Epiqueue does not guarantee that: (i) the Services will be secure or available at any particular time or location; (ii) any defects or errors will be corrected; (iii) the Services will be free of viruses or other harmful materials; or (iv) the results of using the Services will meet expectations. Sellers use the Services solely at their own risk. Some jurisdictions do not allow limitations on implied warranties, so the above limitations may not apply to Seller.
        <p/>

          14.4 In addition, to the extent permitted by applicable law, Epiqueue is not liable, and Seller agrees not to hold Epiqueue responsible, for any damages or losses (including, but not limited to, loss of money, goodwill or reputation, profits, other intangible losses, or any special, indirect, or consequential damages) resulting directly or indirectly from:
        <p/>

          a) the content Seller provides (directly or indirectly) using the Services;
        <p/>



          b) Seller's use of or Seller's inability to use Epiqueue's Services;
        <p/>



          c) delays or disruptions in Epiqueue's Services;
        <p/>



          d) viruses or other malicious software obtained by accessing or linking to Epiqueue's Services;
        <p/>

          e) glitches, bugs, errors, or inaccuracies of any kind in Epiqueue's Services;
        <p/>

          f) damage to Seller's hardware device from the use of any Epiqueue's Service;
        <p/>

          g) the content, actions, or inactions of third parties, including items listed using Epiqueue's Services or the destruction of allegedly fake items;
        <p/>

          h) a suspension or other action taken with respect to Seller's account for breach of this policy;
        <p/>

          i) the duration or manner in which Seller's listings appear in search results as set out in the Listing Conditions Section above; or
        <p/>

          j) Seller's need to modify practices, content, or behavior, or Seller's loss of or inability to do business, as a result of changes to this User Agreement or Epiqueue's policies.
        <p/>

          14.5 Regardless of the previous paragraphs, if Epiqueue is found to be liable, Epiqueue's liability to Seller or to any third party is limited to the greater of (a) any amounts due up to the price the item sold (including any applicable sales tax) and its original shipping costs, or (b) the amount of fees in dispute not to exceed the total fees.
        <p/>

        <strong>15. Nondiscrimination</strong>
        <p/>

          15.1 Discrimination is not allowed on Epiqueue. Examples of prohibited behavior include, but are not limited to:
        <p/>

          a) Refusal of service based on membership in one or more protected group
        <p/>

          b) Derogatory or demeaning remarks against protected groups listed above
        <p/>

          c) Racial slurs
        <p/>

          d) Posts that support or glorify hate groups and their members
        <p/>

        <strong>16. Indemnity</strong>
          <p/>

          16.1 Seller will indemnify and hold Epiqueue (including its affiliates and subsidiaries, as well as its and their respective officers, directors, employees, agents) harmless from any claim or demand, including reasonable legal fees, made by any third party due to or arising out of Seller's breach of this User Agreement, Seller's improper use of Epiqueue's Services or Seller's breach of any law or the rights of a third party.
          <p/>

          16.2 Epiqueue reserves the right to handle Epiqueue's legal defense however we see fit, even if Seller is indemnifying us, in which case Seller agree to cooperate with us so we can execute Epiqueue's strategy.
          <p/>

        <strong>17. Legal Disputes</strong>
          <p/>

          17.1 Seller and Epiqueue agree that any claim or dispute at law or equity that has arisen, or may arise, between Seller and Epiqueue (including any claim or dispute between Seller and a third-party agent of Epiqueue) that relates in any way to or arises out of this Sellers Policy, Seller's use of or access to the services, the actions of Epiqueue or its agents, or any products or services sold or purchased through the services, will be resolved in accordance with the provisions set forth in this section.
          <p/>

          17.2 Seller agrees that, except to the extent inconsistent with or preempted by federal law, legal disputes between Seller and Epiqueue will be decided as described in the Dispute Resolution Section of Epiqueue's Terms of Use.
          <p/>

          17.3 Except as otherwise provided in this Policy, if any provision of this Policy is held to be invalid, void or for any reason unenforceable, such provision shall be struck out and shall not affect the validity and enforceability of the remaining provisions.
          <p/>

        <strong>18. Miscellaneous</strong>
          <p/>

          18.1 Headings are for reference purposes only and do not limit the scope or extent of such Section.
          <p/>

          18.2 Epiqueue's failure to act with respect to a breach by Seller or others does not waive Epiqueue's right to act with respect to subsequent or similar breaches. Epiqueue does not guarantee Epiqueue will take action against all breaches of this User Agreement.
          <p/>

          18.3 Epiqueue may amend this User Agreement at any time by posting the amended terms on Epiqueue.com Epiqueue's right to amend the User Agreement includes the right to modify, add to, or remove terms in the User Agreement. Epiqueue will provide user 10 days' notice by posting the amended terms. Additionally, Epiqueue will notify users by email. User's continued access or use of Epiqueue's Services constitutes Seller's acceptance of the amended terms.  These terms may not otherwise be amended except through mutual agreement by Seller and an Epiqueue representative who intends to amend this User Agreement and is duly authorized to agree to such an amendment.
          <p/>
          18.4 These terms and all terms and polices posted through Epiqueue's Services set forth the entire understanding and agreement between Seller and Epiqueue and supersede all prior understandings and agreements of the parties.
          <p/>
          18.5 The following Sections survive any termination of this User Agreement: Fees, Content, Disclaimer of Warranties and Limitation of Liability, Indemnity.


      </div>
    );
  }

renderContent() {
  return (

    <>
      <BlankBox title={"Sellers Policy"} contentCallback={this.document} backgroundColor={'bg-white'}/>
    </>
  );
}

}

export default SellersTermsScreen;