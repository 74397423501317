import React, {} from 'react';
import {withRouter, Redirect} from "react-router-dom";
import FaqHelp from "../components/help/FaqHelp";
import AccountHelp from "../components/help/AccountHelp";
import QueueHelp from "../components/help/QueueHelp";
import {Logger} from "aws-amplify";
import Breadcrumb from "../components/Breadcrumb";
import RootScreen from "./RootScreen";
import ContactUs from "../components/help/ContactUs";
import ManagingQueues from "../components/help/ManagingQueues";
import CreatingAQueue from "../components/help/CreatingAQueue";
import CreatingAProduct from "../components/help/CreatingAProduct";
import SellerGetStarted from "../components/help/SellerGetStarted";

const logger = new Logger('HelpScreen');

class HelpScreen extends RootScreen {

  static HELP_COMPONENTS = {
    'faq': FaqHelp,
    'account-help': AccountHelp,
    'how-queues-work': QueueHelp,
    'managing-queues': ManagingQueues,
    'seller-get-started': SellerGetStarted,
    'creating-a-queue': CreatingAQueue,
    'creating-a-product': CreatingAProduct,
    'contact-us': ContactUs,
  }


  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'HelpScreen'
    });

    const { match } = this.props;

    this.state = {
      id: (match.params.id) ? match.params.id : null,
    }
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }

    if (prevProps.match.params.id !== this.props.match.params.id) {
      logger.debug("new id: " + this.props.match.params.id);
      if (this.props.match.params.id === undefined) {
        this.setState({
          id: null,
        });
      } else {
        this.setState({
          id: this.props.match.params.id,
        });
      }
    }
  }


  renderIndex() {
    return (
      <h2>
        This is the Help Index screen
      </h2>
    );
  }

  renderHelp() {

    if (this.state.id === null || this.state.id === 'undefined' || this.state.id === '') {
      return this.renderIndex();
    }

    if (HelpScreen.HELP_COMPONENTS[this.state.id] === undefined) {
      return (
        <h2>
          Unable to to find that help page ({this.state.id})
        </h2>
      );
    }

    let HelpComponent = HelpScreen.HELP_COMPONENTS[this.state.id];
    return <HelpComponent {...this.props} />;
  }

  renderContent() {
      if (this.state.id && (HelpScreen.HELP_COMPONENTS[this.state.id] === undefined)) {
        return (
          <Redirect to="/help"/>
        );
      }

      let crumbs = null;
      if (this.state.id) {
        crumbs = [
          {link: "/", label: "Home"},
          {link: "/help", label: "Help"},
          {link: null, label: HelpScreen.HELP_COMPONENTS[this.state.id].TITLE}
        ];
      } else {
        crumbs = [
          {link: "/", label: "Home"},
          {link: null, label: "Help"},
        ]
      }

    return (
      <>
        <Breadcrumb crumbs={crumbs}/>

        {this.renderHelp()}
        </>
    );
  }

}

export default withRouter(HelpScreen);