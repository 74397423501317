import React from 'react';
import {API} from "aws-amplify";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Loading from "../../components/Loading";
import RootScreen from "../RootScreen";
import Product from "../../models/Product";
import ScrollingProductList from "../../components/ScrollingProductList";
import BackLink from "../../components/BackLink";

//import "../../css/OrdersScreen.css";

//const logger = new Logger('ProductListScreen');

class ProductListScreen extends RootScreen {

  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'ProductListScreen'
    });

    this.state = {
      errorMessage: null,
      products: [],
      productsLoaded: false,
    }

  }


  componentDidMount() {
    if (this.props.loggedInUser) {
      this.loadProducts();
    }
  }



  loadProducts = async () => {
    let apiName = 'epiqueueapi';
    let path = '/dashboard/products';
    API.get(apiName, path).then((response) => {

      if (response.success) {
        let products = [];
        for (var i in response.products) {
          products.push(new Product(response.products[i]));
        }
        this.setState({
          products: products,
          productsLoaded: true,
        });
      } else {
        this.setState({
          products: [],
          errorMessage: response.errorMessage,
          productsLoaded: true,
        });
      }
    }).catch(error => {
      this.setState({
        errorMessage: "Exception:"  + error.message,
        productsLoaded: true,
      });
      console.log(error.message);
    });

  };






  renderLoading() {
    return (
      <Loading/>
    );
  }

  renderProductx = () => {
    return this.state.products.map((item, idx) => {
      return (
        <div key={idx} className="bg-white border mb-1">
          {JSON.stringify(item)}
        </div>
      )
    });
  }

  renderProduct = () => {
    return <ScrollingProductList shape={'vertical'} products={this.state.products} /> ;
  }

  renderSeller() {
    return (
      <div>
        <BackLink link="/dashboard" text="Back to Dashboard" />

        <div className="alert alert-info">Under development</div>
        {this.renderProduct()}
      </div>
    );
  }


  renderNotSeller() {

      if (!this.props.loggedInUser) {
        return null;
      }

      if (this.props.loggedInUser.is_seller) {
        return null;
      }

      return(
        <>
          <div className="alert alert-danger">
            You must be a seller to view this page
          </div>

        </>
      );

  }

    renderNotLoggedIn() {
          return(
            <div className="alert alert-danger">
              You must be logged in to view this page
            </div>
          );
    }


    renderBusy() {
      return(
        <Loading/>
      );
    }

    renderContent() {

      if (!this.props.loggedInUser) {
        return this.renderNotLoggedIn();
      }

      let {productsLoaded} = this.state;

      if (!productsLoaded) {
        return this.renderBusy();
      }

      return (
        <>
          { (!this.props.loggedInUser.is_seller) ?
            this.renderNotSeller()
            : this.renderSeller()
          }
        </>
    );
  }

}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
)(ProductListScreen));
