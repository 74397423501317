import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import {Logger} from "aws-amplify";
import Queue from "../models/Queue";

import "../css/QueuePic.css"
import ImagePlaceholder from "../img/image-placeholder.png";

// const logger = new Logger('ProfilePic');

class QueuePic extends Component {

  render() {

    let {queue} = this.props;

    if (!queue) {
      return (
        <img className="queue-pic-image" src={ImagePlaceholder} width={this.props.size + 'px'} height={this.props.size + 'px'} alt={"Placeholder"}/>
      );
    }

    if (!queue.primary_image) {
      return (
        <img className="queue-pic-image" src={ImagePlaceholder} width={this.props.size + 'px'} height={this.props.size + 'px'} alt={"Placeholder"}/>
      );
    }

    if (queue.images === undefined && queue.primary_image === undefined) {
      return (
        <div className="bg-danger" width={this.props.size + 'px'} height={this.props.size + 'px'}>x</div>
      );
    }

    return (
        <img className={ this.props.cover ? "queue-pic-image-cover" : "queue-pic-image"} width={this.props.size + 'px'} height={this.props.size + 'px'} src={"https://cdn.epiqueue.com/public/" + queue.primary_image.url} alt={"primary"}/>
    );
  }
}

QueuePic.defaultProps = {
  size: 40,
  cover: false,
};

QueuePic.propTypes = {
  queue: PropTypes.instanceOf(Queue).isRequired,
  size: PropTypes.number,
  cover: PropTypes.bool
};

export default QueuePic;

