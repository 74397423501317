import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CurrencyInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: (this.props.value / 100).toFixed(2),
      displayValue: (this.props.value / 100).toFixed(2),
      sendBack: this.props.value
    }
    this.handlePriceChange = this.handlePriceChange.bind(this);
  }


  keyFilter = (e) => {
    //console.log(e.key);

    let hasDecimal = e.target.value.indexOf(".") !== -1;
    if (hasDecimal && e.key === '.') {
      e.preventDefault();
    }


    // if (! e.key.match(/[0-9.\.]/)) {
    //   e.preventDefault();
    // }

  }

  handlePriceChange(event) {


    const regex = /^[0-9.\b]+$/;
    let value = event.target.value;

    //if more than three digits past, move the decimal
    let decimalLocation = event.target.value.indexOf(".");
    let stringLength  = event.target.value.length;
    if (decimalLocation !== -1 ) {
      if (stringLength - decimalLocation > 3) {
        let splitString = value.split('.');
        console.log(JSON.stringify(splitString));
        value = splitString;
        console.log(value);
        console.log(splitString.join(''));
        value = splitString[0] + '.' + splitString[splitString.length-1].substring(0, 2);


        //value = Number(splitString.join('') / 100).toFixed(2);
      }
    }




    if (value === '' || regex.test(value)) {

      let displayValue = (Number(value)).toFixed(2)

      this.setState({
        value: value,
        displayValue: displayValue,
        sendBack: Number(displayValue * 100).toFixed(0),
      })
    }


    //
    // console.log(e.target.value);
    // let newValue = e.target.value.replace(/[a-zA-z]/,'');
    // console.log(newValue);
    //
    // let displayValue = (Number(newValue)).toFixed(2)
    //
    // this.setState({
    //   value: newValue,
    //   displayValue: displayValue,
    //   sendBack: displayValue * 100
    // });
    //
    if (this.props.onChange) {
      this.props.onChange(Number(this.state.sendBack));
    }
  }

  render() {
      return (
        <div className="input-group">
          <div className="input-group-prepend">
          <span className="input-group-text purple lighten-3">$</span>
          </div>
          <input type="text"
                 className="form-control"
                 id="pricePerItem"
                 value={this.state.value}
                 onChange={this.handlePriceChange}
                 onBlur={this.handlePriceChange}
                 aria-describedby="pricePerItemHelp"
                 placeholder="price"
                 required
                 onKeyPress={this.keyFilter}
          />
        </div>

      )
  };
}

CurrencyInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func
};

export default CurrencyInput;

