import React, { Component } from 'react';
import PropTypes from 'prop-types';
import User from "../models/User";
import {Logger} from "aws-amplify";

const logger = new Logger('ProfilePic');

class ProfilePic extends Component {

  constructor(props) {
    super(props);

    this.state = {
      profilePic: null,
      renderedUrl: null,
      loadingPic: true,
    }
  }

  componentDidMount() {
    this._refreshProfilePic();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this._refreshProfilePic();
    }
  }


  _getProfilePicUrl  = () => {
    let {user} = this.props;
    if (user == null) {
      this.setState({
        profilePic: null,
        renderedUrl: null,
        loadingPic: false,
      });
      return;
    }

    if (user.profile_url) {
      let url = 'https://cdn.epiqueue.com/protected/';
      url += user.identityId + "/";
      url += user.profile_url;

      this.setState({
        profilePic: url,
        renderedUrl: user.profile_url,
        loadingPic: false,
      });

    } else {
      logger.debug("no profile_url");
      this.setState({
        profilePic: null,
        renderedUrl: null,
        loadingPic: false,
      });
    }

  }

  _refreshProfilePic = () => {
    this._getProfilePicUrl();
  };


  render() {

    if (this.state.loadingPic) {
      return (
        <div width={this.props.size + 'px'} height={this.props.size + 'px'}></div>
      );
    }

    if (this.props.user) {
      if (this.state.profilePic) {
        return (
          <img className="d-inline rounded-circle profilepic" src={this.state.profilePic} width={this.props.size + 'px'}
               height={this.props.size + 'px'} alt={'Profile Pic'}/>
        );
      }
    }

    return (
      <div className="... d-inline-flex rounded-circle empyprofilepic justify-content-center" style={{ width: this.props.size + 'px', height: this.props.size + 'px', fontSize: ((this.props.size / 3)*2) + 'px'}}  >
        <i className="fas fa-user fa-fw fa-1x align-self-center "/>
      </div>
    )
  }
}

ProfilePic.defaultProps = {
  size: 40,
};

ProfilePic.propTypes = {
  user: PropTypes.instanceOf(User),
  size: PropTypes.number,
};

export default ProfilePic;

