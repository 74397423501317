import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Queue from "../models/Queue";
import QueueCard from "./QueueCard";
import {withRouter, Link} from "react-router-dom";
import "../css/ScrollingQueueList.css"
import Pager from "./Pager";
// import {Logger} from "aws-amplify";

// const logger = new Logger('ScrollingQueueList');

class ScrollingQueueList extends Component {


  static QUEUES_PER_PAGE = 4;

  constructor(props) {
    super(props);

    let pages = 1;
    if (this.props.queues && this.props.queues.length > 1) {
      pages =  Math.ceil(this.props.queues.length / ScrollingQueueList.QUEUES_PER_PAGE)
    }

    this.state = {
      pages: pages,
      page: 1,
      queuesPerPage: 4,
    }
  }

  // componentDidMount() {
  // }


  pagerCallback = (page) => {

    if (page === this.state.page) {
      return;
    }

    this.setState({
      page: page,
    });

  };

  createQueue = (e) => {
    e.stopPropagation();

    this.props.createFunc();
  };

  renderQueues() {
    if (!this.props.queues || this.props.queues.length === 0) {
      return (
        <div className="scrollingqueuelist-no-queues">
          <div className="" style={{fontSize:"medium"}}>{this.props.noResultsText}</div>
        </div>
      );
    }

    let startingIdx = (this.state.page-1) * ScrollingQueueList.QUEUES_PER_PAGE;
    let endingIndx = startingIdx +  ScrollingQueueList.QUEUES_PER_PAGE;
    let queuesDisplayed = this.props.queues.filter( (item, idx) => {
      if (idx >= startingIdx && idx < endingIndx) {
        return true;
      }
      return false;
    });

    return queuesDisplayed.map((item, idx) => {
      return (
        <div className="bg-light scrollingqueuelist-item"  key={item.id}>
          <QueueCard queue={item}/>
        </div>
      );
    });
  }


  render() {
    return (
      <div className="d-flex flex-column w-100 scrollingqueuelist">
        <div className="d-flex flex-wrap justify-content-start justify-content-md-start justify-content-lg-start mb-2">
          <div className="d-flex justify-content-between flex-grow-1 scrollingqueuelist-header">
            <div className="d-flex flex-row">
              <div className="scrollingqueuelist-title">{this.props.title}</div>
              <div className="scrollingqueuelist-seemore">
              { this.props.seemore ? <div className="ml-5"><Link className="epiqueue-link" to={this.props.seemore['url']}>{this.props.seemore['label']}</Link></div> : null }
              </div>
            </div>
            {this.props.createFunc ?
            <div className="scrollingqueuelist-create-link">
              <div className="btn btn-outline-primary"
                   data-toggle = "modal"
                   data-target = {"#create-queue-modal"}
                   ><i className="fas fa-plus-square"></i> Create New Queue</div>
            </div>
              : null }
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center justify-content-md-start justify-content-lg-start mt-1">
          {this.renderQueues()}
        </div>
        <div className="d-flex flex-row justify-content-center justify-content-md-end align-items-center ">
          <Pager total={this.state.pages} selected={this.state.page} callback={this.pagerCallback}/>
        </div>
      </div>
    );
  };
}

ScrollingQueueList.defaultProps = {
  noResultsText: "No Queues available",
};

ScrollingQueueList.propTypes = {
  title: PropTypes.string,
  queues: PropTypes.arrayOf(PropTypes.instanceOf(Queue)).isRequired,
  seemore: PropTypes.object,
  createFunc: PropTypes.func,
  noResultsText: PropTypes.string
};

export default withRouter(ScrollingQueueList);

