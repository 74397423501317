import React, { Component } from 'react';
// import logo from '../img/flying-e-logo-white-transparent.png'
import {API, Auth, Logger} from "aws-amplify";
import {withRouter, Link, NavLink} from "react-router-dom";
import {connect} from "react-redux";
import { setLoggedInUser, setPendingCartItemsCount, setUnreadMessagesCount } from '../redux/actions'
import ProfilePic from "./ProfilePic";

import '../css/nav.css';
import CategoryBar from "./CategoryBar";

const logger = new Logger('Nav');

class Nav extends Component {


  constructor(props) {
    super(props);

    this.state = {
      lastMessageCountTime: 0,
      checkingNotifications: false,
    };

    this.collapsableMenu = React.createRef();

    this.pushScreen = this.pushScreen.bind(this);
    this.replaceScreen = this.replaceScreen.bind(this);
  }


  componentDidMount() {
    let user = this.props.loggedInUser;

    // if (user) {
    //   this.messageCount();
    // } else {
    //   this.setState({
    //     unreadMessages: 0,
    //   });
    // }
  }

  collapseMenu() {
    this.collapsableMenu.current.classList.remove('show');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
      this.messageCount();
      this.collapseMenu();
    }

    if (this.props.loggedInUser !== prevProps.loggedInUser) {
      logger.debug("loggedInUser changed")
      if (this.props.loggedInUser === null) {
        this.setState({
          lastMessageCountTime: Date.now(),
        });
      } else {
        this.messageCount();
      }
    } else {
      logger.debug("loggedInUser didn't changed")
    }

    if (this.props.loggedInUser && prevProps.loggedInUser) {
      if (this.props.loggedInUser.is_seller !== prevProps.loggedInUser.is_seller) {
        logger.debug("seller changed")
        this.forceUpdate();
      }
      logger.debug("this.props.loggedInUser.is_seller: " + this.props.loggedInUser.is_seller)
      logger.debug("prevProps.loggedInUser.is_seller: " + prevProps.loggedInUser.is_seller)
    } else {
    }
  }

  messageCount() {
    return;

    // let {loggedInUser} = this.props;
    //
    // if (!loggedInUser) {
    //   if (this.props.unreadMessagesCount !== 0) {
    //     this.props.setUnreadMessagesCount(0);
    //     this.props.setPendingCartItemsCount(0);
    //     this.setState({
    //       lastMessageCountTime: Date.now(),
    //     })
    //   }
    //   return;
    // }
    //
    // // don't check if we are already checking
    // if (this.state.checkingNotifications) {
    //   return;
    // }
    //
    //
    // let newDate =Date.now();
    // let oldDate =this.state.lastMessageCountTime;
    //
    // let diff = Math.round((newDate - oldDate) / 1000) ;
    // if (diff < 30) {
    //   return;
    // }
    //
    //
    // let apiName = 'epiqueueapi';
    // let path = '/user/notifications';
    // API.get(apiName, path).then((response) => {
    //
    //   if (response.success && response.notifications.messages) {
    //     this.props.setUnreadMessagesCount(response.notifications.messages.unread);
    //   }
    //   if (response.success && response.notifications.cart) {
    //     this.props.setPendingCartItemsCount(response.notifications.cart.pending);
    //   }
    //
    // }).catch(error => {
    //   logger.error(error.message)
    // });
    //
    // this.setState({
    //   checkingNotifications: false,
    //   lastMessageCountTime: Date.now(),
    // });

  }



  logout = async () => {
    await Auth.signOut()
      .then(data => {
        this.props.setLoggedInUser(null);
        this.props.setPendingCartItemsCount(0);
        this.props.setUnreadMessagesCount(0);
        this.props.history.replace('/');
      })
      .catch(err => logger.debug(err));
  }

  pushScreen = (screen) => {
    this.props.history.push(screen);
  }

  replaceScreen = (screen) => {
    this.props.history.replace(screen);
  }

  goHome = () => {
    //todo: check how long its been and reload if necessary
    this.pushScreen('/');
  }




  renderLoading() {
    return (
      <nav className="navbar navbar-fixed-top navbar-expand-lg epiqueuenav">
        <div className="container justify-content-center">
          <div className="navbar-brand epiqueue-nav-link mb-1" onClick={ () => this.goHome() }>
            <span className="epiqueuenav-title">Epiqueue</span>
          </div>
        </div>
      </nav>
    );
  }


  render() {
    let user = this.props.loggedInUser;
    let loggedIn =  this.props.loggedInUser != null;

    if (this.props.loading) {
      return this.renderLoading();
    }


    return (
      <>
    <nav className="navbar navbar-fixed-top navbar-expand-lg  epiqueuenav">
      <div className="container">
        <div className="navbar-brand epiqueue-nav-link mb-1" onClick={ () => this.goHome() }>
            <span className="epiqueuenav-title">Epiqueue</span>
        </div>
        {loggedIn ?
          <button className="navbar-toggler navbar-dark" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          : <div className="navbar-toggler nav-item nav-link epiqueue-nav-link" onClick={ () => this.pushScreen('/login')} >Login</div>
        }

        <div className={"navbar-collapse collapse"} id="navbarSupportedContent" ref={this.collapsableMenu}>
          <div className="navbar-nav mr-auto">
            {loggedIn ?
              <>
              <NavLink className="nav-item nav-link epiqueue-nav-link" to={'/orders'} activeClassName="epiqueue-nav-link-selected">Orders</NavLink>
              </>
              : null
            }

            {loggedIn ?
            <li className="nav-item dropdown">
              <div className="nav-link dropdown-toggle epiqueue-nav-link" id="navbarDropdown" role="button" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                Help
              </div>
              <div className="dropdown-menu epiqueue-dropdown-background" aria-labelledby="navbarDropdown">
                <Link className="dropdown-item epiqueue-nav-dropdown-link" to={'/help/how-queues-work'}>How Queues Work</Link>
                <Link className="dropdown-item epiqueue-nav-dropdown-link" to={'/help/faq'}>FAQs</Link>
                {user.is_seller ?
                  <>
                  <Link className="dropdown-item epiqueue-nav-dropdown-link" to={'/help/seller-get-started'}>Getting Started As A Seller</Link>
                  <Link className="dropdown-item epiqueue-nav-dropdown-link" to={'/help/creating-a-queue'}>Creating A Queue</Link>
                  <Link className="dropdown-item epiqueue-nav-dropdown-link" to={'/help/creating-a-product'}>Creating A Product</Link>
                  </>
                  : null }
                <Link className="dropdown-item epiqueue-nav-dropdown-link" to={'/help/contact-us'}>Contact Us</Link>
              </div>
            </li>
              : null }

          </div>




        <div className="navbar-nav ml-auto">

            {loggedIn ?
              <>
                {user.is_seller ?
                  <NavLink className="align-self-lg-center nav-item nav-link epiqueue-nav-link" to={'/dashboard'} activeClassName="epiqueue-nav-link-selected">Seller's Dashboard</NavLink>
                  :
                  null
                }

                <li className="nav-item dropdown">
                  <div className="nav-link dropdown-toggle epiqueue-nav-link" id="navbarDropdown" role="button" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false">
                    <ProfilePic user={user} size={40} />
                  </div>
                  <div className="dropdown dropdown-menu dropdown-menu-md-right epiqueue-dropdown-background" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item epiqueue-nav-dropdown-link" to={'/profile'}><i className="fas fa-user fa-fw"/> Profile</Link>
                    <Link className="dropdown-item epiqueue-nav-dropdown-link" to={'/settings'}><i className="far fa-tools fa-fw"/> Settings</Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item epiqueue-nav-dropdown-link" to={'/logout'} onClick={this.logout}><i className="fas fa-sign-out-alt fa-fw"></i> Logout</Link>
                  </div>
                </li>
              </>
              :
              <>
              <div className="nav-item nav-link epiqueue-nav-link" onClick={ () => this.pushScreen('/login')} >Login</div>
              </>
            }

          </div>
        </div>
      </div>
    </nav>
    </>
  );
  }
}



const mapStateToProps = (state,  ownProps) => {
  return {
    loggedInUser: state.users.loggedInUser,
    unreadMessagesCount: state.notifications.unreadMessagesCount,
    pendingCartItemsCount: state.notifications.pendingCartItemsCount,
  }
};

export default withRouter(connect(
    mapStateToProps,
    {setLoggedInUser, setPendingCartItemsCount, setUnreadMessagesCount}
  )(Nav));

