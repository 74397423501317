import React, {} from 'react';
import RootScreen from "../RootScreen";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {API, Logger} from "aws-amplify";
import ErrorMessage from "../../components/ErrorMessage";
import Sale from "../../models/Sale";
import emptycart from "../../img/empty-cart.png";
import StripeCreditCardForm from "../../components/StripeCreditCardForm";
import {Elements, StripeProvider} from "react-stripe-elements";

import '../../css/CheckoutScreen.css'
import Util from "../../Util";

const logger = new Logger('CartScreen');

class CheckoutScreen extends RootScreen {


  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'CheckoutScreen'
    });

    const { match } = this.props;

    this.state = {
      identifier: match.params.identifier,
      loggedIn: false,
      errorMessage: null,
      successMessage: null,
      sale: null,
      loading: true,
      total: 0,
      stripe: null
    };

    this.onSubmit = this.onSubmit.bind(this);

  }

  componentDidMount = async() => {


    try {
      if (window.Stripe) {
        this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_TOKEN)});
      } else {
        document.querySelector('#stripe-js').addEventListener('load', () => {
          // Create Stripe instance once Stripe.js loads
          this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_TOKEN)});
        });
      }

      let apiName = 'epiqueueapi';
      let path = '/product/sale/' + this.state.identifier;

      let response = await API.get(apiName, path);


      logger.debug("get response: " + JSON.stringify(response));

      let sale = new Sale(response.sale);
      let total = sale.amount + sale.shipping_amount + sale.tax_amount;


      logger.debug("total: " + total);

      this.setState({
        sale: sale,
        errorMessage: null,
        loading: false,
        total: total
      })

    } catch(error) {
      this.setState({
        errorMessage: error.message,
        sale: null,
        loading: false,
        total: 0
      })

    }

  }

  onSubmit(result) {
    if (!result.success) {
      if (result.errorMessage) {
        this.setState({
          errorMessage: result.errorMessage
        });
      }
    }

    logger.debug(JSON.stringify(result))
  }


  renderCart() {

    let totalCharge = this.state.total;
    logger.debug(JSON.stringify(totalCharge));


    return (
    <>
      <div className="d-flex w-100">
        <table className="table checkoutscreen-order-table">
          <thead>
          <tr className="bg-white ">
            <th scope="col">Product</th>
            <th scope="col">Seller</th>
            <th className="text-right" scope="col">Amount</th>
            <th className="text-right" scope="col">Tax</th>
            <th className="text-right" scope="col">shipping amount</th>
            <th className="text-right" scope="col">Total</th>
          </tr>
          </thead>
          <tbody className="">
          {this.renderSales()}
          </tbody>
        </table>
      </div>

      {this.renderStripeForm(totalCharge)}
    </>
    );
  }

  renderNoCart() {
    return (
      <div className="d-flex flex-column justify-content-center">
        <div className="align-self-center text-center">
          <h3>Woah, this place is empty!</h3>
          <h4>Don't worry, you will get to the front of a queue soon</h4>
        </div>
        <img className="center-fit" src={emptycart} alt={"empty cart"}/>
      </div>
    )
  }

  renderSales() {
    let {sale} = this.state;

    if (sale !== null) {
      return (
      <tr className="checkoutscreen-order-row" key={sale.identifier}>
        <td><Link to={'/product/' + sale.product_id}>{sale.product.name}</Link></td>
        <td>{JSON.stringify(sale.product.queue_id)}</td>
        <td align="right">{Util.formatMoney(sale.amount)}</td>
        <td align="right">{Util.formatMoney(sale.tax_amount)}</td>
        <td align="right">{Util.formatMoney(sale.shipping_amount)}</td>
        <td align="right">{Util.formatMoney(sale.amount + sale.shipping_amount + sale.tax_amount)}</td>
      </tr>
      );
    } else {
      return (
        this.renderNoCart()
      )
    }
  }

  renderStripeForm(totalCharge) {
    let {sale} = this.state;
    let {transactions} = sale;

    let completedTransactions = transactions.filter(item => {
      return item.status === 'success'
    });

    if (completedTransactions && completedTransactions.length > 0) {
      let transaction = completedTransactions[0];
      return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3>This sale has been completed</h3>
          <table className="table">
            <tbody>
              <tr>
                <td align="right">Transaction ID</td>
                <td>{transaction.identifier}</td>
              </tr>
              <tr>
                <td align="right">Total Charged</td>
                <td>{transaction.getFormattedTotalAmount()}</td>
              </tr>
              <tr>
                <td align="right">Shipping</td>
                <td>
                  {transaction.shipping_name}<br/>
                  {transaction.shipping_line1}<br/>
                  {transaction.shipping_line2 ? transaction.shipping_line2 + <br/> : null}
                  {transaction.shipping_city}, {transaction.shipping_state} {transaction.shipping_zip}<br/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }


    return(


      <div className="d-flex w-100">
        <div className="flex-grow-1">
          <StripeProvider stripe={this.state.stripe}>
            <Elements>
              <StripeCreditCardForm onSubmit={this.onSubmit} loggedInUser={this.props.loggedInUser} totalCharge={totalCharge} sale={this.state.sale}/>
            </Elements>
          </StripeProvider>
        </div>
      </div>
    );
  }

  renderContent() {
    if (this.state.loading) {
      return null;
    }

    let {sale} = this.state;

    return (

      <>
        <div className="epiqueue-faded-background epiqueue-darkblue-border pb-5">

          <ErrorMessage errorMessage={this.state.errorMessage}/>

          {
            (sale === null)
            ? this.renderNoCart()
            : this.renderCart()
          }

        </div>
        <div className="d-flex flex-row justify-content-center bg-white checkoutscreen-footer">
          {sale.product.shipping_description ? sale.product.shipping_description : "Seller has not indicated shipping terms"}
        </div>
      </>

  );
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps
)(CheckoutScreen));
