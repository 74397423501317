import React, {} from 'react';
import {API, Logger} from "aws-amplify";
import RootScreen from "./RootScreen";
import Queue from "../models/Queue";
import ScrollingQueueList from "../components/ScrollingQueueList";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";

const logger = new Logger('JoinedQueuesScreen');

class JoinedQueuesScreen extends RootScreen {


  constructor(props) {
    super(props);

    this.state = {
      loggedInUser: null,
      queues: null,
      queuesLoaded: false,
      errorMessage: null,
    }

  }


  componentDidMount() {
    this.loadQueues();
  }


  loadQueues = async () => {
    if (this.props.loggedInUser === null) {
      this.setState({
        errorMessage: "You are not logged in",
        queuesLoaded: true,
      });
      logger.debug("not logged in")
      return;
    }


    let apiName = 'epiqueueapi';
    let path = '/queue/joined';
    API.get(apiName, path).then((response) => {

      if (response.success) {
        let queues = [];
        for (var i in response.queues) {
          queues.push(new Queue(response.queues[i]));
        }
        this.setState({
          queues: queues,
          queuesLoaded: true,
        });
      } else {
        this.setState({
          queues: [],
          errorMessage: response.errorMessage,
          queuesLoaded: true,
        });
      }
    }).catch(error => {
      this.setState({
        errorMessage: error.message,
        queuesLoaded: true,
      });
    });

  };


  renderContent() {

  if (!this.state.queuesLoaded) {
    return (
      <Loading/>
    );
  }

  return (
    <>
      <ErrorMessage errorMessage={this.state.errorMessage}/>
      {this.state.queues ?
      <div className="col-12">
          <ScrollingQueueList queues={this.state.queues} title={"Queues You have Joined"} />
      </div>
        : null }
      </>
  );
}

}



const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps
)(JoinedQueuesScreen));

