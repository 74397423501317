import React, { Component } from 'react';
import {Auth, Logger} from "aws-amplify";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ErrorMessage from "../../components/ErrorMessage";

const logger = new Logger('PasswordRequestScreen');

class PasswordRequestScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      emailFieldValue: "",
      code: "",
      password: "",
      passwordCheck: "",
      errorMessage: null,
      successMessage: null,
      awaitingCode: false,
      codeResent: false,
      passwordChanged: false,
    };


  }

  submitForgotPasssword = async () => {
    logger.debug("submit");
    this.setState({
      errorMessage: null,
      successMessage: null,
      passwordChanged: false,
    });



    let email = this.state.emailFieldValue.trim()
    if (email === "") {
      this.setState({
        errorMessage: "You must enter an email address",
      });
      return;
    }


    try {
      let response = await Auth.forgotPassword(email);
      logger.debug(response);
      this.setState({
        awaitingCode: true,
      });
    } catch (error) {
      logger.debug(error);
      this.setState({
        errorMessage: error.message,
      });
    }
  };

  submitChangePassword = async () => {
    this.setState({
      errorMessage: null,
      successMessage: null,
      codeResent: false,
      passwordChanged: false,
    });

    let email = this.state.emailFieldValue.trim();
    let code = this.state.code.trim()
    let password = this.state.password.trim()
    let passwordCheck = this.state.passwordCheck.trim()


    if (email === "") {
      this.setState({
        errorMessage: "You must enter an email address",
      });
      return;
    }


    if (code === "") {
      this.setState({
        errorMessage: "You must enter a code",
      });
      return;
    }
    if (password === "") {
      this.setState({
        errorMessage: "You must enter a password",
      });
      return;
    }

    if (passwordCheck === "") {
      this.setState({
        errorMessage: "You must re-enter your password",
      });
      return;
    }

    if (passwordCheck !== password) {
      this.setState({
        errorMessage: "Both password fields must match",
      });
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      this.setState({
        errorMessage: null,
        successMessage: null,
        codeResent: false,
        passwordChanged: true,
      });


      // logger.debug(response);
    } catch (error) {
      logger.debug(error);
      this.setState({
        errorMessage: error.message,
      });
    }

  };

  resendCode = () => {
    this.setState({
      codeResent: true,
    });

    this.submitForgotPasssword();
  };



  handleEmailChange = (event) => {
    this.setState({emailFieldValue: event.target.value});
  };

  handleCodeChange = (event) => {
    this.setState({code: event.target.value});
  };

  handlePasswordChange = (event) => {
    this.setState({password: event.target.value});
  };

  handlePasswordCheckChange = (event) => {
    this.setState({passwordCheck: event.target.value});
  };

  renderLoggedOut = () => {
    return (
      <>
        <h2>
          Logged in password change request
        </h2>

      </>
    );
  }

  renderEnterEmail = () => {
    return(
      <div className="row justify-content-center" style={{marginTop: 40}}>
        <div className="col-sm-9 col-md-9 col-lg-7 mx-auto">
          <div className="card">
            <div className="card-body">
              <ErrorMessage errorMessage={this.state.errorMessage}/>
              <ErrorMessage errorMessage={this.state.successMessage} type="success"/>

              <h5 className="card-title text-center epiqueue-darkblue p-2 text-light">Forgot Password</h5>


              <div className="form-group row">
                <div className="col-md-8 offset-md-4 epiqueue-darkblue-text">
                  Please enter your email. We will send you a code allowing you to reset your password.
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="email" className="col-md-4 col-form-label text-md-right epiqueue-darkblue-text">Email</label>

                <div className="col-md-6">
                  <input id="email"
                         type="email"
                         className="form-control"
                         name="email"
                         value={this.state.emailNameFieldValue}
                         onChange={this.handleEmailChange}
                         autoComplete="email"
                         required
                         autoFocus/>
                </div>
              </div>


              <div className="form-group row mb-0">
                <div className="col-md-6 offset-md-4">
                  <button className="btn btn-primary epiqueue-darkblue" onClick={this.submitForgotPasssword}>
                    Submit
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };

  renderWaitingForCode = () => {
    return (
      <div className="row justify-content-center" style={{marginTop: 40}}>
        <div className="col-sm-9 col-md-9 col-lg-7 mx-auto">
          <div className="card">
            <div className="card-body">
              <ErrorMessage errorMessage={this.state.errorMessage}/>
              <ErrorMessage errorMessage={this.state.successMessage} type="success"/>
              {this.state.passwordChanged ?
                <div className="alert alert-success">
                  Password Successfully changed. You can now <Link to={"/login"}>Login</Link>
                </div>
                : null}
              <h5 className="card-title text-center p-2 epiqueue-darkblue-text">Forgot Password</h5>


              <div className="form-group row">
                <div className="col-md-8 offset-md-4 epiqueue-darkblue-text">
                  Please check you email and enter the code we sent along with your new password
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="email" className="col-md-4 col-form-label text-md-right epiqueue-darkblue-text">Email</label>

                <div className="col-md-6">
                  <input id="email"
                         type="email"
                         className="form-control"
                         name="email"
                         value={this.state.emailNameFieldValue}
                         onChange={this.handleEmailChange}
                         autoComplete="email"
                         required
                         autoFocus/>
                </div>
              </div>


              <div className="form-group row">
                <label htmlFor="code" className="col-md-4 col-form-label text-md-right epiqueue-darkblue-text">Code</label>

                <div className="col-md-6">
                  <input id="code"
                         type="code"
                         className="form-control"
                         name="code"
                         value={this.state.code}
                         onChange={this.handleCodeChange}
                         autoComplete="code"
                         required
                         autoFocus/>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="password" className="col-md-4 col-form-label text-md-right epiqueue-darkblue-text">Password</label>

                <div className="col-md-6">
                  <input id="password"
                         type="password"
                         className="form-control"
                         name="password"
                         value={this.state.password}
                         onChange={this.handlePasswordChange}
                         autoComplete="password"
                         required
                         autoFocus/>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="passwordCheck" className="col-md-4 col-form-label text-md-right epiqueue-darkblue-text">Re-enter Password</label>

                <div className="col-md-6">
                  <input id="passwordCheck"
                         type="password"
                         className="form-control"
                         name="passwordCheck"
                         value={this.state.passwordCheck}
                         onChange={this.handlePasswordCheckChange}
                         autoComplete="passwordCheck"
                         required
                         autoFocus/>
                </div>
              </div>


              <div className="form-group row mb-0">
                <div className="col-md-6 offset-md-4">
                  <button className="btn btn-primary epiqueue-darkblue" onClick={this.submitChangePassword}>
                    Submit
                  </button>
                </div>
              </div>

              <div className="form-group row mb-0 mt-3">
                <div className="col-12 text-center">
                  <div className="btn btn-link epiqueue-darkblue-text" onClick={this.resendCode}>Resend change password code to email entered above</div>
                  {this.state.codeResent ? <div className="text-danger">Change password code sent</div> : null}
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    )
  };

  render() {
    if (!this.props.loggedInUser) {
      if (this.state.awaitingCode) {
        return this.renderWaitingForCode();
      } else {
        return this.renderEnterEmail();
      }
    } else {
      return this.renderLoggedOut();
    }
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  logger.debug("PasswordRequestScreen.mapStateToProps");
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
)(PasswordRequestScreen));
