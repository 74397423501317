
import React, { Component } from 'react';
import "../../css/HelpScreen.css"

class AccountHelp extends Component {
  static TITLE = "Account Help";

  render() {
    return (
      <>
        <div className="helpscreen-title">{AccountHelp.TITLE}</div>
      </>
    )
  }
}

export default AccountHelp;