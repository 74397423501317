import React, { Component } from 'react';
import {API, Logger} from "aws-amplify";
import PropTypes from 'prop-types';
import Queue from "../models/Queue";
import Product from "../models/Product";
import User from "../models/User";
import {withRouter} from "react-router-dom";

import '../css/SearchBar.css'
import ProfilePic from "./ProfilePic";
import QueuePic from "./QueuePic";
import ProductPic from "./ProductPic";

const logger = new Logger('SearchBar');

class SearchBar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      mostRecentSearchText: '',
      searching: false,
      searchResults: null,
      typingTimeout: 0,
    }
  }

  clearText = () => {
    this.setState({
      searching: false,
      searchResults: null,
      searchText: ''
    });
    window.$('[data-toggle="search-dropdown"]').dropdown('hide');
    if (this.props.updateSearchTextFunc) {
      this.props.updateSearchTextFunc('');
    }
  }


  getReadyToSearch = async (searchText) => {

    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      typingTimeout: setTimeout(function () {
        this.quickSearch(this.state.searchText);
      }.bind(this), 500)
    })
  };


  quickSearch = async (searchText) => {
    if (searchText.trim() === '') {
      this.setState({
        searching: false,
        searchResults: null,
      });
      return;
    }

    this.setState({
      searching: true,
      searchResults: null,
    });


    let apiName = 'epiqueueapi';
    let path = '/discover/search?s=' + searchText;
    try {
        let response = await API.get(apiName, path);
        if (!response.success) {
          this.setState({
            searching: false,
            searchResults: null,
            errorMessage: response.errorMessage,
          });
          return;
      }

      let searchResults = {
        queues: response.queues.map(item => {
          return new Queue(item);
        }),
        products: response.products.map(item => {
          return new Product(item);
        }),
        sellers: response.sellers.map(item => {
          return new User(item);
        }),
      };

      this.setState({
        searching: false,
        searchResults: searchResults,
        errorMessage: null,
      });

    } catch(error) {
      logger.error(error);
      this.setState({
        searching: false,
        searchResults: null,
        errorMessage: error.message,
      });

    }
  }




  // search() {
  //
  //   window.$('[data-toggle="search-dropdown"]').dropdown('hide');
  //
  //   if (this.state.searchText.trim() === '') {
  //     this.setState({
  //       searching: false,
  //       searchResults: null,
  //     });
  //     return;
  //   }
  //
  //   this.setState({
  //     mostRecentSearchText: this.state.searchText,
  //     searching: true,
  //     searchResults: null,
  //   });
  //
  //   if (this.props.startSearchFunc) {
  //     this.props.startSearchFunc();
  //   }
  //
  //
  //   let apiName = 'epiqueueapi';
  //   let path = '/discover/search?s=' + this.state.searchText;
  //   API.get(apiName, path).then(response => {
  //     logger.debug(response);
  //
  //     if (!response.success) {
  //       this.setState({
  //         searching: false,
  //         searchResults: null,
  //         errorMessage: response.errorMessage,
  //       });
  //
  //       if (this.props.searchResultsFunc) {
  //         logger.debug("sending null (!success)");
  //         this.props.searchResultsFunc(null);
  //       }
  //
  //       return;
  //     }
  //
  //     let searchResults = {
  //       queues: response.queues.map(item => {
  //         return new Queue(item);
  //       }),
  //       products: response.products.map(item => {
  //         return new Product(item);
  //       }),
  //       sellers: response.sellers.map(item => {
  //         return new User(item);
  //       }),
  //       searchText: response.searchText,
  //     };
  //
  //     this.setState({
  //       searching: false,
  //       searchResults: searchResults,
  //     });
  //
  //     if (this.props.searchResultsFunc) {
  //       logger.debug("sending redults");
  //       this.props.searchResultsFunc(searchResults);
  //     }
  //
  //   }).catch(error => {
  //     this.setState({
  //       errorMessage: error.message,
  //       searching: false,
  //       searchResults: null,
  //     });
  //
  //     if (this.props.searchResultsFunc) {
  //       logger.debug("sending null (error): " + error.message);
  //       this.props.searchResultsFunc(null);
  //     }
  //
  //   });
  //
  // }

  handleSearchInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      return;
      //this.search();
    }
  };

  updateSearchText = (event) => {
    logger.debug("updateSearchText");

    let searchText = event.target.value.trim();
    this.setState({searchText: event.target.value});
    // if (this.props.updateSearchTextFunc) {
    //   this.props.updateSearchTextFunc(searchText);
    // }

    if (searchText !== '') {
      this.showSearchResults();
      this.getReadyToSearch(searchText);
    } else {
      this.hideSearchResults();
    }

  };

  handleFocus = (e) => {
    this.hideSearchResults();
  };

  handleBlur = (e) => {
    this.hideSearchResults();
  };

  showSearchResults = () => {
    window.$('[data-toggle="search-dropdown"]').dropdown('show');
  };

  hideSearchResults = () => {
    window.$('[data-toggle="search-dropdown"]').dropdown('hide');
  };


  handleMenuSelect = (item) => {
    this.props.history.push(item.getUrl());
  };

  renderQueueResults = () => {
    if (this.state.searching) {
      return (
        <div className="spinner-border epiqueue-darkblue-text align-self-center m-2 ml-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )
    }

    if (!this.state.searchResults || !this.state.searchResults.queues || this.state.searchResults.queues.length === 0) {
      return <span className="dropdown-item-text">No Results</span>
    }

    return this.state.searchResults.queues.map( (item, idx) => {
      return <div key={idx} className="dropdown-item searchbar-item" onMouseDown={(e) => this.handleMenuSelect(item)}>
        <QueuePic queue={item} size={25} cover={true} /> {item.name}
      </div>
    })
  };

  renderProductResults = () => {
    if (this.state.searching) {
      return (
        <div className="spinner-border epiqueue-darkblue-text align-self-center m-2 ml-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )
    }

    if (!this.state.searchResults || !this.state.searchResults.products || this.state.searchResults.products.length === 0) {
      return <span className="dropdown-item-text">No Results</span>
    }

    return this.state.searchResults.products.map( (item, idx) => {
      return <div key={idx} className="dropdown-item searchbar-item" onMouseDown={(e) => this.handleMenuSelect(item)}>
        <ProductPic product={item} size={25} cover={true}/> {item.name}
      </div>
    })
  };

  renderSellerResults = () => {
    if (this.state.searching) {
      return (
        <div className="spinner-border epiqueue-darkblue-text align-self-center m-2 ml-5" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )
    }

    if (!this.state.searchResults || !this.state.searchResults.sellers || this.state.searchResults.sellers.length === 0) {
      return <span className="dropdown-item-text">No Results</span>
    }

    return this.state.searchResults.sellers.map( (item, idx) => {
      return <div key={idx} className="dropdown-item searchbar-item" onMouseDown={(e) => this.handleMenuSelect(item)}>
        <ProfilePic user={item} size={25}  /> <span className="ml-1">{item.username}</span>
      </div>
    })
  };



  renderDropDown = () => {
    if (this.state.errorMessage) {
      return (
        <div>Error: {this.state.errorMessage}</div>
      )
    }

    return (
      <div>
        <span className="dropdown-header searchbar-header"><i className="fas fa-users"></i>&nbsp;&nbsp;Queues</span>
        {this.renderQueueResults()}
        <span className="dropdown-header searchbar-header"><i className="fas fa-gift"></i>&nbsp;&nbsp;Products</span>
        {this.renderProductResults()}
        <span className="dropdown-header searchbar-header"><i className="fas fa-store"></i>&nbsp;&nbsp;Sellers</span>
        {this.renderSellerResults()}
      </div>
    )
  }


  renderResults() {
    return (
      null
    );
  }

  render() {
    return (
      <div className="d-flex flex-grow-1 flex-column searchbar">
        <div className="input-group" >
          <div className="input-group-prepend">
            <span className="input-group-text purple lighten-3" id="basic-text1">
              <i className="fas fa-search text-black" aria-hidden="true"></i></span>
          </div>
          <input type="text"
                 className="form-control rounded-right"
                 placeholder="Search Queues, Products & Sellers"
                 onChange={this.updateSearchText}
                 onKeyPress={this.handleSearchInputKeyPress}
                 value={this.state.searchText}
                 //onClick={(e) => e.preventDefault()}
                 onFocus={(e) => this.handleFocus(e)}
                 onBlur={(e) => this.handleBlur(e)}
                 aria-label="Search"
                 aria-describedby="button-addon2"
                 aria-haspopup="true"
                 id='search-dropdown'
                 data-toggle="search-dropdown"
                 data-display="static"
          />
          <div className="btn bg-transparent"
               style={{marginLeft: '-40px', zIndex: 100}}
               onClick={this.clearText}
               hidden={this.state.searchText.trim() === ''}
          >
            <i className="fa fa-times"/>
          </div>
          <div className="dropdown-menu dropdown-menu-right searchbar-menu">
            {this.renderDropDown()}
          </div>

        </div>

      </div>
    );
  };
}


SearchBar.propTypes = {
  startSearchFunc: PropTypes.func,
  searchResultsFunc: PropTypes.func,
  updateSearchTextFunc: PropTypes.func,
};


export default withRouter(SearchBar);

