import React, { Component } from 'react';
import {Link, withRouter} from "react-router-dom";
import smallerImage from "../img/svetlana-pochatun-203730-unsplash.jpg";
import largerImage from "../img/annie-spratt-397303-unsplash.jpg";

import "../css/LoggedOutIndexView.css"

class LoggedOutIndexView extends Component {


  render() {

    return (
      <div className="loggedoutindexview-container epiqueue-font">
        <div className='loggedoutindexview-small-view'>
            <img className="loggedoutindexview-small-image" src={smallerImage}  height={500} width={"100%"} alt={"left"}/>
        </div>
        <div className="loggedoutindexview-large-view">
            <img className="loggedoutindexview-large-image" src={largerImage}  height={500} width={"100%"} alt={"right"}/>
            <div className="loggedoutindexview-text-area">
              <h5 className="loggedoutindexview-head">A New Kind of Marketplace</h5>
              <h5 className="loggedoutindexview-subhead">Building a community supporting small batch makers.</h5>
              <div className="loggedoutindexview-text pb-3">Where real curators like you get first dibs and equal access to limited editions from your favorite makers without having to set an alarm, stand in line, or compete with resellers.</div>
              <div className="align-self-end mr-5">
              <Link className="btn btn-dark loggedoutindexview-register-button" to="/register">Join a queue</Link>
              </div>
            </div>
        </div>

      </div>
    );
  }
}

export default withRouter(LoggedOutIndexView);