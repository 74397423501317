import React from 'react';

import {connect} from "react-redux";
import {addUserToCache} from "../redux/actions";

import {Logger } from 'aws-amplify';

import {Link, withRouter} from "react-router-dom";
import RootScreen from "./RootScreen";
import LoggedOutIndexView from "../components/LoggedOutIndexView";
import ScrollingQueueList from "../components/ScrollingQueueList";
import ScrollingSellerList from "../components/ScrollingSellerList";
import ScrollingProductList from "../components/ScrollingProductList";
import Loading from "../components/Loading";

import "../css/IndexScreen.css"
import ErrorMessage from "../components/ErrorMessage";
import LoggedInIndexView from "../components/LoggedInIndexView";

const logger = new Logger('IndexScreen');

class IndexScreen extends RootScreen {

  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'IndexScreen'
    });

    logger.debug("IndexScreen::constructor");

    this.state = {
      yourQueues: [],
      queues: null,
      liveProducts: null,
      closedProducts: null,
      sellers: null,

      searchResults: null,
      searching: false,

      queuesLoaded: false,

      errorMessage: null,
      isLoggedIn: this.props.loggedInUser !== null,
    };

    this.startSearch = this.startSearch.bind(this);
    this.searchResults = this.searchResults.bind(this);
    this.updateSearchText = this.updateSearchText.bind(this);
  }

  componentDidMount = async() => {
    logger.debug("IndexScreen::componentDidMount");
    window.scrollTo(0, 0);

    let waitingToVerify = localStorage.getItem('epiqueue.confirmation');
    if (waitingToVerify) {
      this.props.history.replace("/register");
    } else {
      this._loadQueues();
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this._loadQueues();
      window.scrollTo(0, 0);
    }

  }


  _loadQueues = async () => {
    return;
  };


  startSearch() {
    this.setState({
      searching: true,
    })
  }

  searchResults(searchResults = null) {

    logger.debug("got search results");
    logger.debug(JSON.stringify(searchResults));

    this.setState({
      searching: false,
      searchResults: searchResults,
    })
  }

  updateSearchText(searchText) {
    if (searchText === '') {
      this.clearSearch();
    }
  }

  clearSearch = () => {
    logger.debug("clearSearch");
    this.setState({
      searching: false,
      searchResults: null,
    })
  }

  renderContent() {
    logger.debug("IndexScreen::renderContent")

    if (!this.state.isLoggedIn) {
      return (
        <LoggedOutIndexView/>
      );
    }

    return (
      <>
        <ErrorMessage errorMessage={this.state.errorMessage}/>
        <LoggedInIndexView/>
      </>
    );
  }
}




const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
  {addUserToCache}
)(IndexScreen));

