import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BusyModal extends Component {

  render() {
    if (this.props.show === false) {
      return null
    }

    return (
      <>
        <div className='modal-backdrop in' />
        <div className="modal fade show d-inline-block" ref={this.props.setRef} id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="busyModalLabel" aria-hidden="true">
          <div className="modal-dialog busy-modal" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  {this.props.message ? this.props.message : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  };
}



BusyModal.defaultProps = {
  message: null,
};

BusyModal.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string,
  show: PropTypes.bool.isRequired,
  setRef: PropTypes.func.isRequired,
};

export default BusyModal;

