import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Loading from "../../components/Loading";
import RootScreen from "../RootScreen";
import BackLink from "../../components/BackLink";
import {API} from "aws-amplify";
import Sale from "../../models/Sale";
import ErrorMessage from "../../components/ErrorMessage";
import Util from "../../Util";
// import DashboardTransaction from "../../components/dashboard/DashboardTransaction";
import Transaction from "../../models/Transaction";

import "../../css/OrderScreen.css";

class OrderScreen extends RootScreen {

  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'OrderScreen'
    });

    this.state = {
      errorMessage: null,
      order: null,
      orderLoaded: false,
      identifier: this.props.match.params.identifier
    }

  }


  componentDidMount() {

    if (!this.props.loggedInUser) {
      this.setState({
        errorMessage: "You must be logged in to see this page",
        ordersLoaded: true,
      });
      return;
    }


    if (!this.state.identifier) {
      this.setState({
        errorMessage: "That order does notr exist",
        ordersLoaded: true,
      });
      return;
    }


    this.loadOrder();
  }

  loadOrder = async() => {


    let apiName = 'epiqueueapi';
    let path = '/dashboard/order';
    let postData = {
      body: {
        identifier: this.state.identifier
      }
    };
    try {
      let response = await API.post(apiName, path, postData);
      if (response.success) {
        let order = new Sale(response.order);
        this.setState({
          orderLoaded: true,
          order: order,
        });
      } else {
          this.setState({
            errorMessage: response.errorMessage,
            orderLoaded: true,
            order: null,
          })
      }
    } catch(error) {
      this.setState({
        orderLoaded: true,
        errorMessage: error.message,
      });
    }
  };



  renderLoading() {
    return (
      <Loading/>
    );
  }

  renderTransactions = () => {
    return (
      <div className="mt-3">
      <div className="orderscreen-table-label">Transactions</div>
      <table className="table table-bordered table-md-responsive">
        <thead>
        <tr>
          <th>Id</th>
          <th className="text-right">Status</th>
          <th className="text-right">Total Charged</th>
          <th className="text-right">Platform Fee</th>
          <th className="text-right">CC Fee</th>
          <th className="text-right">Paid to Seller</th>
        </tr>
        </thead>
        <tbody>
        {this.transactionRows()}
        </tbody>
      </table>
      </div>
    );


    // return transactions.map( item => {
    //     return <DashboardTransaction key={item.identifier} transaction={item} order={order}/>
    //   }
    // );
  }


  transactionRows = () => {
    let {order} = this.state;
    let transactions = order.transactions;
    return transactions.map( item => {
        if (item.status === Transaction.STATUS_SUCCESS) {
          return (
            <tr key={item.identifier}>
              <td>{item.identifier}</td>
              <td className="text-right">{item.status}</td>
              <td className="text-right">{Util.formatMoney(item.total_amount)}</td>
              <td className="text-right">{Util.formatMoney(item.platform_amount)}</td>
              <td className="text-right">{Util.formatMoney(item.stripe_fee)}</td>
              <td className="text-right">{Util.formatMoney(item.seller_amount)}</td>
            </tr>
          );

        } else {
          return (
            <tr key={item.identifier}>
              <td>{item.identifier}</td>
              <td>{item.status}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          );
        }
      }
    );

  }


  renderSeller() {

    let {order} = this.state;

    if (!order) {
      return (
        <>
          <BackLink link="/dashboard/orders" text="Back to the order list" />
          <ErrorMessage errorMessage={this.state.errorMessage}/>
          {this.state.identifier}
        </>
      );
    }


    return (
      <>
        <BackLink link="/dashboard/orders" text="Back to the order list" />
        <ErrorMessage errorMessage={this.state.errorMessage}/>

        <div className="d-flex flex-column">
          <div>Identifier: {order.identifier}</div>
          <div>Buyer: <Link className="epiqueue-darkblue-text" to={order.user.getUrl()} target="_blank">{order.user.username}</Link></div>
          <div>Product: <Link className="epiqueue-darkblue-text" to={order.product.getUrl()} target="_blank">{order.product.name}</Link></div>
          <div>Queue: <Link className="epiqueue-darkblue-text" to={order.product.queue.getUrl()} target="_blank">{order.product.queue.name}</Link></div>
          <div>Quantity: {order.quantity}</div>
          <div>Status: {order.status}</div>
          <div>Created: {order.created_at}</div>
          <div className="mt-2">Comment: {order.product_member ? order.product_member.comment : null}</div>
        </div>

        <div className="d-flex flex-column mt-4">
          <table className="table-bordered">
              <thead>
              <tr>
                <th className="text-right">Price</th>
                <th className="text-right">Tax ({Util.formatPercentage(order.tax_percentage)})</th>
                <th className="text-right">Shipping</th>
                <th className="text-right">Total</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td align="right">{Util.formatMoney(order.product_price)}</td>
                <td align="right">{Util.formatMoney(order.tax_amount)}</td>
                <td align="right">{Util.formatMoney(order.shipping_amount)}</td>
                <td align="right">{Util.formatMoney(order.amount)}</td>
              </tr>
              </tbody>
            </table>
        </div>

        {this.renderTransactions()}
      </>
    );
  }


  renderNotSeller() {

      if (!this.props.loggedInUser) {
        return null;
      }

      if (this.props.loggedInUser.is_seller) {
        return null;
      }

      return(
        <>
          <div className="alert alert-danger">
            You must be a seller to view this page
          </div>

        </>
      );
  }

  renderNotLoggedIn() {
    return(
      <div className="alert alert-danger">
        You must be logged in to view this page
      </div>
    );
    }


    renderBusy() {
      return(
        <Loading/>
      );
    }

    renderContent() {

      if (!this.props.loggedInUser) {
        return this.renderNotLoggedIn();
      }

      let {orderLoaded} = this.state;

      if (!orderLoaded) {
        return this.renderBusy();
      }

      return (
        <>
          { (!this.props.loggedInUser.is_seller) ?
            this.renderNotSeller()
            : this.renderSeller()
          }
        </>
    );
  }

}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
)(OrderScreen));
