import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AddCommentModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: "",
    }
  }

  handleSubmit() {
    let {message} = this.state;
    if (message.trim() === '') {
      message = null;
    }

    if (this.props.submitCallback) {
      this.props.submitCallback(message);
    }
  }

  updateTextField(field, event) {
    event.preventDefault();
    event.stopPropagation();


    this.setState({
      [field]: event.target.value,
    });
  }


  render() {
    return (
      <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="passCommentModalLabel" aria-hidden="true">
        <div className="modal-dialog w-75" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="passCommentModalLabel">{this.props.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group border p-2" style={{backgroundColor: '#eeeeee'}}>
                <div className="form w-100">
                    <textarea className={"form-control"}
                              id="message"
                              placeholder={this.props.message}
                              rows={5}
                              onChange={(event) => this.updateTextField('message', event)}
                              aria-label="message"
                              value={this.state.message}
                    />
                </div>
                <div style={{'fontSize': 'smaller'}}>Comments can not be seen by other queue members</div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary epiqueue-darkblue" data-dismiss="modal" onClick={() => this.handleSubmit()}>{this.props.submitLabel}</button>
            </div>
          </div>
        </div>
      </div>
    )
  };
}

AddCommentModal.defaultProps = {
  submitLabel: "Submit",
  submitCallback: null,
  message: "Add a comment",
  title: "Add a comment"
};

AddCommentModal.propTypes = {
  submitLabel: PropTypes.string.isRequired,
  submitCallback: PropTypes.func,
  message: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddCommentModal;

