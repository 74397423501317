
import Model from "./Model";
import User from "./User";
import {Logger} from "aws-amplify";

const logger = new Logger('Message');

export default class Message extends Model {

  static EPIQUEUE_SUPPORT_ACCOUNT_ID    = 39;

  /**
   *
   * @param json
   */
  constructor(json) {
    super();

    let {
      id,
      identifier,
      user_id,
      from_user_id,
      message,
      subject,
      read_at,
      created_at,
      updated_at,
      from_user,
      to_user,
    } = json;


    // database fields
    this.id = id;
    this.identifier = identifier;
    this.user_id = user_id;
    this.from_user_id = from_user_id;
    this.message = message;
    this.subject = subject;
    this.read_at = read_at;
    this.created_at = created_at;
    this.updated_at = updated_at;

    if (from_user) {
      this.fromUser = new User(from_user)
    }
    if (to_user) {
      this.toUser = new User(to_user);
      logger.debug("got to_user ");
      logger.debug(JSON.stringify(this.toUser));
    } else {
      logger.debug("no to_user ")
    }
  }


  isDirectMessage() {
    return this.from_user_id !== Message.EPIQUEUE_SUPPORT_ACCOUNT_ID;
  }
}

/**
 +------------+---------------------+------+-----+-------------------+-----------------------------+
 | Field      | Type                | Null | Key | Default           | Extra                       |
 +------------+---------------------+------+-----+-------------------+-----------------------------+
 | id         | bigint(20) unsigned | NO   | PRI | NULL              | auto_increment              |
 | identifier | char(36)            | NO   |     | NULL              |                             |
 | user_id    | bigint(20) unsigned | NO   | MUL | NULL              |                             |
 | subject    | varchar(255)        | NO   |     | NULL              |                             |
 | message    | text                | NO   |     | NULL              |                             |
 | read_at    | timestamp           | YES  |     | NULL              |                             |
 | created_at | timestamp           | NO   |     | CURRENT_TIMESTAMP |                             |
 | updated_at | timestamp           | NO   |     | CURRENT_TIMESTAMP | on update CURRENT_TIMESTAMP |
 +------------+---------------------+------+-----+-------------------+-----------------------------+
 8 rows in set (0.08 sec)

 */

