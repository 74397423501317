import React, {} from 'react';
import RootScreen from "../RootScreen";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {API, Logger} from "aws-amplify";
import ErrorMessage from "../../components/ErrorMessage";
import Sale from "../../models/Sale";

import "../../css/OrdersScreen.css";
import Util from "../../Util";
const logger = new Logger('CartScreen');

class OrdersScreen extends RootScreen {


  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'OrdersScreen'
    });

    this.state = {
      loggedIn: false,
      errorMessage: null,
      successMessage: null,
      sales: null,
      loading: true,
      total: 0,
      stripe: null
    };

    this.onSubmit = this.onSubmit.bind(this);

  }

  componentDidMount = async() => {

    if (!this.props.loggedInUser) {
      logger.debug("not logged in")
      return;
    }


    if (window.Stripe) {
      this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_TOKEN)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_TOKEN)});
      });
    }

    try {
      let apiName = 'epiqueueapi';
      let path = '/user/sales';

      let response = await API.get(apiName, path);
      logger.debug("get response: " + JSON.stringify(response));

      let sales = [];
      for (let i in response.sales) {
        sales.push(new Sale(response.sales[i]));
      }

      let total = 0;
      sales.forEach(function(item) {
        total += item.calculateTotal();
      });


      logger.debug("total: " + total);

      this.setState({
        sales: sales,
        errorMessage: null,
        loading: false,
        total: total
      })

    } catch(error) {
      this.setState({
        errorMessage: error.message,
        sales: [],
        loading: false,
        total: 0
      })

    }

  }

  onSubmit(result) {
    if (!result.success) {
      if (result.errorMessage) {
        this.setState({
          errorMessage: result.errorMessage
        });
      }
    }

    logger.debug(JSON.stringify(result))
  }


  renderCart() {

    let totalCharge = this.state.total;
    logger.debug(JSON.stringify(totalCharge));


    return (
    <>
      <div className="d-flex flex-grow-1 justify-content-between mb-3">
        <div>
        </div>
        <div className="d-flex">
        </div>
      </div>

      <div className="d-flex w-100">
        <div className="table-responsive">
          <table className="table table-striped epiqueue-darkblue-border">
            <thead className="table-secondary">
            <tr className="bg-white">
              <th scope="col">#</th>
              <th scope="col">Product</th>
              <th scope="col">Seller</th>
              <th className="text-right" scope="col">Amount</th>
              <th className="text-right" scope="col">Tax</th>
              <th className="text-right" scope="col">shipping amount</th>
              <th className="text-right" scope="col">Total</th>
              <th></th>
            </tr>
            </thead>
            <tbody className="bg-white">
            {this.renderSales()}
            </tbody>
            <tfoot className="table-secondary">
            <tr className="bg-white">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <th className="text-right">Total</th>
              <th className="text-right">{Util.formatMoney(this.state.total)}</th>
              <th></th>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
    );
  }

  renderNoCart() {
    return (
      <div className="d-flex flex-column justify-content-center">
        <div className="align-self-center text-center cartscreen-header mt-5">
          <h3>No orders yet.</h3>
          <h4>Don't worry, you will get to the front of a queue soon</h4>
        </div>
      </div>
    )
  }

  renderStatus = (sale) => {
    if (sale.isOpen()) {
      return (
        <td className="align-middle text-right"><Link to={"/orders/checkout/" + sale.identifier}>
          <div className="btn btn-sm btn-primary btn-epiqueue">Checkout</div>
        </Link></td>
      )
    }

    if (sale.isComplete()) {
      return(
        <td className="align-middle text-right">
          <Link className="epiqueue-darkblue-text" to={"/orders/receipt/" + sale.identifier}>View Receipt</Link>
          <div>{sale.isShipped() ? "Shipped on " + Util.formatDate(sale.shipped_at, true, false) : "Not Yet Shipped"}</div>
        </td>
      );
    }

    if (sale.status === Sale.STATUS_PAYMENT_FAILED) {
      return(
        <td className="align-middle text-right">
          <div className="mb-2">{sale.status}</div>
          <Link to={"/settings/"}>
            <div className="btn btn-sm btn-primary btn-epiqueue">Update Payment Details</div>
          </Link>
        </td>
      )
    }


    return(
      <td className="align-middle text-right">
        <div>{sale.status}</div>
      </td>
    )
  }

  renderSales() {
    if (this.state.sales !== null && this.state.sales.length !== 0) {
      let count = 0;
      return this.state.sales.map((item) => {
        count++;
        return (
          <tr className="" key={item.identifier}>
            <th scope="row">{count}</th>
            <td className="align-middle"><Link  className="epiqueue-darkblue-text" to={'/product/' + item.product_id}>{item.product.name}</Link></td>
            <td className="align-middle"><Link className="epiqueue-darkblue-text" to={item.product.queue.owner.getUrl()}> {item.product.queue.owner.username}</Link></td>
            <td className="align-middle text-right">{Util.formatMoney(item.amount)}</td>
            <td className="align-middle text-right">{Util.formatMoney(item.tax_amount)}</td>
            <td className="align-middle text-right">{Util.formatMoney(item.shipping_amount)}</td>
            <td className="align-middle text-right">{Util.formatMoney(item.calculateTotal())}</td>
            {this.renderStatus(item)}
          </tr>
        );
      });
    } else {
      return (
        this.renderNoCart()
      )
    }
  }


  renderContent() {
    if (!this.props.loggedInUser) {
      return <ErrorMessage errorMessage={"You are not logged in"}/>
    }

    if (this.state.loading) {
      return null;
    }

    return (

      <>

        <ErrorMessage errorMessage={this.state.errorMessage}/>

        {
          (this.state.sales === null || this.state.sales.length === 0)
          ? this.renderNoCart()
          : this.renderCart()
        }

      </>
    );
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps
)(OrdersScreen));
