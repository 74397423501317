
import React, { Component } from 'react';
import "../../css/HelpScreen.css"
import BlankBox from "../BlankBox";

class FaqHelp extends Component {
  static TITLE = "FAQs";

  content() {
    return (
      <ol>
        <li className="mb-3"><strong>What's is this all about?</strong><br/>
          Epiqueue is a new marketplace for buying and selling small batch and limited edition items (think artwork, jewelery, and crafts). Our goal is to provide a fair and equitable way to have access to limited quantity items
        </li>


        <li className="mb-3"><strong>What is a Queue</strong><br/>
          Think of a queue as a storefront or category that a seller creates. A seller may have multiple queues if they
          sell different types of items. For example an artist may have one queue for higher priced originals and another
          for lower priced prints. When you join a queue, you are getting in line for the option to purchase the next products the seller creates.
        </li>


        <li className="mb-3"><strong>How do I buy</strong><br/>
          Once you are in a queue, you will be notified when the next products are released. You then have up to 48 hours to decide if you want to purchase or pass on that product.
        </li>

        <li className="mb-3"><strong>Do I always have 48 hours to decide</strong><br/>
          No, a seller can close the sale at any time. The sale automatically closes after 48 hours if the seller has not closed it earlier.
        </li>

        <li className="mb-3"><strong>If I say "Purchase", do I get the product?</strong><br/>
          Not necessarily, the items are doled out in the order you are in the queue. It depends on the quantity available and how many ahead of you decide to pass.
          Whenever someone buys, they move to the back of the line and you move up, increasing your chances for the next product.
          You always know where you are in the queue and and can see how many people have passed ahead of you.
        </li>

        <li className="mb-3"><strong>Why do I need to have my credit card stored?</strong><br/>
          When you mark "purchase", you are committing to buy. However we do not charge your card until the sale closes. When the sale closes we look at the inventory and then purchase the product for you with the card you have stored.
          The cards are stored securely with our payment processor, Stripe. We never store the card itself in our database.
        </li>
        
      </ol>
    )
  }

  render() {
    return (
      <BlankBox contentCallback={this.content} title="FAQs"/>
    )
  }
}

export default FaqHelp;