import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";

import ShippingAddress from "../../models/ShippingAddress";
import {Logger} from "aws-amplify";

import "../../css/SelectAddressModal.css"

const logger = new Logger('SelectAddressModal');

class SelectAddressModal extends Component {

  makeSelection(id) {

    logger.debug("made selection: " + id);

    if (this.props.okCallback) {
      this.props.okCallback(id);
    }
  }

  renderAddresses() {
    return this.props.addresses.map(item => {
      return (
        <tr key={item.id}>
          <td valign="top">
            {this.props.selected !== item.id ?
            <div className="btn btn-sm btn-primary mr-2" data-dismiss="modal" onClick={() => this.makeSelection(item.id)}>Select</div>
              : <div className="text-center"><i className="fas fa-check mr-2"></i></div> }
          </td>
          <td>
          <div className="pb-2 mb-2">
          <div>{item.name}</div>
          <div>{item.address1}</div>
          <div>{item.address2}</div>
          <div>{item.city}, {item.state} {item.zip}</div>
          <div>{item.country}</div>
          </div>
          </td>
        </tr>
      );
    })
  }


  render() {
    return (
      <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog shippingaddressmodal-modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Select Shipping Address</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body shippingaddressmodal-modal-body">
              <table>
                <tbody>
                {this.renderAddresses()}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
                <div className="btn btn-link epiqueue-darkblue-text" data-dismiss="modal" onClick={() => this.props.history.push('/settings')}>Manage Addresses Here</div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.props.cancelCallback}>{this.props.cancelLabel}</button>
            </div>
          </div>
        </div>
      </div>
    )
  };
}

SelectAddressModal.defaultProps = {
  okLabel: "OK",
  cancelLabel: "Cancel",
  okCallback: null,
  cancelCallback: null,
  selected: null
};

SelectAddressModal.propTypes = {
  id: PropTypes.string.isRequired,
  okLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  okCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  addresses: PropTypes.arrayOf(PropTypes.instanceOf(ShippingAddress)).isRequired,
  selected: PropTypes.number
};

export default withRouter(SelectAddressModal);

