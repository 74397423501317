import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Queue from "../../models/Queue";

class CreateQueueModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      queueName: '',
      queueDescription: '',
      hideError: true,
    }
  }

  handleQueueNameChange = (event) => {
    let txt = event.target.value.substr(0, Queue.NAME_CHAR_LIMIT);
    this.setState({queueName: txt});
  };

  handleQueueDescriptionChange = (event) => {
    let txt = event.target.value;
    this.setState({queueDescription: txt});
  };


  handleOk = (event) => {
    event.stopPropagation();
    if (this.state.queueName.trim() === '') {
      this.setState({
        hideError: false,
        queueName: '',
      });
    } else {
      this.props.okCallback(this.state.queueName.trim(), this.state.queueDescription.trim());
      this.setState({
        queueName: '',
        hideError: true,
      });
    }
  };



  render() {
    return (
      <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Create a New Queue</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              <div>To get started, enter the name of your new queue <br/>(you can always change it later)</div>

              <div className="form-group mt-3">
                <input className="form-control"
                       type="text"
                       id="queuename"
                       value={this.state.queueName}
                       onChange={this.handleQueueNameChange}
                       placeholder="Queue Name"
                       autoFocus
                />
                <div className="small text-danger" hidden={this.state.hideError}>You did not enter any text</div>
              </div>

              <div>Once created, you will be able to add details</div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.props.cancelCallback}>{this.props.cancelLabel}</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.handleOk} disabled={this.state.queueName.trim() === ''}>{this.props.okLabel}</button>
            </div>
          </div>
        </div>
      </div>
    )
  };
}

CreateQueueModal.defaultProps = {
  okLabel: "OK",
  cancelLabel: "Cancel",
  okCallback: null,
  cancelCallback: null,
};

CreateQueueModal.propTypes = {
  id: PropTypes.string.isRequired,
  okLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  okCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
};

export default CreateQueueModal;

