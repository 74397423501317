import React, {} from 'react';
import {Logger} from "aws-amplify";
import RootScreen from "./RootScreen";
import Image404 from '../img/404.jpg'

import "../css/NoMatchScreen.css";



const logger = new Logger('NoMatchScreen');


class NoMatchScreen extends RootScreen {


  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'screen_name' : 'NoMatchScreen'
    });

    this.state = {
      loggedIn: false,
      admin: false,
      user: null
    }

  }


renderContent() {

  logger.error("no match: ");

  return (
      <div className="col-12 text-center p-3">
        <div className="col-lg-6 offset-lg-3  text-center p-3 text-dark nomatchscreen-text">
          <h3>OOPSIE DAISY</h3>
          <h4>That page seems to be missing.</h4>
        </div>
      <img className="four0four-image center-fit" src={Image404} alt="page not found"/>
      </div>
  );
}

}

export default NoMatchScreen;