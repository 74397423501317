import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {CardElement, injectStripe} from "react-stripe-elements";
import {API, Logger} from "aws-amplify";
import User from "../models/User";
import ErrorMessage from "./ErrorMessage";
import StateDropdown from "./StateDropdown";

const logger = new Logger('StripeCreditCardForm');

class StripeSaveCreditCardForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      address_name: this.props.stripeCustomer ? this.props.stripeCustomer.billing_name : "",
      address_line1: this.props.stripeCustomer ? this.props.stripeCustomer.billing_address1 : "",
      address_line2: this.props.stripeCustomer ? this.props.stripeCustomer.billing_address2 : "",
      address_city: this.props.stripeCustomer ? this.props.stripeCustomer.billing_city : "",
      address_state: this.props.stripeCustomer ? this.props.stripeCustomer.billing_state : "State...",
      address_zip: this.props.stripeCustomer ? this.props.stripeCustomer.billing_zip : "",
      address_country: this.props.stripeCustomer ? this.props.stripeCustomer.billing_country : "",

      currency: 'usd',

      errorMessage: null,
      successMessage: null,

      invalidItems: [],

      options: StateDropdown.STATES_ABBREVIATIONS,

      isUpdate: this.props.stripeCustomer !== null
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }


  updateTextField(field, event) {
    let alsoUpdateField =  null;

    logger.debug("field: " + field)

    logger.debug("alsoUpdateField: " + alsoUpdateField)

    if (alsoUpdateField) {
      this.setState({
        [field]: event.target.value,
        [alsoUpdateField]: event.target.value,
        invalidItems: [],
        errorMessage: null,

      });
    } else {
      this.setState({
        [field]: event.target.value,
        invalidItems: [],
        errorMessage: null,
      });
    }

  }


  invalidState(item) {
    if (item in this.state.invalidItems) {
      return 'is-invalid'
    }
  }

  validate() {
    let invalidItems = {};

    // check required fields

    if (this.state.address_name.trim() === '') {
      invalidItems['address_name'] = 'Name is a required field';
    }
    if (this.state.address_line1.trim() === '') {
      invalidItems['address_line1'] = 'Address is a required field';
    }
    if (this.state.address_city.trim() === '') {
      invalidItems['address_city'] = 'City is a required field';
    }
    logger.debug("checking address_state")
    if (this.state.address_state.trim() === '' || this.state.address_state.trim() === 'State...') {
      logger.debug("yep")
      invalidItems['address_state'] = 'Required';
    } else {
      logger.debug("nope")

    }
    if (this.state.address_zip.trim() === '') {
      invalidItems['address_zip'] = 'Zip is a required field';
    }

    if (Object.keys(invalidItems).length > 0) {
      this.setState({
        invalidItems: invalidItems,
        errorMessage: "Please correct the errors above",
      });
      return false;
    }

    return true;
  }

  async onCancel(e) {
    e.preventDefault();
    this.props.onCancel();
  }

  async onSubmit(e) {
    e.preventDefault();

    this.setState({
      errorMessage: null,
    });

    if (!this.validate()) {
      return;
    }

    try {
      let {token} = await this.props.stripe.createToken(
        {
          name: this.state.address_name,
          address_line1: this.state.address_line1,
          address_line2: this.state.address_line2,
          address_city: this.state.address_city,
          address_state: this.state.address_state,
          address_zip: this.state.address_zip,
          address_country: this.state.address_country,
        });
      logger.debug(JSON.stringify(token));
      if (!token) {
        this.setState({
          errorMessage: "There was an error with the credit card information entered",
        });
        return;
      }

      if (token.error) {
        this.setState({
          errorMessage: token.error,
        });
        return;
      }


      let apiName = 'epiqueueapi';
      let path = '/payment/save_customer';

      let postData = {
        body: {
          stripe_token: token.id,
          name: this.state.address_name,
          address_line1: this.state.address_line1,
          address_line2: this.state.address_line2,
          address_city: this.state.address_city,
          address_state: this.state.address_state,
          address_zip: this.state.address_zip,
          address_country: this.state.address_country,
        }
      };

      let response = await API.post(apiName, path, postData);
      if (response.success) {
        this.props.onSubmit(response.stripeCustomerObject);
      } else {
        await this.setState({
          errorMessage: response.errorMessage,
        });
      }
    } catch (error) {
      await this.setState({
        errorMessage: error.message,
      });
    }

  }

  renderStates = () => {
    return this.state.options.map( (item) => {
      return <option key={item}>{item}</option>
    })
  };


  render() {
    let {loggedInUser} = this.props;

    if (!loggedInUser) {
      return null;
    }

    return (
      <div className="container">
      <div className="row">
        <div className="card shadow border col-lg-8 offset-lg-2 offset-md-1 col-md-10 col-sm-12">
          <div className="card-body">
            <h5 className="card-title text-center">{this.state.isUpdate === true ? "Update" : "Save"} Payment Method</h5>
            <div className="d-flex flex-column">
              <div className="mb-4">
              Please fill out the information below to save your card.
              </div>


              <div className="border-label"><h6>Credit Card Information</h6></div>
              <div className="form-group border p-2" style={{backgroundColor: '#eeeeee'}}>
                <div>
                  <div className="form-control">
                    <CardElement style={{base: {fontSize: '18px'}}} />
                  </div>
                </div>
              </div>

              <div className="border-label"><h6>Credit Card Address</h6></div>
              <div className="form-group border border p-2 " style={{backgroundColor: '#eeeeee'}}>




                <div className="form w-100">
                  <input className={"form-control " + this.invalidState('address_name')}
                         type="text"
                         id="address_name"
                         placeholder="Name"
                         onChange={(event) => this.updateTextField('address_name', event)}
                         value={this.state.address_name}
                  />
                  <div className="invalid-feedback">{this.state.invalidItems.address_name}</div>
                </div>
                <div className="mt-2">
                  <input className={"form-control " + this.invalidState('address_line1')}
                         type="text"
                         id="address_line1"
                         placeholder="Address1"
                         onChange={(event) => this.updateTextField('address_line1', event)}
                         value={this.state.address_line1}
                  />
                  <div className="invalid-feedback">{this.state.invalidItems.address_line1}</div>
                </div>
                <div className="mt-2">
                  <input className={"form-control " + this.invalidState('address_line2')}
                         type="text"
                         id="address_line2"
                         placeholder="Address2"
                         onChange={(event) => this.updateTextField('address_line2', event)}
                         value={this.state.address_line2}
                  />
                  <div className="invalid-feedback">{this.state.invalidItems.address_line2}</div>
                </div>
                <div className="form-inline">
                  <div className="mt-2 mr-lg-3 mr-md-2 mr-sm-0">
                    <input className={"form-control " + this.invalidState('address_city')}
                           type="text"
                           id="address_city"
                           placeholder="City"
                           onChange={(event) => this.updateTextField('address_city', event)}
                           value={this.state.address_city}
                    />
                    <div className="invalid-feedback">{this.state.invalidItems.address_city}</div>
                  </div>
                  <div className="mt-2 mr-lg-3 mr-md-2 mr-sm-0">
                    <select className={"form-control " + this.invalidState('address_state')}
                            id="address_state"
                            onChange={(event) => this.updateTextField('address_state', event)}
                            value={this.state.address_state}
                    >
                      <option>State...</option>
                      {this.renderStates()}
                    </select>
                    <div className="invalid-feedback">{this.state.invalidItems.address_state}</div>
                  </div>
                  <div className="mt-2">
                    <input className={"form-control " + this.invalidState('address_zip')}
                           type="text"
                           id="address_zip"
                           placeholder="Zip"
                           onChange={(event) => this.updateTextField('address_zip', event)}
                           value={this.state.address_zip}
                    />
                    <div className="invalid-feedback">{this.state.invalidItems.address_zip}</div>
                  </div>
                </div>
              </div>


              <ErrorMessage errorMessage={this.state.errorMessage}/>

              <div className="mt-3 row justify-content-end">
                <div className="mr-3">
                  <button className="btn btn-secondary" onClick={e => this.onCancel(e)}>Cancel</button>
                </div>
                <div className="">
                  <button className="btn btn-primary" onClick={e => this.onSubmit(e)}>{this.state.isUpdate === true ? "Update" : "Save"} Payment Method</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  };
}


StripeSaveCreditCardForm.defaultProps = {
};

StripeSaveCreditCardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loggedInUser: PropTypes.instanceOf(User).isRequired,
  stripeCustomer: PropTypes.object
};

export default injectStripe(StripeSaveCreditCardForm);
