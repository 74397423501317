import {API, Logger} from 'aws-amplify';

import Model from "./Model";
import Tag from "./Tag";

const logger = new Logger('SellerModel');


export default class Seller extends Model {

  static STATUS_REGISTERING = 'registering';
  static STATUS_ACTIVE = 'active';
  static STATUS_DISABLED = 'disabled';

  /**
   *
   * @param json
   */
  constructor(json) {
    super();

    let {
      id,
      user_id,
      stripe_user_id,
      refresh_token,
      status,
      max_queues,
      rake,
      testseller,
      is_premium,
      premium_expires_at,
      nexus_states,
      country,
      created_at,
      updated_at,
      tags
    } = json;

    // database fields
    this.id = id;
    this.user_id = user_id;
    this.stripe_user_id = stripe_user_id;
    this.refresh_token = refresh_token;
    this.status = status;
    this.max_queues = max_queues;
    this.rake = rake;
    this.testseller = testseller;
    this.is_premium = is_premium;
    this.premium_expires_at = premium_expires_at;
    this.nexus_states = nexus_states ? JSON.parse(nexus_states) : null;
    this.country = country;
    this.created_at = created_at;
    this.updated_at = updated_at;

    this.tags = [];
    if (tags) {
      this.tags = tags.map((item)=>{
        return new Tag(item)
      });
    }

  }

  /**
   * local id, not cognito id
   *
   * @param userId
   * @returns {Promise<void>}
   */
  static async loadByUserId(userId) {
    logger.debug("load by userId: " + userId);
    let errorMessage = null;
    let success = true;

    logger.debug("/seller/" + userId);

    var sellerObj = null;
    if (userId) {
      let apiName = 'epiqueueapi';
      let path = '/seller/' + userId;
      try {
        var response = await API.get(apiName, path);
        logger.debug(JSON.stringify(response));
        if (response.success) {
          success = true;
          sellerObj = new Seller(response.seller);
          sellerObj.cognitoUser = null;
          errorMessage = null;

        } else {
          success = false;
          errorMessage = response.error;
        }
      } catch (error) {
        success = false;
        errorMessage = error.message;
        logger.debug(error.message)
      }
    } else {
      success = false;
      errorMessage = "error";
      logger.debug("error")
    }


    return {success: success, error: errorMessage, seller: sellerObj}
  }


  /**
   *
   * @param user_id
   * @returns {Promise<{success: boolean, error: string, user: *}>}
   */
  static async load(user_id) {
    let errorMessage = "Not implemented Yet";
    let success = false;
    let sellerObj = null;


    return {success: success, error: errorMessage, seller: sellerObj}
  }


  /**
   *
   * @returns {Promise<null>}
   */
  static async getAuthenticated() {
    let errorMessage = "Not implemented Yet";
    let success = false;
    let sellerObj = null;


    return {success: success, error: errorMessage, seller: sellerObj}
  }

  save = async () => {
    //TODO:  make sure user_id matches logged in user


    let seller = Object.assign({}, this);

    // auto updates in db
    delete seller.created_at;
    delete seller.updated_at;

    logger.debug(JSON.stringify(seller));

    let apiName = 'epiqueueapi';
    let path = '/seller/';
    const params = {
      body: {
        seller: seller,
      }
    };
    try {
      var response = await API.post(apiName, path, params);
      logger.debug("API.post: " + JSON.stringify(response));
      if (response.success) {
      } else {
      }
    } catch (error) {
      logger.debug("API.post error: " + error.message)
    }
  }

  getUrl() {
    return "/profile/" + this.user_id;
  }

}

// +--------------------+---------------------+------+-----+-------------------+-----------------------------+
// | Field              | Type                | Null | Key | Default           | Extra                       |
// +--------------------+---------------------+------+-----+-------------------+-----------------------------+
// | id                 | bigint(20) unsigned | NO   | PRI | NULL              | auto_increment              |
// | user_id            | bigint(20) unsigned | NO   | UNI | NULL              |                             |
// | stripe_user_id     | varchar(255)        | YES  |     | NULL              |                             |
// | refresh_token      | varchar(255)        | YES  |     | NULL              |                             |
// | status             | varchar(255)        | NO   |     | registering       |                             |
// | max_queues         | int(10) unsigned    | NO   |     | 3                 |                             |
// | rake               | int(11)             | NO   |     | 10                |                             |
// | testseller         | tinyint(1)          | NO   |     | 0                 |                             |
// | is_premium         | tinyint(1)          | NO   |     | 0                 |                             |
// | premium_expires_at | timestamp           | YES  |     | NULL              |                             |
// | nexus_states       | json                | YES  |     | NULL              |                             |
// | country            | varchar(2)          | NO   |     | NULL              |                             |
// | created_at         | timestamp           | NO   |     | CURRENT_TIMESTAMP |                             |
// | updated_at         | timestamp           | NO   |     | CURRENT_TIMESTAMP | on update CURRENT_TIMESTAMP |
// +--------------------+---------------------+------+-----+-------------------+-----------------------------+
//   14 rows in set (0.10 sec)
