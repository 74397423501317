import React, {} from 'react';
import Breadcrumb from "../components/Breadcrumb";
import RootScreen from "./RootScreen";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {addUserToCache} from "../redux/actions";
import {API, Logger} from "aws-amplify";
import Queue from "../models/Queue";
import QueueComponent from "../components/QueueComponent";
import SearchBar from "../components/SearchBar";

const logger = new Logger('DiscoverScreen');

class DiscoverScreen extends RootScreen {

  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'DiscoverScreen'
    });

    this.state = {
      errorMessage: null,
      loggedIn: false,
      admin: false,
      user: null,
      suggestedQueues: null,
      suggestedCreators: null,
      searchText: '',
      mostRecentSearchText: '',
      searching: true,
      searchResults: null,
    }

  }


  componentDidMount() {
    this._loadItems();
  }


  _loadItems = async () => {

    try {

      let apiName = 'epiqueueapi';
      let path = '/discover/suggested';

      let response = await API.get(apiName, path);
      logger.debug("get response: " + JSON.stringify(response));

      let suggestedQueues = [];
      let suggestedCreators = [];
      for (let i in response.queues) {
        suggestedQueues.push(new Queue(response.queues[i]));
      }

      this.setState({
        searching: false,
        suggestedQueues: suggestedQueues,
        suggestedCreators: suggestedCreators,
      })

    } catch(error) {
      this.setState({
        errorMessage: error.message,
        searching: false,
      })
    }

  };

  search = () => {
  }


  renderLoading() {
    return null;
  }

  renderSuggestions() {

    if (this.state.suggestedQueues === null) {
      return this.renderLoading();
    }


    if (this.state.suggestedQueues === undefined || this.state.suggestedQueues.length === 0) {
      return <>
        <h3>No Suggestions</h3>
      </>
    }

    if (!Array.isArray(this.state.suggestedQueues)) {
      return <div>
        Not array
      </div>
    }

    return this.state.suggestedQueues.map(function(item, idx) {
      return (
        <div key={item.id} className="col col-12 pl-0 pr-0">
          <QueueComponent key={item.id}
                          queue={item}
          />
        </div>
      )
    });

  }

  renderContent() {
    return (
      <>
        <Breadcrumb crumbs={[
          {link: "/", label: "Home"},
          {link: null, label: "Discover"}
        ]}/>


        {this.state.errorMessage ?
          <div className="row">
            <div className="alert alert-danger">{this.state.errorMessage}</div>
          </div>
          : null }

          <SearchBar/>

        {this.renderSuggestions()}
        </>
    );
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
  {addUserToCache}
)(DiscoverScreen));

