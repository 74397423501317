import React, { Component } from 'react';
import {withRouter, Link} from "react-router-dom";
import PropTypes from 'prop-types';

class Breadcrumb extends Component {

  renderLinks = () => {
    return this.props.crumbs.map( (item, idx) => {
      if (item.link === null) {
        return (
          <li key={'bc-' + idx} className="breadcrumb-item active">{item.label}</li>
        )
      } else {
        return (
          <li key={'bc-' + idx} className="breadcrumb-item"><Link to={item.link}>{item.label}</Link></li>
        )
      }
    });
  };

  render() {
    return null;

    // return (
    //   <div aria-label="breadcrumb">
    //     <ol className="breadcrumb">
    //       {this.renderLinks()}
    //     </ol>
    //   </div>
    // )
  };
}

Breadcrumb.propTypes = {
  crumbs: PropTypes.array.isRequired,
};

export default withRouter(Breadcrumb);

