import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {API, Logger} from "aws-amplify";

import "../../css/EditProfileModal.css"
import User from "../../models/User";

const logger = new Logger('EditProfileModal');

class EditProfileModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: this.props.user.username,
      description: this.props.user.description,
      invalidItems: [],
      errorMessage: null,
    }
  }

  cancelChanges = () => {
    this.setState({
      username: this.props.user.username,
      description: this.props.user.description,
    });

    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }
  };


  hasChanges = () => {
    if (this.props.user.username !== this.state.username) {
      logger.debug("username changed: ")
      return true;
    }
    if (this.props.user.description !== this.state.description) {
      logger.debug("description changed: ")
      return true;
    }

    return false;
  }

  validate = () => {
    let invalidItems = {};

    if (this.state.username.trim() === '') {
      invalidItems['username'] = 'You can not remove your username';
    }

    if (Object.keys(invalidItems).length > 0) {
      this.setState({
        invalidItems: invalidItems,
        username: this.props.user.username,
        errorMessage: "Please correct the errors above",
      });
      return false;
    }


    // if (true) {
    //   invalidItems['username'] = 'Just Testing';
    //   this.setState({
    //     invalidItems: invalidItems,
    //     username: this.props.user.username,
    //     errorMessage: "Please correct the errors above",
    //   });
    //   return false;
    // }

    return true;
  };




  saveChanges = async(e) => {
    e.preventDefault();
    e.stopPropagation();


    if (!this.validate()) {
      return;
    }

    if (!this.hasChanges()) {
      this.cancelChanges();
      return;
    }

    let apiName = 'epiqueueapi';
    let path = '/user/unique_username';
    let params = {
      body: {
        username: this.state.username,
      }
    };
    try {
      var response = await API.post(apiName, path, params);
      if (response.success) {
        // get on with our lives
      } else {
        this.setState({
          errorMessage: response.errorMessage
        });
        return;
      }
    } catch (error) {
      this.setState({
        errorMessage: error.message
      });
      return;
    }


    path = '/user';
    params = {
      body: {
        user: {
          description: this.state.description,
          username: this.state.username,
        }
      }
    };
    try {
      response = await API.post(apiName, path, params);
      if (response.success) {
        this.props.user.description = this.state.description;
        this.props.user.username = this.state.username;

       if (this.props.okCallback) {
          this.props.okCallback();
        }

      } else {
        this.setState({
          errorMessage: response.errorMessage
        });
      }
    } catch (error) {
      this.setState({
        errorMessage: error.message
      });
    }


  };


  handleUsernameInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  };

  updateTextField(field, event) {
    event.preventDefault();

    this.setState({
      [field]: event.target.value,
      invalidItems: [],
      errorMessage: null,
    });
  }

  invalidState(item) {
    if (item in this.state.invalidItems) {
      return 'is-invalid'
    }
  }


  render() {
    return (
      <div className="modal fade editprofilemodal" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="editprofilemodal" aria-hidden="true" >
        <div className="modal-dialog shippingaddressmodal-modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editprofilemodal">Edit Your Profile</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body shippingaddressmodal-modal-body">
              <div className="d-flex flex-column">
                <form className={"form"}>

                  <div className="border-label"><h6>User Name</h6></div>
                  <div className="form-group border p-2" style={{backgroundColor: '#eeeeee'}}>
                    <div className="form w-100">
                      <input type="text" className={"form-control " + this.invalidState('username')}
                                id="username"
                                placeholder="User Name"
                              onKeyPress={this.handleUsernameInputKeyPress}
                                onChange={(event) => this.updateTextField('username', event)}
                                aria-label="username"
                             value={this.state.username}
                      />
                      <div className="invalid-feedback">{this.state.invalidItems.username}</div>
                    </div>
                  </div>

                  <div className="border-label"><h6>Description</h6></div>
                  <div className="form-group border p-2" style={{backgroundColor: '#eeeeee'}}>
                    <div className="form w-100">
                      <textarea className={"form-control " + this.invalidState('description')}
                                id="description"
                                placeholder="Description"
                                rows={5}
                                onChange={(event) => this.updateTextField('description', event)}
                                aria-label="description"
                                value={this.state.description}
                      />
                      <div className="invalid-feedback">{this.state.invalidItems.description}</div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
                <div className="text-danger">{this.state.errorMessage}</div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onKeyDown={this.userNameKey} onClick={this.cancelChanges}>{this.props.cancelLabel}</button>
              <button type="button" className="btn btn-primary" onClick={this.saveChanges} disabled={this.state.username.trim() === ''}>{this.props.okLabel}</button>
            </div>
          </div>
        </div>
      </div>
    )
  };
}

EditProfileModal.defaultProps = {
  okLabel: "Update",
  cancelLabel: "Cancel",
};

EditProfileModal.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(User).isRequired,
  okLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  okCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
};

export default EditProfileModal;

