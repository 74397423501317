
import Model from "./Model";

export default class ShippingAddress extends Model {

  /**
   *
   * @param json
   */
  constructor(json) {
    super();

    let {
      id,
      user_id,
      primary,
      name,
      address1,
      address2,
      city,
      state,
      zip,
      country,
      created_at,
      updated_at
    } = json;

    // database fields
    this.id = id;
    this.user_id = user_id;
    this.primary = primary;
    this.name = name;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.country = country;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }

}

/**
 +------------+---------------------+------+-----+-------------------+-----------------------------+
 | Field      | Type                | Null | Key | Default           | Extra                       |
 +------------+---------------------+------+-----+-------------------+-----------------------------+
 | id         | bigint(20) unsigned | NO   | PRI | NULL              | auto_increment              |
 | user_id    | bigint(20) unsigned | NO   |     | NULL              |                             |
 | primary    | tinyint(1)          | NO   |     | 0                 |                             |
 | name       | varchar(255)        | NO   |     | NULL              |                             |
 | address1   | varchar(255)        | NO   |     | NULL              |                             |
 | address2   | varchar(255)        | YES  |     | NULL              |                             |
 | city       | varchar(255)        | NO   |     | NULL              |                             |
 | state      | varchar(255)        | NO   |     | NULL              |                             |
 | zip        | varchar(255)        | NO   |     | NULL              |                             |
 | country    | varchar(2)          | NO   |     | NULL              |                             |
 | created_at | timestamp           | NO   |     | CURRENT_TIMESTAMP |                             |
 | updated_at | timestamp           | NO   |     | CURRENT_TIMESTAMP | on update CURRENT_TIMESTAMP |
 +------------+---------------------+------+-----+-------------------+-----------------------------+
 12 rows in set (0.07 sec)

 */

