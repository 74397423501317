import React, {} from 'react';
import {withRouter, Link} from "react-router-dom";
import RootScreen from "./RootScreen";
import FamilyPic from "../img/who_we_are.png"
import WhatWeDoPic from "../img/what_we_do.png"
import BlankBox from "../components/BlankBox";

import "../css/AboutScreen.css";

class AboutScreen extends RootScreen {


  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'AboutScreen'
    });

    this.state = {
      loggedIn: false,
      admin: false,
      user: null
    }
  }


  content = () => {
    return(
      <div className="container aboutscreen">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-8">
            <div className="epiqueue-font-bold aboutscreen-heading">Who We Are</div>
            <div>
              Epiqueue was founded by 25-year startup veteran Carl Haynes. The idea came to him after trying to purchase some prints from artist Shephard Fairey.
              Fairey would put the prints up for sale at a certain time and they’d be gone in seconds, then resold on Artsy within hours.
              Carl always missed the sales, and knew there had to be a better way to give artists’ real customers fair,
              equal access to their work without paying four times the amount to get it through a reseller.
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div>
              <img src={FamilyPic} width="100%" alt={"family"}/>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-6 col-lg-4">
            <img src={WhatWeDoPic} width="100%" alt={"What We Do"}/>
          </div>
          <div className="col-12 col-md-6 col-lg-8 mt-sm-4 mt-md-0">
            <div className="epiqueue-font-bold aboutscreen-heading">What We Do</div>
            <div className="mt-3">
              <strong>Introduce Indie Makers to Tastemakers</strong>
              <p/>
              We're like an "art dealer" for normal people, introducing you to new makers, and giving you first access to their work.
              <p/>
              <strong>Democratize Limited Editions</strong>
              <p/>
              You don’t have to worry about resellers wiping out limited inventory. In the queue, everyone gets the same one chance to purchase or pass on unique finds.
              <p/>
              <strong>Celebrate the Small Batch Community</strong>
              <p/>
              We share a love of makers and their unique creations, jumping at the chance to support them in their craft.
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderContent() {
    return (
        <BlankBox title="About Us" contentCallback={this.content}/>
    );
  }

}

export default withRouter(AboutScreen);