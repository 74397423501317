import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import './App.css';

import Amplify, {Logger, Storage} from 'aws-amplify';
// import aws_exports from './aws-exports';
import User from "./models/User";

import {bindActionCreators} from "redux";
import { connect } from 'react-redux';
import { setLoggedInUser, addUserToCache } from './redux/actions'

import RegisterScreen from "./screens/auth/RegisterScreen";
import VerifyScreen from "./screens/auth/VerifyScreen";
import PasswordRequestScreen from "./screens/auth/PasswordRequestScreen";
import LoginScreen from "./screens/auth/LoginScreen";

import QueueScreen from "./screens/QueueScreen";
import NoMatchScreen from "./screens/NoMatchScreen";
import ProfileScreen from "./screens/ProfileScreen";
import AboutScreen from "./screens/AboutScreen";
import TermsScreen from "./screens/TermsScreen";
import PrivacyScreen from "./screens/PrivacyScreen";
import ProductScreen from "./screens/ProductScreen";
import AccountScreen from "./screens/AccountScreen";
import IndexScreen from "./screens/IndexScreen";
import DiscoverScreen from "./screens/DiscoverScreen";

import Spinner from "./components/Spinner";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import CreateQueueScreen from "./screens/CreateQueueScreen";
import HelpScreen from "./screens/HelpScreen";
import CreateProductScreen from "./screens/CreateProductScreen";
import MessagesScreen from "./screens/MessagesScreen";
import AlertsScreen from "./screens/AlertsScreen";
import NewAccountScreen from "./screens/NewAccountScreen";
import CheckoutForm from "./screens/testscreens/CheckoutForm";
import ProductCompleteScreen from "./screens/ProductCompleteScreen";
import ProductPurchaseScreen from "./screens/ProductPurchaseScreen";
import OrdersScreen from "./screens/cart/OrdersScreen";
import CheckoutScreen from "./screens/cart/CheckoutScreen";
import JoinedQueuesScreen from "./screens/JoinedQueuesScreen";
import MessageScreen from "./screens/MessageScreen";
import UnsubscribeScreen from "./screens/UnsubscribeScreen";
import SellersTermsScreen from "./screens/SellersTermsScreen";
import ReceiptScreen from "./screens/cart/ReceiptScreen";
import DashboardScreen from "./screens/dashboard/DashboardScreen";
import ProductListScreen from "./screens/dashboard/ProductListScreen";
import OrderScreen from "./screens/dashboard/OrderScreen";
import OrderListScreen from "./screens/dashboard/OrderListScreen";



// Amplify.configure(aws_exports);
Amplify.Logger.LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL;
Storage.configure({ level: 'protected' });

// const API_GATEWAY = process.env.REACT_APP_AWS_API_GATEWAY;
// var API_GATEWAY = process.env.REACT_APP_AWS_API_GATEWAY;

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-east-1:8cb06123-a379-4378-a442-cb1a6073acb6',

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    //identityPoolRegion: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_0f0cNoClG',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '2tkftlh0bmgjfri0idv5erpi27',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
  },
    API: {
    endpoints: [
      {
        name: "epiqueueapi",
        endpoint: process.env.REACT_APP_AWS_API_GATEWAY,
        region: "us-east-1"
      },
    ]
  },
  Storage: {
    AWSS3: {
      bucket: 'epiqueue-user-data', //REQUIRED -  Amazon S3 bucket
      region: "us-east-1"
    }
  }



});






const logger = new Logger('App');
logger.debug("worky");
logger.debug("worky " + JSON.stringify(process.env));

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      admin: false,
      isLoadingComplete: false,
    }
  }


  componentDidMount = async() => {

    // if (this.props.loggedInUser) {
    //   logger.debug("loggedInUser is set");
    // } else {
    //   logger.debug("loggedInUser is NOT set");
    // }


    User.getAuthenticated({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(
      (user) => {
        logger.debug(user);
        this.props.setLoggedInUser(user);
        this.setState({
          loggedIn: true,
          isLoadingComplete: true,
        });
      }).catch(err => {
        logger.debug(err);
        this.setState({
          isLoadingComplete: true,
      });

    });
  };

  render() {
    if (!this.state.isLoadingComplete) {
      return (
        <Router>
          <div>
            <Nav user={this.props.loggedInUser}/>
            <div className="container epiqueuecontainer">
              <Spinner icon={Spinner.ICONS.cog} size={Spinner.SIZE.mediumLarge} pageCenter={true}/>
            </div>
          </div>
        </Router>
      );
    }

    return (
      <Router>
        <div className="epiqueueapp">
          <Nav user={this.props.loggedInUser}/>

          <div className="container epiqueuecontainer">
            <Switch>

              <Route exact path="/" component={IndexScreen} />
              <Route exact path="/discover" component={DiscoverScreen} />

              <Route exact path="/queue/:id/createproduct" component={CreateProductScreen} />
              <Route exact path="/queue/:id/editproduct/:productid" component={CreateProductScreen} />
              <Route exact path="/queue/:id/:title?" component={QueueScreen} />

              <Route exact path="/queues" component={JoinedQueuesScreen} />

              <Route exact path="/product/complete/:identifier" component={ProductCompleteScreen} />
              <Route exact path="/product/purchase/:identifier" component={ProductPurchaseScreen} />

              <Route exact path="/product/:id/:title?" component={ProductScreen} />

              <Route path="/profile/:id?" component={ProfileScreen} />

              <Route exact path="/settings" component={AccountScreen} />
              <Route path="/help/:id?" component={HelpScreen} />
              <Route exact path="/messages" component={MessagesScreen} />
              <Route exact path="/message/:identifier" component={MessageScreen} />
              <Route exact path="/alerts" component={AlertsScreen} />

              <Route exact path="/dashboard" component={DashboardScreen} />
              <Route exact path="/dashboard/create" component={CreateQueueScreen} />
              <Route exact path="/dashboard/edit/:id" component={CreateQueueScreen} />
              <Route exact path="/dashboard/orders" component={OrderListScreen} />
              <Route exact path="/dashboard/order/:identifier" component={OrderScreen} />
              <Route exact path="/dashboard/products" component={ProductListScreen} />

              <Route exact path="/newaccount" component={NewAccountScreen} />

              <Route exact path="/about" component={AboutScreen} />
              <Route exact path="/terms" component={TermsScreen} />
              <Route exact path="/sellersterms" component={SellersTermsScreen} />
              <Route exact path="/privacy" component={PrivacyScreen} />

              <Route exact path="/login" component={LoginScreen} />
              <Route exact path="/register" component={RegisterScreen} />
              <Route exact path="/password_request" component={PasswordRequestScreen} />
              <Route exact path="/verify" component={VerifyScreen} />

              <Route exact path="/unsubscribe/:code" component={UnsubscribeScreen} />


              <Route exact path="/orders" component={OrdersScreen} />
              <Route exact path="/orders/checkout/:identifier" component={CheckoutScreen} />
              <Route exact path="/orders/receipt/:identifier" component={ReceiptScreen} />

              {/*Stripe Enabled Screens*/}
              <Route exact path="/checkout" component={CheckoutForm} />

              <Route component={NoMatchScreen} />
            </Switch>

          </div>

          <Footer/>

          { (process.env.REACT_APP_ENVIRONMENT !== 'production' ) ?
            <>
              <div className="pb-5"></div>
            <footer className="footer fixed-bottom alert-danger text-center">
              <span className="text-muted">This is a '{process.env.REACT_APP_ENVIRONMENT}' server.</span>
            </footer>
            </>
            : null }

        </div>


      </Router>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators({ setLoggedInUser, addUserToCache }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
