
import React, { Component } from 'react';
import "../../css/HelpScreen.css"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {API} from "aws-amplify";
import Message from "../../models/Message";
import BlankBox from "../BlankBox";

class ContactUs extends Component {
  static TITLE = "Contact Us";

  constructor(props) {
    super(props);

    this.state = {
      userNameFieldValue: this.props.loggedInUser ? this.props.loggedInUser.username : "",
      emailFieldValue: this.props.loggedInUser ? this.props.loggedInUser.email : "",
      messageFieldValue: "",
    };
  }

    renderContactInfo = () => {
    if (this.props.loggedInUser) {
      return this.renderLoggedOutContactInfo();
      //return this.renderLoggedInContactInfo();
    } else {
      return this.renderLoggedOutContactInfo();
    }
  };

  renderLoggedInContactInfo = () => {
    return(
      <div className="d-flex flex-row justify-content-start align-items-center p-2">
        <div className="w-50">Name: {this.props.loggedInUser.username}</div>
        <div className="w-50">Email: {this.props.loggedInUser.email}</div>
      </div>
    );
  };


  submitContactUs = (event) => {
    event.preventDefault();

    let apiName = 'epiqueueapi';
    let path = '/messaging/contact_us';
    let postData = {
      body: {
        message: this.state.messageFieldValue,
      }
    };
    API.post(apiName, path, postData).then((response) => {
      if (response.success) {
        let message = new Message(response.message);

        this.setState({
            messageFieldValue: message.message,
          }
        );
      }
    });

  }

  handleUserNameChange = (event) => {
    this.setState({userNameFieldValue: event.target.value});
  };

  handleEmailChange = (event) => {
    this.setState({emailFieldValue: event.target.value});
  };

  handleMessageChange = (event) => {
    this.setState({messageFieldValue: event.target.value});
  };

  renderLoggedOutContactInfo = () => {
    return(
      <div className="d-flex flex-row justify-content-between align-items-center p-2">
        <div className="mr-1">Name: </div>
        <input className={"form-control mr-3"}
               type="text"
               id="name"
               value={this.state.userNameFieldValue}
               onChange={this.handleUserNameChange}
               placeholder="Name"
               required={true}
        />
        <div className="invalid-feedback-name"/>

        <div className="mr-1">Email: </div>
        <input className={"form-control"}
               type="email"
               id="email"
               placeholder="Email"
               value={this.state.emailFieldValue}
               onChange={this.handleEmailChange}
               required={true}
        />
        <div className="invalid-feedback-email"/>
      </div>
    );
  }


  content  = () => {
    return (

          <div className="helpscreen-contactform">

            {this.renderContactInfo()}

            <div className="d-flex flex-column justify-content-center align-items-start p-2">
              <div>Message: </div>
              <div className="w-100">
              <textarea className="form-control"
                        id="message-text-area"
                        rows="5"
                        value={this.state.messageFieldValue}
                        onChange={this.handleMessageChange}
                        required={true}
              />
              </div>
              <div className="invalid-feedback-message"></div>
            </div>

            <div className="btn btn-primary epiqueue-darkblue m-3" onClick={this.submitContactUs}><span className="pl-3 pr-3">Send</span></div>
          </div>
    )

  }

  render() {
    return (
      <BlankBox contentCallback={this.content} title={ContactUs.TITLE} footer="Epiqueue, Inc, 1411 4th Ave, Seattle WA 98101"/>
    );
  }
}

const mapStateToProps = (state,  ownProps) => {
  return {
    loggedInUser: state.users.loggedInUser,
  }
};

export default withRouter(connect(
  mapStateToProps,
)(ContactUs));
