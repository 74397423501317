import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
// import {Logger} from "aws-amplify";
import "../css/ImageViewer.css"
import NoImage from "../img/image-placeholder.png"
// const logger = new Logger('ImageViewer');

class ImageViewer extends Component {

  static MAX_PROFILE_FILE_SIZE = 1000000;

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      selectedImage:0,
    }
  }


  showNextImage = () => {

    if (this.props.images.length === 0) {
      return;
    }

    let maxImages = this.props.images.length;

    let selectedImage = this.state.selectedImage < maxImages-1 ? this.state.selectedImage + 1 : this.state.selectedImage;
    this.setState({
      selectedImage: selectedImage,
    });
  }

  showPreviousImage = () => {
    if (this.props.images.length === 0) {
      return;
    }

    let selectedImage = this.state.selectedImage > 0 ? this.state.selectedImage - 1 : 0;
    this.setState({
      selectedImage: selectedImage,
    });
  }

  render() {


    let {images} = this.props;

    if (images === null || images === undefined) {
      images = [];
    }

    let numberOfImage = images.length;

    let primaryImage  = null;
    if (numberOfImage === 0) {
      primaryImage = <img className="image-viewer-image" src={NoImage} alt={"none"}/>
    } else {
      let image = images[this.state.selectedImage];
      primaryImage = <img className="image-viewer-image" src={"https://cdn.epiqueue.com/public/" + image.url} alt={"primary"}/>
    }


    let thumbNailSize =  "100%";

    let imagesDivs = images.map((item, index)=> {
        return (
          <div key={index}>
          <img className={"image-viewer-thumbnail" + ((index===this.state.selectedImage) ? " image-viewer-thumbnail-selected" : "")} src={"https://cdn.epiqueue.com/public/" + item.url}
               width={thumbNailSize} height={thumbNailSize}
               onClick={() => this.setState({selectedImage: index})}
               alt={"item " + index}
          />
          </div>
        )
    });

    return (
      <div className="d-flex flex-column justify-content-start align-items-start image-viewer">
        <div className="image-viewer-primary-image">
          {numberOfImage > 1 ?
          <div className="btn btn-link image-viewer-primary-nav epiqueue-darkblue-text image-viewer-chevron-buttons" onClick={this.showPreviousImage}><i className="far fa-chevron-left"></i></div>
            : null }
          <div>
            {primaryImage}
          </div>
          {numberOfImage > 1 ?
          <div className="btn btn-link image-viewer-primary-nav epiqueue-darkblue-text image-viewer-chevron-buttons" onClick={this.showNextImage}><i className="far fa-chevron-right"></i></div>
            : null }
        </div>
        {numberOfImage > 1 ?
            <div className="image-viewer-thumbnail-area">
                {imagesDivs}
            </div>
          : null }
      </div>
    )
  };
}


ImageViewer.defaultProps = {
  images: [],
  size: 200,
};

ImageViewer.propTypes = {
  images: PropTypes.array.isRequired,
  size: PropTypes.number.isRequired
};

export default withRouter(ImageViewer);

