import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {API, Logger} from "aws-amplify";

import "../../css/ShippingInfoModal.css"
import Sale from "../../models/Sale";
import Product from "../../models/Product";

const logger = new Logger('ShippingInfoModal');

class ShippingInfoModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      shipBy: this.props.order.carrier ? this.props.order.carrier : "",
      trackingNumber:  this.props.order.tracking_number ? this.props.order.tracking_number : "",
      comment: this.props.order.shipping_comment ? this.props.order.shipping_comment : "",
      invalidItems: [],
      errorMessage: null,
      okLabel: this.props.order.isShipped() ? "Update Shipping" : "Ship It",
      cancelLabel: "Cancel",
    }
  }

  cancelChanges = (e) => {
    this.setState({
      shipBy: "",
      trackingNumber: "",
      comment: "",
    });

    logger.debug("cancel");

    if (this.props.cancelCallback) {
      logger.debug("callback");
      this.props.cancelCallback();
    } else {
      logger.debug("no callback");
    }
  };


  hasChanges = () => {
    if (this.props.shipBy !== this.state.shipBy) {
      logger.debug("shipBy changed: ");
      return true;
    }
    if (this.props.trackingNumber !== this.state.trackingNumber) {
      logger.debug("trackingNumber changed: ");
      return true;
    }
    if (this.props.comment !== this.state.comment) {
      logger.debug("comment changed: ");
      return true;
    }

    return false;
  };

  validate = () => {
    let invalidItems = {};

    if (this.state.shipBy.trim() === '') {
      invalidItems['shipBy'] = 'Please indicate how it was shipped';
    }
    // if (this.state.trackingNumber.trim() === '') {
    //   invalidItems['trackingNumber'] = 'Please include a tracking number';
    // }

    if (Object.keys(invalidItems).length > 0) {
      this.setState({
        invalidItems: invalidItems,
        errorMessage: "Please correct the errors above",
      });
      return false;
    }

    return true;
  };


  saveChanges = async(e) => {
    e.preventDefault();
    e.stopPropagation();


    if (!this.validate()) {
      return;
    }

    if (!this.hasChanges()) {
      this.cancelChanges();
      return;
    }

    let apiName = 'epiqueueapi';
    let path = '/dashboard/shipping';
    let params = {
      body: {
        carrier: this.state.shipBy,
        tracking_number: this.state.trackingNumber,
        shipping_comment: this.state.comment,
        identifier: this.props.order.identifier,
      }
    };
    try {
      var response = await API.post(apiName, path, params);
      if (response.success) {
        if (this.props.okCallback) {
          this.props.okCallback(new Sale(response.order));
        }
      } else {
        this.setState({
          errorMessage: response.errorMessage
        });
        return;
      }
    } catch (error) {
      this.setState({
        errorMessage: error.message
      });
      return;
    }



  };


  stopEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  };

  updateTextField(field, event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      [field]: event.target.value,
      invalidItems: [],
      errorMessage: null,
    });
  }

  invalidState(item) {
    if (item in this.state.invalidItems) {
      return 'is-invalid'
    }
  }


  render() {

    let classes = "modal fade d-inline-block shippinginfo-modal show";

    return (
      <>
      <div className='modal-backdrop in' onClick={(e) => this.cancelChanges(e)}/>
      <div className={classes} id={this.props.order.identifier} tabIndex="-1" role="dialog" aria-labelledby="shippinginfo-modal" aria-hidden="true" >
        <div className="modal-dialog shippinginfo-modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="shippinginfo-modal">Enter Shipping Information</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => this.cancelChanges(e)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body shippingaddressmodal-modal-body">
              <div className="d-flex flex-column">
                <div>{JSON.stringify(this.props.product.name)}</div>
                <form className={"form"}>

                  <div className="border-label"><h6>Shipped Via</h6></div>
                  <div className="form-group border p-2" style={{backgroundColor: '#eeeeee'}}>
                    <div className="form w-100">
                      <input type="text" className={"form-control " + this.invalidState('shipBy')}
                                id="shipBy"
                                placeholder="Ship By"
                                onKeyPress={this.stopEnterKeyPress}
                                onChange={(event) => this.updateTextField('shipBy', event)}
                                aria-label="username"
                             value={this.state.shipBy}
                      />
                      <div className="invalid-feedback">{this.state.invalidItems.shipBy}</div>
                    </div>
                  </div>

                  <div className="border-label"><h6>Tracking Number</h6></div>
                  <div className="form-group border p-2" style={{backgroundColor: '#eeeeee'}}>
                    <div className="form w-100">
                      <input type="text" className={"form-control " + this.invalidState('trackingNumber')}
                             id="trackingNumber"
                             placeholder="Tracking Number"
                             onKeyPress={this.stopEnterKeyPress}
                             onChange={(event) => this.updateTextField('trackingNumber', event)}
                             aria-label="trackingNumber"
                             value={this.state.trackingNumber}
                      />
                      <div className="invalid-feedback">{this.state.invalidItems.trackingNumber}</div>
                    </div>
                  </div>

                  <div className="border-label"><h6>Comment</h6></div>
                  <div className="form-group border p-2" style={{backgroundColor: '#eeeeee'}}>
                    <div className="form w-100">
                      <textarea className={"form-control " + this.invalidState('comment')}
                                id="comment"
                                placeholder="comment"
                                rows={2}
                                onChange={(event) => this.updateTextField('comment', event)}
                                aria-label="comment"
                                value={this.state.comment}
                      />
                      <div className="invalid-feedback">{this.state.invalidItems.description}</div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
                <div className="text-danger">{this.state.errorMessage}</div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onKeyDown={this.userNameKey} onClick={ (e) => this.cancelChanges(e)}>{this.state.cancelLabel}</button>
              <button type="button" className="btn btn-primary" onClick={this.saveChanges}>{this.state.okLabel}</button>
            </div>
          </div>
        </div>
      </div>
        </>
    )
  };
}

ShippingInfoModal.defaultProps = {
  okLabel: "Ship It",
  cancelLabel: "Cancel",
};

ShippingInfoModal.propTypes = {
  order: PropTypes.instanceOf(Sale).isRequired,
  product: PropTypes.instanceOf(Product).isRequired,
  okCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
};

export default ShippingInfoModal;

