
import Model from "./Model";

// const logger = new Logger('Tag');


export default class Tag extends Model {

  /**
   *
   * @param json
   */
  constructor(json) {
    super();

    let {
      id,
      tag,
      table_name,
      primary_key,
      created_at,
      updated_at
    } = json;

    // database fields
    this.id = id;
    this.tag = tag;
    this.table_name = table_name;
    this.primary_key = primary_key;
    this.created_at = created_at;
    this.updated_at = updated_at;

  }

}
