import React, {} from 'react';
import {withRouter} from "react-router-dom";
import RootScreen from "./RootScreen";
import {connect} from "react-redux";
import ErrorMessage from "../components/ErrorMessage";
import {Elements, StripeProvider} from "react-stripe-elements";
import StripeSaveCreditCardForm from "../components/StripeSaveCreditCardForm";

import "../css/AccountScreen.css"
import {API, Auth, Logger} from "aws-amplify";
import Loading from "../components/Loading";
import StripeCustomer from "../models/StripeCustomer";
import ShippingAddress from "../models/ShippingAddress";
import ShippingAddressForm from "../components/ShippingAddressForm";
import OKCancelModal from "../components/modals/OKCancelModal";
import {setLoggedInUser, setPendingCartItemsCount, setUnreadMessagesCount} from "../redux/actions";

const logger = new Logger('AccountScreen');

class AccountScreen extends RootScreen {


  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'AccountScreen'
    });

    this.state = {
      errorMessage: null,
      successMessage: null,
      stripe: null,
      showCardForm: false,
      showShippingAddressForm: false,
      shippingAddresses: [],
      stripeCustomer: null,
      loaded: false,
      deleteingAccount: false,
    }
  }


  componentDidMount = async() => {
    window.scrollTo(0, 0);

    if (window.Stripe) {
      this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_TOKEN)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_TOKEN)});
      });
    }

    this.loadCustomer();

  };

  loadCustomer = async () => {
    let apiName = 'epiqueueapi';
    let path = '/payment/get_customer';
    let postData = {
      body: {
      }
    };

    try {
      let response = await API.post(apiName, path, postData);
      if (response.success) {
        this.setState({
          stripeCustomer: response.stripeCustomer ? new StripeCustomer(response.stripeCustomer) : null,
          shippingAddresses: response.shippingAddresses.map(item => {
            return new ShippingAddress(item);
          }),
          loaded: true,
        });
      } else {
        await this.setState({
          errorMessage: response.errorMessage,
          loaded: true,
        });
      }
    } catch (error) {
      await this.setState({
        errorMessage: error.message,
        loaded: true,
      });
    }
  };


  showCardForm = () => {
    this.setState({
      showCardForm: true
    });
  };

  showShippingAddressForm = () => {
    this.setState({
      showShippingAddressForm: true
    });
  };

  onCancel = () => {
    this.setState({
      showCardForm: false,
    });
    window.scrollTo(0, 0);
  };

  onSubmit = (stripeCustomer) => {
    this.setState({
      showCardForm: false,
      stripeCustomer: stripeCustomer,
      successMessage: "Successfully updated account"
    });
    this.loadCustomer();

  };

  onAddShippingAddressCancel = () => {
    this.setState({
      showShippingAddressForm: false,
    });
  };

  onAddShippingAddressSubmit = (newShippingAddress) => {
    let {shippingAddresses} = this.state;
    if (shippingAddresses === null) {
      shippingAddresses = [];
    }

    shippingAddresses.push(newShippingAddress);

    this.setState({
      shippingAddresses: shippingAddresses,
      showShippingAddressForm: false,
    });
  };

  makeAddressPrimary = async(addressId) => {
    logger.debug("makeAddressPrimary: " + addressId)

    let apiName = 'epiqueueapi';
    let path = '/user/make_shipping_address_primary';
    let postData = {
      body: {
        shipping_address_id: addressId
      }
    };

    try {
      let response = await API.post(apiName, path, postData);
      logger.debug("response: " + JSON.stringify(response))
      if (response.success) {
        this.setState({
          shippingAddresses: response.shippingAddresses.map(item => {
            return new ShippingAddress(item);
          }),
          loaded: true,
        });
      } else {
        await this.setState({
          errorMessage: response.errorMessage,
          loaded: true,
        });
      }
    } catch (error) {
      logger.debug(error)
      await this.setState({
        errorMessage: error.message,
        loaded: true,
      });
    }


  }

  deleteAddress = async(addressId) => {
    logger.debug("deleteAddress: " + addressId)

    let apiName = 'epiqueueapi';
    let path = '/user/shipping_address';
    let deleteData = {
      body: {
        shipping_address_id: addressId
      }
    };

    logger.debug("deleteData: " + JSON.stringify(deleteData))

    try {
      logger.debug("GO");
      let response = await API.del(apiName, path, deleteData);
      logger.debug("response: " + JSON.stringify(response))
      if (response.success) {
        this.setState({
          shippingAddresses: response.shippingAddresses.map(item => {
            return new ShippingAddress(item);
          }),
          loaded: true,
        });
      } else {
        await this.setState({
          errorMessage: response.errorMessage,
          loaded: true,
        });
      }
    } catch (error) {
      logger.debug(error)
      await this.setState({
        errorMessage: error.message,
        loaded: true,
      });
    }
  };


  closeAccount = async() => {
    logger.debug("close account");

    this.setState({
      deleteingAccount: true,
    });


    let apiName = 'epiqueueapi';
    let path = '/user/close_account';
    try {
      let response = await API.post(apiName, path);
      if (response.success) {
        this.setState({
          deleteingAccount: false,
        });
      } else {
        await this.setState({
          errorMessage: response.errorMessage,
          deleteingAccount: false,
        });

        window.scrollTo(0, 0);

        return;
      }
    } catch (error) {
      logger.debug(error)
      await this.setState({
        errorMessage: error.message,
        deleteingAccount: false,
      });

      window.scrollTo(0, 0);

      return;
    }



    Auth.signOut()
      .then(data => {
        this.props.setLoggedInUser(null);
        this.props.setPendingCartItemsCount(0);
        this.props.setUnreadMessagesCount(0);
        this.props.history.replace('/');
      })
      .catch(err => logger.debug(err));


  };

  renderDefaultCreditCard() {
    if (!this.state.stripeCustomer) {
      return null;
    }

    if (!this.state.stripeCustomer.last_4) {
      return null;
    }



    return (
      <div className="accountscreen-default-source">
        <span>{this.state.stripeCustomer.getMaskedCreditCardNumber()}</span>
        <br/>
        Expires: {this.state.stripeCustomer.expiration}
      </div>
    );
  }


  renderShippingAddresses = () => {
    return (
      this.state.shippingAddresses.map( (item) => {
        return (
          <tr key={item.id}>
            <td>
              {item.name}{item.primary ? <span>&nbsp;&nbsp;&nbsp;<span className="badge badge-warning">primary</span></span> : null } <br/>
            {item.address1}<br/>
              {item.address2 ? item.address2 : null}
              {item.address2 ? <br/> : null}
            {item.city}, {item.state} {item.zip}<br/>
            {item.country}
            </td>
            <td>
              {item.primary ? null : <div className="btn btn-sm btn-success mr-2" onClick={() => this.makeAddressPrimary(item.id)}>Make Primary</div>}
              {item.primary ? null : <div className="btn btn-sm btn-danger" onClick={() => this.deleteAddress(item.id)}>Delete Address</div>}
              {item.primary ? <span className="text-secondary">Can not delete the primary address</span> : null}
            </td>
          </tr>
        );
      })
    );

  }



  renderContent() {
    if (!this.props.loggedInUser) {
      return <ErrorMessage errorMessage={"You are not logged in"}/>
    }


    if (!this.state.loaded) {
      return (
        <Loading/>
      );
    }


    return (
      <div className="accountscreen mb-5">


        <ErrorMessage errorMessage={this.state.errorMessage}/>
        <ErrorMessage errorMessage={this.state.successMessage} type={'success'}/>

        <div className="accountscreen-heading">
        Your Account Settings
      </div>



        <div className="accountscreen-section-heading col-lg-6 col-md-9">
          Saved Payment Method
        </div>
        <div className="mb-3">
          Don't miss out on getting that hard to find item.<br/>Adding a payment method allows you to immediately commit to purchasing an item from a queue when it becomes available.
        </div>


        {this.renderDefaultCreditCard()}


        <div className={"btn btn-sm btn-primary epiqueue-darkblue"  + (this.state.showCardForm ? " d-none" : "")} onClick={this.showCardForm}>{this.state.stripeCustomer ? 'Update ' : "Add "} Payment Method</div>

        <div className={"w-100 mt-3" + (!this.state.showCardForm ? " d-none" : " d-flex")}>
          <div className="flex-grow-1">
            <StripeProvider stripe={this.state.stripe}>
              <Elements>
                <StripeSaveCreditCardForm onSubmit={this.onSubmit} onCancel={this.onCancel} loggedInUser={this.props.loggedInUser} stripeCustomer={this.state.stripeCustomer}/>
              </Elements>
            </StripeProvider>
          </div>
        </div>

        <div className="accountscreen-section-heading col-lg-6 col-md-9">
          Saved Shipping Addresses
        </div>

        <table className="table table-borderless">
          <tbody>
            {this.renderShippingAddresses()}
          </tbody>
        </table>
        <div>

          { this.state.showShippingAddressForm ?
            <ShippingAddressForm user={this.props.loggedInUser} onCancel={this.onAddShippingAddressCancel} onSubmit={this.onAddShippingAddressSubmit}/>
          :
            <div className={"btn btn-sm btn-primary mt-2 epiqueue-darkblue"  + (this.state.showShippingAddressForm ? " invisible" : "")} onClick={this.showShippingAddressForm}>Add A Shipping Address</div>
          }
        </div>


        <div className="d-none">
          <div className="accountscreen-section-heading col-lg-6 col-md-9">
            Account Status
          </div>
          <div>
          <small>When you close your account, we remove any stored payment methods, remove you from all subscribed queues and your profile will not appear anywhere on the site. You can re-open your account simply by logging back in.</small>
          </div>
          <div className={"btn btn-sm btn-primary mt-2 epiqueue-darkblue"}
               data-toggle = "modal"
               data-target = {"#delete-account-modal"}
          >Close My Account</div>
          <OKCancelModal message={<div>Are you sure you want to close your account?</div>} id={"delete-account-modal"} title={"Delete Account?"} okCallback={this.closeAccount} okLabel={"Close My Account"}/>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
  {setLoggedInUser, setPendingCartItemsCount, setUnreadMessagesCount}
)(AccountScreen));
