import React, { Component } from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {API, Logger} from "aws-amplify";

const logger = new Logger('CurrentStats');

class CurrentStats extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errorMessage:null,
      dataLoaded: false,
      errorLoading: false,
      data: [],
    };
  }


  componentDidMount() {
    if (this.props.loggedInUser) {
      this.loadData();
    } else {
      let data = ['stats'];
      data['stats']['order_count'] = 'error';
      data['stats']['total_sales'] = 'error';
      this.setState({
        data: data,
        dataLoaded: true,
      });
    }
  }

  loadData = async () => {
    try {
      let apiName = 'epiqueueapi';
      let path = '/dashboard/current';
      let response = await API.get(apiName, path);
      if (response.success) {
        this.setState({
          data: response.data,
          dataLoaded: true,
        });
      } else {
        this.setState({
          data: this.createErrorData(),
          errorMessage: response.errorMessage,
          errorLoading: true,
          dataLoaded: true,
        });
      }
    } catch(error) {
      logger.error("ERROR!!!!");
      logger.error(error);

      this.setState({
        errorMessage: "Exception:"  + error.message,
        data: this.createErrorData(),
        errorLoading: true,
        dataLoaded: true,
      });
    };

  };


  createErrorData = () => {
    let data = [];
    data['orders'] = [];
    data['orders']['new'] = 'error';
    data['orders']['shipped'] = 'error';
    data['orders']['messages'] = 'error';

    data['products'] = [];
    data['products']['active'] = 'error';
    data['products']['expired'] = 'error';
    data['products']['soldout'] = 'error';


    return data;
  }


  renderOrderOverview = () => {
    return (
      <>
        <tr key={'new'}>
          <td>
            New
          </td>
          <td align="right">
            { this.state.dataLoaded === false ? <div>-</div>
              :  this.state.data['orders']['new']
            }
          </td>
        </tr>
        <tr key={'shipped'}>
          <td>
            Shipped
          </td>
          <td align="right">
            { this.state.dataLoaded === false ? <div>-</div>
              :  this.state.data['orders']['shipped']
            }
          </td>
        </tr>
        <tr key={'other'}>
          <td>
            Other
          </td>
          <td align="right">
            { this.state.dataLoaded === false ? <div>-</div>
              :  this.state.data['orders']['other']
            }
          </td>
        </tr>
        <tr key={'mess'}>
          <td>
            Customer Messages
          </td>
          <td align="right">
            { this.state.dataLoaded === false ? <div>-</div>
              :  this.state.data['orders']['messages']
            }
          </td>
        </tr>
      </>
    );
  };

  renderProductOverview = () => {
    return (
      <>
        <tr key={'new'}>
          <td>
            Active
          </td>
          <td align="right">
            { this.state.dataLoaded === false ? <div>-</div>
              :  this.state.data['products']['active']
            }
          </td>
        </tr>
        <tr key={'shipped'}>
          <td>
            Inactive
          </td>
          <td align="right">
            { this.state.dataLoaded === false ? <div>-</div>
              :  this.state.data['products']['not_active']
            }
          </td>
        </tr>
        <tr key={'second_chance'}>
          <td>
            Upcoming
          </td>
          <td align="right">
            { this.state.dataLoaded === false ? <div>-</div>
              :  this.state.data['products']['upcoming']
            }
          </td>
        </tr>
        <tr key={'mess'}>
          <td>
            Other
          </td>
          <td align="right">
            { this.state.dataLoaded === false ? <div>-</div>
              :  this.state.data['products']['other']
            }
          </td>
        </tr>
      </>
    );
  };


  render() {
    if (!this.props.loggedInUser) {
      return null;
    }

    return (
      <>
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <div className="dashboard-screen-title">Current Statistics</div>
        </div>

        <div className="dashboard-current-stats-card">
          <div className="dashboard-current-stats-box">
            <table className="table w-100 mb-0">
              <thead>
              <tr>
                <th>Orders</th>
                <th align="right" style={{'textAlign': 'right'}}><Link className="epiqueue-darkblue-text" to="/dashboard/orders"><div className="btn btn-primary btn-sm btn-epiqueue">View All Orders</div></Link></th>
              </tr>
              </thead>
              <tbody>
              {this.renderOrderOverview()}
              </tbody>
            </table>
          </div>

          <div className="dashboard-current-stats-box dashboard-current-stats-box-right">
            <table className="table w-100 mb-0">
              <thead>
              <tr>
                <th>Product Listings</th>
                <th className="d-none" align="right" style={{'textAlign': 'right'}}><Link className="epiqueue-darkblue-text" to="/dashboard/products"><div className="btn btn-primary btn-sm btn-epiqueue">View Products</div></Link></th>
              </tr>
              </thead>
              <tbody>
              {this.renderProductOverview()}
              </tbody>
            </table>
          </div>

        </div>
        </>
      )
  };
}

const mapStateToProps = (state,  ownProps) => {
  return {
    loggedInUser: state.users.loggedInUser,
  }
};

export default withRouter(connect(
  mapStateToProps,
)(CurrentStats));


