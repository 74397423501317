
import Model from "./Model";

export default class StripeCustomer extends Model {

  /**
   *
   * @param json
   */
  constructor(json) {
    super();

    let {
      id,
      user_id,
      stripe_customer_id,
      last_4,
      expiration,
      brand,
      billing_name,
      billing_address1,
      billing_address2,
      billing_city,
      billing_state,
      billing_zip,
      billing_country,
      created_at,
      updated_at
    } = json;

    // database fields
    this.id = id;
    this.user_id = user_id;
    this.stripe_customer_id = stripe_customer_id;
    this.last_4 = last_4;
    this.expiration = expiration;
    this.brand = brand;
    this.billing_name = billing_name;
    this.billing_address1 = billing_address1;
    this.billing_address2 = billing_address2;
    this.billing_city = billing_city;
    this.billing_state = billing_state;
    this.billing_zip = billing_zip;
    this.billing_country = billing_country;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }


  getMaskedCreditCardNumber() {
    if (!this.last_4) {
      return "No credit card available";
    }

    if (this.brand === 'American Express') {
      return '' + this.brand + '*** ****** *' + this.last_4;
    }

    return this.brand + ' **** **** **** ' + this.last_4;


  }
}

/**
 +--------------------+---------------------+------+-----+-------------------+-----------------------------+
 | Field              | Type                | Null | Key | Default           | Extra                       |
 +--------------------+---------------------+------+-----+-------------------+-----------------------------+
 | id                 | bigint(20) unsigned | NO   | PRI | NULL              | auto_increment              |
 | user_id            | bigint(20) unsigned | NO   |     | NULL              |                             |
 | stripe_customer_id | varchar(255)        | YES  |     | NULL              |                             |
 | last_4             | varchar(8)          | YES  |     | NULL              |                             |
 | expiration         | varchar(12)         | YES  |     | NULL              |                             |
 | brand              | varchar(255)        | YES  |     | NULL              |                             |
 | billing_name       | varchar(255)        | YES  |     | NULL              |                             |
 | billing_address1   | varchar(255)        | YES  |     | NULL              |                             |
 | billing_address2   | varchar(255)        | YES  |     | NULL              |                             |
 | billing_city       | varchar(255)        | YES  |     | NULL              |                             |
 | billing_state      | varchar(4)          | YES  |     | NULL              |                             |
 | billing_zip        | varchar(255)        | YES  |     | NULL              |                             |
 | billing_country    | varchar(2)          | YES  |     | NULL              |                             |
 | created_at         | timestamp           | NO   |     | CURRENT_TIMESTAMP |                             |
 | updated_at         | timestamp           | NO   |     | CURRENT_TIMESTAMP | on update CURRENT_TIMESTAMP |
 +--------------------+---------------------+------+-----+-------------------+-----------------------------+
 15 rows in set (0.09 sec)

 */

