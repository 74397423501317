
import React, { Component } from 'react';
import "../../css/HelpScreen.css"
import {Link, withRouter} from "react-router-dom";

class SellerGetStarted extends Component {
  static TITLE = "Getting Started As a Seller";

  content() {
    return (
      <>
      <div className="alert alert-info">Put some stuff here</div>
        <div>We made a short video to help you get started: <a className="epiqueue-darkblue-text" target="_blank" rel="noopener noreferrer" href="https://youtu.be/xVHdgJbjQ3A">Watch on YouTube</a></div>
      </>
    )
  }

  render() {
    return (
      <>
        <div className="helpscreen-largetitle">Getting Started As a Seller</div>

        <div className="helpscreen-subheader">
          Overview
        </div>

        <div className="helpscreen-paragraph">
          As a seller you will mainly be creating two kinds of items, <strong>queues and products</strong>.
          <ul>
            <li>
              <strong>Queues</strong> are like mini-storefronts. <br/>You can create multiple queues, splitting out the different types of products you sell. For example, if you are an artist, you might have
              have one queue for originals and another for prints. For fashion, you might create one for Men's clothes and one for Women's. If and How you separate them is up to you. <strong>Think of queues as categories and are
            more general than the products that will be sold</strong>
            </li>
            <li>
              <strong>Products</strong> are the actual items you sell. You sell your product through your queue. Users join queues in which they are interested, and then become eligible to buy the products as they are made available in a queue.
            </li>
          </ul>
        </div>


        <div className="helpscreen-subheader">
          How Epiqueue Works
        </div>
        <div className="helpscreen-paragraph">
          <strong>Epiqueue is geared towards limited edition and small batch products</strong>. Users become members of your queue and get the option to buy based on their position in the queue. We do this to make buying limited items more fair and equatible.
          Once a member buys from a queue, they are re-queued at the back of the line and other members move up, giving them a better shot at the next product.
          <ul>
            <li>You have one item for sale at a given time.</li>
            <li>An item is listed as "live" for 48 hours from the time you make it live. You, however, can decide to end a sale at any time before the 48 hours is up.</li>
            <li>When you make an item live, each member of the queue is given the option to decide "Purchase" or "Pass".</li>
            <li>When the sale ends, those that were able to purchase, have their cards charged and are notified of their successful purchase.</li>
          </ul>
        </div>


        <div className="helpscreen-subheader">
          First Steps
        </div>
        <div className="helpscreen-paragraph">
          <ol>
            <li><strong>Create your first queue</strong><br/>
              We suggesting making your first queue as broad as possible: "Watercolors" or "Men's T-Shirts".
              <br/>
              To help get you started, we have written a small <Link className="epiqueue-darkblue-text" to="/help/creating-a-queue"> step-by-step quide</Link> to getting your first queue created
            </li>
            <li><strong>Create your first product</strong><br/>
              We've also written a <Link className="epiqueue-darkblue-text" to="/help/creating-a-product"> step-by-step quide</Link> to getting your first product created
            </li>
            <li><strong>Make your product "live"</strong><br/>
              When you are happy with your product and ready to sell. You <strong>Make it live</strong> which notifies your queue members that it is available.
              If a member joins your queue after a product is live, they automatically become eligible to buy the currently live item.
            </li>
            <li><strong>Market your queue</strong><br/>
              Of course, just having a queue is not enough, you need members to join your queue. We feature some queues on the home page and through our social media. You can also encourage members to join through your usual
              channels: social media, websites, etc. We are trying to grow the user pool as quickly as we can and as you bring users interested in your items, it not only helps you but the other artists and sellers onboard.
            </li>
          </ol>
        </div>

        <div className="helpscreen-subheader">
          After a sale
        </div>
        <div className="helpscreen-paragraph">
          <ol>
            <li><strong>Get Paid!</strong><br/>
              When a queue member purchases a product, you are paid your share of the amount immediately (we never hold the money in our account). You should see the amount deposited immediately into your Stripe account.
            </li>
            <li><strong>Check your order</strong><br/>
              Check your : <Link className="epiqueue-darkblue-text" to="dashboard/orders">Seller's Orders Page</Link> for a list of orders. Items with a status of <strong>Paid</strong> have successfully been charged and are awaiting shipment.
            </li>
            <li><strong>Ship Your Product</strong><br/>
              When ready, ship your product to the successful buyers. Use the <strong>Ship Product</strong> button to enter shipping information. This will trigger an email to the user that the product is on its way.
            </li>
            <li><strong>Support Your Customers</strong><br/>
              When a member buys from you in Epiqueue, they are your customer. We encourage all customers to contact you directly to handle any disputes.
            </li>
          </ol>
        </div>



      </>
    )
  }
}

export default withRouter(SellerGetStarted);