import React, { Component } from 'react';
import PropTypes from 'prop-types';

import "../css/Pager.css";
import {Logger} from "aws-amplify";

const logger = new Logger('Pager');

class Pager extends Component {

  constructor(props) {
    super(props);

    let selected = this.props.selected;
    if (selected < 1) {
      selected = 1;
    } else if (selected > this.props.total) {
      selected = this.props.total;
    }

    this.state = {
      selected: selected,
    };
  }

  setSelected = (selected) => {

    logger.debug(selected);

    if (selected === this.state.selected) {
      return;
    }

    if (selected < 1) {
      selected = 1;
    }

    if (selected > this.props.total) {
      selected = this.props.total;
    }


    this.setState({
      selected: selected,
    });

    logger.debug("?");

    if (this.props.callback) {
      logger.debug("callback");
      this.props.callback(selected);
    }
  };

  selectNext = () => {
    if (this.state.selected === this.props.total) {
      return;
    }

    if (this.state.selected > this.props.total) {
      this.setSelected(this.props.total);
      return;
    }

    this.setSelected(this.state.selected+1);
  };

  selectPrev = () => {
    if (this.state.selected === 1) {
      return;
    }

    if (this.state.selected < 1) {
      this.setSelected(1);
      return;
    }

    this.setSelected(this.state.selected-1);
  };

  selectFirst = () => {
    this.setSelected(1);
  };

  selectLast = () => {
    this.setSelected(this.props.total);
  };

  renderItems = () => {
    let {selected} = this.state;
    let {total} = this.props;

    let items = [];
    for (let i = 1 ; i <= total ; i++) {
      if (i === selected) {
        items.push(<div key={i} className="btn btn-link pager-item pager-item-selected" onClick={() => this.setSelected(i)}>{i}</div>);
      } else {
        items.push(<div key={i} className="btn btn-link pager-item" onClick={() => this.setSelected(i)}>{i}</div>);
      }
    }
    return items;
  };

  render() {

    if (this.props.total <= 1) {
      return null;
    }

    return (
      <div className="d-flex flex-row">
        {this.props.total > 10 ?
        <div className="btn btn-link pager-item" onClick={this.selectFirst}><i className="fal fa-chevron-double-left"></i></div>
          : null }
        <div className="btn btn-link pager-item" onClick={this.selectPrev}><i className="fal fa-chevron-left"></i></div>
        {this.renderItems()}
        <div className="btn btn-link pager-item" onClick={this.selectNext}><i className="fal fa-chevron-right"></i></div>
        {this.props.total > 10 ?
          <div className="btn btn-link pager-item" onClick={this.selectLast}><i className="fal fa-chevron-double-right"></i></div>
          : null }
      </div>
    )
  };
}

Pager.defaultProps = {
};

Pager.propTypes = {
  total: PropTypes.number.isRequired,
  selected: PropTypes.number.isRequired,
  callback: PropTypes.func,
};

export default Pager;
