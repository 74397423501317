import React, {} from 'react';
import {withRouter} from "react-router-dom";
import {setLoggedInUser} from "../redux/actions";
import {connect} from "react-redux";
import User from "../models/User";
import {API, Logger, Storage} from "aws-amplify";
import ProfilePic from "../components/ProfilePic";
import moment from "moment";
import ErrorMessage from "../components/ErrorMessage";
import Util from '../Util';
import RootScreen from "./RootScreen";

import "../css/ProfileScreen.css";
import ScrollingQueueList from "../components/ScrollingQueueList";
import Queue from "../models/Queue";
import EditProfileModal from "../components/modals/EditProfileModal";
import Loading from "../components/Loading";
import SendMessageModal from "../components/modals/SendMessageModal";

const logger = new Logger('ProfileScreen');

class ProfileScreen extends RootScreen {

  static MAX_PROFILE_FILE_SIZE = 1000000;

  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'ProfileScreen'
    });

    const { match } = this.props;

    let isSelf = false;
    if (!match.params.id) {
      isSelf = true;
    } else {
      if (this.props.loggedInUser && match.params.id) {
        isSelf = Number(this.props.loggedInUser.id) === Number(match.params.id);
      } else {

      }
    }

    this.state = {
      id: (match.params.id) ? match.params.id : null,
      isSelf: isSelf,
      user: null,
      userLoaded: false,
      errorMessage: null,
      uploadingProfilePic: false,
      currentUserInfo: null,
      queueMemberships: [],
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0);

    this.loadUser();
  }

  loadUser = async() => {

    this.setState({
      userLoaded: false,
    });


    let userId = this.state.id;
    if (!userId && this.props.loggedInUser) {
      userId = this.props.loggedInUser.id;
    }

    if (!userId) {
      this.setState({
        user: null,
        errorMessage: "No user Id",
        userLoaded: true,
      });
      return;
    }

    let apiName = 'epiqueueapi';
    let path = '/user/id/' + userId;
    try {
      let response = await API.get(apiName, path);

      if (response.success) {

        let newUser = new User(response.user);
        this.setState({
          user: newUser,
          errorMessage: null,
          userLoaded: true,
        });
      } else {
        this.setState({
          user: null,
          errorMessage: response.error,
          userLoaded: true,
        });
      }
    } catch (error) {
      this.setState({
        user: null,
        errorMessage: error.message,
        userLoaded: true,
      });
    }

    if (this.state.isSelf) {
      path = '/queue/joined';
    } else {
      path = '/queue/joined/' + userId;
    }
    try {
      let response = await API.get(apiName, path);

      if (response.success) {

        let queueMemberships = response.queues.map(item => {
          return new Queue(item);
        });
        this.setState({
          queueMemberships: queueMemberships,
          errorMessage: response.error,
          userLoaded: true,
        });
      } else {
        this.setState({
          errorMessage: response.error,
          userLoaded: true,
        });
      }
    } catch (error) {
      logger.error(error);

      this.setState({
        errorMessage: error.message,
        userLoaded: true,
      });
    }

  };


  componentDidUpdate(prevProps) {

    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }

    if (prevProps.match.params.id !== this.props.match.params.id) {

      let isSelf = false;
      if (!this.props.match.params.id) {
        isSelf = true;
      } else {
        if (this.props.loggedInUser && this.props.match.params.id) {
          isSelf = Number(this.props.loggedInUser.id) === Number(this.props.match.params.id);
        }
      }

      this.setState({
        id: this.props.match.params.id,
        isSelf: isSelf,
      }, () => {
        this.loadUser();
      });
    } else {

    }
  }

  closeModal = () => {
    window.$('edit_profile_modal').modal('hide');
    window.$('body').removeClass('modal-open');
    window.$('.modal-backdrop').remove();
  };

  userUpdated = () => {

    this.closeModal();
    this.loadUser();
  };


  uploadNewPicture = async(files) => {
    let {loggedInUser} = this.props;
    let selectedFile = (files && files.length > 0) ? files[0] : null


    if (!selectedFile) {
      this.setState({
        errorMessage: "Please select a file before uploading",
        uploadingProfilePic: false,
      });
      return;
    }

    let fileSize = selectedFile.size;
    if (fileSize > ProfileScreen.MAX_PROFILE_FILE_SIZE) {
      fileSize /= 1024000;
      fileSize = fileSize.toFixed(2);
      this.setState({
        errorMessage: "The file is too large, please choose a file less than 1MB. Your file was " + fileSize + " MBs",
        uploadingProfilePic: false,
      });
      return;
    }



    try {
      await this.setState({
        uploadingProfilePic: true,
        errorMessage: null,
      });

      let oldKey = loggedInUser.profile_url;

      let newKey =   'profile/' + Util.createUUID() + '.jpg';
      let result = await Storage.put(newKey, selectedFile, {
        contentType: 'image/jpeg',
        level: 'protected'
      });

      await Storage.remove(oldKey, {
        level: 'protected'
      });

      loggedInUser.profile_url =  result.key;
      await loggedInUser.save();

      // need to save a copy
      await this.props.setLoggedInUser(new User(loggedInUser));

      await this.setState({
        uploadingProfilePic: false,
      });

      //this.props.history.go(0);

    } catch(error) {
      logger.error(error)
      this.setState({
        uploadingProfilePic: false,
        errorMessage: error.message,
      });
    }

  };


  fileChange = (files) => {
    this.setState({
      selectedFile: (files && files.length > 0) ? files[0] : null
    })
  };


  renderProfilePic = (user) => {

    return(
      <div className="profilescreen-profilepic">
        <div className="">
          <ProfilePic user={user} size={150}/>
        </div>

        {this.state.isSelf ?
          <>
            {
              (this.state.uploadingProfilePic) ?
                <div className="spinner-border epiqueue-darkblue-text" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                :
                <div className="mt-2">
                  <div className="btn btn-sm  btn-success cursor-pointer">
                    <label className="mb-0 cursor-pointer" htmlFor="profile">
                      <i className="fas fa-upload cursor-pointer"></i>&nbsp;&nbsp;&nbsp;Upload&nbsp;New&nbsp;Profile&nbsp;Image
                    </label>
                  </div>
                  <input className="profilePicInput hide" type='file' id='profile' name="profile"
                         accept="image/png, image/jpeg" onChange={(e) => this.uploadNewPicture(e.target.files)}/>
                </div>
            }
          </>
          : null
        }
      </div>
    );
  }


  renderProfileSelf() {
    if (!this.state.isSelf) {
      return null;
    }

    let user = this.props.loggedInUser;
    if (!user) {
      return this.renderNoUser();
    }

    if (user.status !== User.STATUS_ACTIVE) {
      return this.renderNotActive();
    }

    return (
      <div className="profilescreen">

        <ErrorMessage errorMessage={this.state.errorMessage}/>

        <div className="profilescreen-infobox">
          <div className="profilescreen-infobox-imagearea">
            {this.renderProfilePic(user)}
          </div>

          <div className="profilescreen-infobox-textarea">
            <div className="">
              <p className="profilescreen-username">{user.username}</p>
                <p className="profilescreen-description">
                  {user.description ? user.description : 'No profile description yet'}
                </p>
              <p className="">Email: {user.email} (only visible to you)</p>
              <p>
                Member Since: {moment(user.created_at).format("MMMM Do, YYYY")}
              </p>
            </div>


          </div>

          <div className="profilescreen-infobox-actionarea">
            <button className="btn btn-sm btn-outline-primary epiqueue-darkblue-text"
                    data-toggle = "modal"
                    data-target = {"#edit_profile_modal"}
            ><i className="fas fa-edit"></i>&nbsp;Edit&nbsp;Profile</button>
          </div>


        </div>

        {user.is_seller ?
          <ScrollingQueueList queues={this.state.user.queues} noResultsText={"No Queues Yet"} title={"Queues You Have Created"}/>
          : null }

        <ScrollingQueueList queues={this.state.queueMemberships} noResultsText={"No Queues Yet"} title={"You are a member of these queues"}/>

         <EditProfileModal user={user} id={"edit_profile_modal"} okCallback={this.userUpdated} cancelCallback={this.closeModal}/>

      </div>
    );
  }



  renderProfile() {

    let user = this.state.user;
    if (!user) {
      return this.renderNoUser();
    }

    if (user.status !== User.STATUS_ACTIVE) {
      return this.renderNotActive();
    }

    return (
      <div className="profilescreen">
        <ErrorMessage errorMessage={this.state.errorMessage}/>

        <div className="profilescreen-infobox">
          <div className="p-3">
            <ProfilePic user={user} size={150}/>
          </div>

          <div className="profilescreen-infobox-textarea flex-fill">
            <div className="">
              <p className="profilescreen-username">{user.username}</p>
              <p className="profilescreen-description">
                {user.description ? user.description : 'No profile description yet'}
              </p>
              <p>
                Member Since: {moment(user.created_at).format("MMMM Do, YYYY")}
              </p>
            </div>
          </div>

          {this.state.user.is_seller ?
            <>
              <div className="profilescreen-infobox-actionarea">
                <button className="btn btn-sm btn-outline-primary btn-outline-epiqueue"
                        data-toggle = "modal"
                        data-target = {"#send_message_modal"}
                ><i className="fas fa-envelope"></i>&nbsp;Send&nbsp;Message</button>
              </div>
              <SendMessageModal toUser={user} setRef={"send_message_modal"} id={"send_message_modal"}/>
              </>
            : null }
        </div>



        {this.state.user.is_seller ?
          <ScrollingQueueList queues={this.state.user.queues} noResultsText={"No Queues Yet"} title={this.state.user.username + " has created these queues"}/>
        : null }

        <ScrollingQueueList queues={this.state.queueMemberships} noResultsText={"No Queues Yet"} title={this.state.user.username + " has joined these queues"}/>

      </div>
    );
  }

  renderNoUser() {
    return (
      <h2>
        This is a Profile screen (no user found)
      </h2>
    );
  }

  renderNotActive() {
    return (
      <h2>
        This member is no longer active
      </h2>
    );
  }

  renderLoading() {
    return (
      <Loading/>
    );
  }

  renderContent() {
    if (!this.state.userLoaded) {
      return this.renderLoading();
    }

    if (this.state.isSelf) {
      return this.renderProfileSelf()
    } else {
      return this.renderProfile()
    }

  }

  render() {
    return this.renderContent();
  }

}



const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
  {setLoggedInUser}
)(ProfileScreen));

