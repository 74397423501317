import React, {} from 'react';
import RootScreen from "./RootScreen";

class AlertsScreen extends RootScreen {


  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      admin: false,
      user: null
    }

  }


renderContent() {
  return (
    <>
      <h2>
      This is the Alerts screen
    </h2>
      </>
  );
}

}

export default AlertsScreen;