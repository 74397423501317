
import React, { Component } from 'react';
import "../../css/HelpScreen.css"
import BackLink from "../BackLink";
import {withRouter} from "react-router-dom";

class CreatingAProduct extends Component {
  static TITLE = "Creating A Product";

  render() {
    return (
      <>
        <BackLink link="/help/seller-get-started" text="Back to Getting Started Guide"/>
        <div className="helpscreen-largetitle">Creating a Product</div>

        <div className="helpscreen-subheader">
          Step 1: Name your queue
        </div>
        <div className="helpscreen-paragraph">
          From the Queue Screen, choose "New Product" and you will be transported into the product creation page.
        </div>
        <div className="d-flex justify-content-center">
          <img className="border m-1 bg-dark" height="300" src="/help/createproduct.png" alt="Creating A Product"/>
        </div>


        <div className="helpscreen-subheader">
          Step 2: Fill in details
        </div>
        <div className="helpscreen-paragraph">
          Similar to creating a queue, fill in the name and description. Aso add inventory, pricing and shipping info.
          <p/>
          You have the option to mark the product as tax free (useful for gift cards and other items for which you don't collect tax).
          <p/>
          After you have filled in details, click on the "Create Product" button.
        </div>

        <div className="helpscreen-subheader">
          Step 3: Add Images
        </div>
        <div className="helpscreen-paragraph">
          Products are a bit different than queues in that you can't add images until after creating the product. The Add image
          section will appear after creating the product.
          <p/>
          Products can have as many images as you wish and the user will be able to scroll through them on the site.
        </div>


        <div className="helpscreen-subheader">
          Step 4: When ready, make your product visible
        </div>
        <div className="helpscreen-paragraph">
          Once your product is set up, you can make it visible by selecting the "Make this product visible to all" selector. This will make the product visible in the "Upcoming" Section of the Queue page.
          You do not have to make the product visible before going live (Going live auto-sets that flag to visible).
        </div>

      </>
    )
  }
}

export default withRouter(CreatingAProduct);