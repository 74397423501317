import React, { Component } from 'react';
import PropTypes from 'prop-types';
import User from "../../models/User";
import {API, Logger} from "aws-amplify";

import "../../css/SendMessageModal.css"
import Message from "../../models/Message";

const logger = new Logger('SendMessageModal');

class SendMessageModal extends Component {

  constructor(props) {
    super(props);

    let subject = '';
    if (this.props.respondingTo) {
      subject = 're: ' + this.props.respondingTo.subject;
    }
    this.state = {
      message: "",
      subject: subject,
    }
  }

  updateTextField(field, event) {
    event.preventDefault();
    event.stopPropagation();


    logger.debug(event.target.value);

    this.setState({
      [field]: event.target.value,
    });
  }

  stopEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  };


  handleOK = async (event) => {

    let message = this.state.message.trim();
    let subject = this.state.subject.trim();


    let apiName = 'epiqueueapi';
    let path = '/messaging/direct_message';
    let params = {
      body: {
        message: message,
        subject: subject,
        to_identifier: this.props.toUser.identifier,
      }
    };
    try {
      var response = await API.post(apiName, path, params);
      if (response.success) {
        // get on with our lives
      } else {
        this.setState({
          errorMessage: response.errorMessage
        });
        return;
      }
    } catch (error) {
      this.setState({
        errorMessage: error.message
      });
      return;
    }


  };



  render() {
    return (
      <div className="modal fade sendmessagemodal" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog sendmessagemodal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Send Message</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-2">
              To: {this.props.toUser.username}
              </div>

              <div className="form-group border p-2" style={{backgroundColor: '#eeeeee'}}>
                <div className="form w-100">
                  <input type="text" className={"form-control"}
                         id="subject"
                         placeholder="Subject"
                         onKeyPress={this.stopEnterKeyPress}
                         onChange={(event) => this.updateTextField('subject', event)}
                         aria-label="subject"
                         value={this.state.subject}
                  />
                </div>
              </div>

              <div className="form-group border p-2" style={{backgroundColor: '#eeeeee'}}>
                <div className="form w-100">
                      <textarea className={"form-control"}
                                id="message"
                                placeholder="Message"
                                rows={5}
                                onChange={(event) => this.updateTextField('message', event)}
                                aria-label="message"
                                value={this.state.message}
                      />
                </div>
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.props.cancelCallback}>{this.props.cancelLabel}</button>
              <button type="button" className="btn btn-primary " data-dismiss="modal" onClick={this.handleOK} disabled={this.state.message.trim() === ''}>{this.props.okLabel}</button>
            </div>
          </div>
        </div>
      </div>
    )
  };
}



SendMessageModal.defaultProps = {
  message: null,
  okLabel: "Send",
  cancelLabel: "Cancel",
  okCallback: null,
  cancelCallback: null,
};

SendMessageModal.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string,
  toUser: PropTypes.instanceOf(User).isRequired,
  okCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  respondingTo: PropTypes.instanceOf(Message),
};

export default SendMessageModal;

