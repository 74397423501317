import React, { Component } from 'react';
import Util from "../../Util";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {API, Logger} from "aws-amplify";
import Loading from "../Loading";

const logger = new Logger('Overview');

class Overview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errorMessage:null,
      dataLoaded: false,
      errorLoading: false,
      data: [],
    };
  }


  componentDidMount() {
    if (this.props.loggedInUser) {
      this.loadData();
    } else {
      let data = ['stats'];
      data['stats']['order_count'] = 'error';
      data['stats']['total_sales'] = 'error';
      this.setState({
        data: data,
        dataLoaded: true,
      });
    }
  }

  loadData = async () => {
    try {
      let apiName = 'epiqueueapi';
      let path = '/dashboard/overview';
      let response = await API.get(apiName, path);
      if (response.success) {
        this.setState({
          data: response.data,
          dataLoaded: true,
        });
      } else {
        this.setState({
          data: this.createErrorData(),
          errorMessage: response.errorMessage,
          errorLoading: true,
          dataLoaded: true,
        });
      }
    } catch(error) {
      logger.error("ERROR!!!!");
      logger.error(error);

      this.setState({
        errorMessage: "Exception:"  + error.message,
        data: this.createErrorData(),
        errorLoading: true,
        dataLoaded: true,
      });
    };

  };


  createErrorData = () => {
    let data = [];
    data['stats'] = [];
    data['stats']['order_count'] = 'error';
    data['stats']['total_sales'] = 'error';
    return data;
  }

  render() {
    if (!this.props.loggedInUser) {
      return null;
    }

    return (
      <>
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <div className="dashboard-screen-title">Stats Overview</div>
        </div>
        <div className="dashboard-stats-card">
        <div className="dashboard-stats-box">
        <div className="dashboard-stats-box-label">
        ORDERS
        </div>
      <div className="dashboard-stats-box-value">
        { this.state.dataLoaded === false ? <Loading/>
          :  this.state.data['stats']['order_count']
        }
      </div>
      </div>
      <div className="dashboard-stats-box dashboard-stats-box-left-border">
        <div className="dashboard-stats-box-label">
          REVENUE
        </div>
        <div className="dashboard-stats-box-value mb-0">
          <div className="table table-sm table-borderless">
            <tr>
              <td style={{'textAlign':  'right'}}>Gross Sales:</td>
              <td>
                { this.state.dataLoaded === false ? <Loading/>
                  :  this.state.errorLoading ? this.state.data['stats']['total_amount_collected'] : Util.formatMoney(this.state.data['stats']['total_amount_collected'])
                }
              </td>
            </tr>
            <tr>
              <td style={{'textAlign':  'right'}}>Net Revenue:</td>
              <td>
                { this.state.dataLoaded === false ? <Loading/>
                  :  this.state.errorLoading ? this.state.data['stats']['sellers_take'] : Util.formatMoney(this.state.data['stats']['sellers_take'])
                }
              </td>
            </tr>
          </div>
        </div>
      </div>
      </div>
        </>
      )
  };
}

const mapStateToProps = (state,  ownProps) => {
  return {
    loggedInUser: state.users.loggedInUser,
  }
};

export default withRouter(connect(
  mapStateToProps,
)(Overview));


