import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Logger} from "aws-amplify";
import Product from "../models/Product";
import ImagePlaceholder from "../img/image-placeholder.png"

import "../css/ProductPic.css"

const logger = new Logger('ProfilePic');

class ProductPic extends Component {

  render() {

    let {product} = this.props;

    logger.debug(product)

    if (!product) {
      return (
        <img className="product-pic-image" src={ImagePlaceholder} width={this.props.size + 'px'} height={this.props.size + 'px'} alt={"Placeholder"}/>
    );
    }

    if (!product.primary_image) {
      return (
        <img className="product-pic-image" src={ImagePlaceholder} width={this.props.size + 'px'} height={this.props.size + 'px'} alt={"Placeholder"}/>
      );
    }

    if (product.images === undefined && product.primary_image === undefined) {
      return (
        <div className="bg-danger" width={this.props.size + 'px'} height={this.props.size + 'px'}>x</div>
      );
    }

    return (
        <img className={ this.props.cover ? "product-pic-image-cover" : "product-pic-image"} width={this.props.size + 'px'} height={this.props.size + 'px'} src={"https://cdn.epiqueue.com/public/" + product.primary_image.url} alt={"primary"}/>
    );
  }
}

ProductPic.defaultProps = {
  size: 40,
  cover: false
};

ProductPic.propTypes = {
  product: PropTypes.instanceOf(Product).isRequired,
  size: PropTypes.number,
  cover: PropTypes.bool
};

export default ProductPic;

