import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Spinner extends Component {

  static ICONS = {
    'circlenotch': 'fa-circle-notch',
    'cog': 'fa-cog',
    'compass': 'fa-compass',
    'ring': 'fa-ring',
    'spinner': 'fa-spinner',
    'slash': 'fa-slash',
    'stroopwafel': 'fa-stroopwafel',
    'sync': 'fa-sync',
    'syncAlt': 'fa-sync-alt',
    'yinyang': 'fa-yin-yang',
  };

  static SIZE = {
    'smallest': 'fa-1x',
    'small': 'fa-2x',
    'medium': 'fa-4x',
    'mediumLarge': 'fa-6x',
    'large': 'fa-8x',
    'largest': 'fa-10x',
    'fa_1x' : 'fa-1x',
    'fa_2x' : 'fa-2x',
    'fa_3x' : 'fa-3x',
    'fa_4x' : 'fa-4x',
    'fa_5x' : 'fa-5x',
    'fa_6x' : 'fa-6x',
    'fa_7x' : 'fa-7x',
    'fa_8x' : 'fa-8x',
    'fa_9x' : 'fa-9x',
    'fa_10x' : 'fa-10x',
  }

    render() {
    return (
      <div className="d-flex vh-100 justify-content-center align-items-center">
        <i className={'fas ' + this.props.icon + ' fa-spin ' + this.props.size }></i>
        {this.props.label ?
          <h2>{this.props.label}</h2>
          : null
        }
      </div>

    )
  };
}

// Specifies the default values for props:
Spinner.defaultProps = {
  size: Spinner.SIZE.small,
  label: null,
  pageCenter: false,
  icon: Spinner.ICONS.cog
};

Spinner.propTypes = {
  size: PropTypes.string.isRequired,
  label: PropTypes.string,
  pageCenter: PropTypes.bool,
  icon: PropTypes.string.isRequired
};

export default Spinner;

