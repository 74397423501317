import React, {} from 'react';
import RootScreen from "../RootScreen";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {API, Logger} from "aws-amplify";
import ErrorMessage from "../../components/ErrorMessage";
import Sale from "../../models/Sale";
import Transaction from "../../models/Transaction";

import '../../css/ReceiptScreen.css';

const logger = new Logger('CartScreen');

class ReceiptScreen extends RootScreen {


  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'ReceiptScreen'
    });

    const { match } = this.props;

    this.state = {
      identifier: match.params.identifier,
      loggedIn: false,
      errorMessage: null,
      successMessage: null,
      sale: null,
      loading: true,
      total: 0,
    };

    this.onSubmit = this.onSubmit.bind(this);

  }

  componentDidMount = async() => {


    try {

      let apiName = 'epiqueueapi';
      let path = '/product/sale/' + this.state.identifier;

      let response = await API.get(apiName, path);
      logger.debug("get response: " + JSON.stringify(response));
      if (response.success) {

        let sale = new Sale(response.sale);

        let transaction = null;
        if (sale.transactions && sale.transactions.length > 0) {
          transaction = sale.transactions.find(item => {
            return item.status === Transaction.STATUS_SUCCESS
          })
        }
        let total = sale.amount + sale.shipping_amount;

        logger.debug("total: " + total);

        this.setState({
          sale: sale,
          transaction: transaction,
          errorMessage: null,
          loading: false,
          total: total
        })

      } else {
        this.setState({
          errorMessage: response.message,
          sale: null,
          transaction: null,
          loading: false,
          total: 0
        })
      }

    } catch(error) {
      this.setState({
        errorMessage: error.message,
        sale: null,
        loading: false,
        total: 0
      })

    }

  }

  onSubmit(result) {
    if (!result.success) {
      if (result.errorMessage) {
        this.setState({
          errorMessage: result.errorMessage
        });
      }
    }

    logger.debug(JSON.stringify(result))
  }


  renderSale() {

    let {sale, transaction} = this.state;

    let totalCharge = this.state.total;
    logger.debug(JSON.stringify(totalCharge));


    return (
    <div className="mt-4">
      <table className="table table-borderless receiptscreen-table epiqueue-darkblue-text ">
        <thead className="">
          <tr className="receiptscreen-table-header">
            <th className="">Item</th>
            <th></th>
            <th>Amount</th>
          </tr>
        </thead>

        <tbody className=" receiptscreen-table-body epiqueue-faded-background">

          <tr>
            <td colSpan={2}>
              <Link to={sale.product.getUrl()}>{sale.product.name}</Link>
            <br/>
              Transaction Date: {transaction.created_at}
              <br/>
              Transaction ID: {transaction.identifier}
            </td>
            <td align="left">{sale.getFormattedAmount()}</td>
          </tr>

          <tr>
            <td colSpan={2} align="right" className="">Shipping:</td>
            <td align="left">{sale.getFormattedShippingAmount()}</td>
          </tr>


          <tr>
            <td colSpan={2} align="right">Tax:</td>
            <td align="left">{sale.getFormattedTaxAmount()}</td>
          </tr>

          <tr className="receiptscreen-table-footer">
            <td colSpan={2} align="right" className="">Total Charged:</td>
            <td align="left" className="">{transaction.getFormattedTotalAmount()}</td>
          </tr>


        </tbody>
      </table>
    </div>
    );
  }

  renderNoSale() {
    return (
      <div className="d-flex flex-column justify-content-center">
        <div className="align-self-center text-center">
          <h3>Woah, This isn't good!</h3>
        </div>
      </div>
    )
  }



  renderContent() {
    if (this.state.loading) {
      return null;
    }

    let {sale} = this.state;

    if (!this.props.loggedInUser) {
      return  <ErrorMessage errorMessage="You must be logged in to view this page"/>
    }


    return (
      <>
        <ErrorMessage errorMessage={this.state.errorMessage}/>
        {
          (sale === null)
          ? this.renderNoSale()
          : this.renderSale()
        }
      </>
    );
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps
)(ReceiptScreen));
