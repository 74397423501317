import { SET_UNREAD_MESSAGES_COUNT, SET_PENDING_CART_ITEMS_COUNT } from "../actionTypes";
//import {Logger} from "aws-amplify";
//const logger = new Logger('redux.notifications');

const initialState = {
  unreadMessagesCount: 0,
  pendingCartItemsCount: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {

    case SET_UNREAD_MESSAGES_COUNT: {
      let { unreadMessagesCount } = action.payload;
      return {...state, unreadMessagesCount: unreadMessagesCount}
    }
    case SET_PENDING_CART_ITEMS_COUNT: {
      let { pendingCartItemsCount } = action.payload;
      return {...state, pendingCartItemsCount: pendingCartItemsCount}
    }
    default:
      return state;
  }



}
