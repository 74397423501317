import React, {} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {API, Logger} from "aws-amplify";
import {setUnreadMessagesCount, setMessageBox} from "../redux/actions";
import moment from "moment";

import RootScreen from "./RootScreen";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import Message from "../models/Message";

import "../css/MessagesScreen.css"
import ProfilePic from "../components/ProfilePic";

const logger = new Logger('MessagesScreen');

class MessagesScreen extends RootScreen {


  static NAV_INBOX = 'inbox';
  static NAV_SENT = 'sent';
  static NAV_UNREAD = 'unread';
  static NAV_ARCHIVED = 'archived';


  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'MessagesScreen'
    });

    this.state = {
      loading: true,
      messageListLoading: false,
      messageLoading: false,
      errorMessage: null,
      messages: [],
      message: null,

      selected: [],

      messagesDisplayed: [],
      maxDisplayed: 25,
      page: 1,
      numPages: 1,

      selectedNav: this.props.messageBox,
    };

    window.scrollTo(0, 0);
  }

  componentDidMount = async() => {
    window.scrollTo(0, 0);

    if (this.props.loggedInUser) {
      switch(this.props.messageBox) {
        case MessagesScreen.NAV_INBOX:
          this.loadMessageList();
          break;
        case MessagesScreen.NAV_SENT:
          this.loadMessageList('sent');
          break;
        case MessagesScreen.NAV_UNREAD:
          this.loadMessageList('unread');
          break;
        case MessagesScreen.NAV_ARCHIVED:
          this.loadMessageList('archived');
          break;
        default:
          break;
      }
    }

  };


  reloadMessageList() {
    this.loadMessageList(this.props.messageBox);
  }

  loadMessageList(box = null) {
    let apiName = 'epiqueueapi';

    this.setState({
      messageListLoading: true,
      messages: [],
    })

    let path = '/messaging/messagelist';
    if (box) {
      path += "/" + box
    }
    API.get(apiName, path).then((response) => {

      if (response.success) {
        let messages = response.messages.map(item => {
          return new Message(item);
        });

        let numPages = Math.ceil(messages.length / this.state.maxDisplayed);
        // set up first page
        let messagesDisplayed = messages.filter( (item, idx) => {
          if (idx < this.state.maxDisplayed) {
            logger.debug(JSON.stringify(item));
            return true;
          }
          return false;
        });

        this.setState({
          messageListLoading: false,
          errorMessage: null,
          loading:false,
          messages:messages,
          messagesDisplayed: messagesDisplayed,
          page: 1,
          numPages: numPages,
          selected: [],
        })
      } else {
        this.setState({
          errorMessage: response.errorMessage,
          messageListLoading: false,
          loading:false,
        })
      }
    }).catch(error => {
      logger.error(error);
      this.setState({
        loading:false,
        messageListLoading: false,
        errorMessage: error.message,
      })
    });
  }


  loadMessage(identifier) {

    if (this.state.messageLoading) {
      return;
    }

    if (this.state.message.message !== null && this.state.message.message !== undefined) {
      return;
    }

    this.setState({
      messageLoading: true,
    });


    let apiName = 'epiqueueapi';
    let path = '/messaging/message/' + identifier;
    API.get(apiName, path).then((response) => {

      let newMessage = new Message(response.message);

      if (response.success) {
        this.setState({
          errorMessage: null,
          loading: false,
          messageLoading: false,
          message: newMessage,
          messages: this.state.messages.map(item => {
            if (item.identifier === newMessage.identifier) {
              return newMessage
            }
            return item;
          })
        });
        this.props.setUnreadMessagesCount(this.props.unreadMessagesCount - 1);
      } else {
        this.setState({
          errorMessage: response.errorMessage,
          loading:false,
          messageLoading: false,
        })
      }
    }).catch(error => {
      this.setState({
        loading:false,
        messageLoading: false,
        errorMessage: error.message,
      })
    });
  }


  selectNav = (nav) => {
    if (nav === this.state.selectedNav) {
      return;
    }


    this.setState({
      selectedNav: nav,
      messages: [],
    });

    this.props.setMessageBox(nav);

    switch(nav) {
      case MessagesScreen.NAV_INBOX:
        this.loadMessageList();
        break;
      case MessagesScreen.NAV_SENT:
        this.loadMessageList('sent');
        break;
      case MessagesScreen.NAV_UNREAD:
        this.loadMessageList('unread');
        break;
      case MessagesScreen.NAV_ARCHIVED:
        this.loadMessageList('archived');
        break;
      default:
        break;
    }
  };


  setPage = (page) => {
    logger.debug("page: " + page)

    if (page === this.state.page) {
      return;
    }

    if (page > this.state.numPages) {
      page = this.state.numPages;
    }

    if (page < 1) {
      page = 1;
    }

    let startingIdx = (page-1) * this.state.maxDisplayed;
    let endingIndx = startingIdx +  this.state.maxDisplayed;
    let messagesDisplayed = this.state.messages.filter( (item, idx) => {
      if (idx >= startingIdx && idx < endingIndx) {
        return true;
      }
      return false;
    });



    this.setState({
      page: page,
      messagesDisplayed: messagesDisplayed,
      selected: [],
    });
  };

  nextPage = () => {
    if (this.state.page === this.state.numPages) {
      return;
    }

    if (this.state.page > this.state.numPages) {
      this.setPage(this.state.page);
      return;
    }

    this.setPage(this.state.page + 1);
  }

  previousPage = () => {
    if (this.state.page === 1) {
      return;
    }

    this.setPage(this.state.page - 1);
  }


  goToMessage = (identifier) => {
    this.props.history.push("/message/" + identifier);
  }

  renderMessage() {
    if (this.state.message.message === null || this.state.message.message === undefined) {
      this.loadMessage(this.state.message.identifier);
    }

    return(
      <>
        <div className="btn btn-link" onClick={(e) => {
          e.stopPropagation();
          this.setState({
            loading: false,
            messageLoading: false,
            message: null
          })
        }}><i className="fas fa-chevron-left"/> Back to Messages</div>
        <h2>{this.state.message.subject}</h2>
        {this.state.messageLoading ?
          <>Loading....<Loading/></>
          :
          <>
          <div dangerouslySetInnerHTML={{__html: this.state.message.message}}/>
          </>
        }
      </>
    )

  }

  handleItemSelect = async(e, item) => {
    e.stopPropagation();

    logger.debug(item.identifier)


    let {selected} = this.state;
    if (selected.indexOf(item.identifier) === -1) {
      logger.debug("not in list")
      selected.push(item.identifier);
      logger.debug(selected);
      await this.setState({
        selected: selected,
      })
      this.forceUpdate();
    } else {
      // remove

      selected = selected.filter(id => {
        if (id === item.identifier) {
          return false;
        }
        return true;
      });
      await this.setState({
        selected: selected,
      });
      this.forceUpdate();
    }

  };

  selectAll = (e) => {
    logger.debug(e.target.checked);


    let selected = [];
    if (e.target.checked) {
      this.state.messagesDisplayed.forEach(item => {
        selected.push(item.identifier);
      })
    }

    this.setState({
      selected: selected,
    });

  };

  messageAction = async (action) => {

    if (this.state.selected.length === 0) {
      return;
    }

    let {selected} = this.state;

    let apiName = 'epiqueueapi';
    let path = '/messaging/message_action';
    let postData = {
      body: {
        action: action,
        identifiers: this.state.selected
      }
    };
    let response = await API.post(apiName, path, postData);
    if (response.success) {
    } else {
      this.setState({
        errorMessage: response.errorMessage,
      });
    }

    if (action === 'mark_as_read' || action === 'mark_as_unread') {


      // mark them all as read locally
      let messages = this.state.messages.map(item => {
        if (selected.indexOf(item.identifier) !== -1) {
          if (action === 'mark_as_read') {
            if (item.read_at === null) {
              item.read_at = 'now';
            }
          } else if (action === 'mark_as_unread') {
            if (item.read_at !== null) {
              item.read_at = null;
            }
          }
        }
        return item;
      });

      let messagesDisplayed = this.state.messagesDisplayed.map(item => {
        if (selected.indexOf(item.identifier) !== -1) {
          if (action === 'mark_as_read') {
            if (item.read_at === null) {
              item.read_at = 'now';
            }
          } else if (action === 'mark_as_unread') {
            if (item.read_at !== null) {
              item.read_at = null;
            }
          }
        }
        return item;
      });


      this.setState({
        messages: messages,
        messagesDisplayed: messagesDisplayed,
      })

    } else {
      this.reloadMessageList();
    }


  };


  renderPageLinks = () => {
    if (this.state.numPages <= 1) {
      return null;
    }

    let links = [];
    links.push(<div className="btn btn-link m-1 p-0 epiqueue-darkblue-text"  key='lt' onClick={this.previousPage}>&lt;</div>);
    for (let i = 1 ; i <= this.state.numPages ; i++) {
      if (this.state.page === i) {
        links.push(<div className="btn btn-link m-1 p-0 epiqueue-darkblue-text" key={i} onClick={(e) => e.stopPropagation()}><span className="epiqueue-font-bold">{i}</span></div>);
      } else {
        links.push(<div className="btn btn-link m-1 p-0 epiqueue-darkblue-text" key={i} onClick={() => this.setPage(i)}>{i}</div>);
      }
    }
    links.push(<div className="btn btn-link m-1 p-0 epiqueue-darkblue-text"  key='gt' onClick={this.nextPage}>&gt;</div>);
    return links;
  };

  renderNoMessages = () => {
    return (
      <div className="messagesscreen">
        <div>
          <div className="messagesscreen-left-nav">
            <div className="messagesscreen-left-nav-items epiqueue-darkblue-text">
              <div className={"messagesscreen-left-nav-item" + (this.props.messageBox === MessagesScreen.NAV_INBOX ? " messagesscreen-left-nav-item-selected" : "")} onClick={() => this.selectNav(MessagesScreen.NAV_INBOX)}>Inbox</div>
              <div className={"messagesscreen-left-nav-item" + (this.props.messageBox === MessagesScreen.NAV_UNREAD ? " messagesscreen-left-nav-item-selected" : "")} onClick={() => this.selectNav(MessagesScreen.NAV_UNREAD)}>Unread</div>
              <div className={"messagesscreen-left-nav-item" + (this.props.messageBox === MessagesScreen.NAV_SENT ? " messagesscreen-left-nav-item-selected" : "")} onClick={() => this.selectNav(MessagesScreen.NAV_SENT)}>Sent</div>
              <div className={"messagesscreen-left-nav-item" + (this.props.messageBox === MessagesScreen.NAV_ARCHIVED ? " messagesscreen-left-nav-item-selected" : "")} onClick={() => this.selectNav(MessagesScreen.NAV_ARCHIVED)}>Archived</div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column w-100">
          <div className="messagesscreen-action-box-area">
            <div className="messagesscreen-action-box messagesscreen-action-box-checkbox bg-transparent">
              <input type="checkbox" disabled />
            </div>
            <div className="d-flex flex-row">
              <div className="d-flex flex-column flex-lg-row">
                <div className="btn btn-outline-dark messagesscreen-action-box  mb-1 mb-lg-0 disabled">Mark as Read</div>
                <div className="btn btn-outline-dark messagesscreen-action-box disabled">Mark as Unread</div>
              </div>
              <div className="d-flex flex-column flex-lg-row">
                { this.props.messageBox === MessagesScreen.NAV_INBOX ||  this.props.messageBox === MessagesScreen.NAV_UNREAD ?
                <div className="btn btn-outline-dark messagesscreen-action-box  mb-1 mb-lg-0 disabled">Archive</div>
                  : null }
                { this.props.messageBox === MessagesScreen.NAV_ARCHIVED ?
                <div className="btn btn-outline-dark messagesscreen-action-box disabled">Un-Archive</div>
                  : null }
              </div>
            </div>
          </div>
          <div className="messagesscreen-action-messages-area">
            <table className="table messagesscreen-action-table">
              <tbody className="bg-white">
              {this.state.messageListLoading ?
                <tr>
                  <td><Loading/></td>
                </tr>
                :
                <tr>
                  <td>No Messages</td>
                </tr>
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  };

  renderMessages() {

    logger.debug("renderMessages")


    logger.debug("selectedNav: " + this.state.selectedNav);

    if (this.state.messages === null || this.state.messages.length === 0) {
      return this.renderNoMessages();
    }

    let {selected} = this.state;

    let noneSelected = selected.length === 0;

    let messages = this.state.messagesDisplayed.map(item => {

      return (
        <tr key={item.identifier} className="messagesscreen-table-row">

          <td className="messagesscreen-table-cell">
            <input type="checkbox" onChange={(e) => this.handleItemSelect(e, item)}  checked={selected.indexOf(item.identifier) !== -1}/>
          </td>
          {this.state.selectedNav !== MessagesScreen.NAV_SENT ?
            <td className="messagesscreen-table-cell" onClick={() => this.goToMessage(item.identifier)}>
              <div className="d-flex flex-row align-items-center">
                <ProfilePic user={item.fromUser} size={40}/>
                <div className="ml-2 ">{item.fromUser ? item.fromUser.username : null}</div>
              </div>
            </td>
            :
            <td className="messagesscreen-table-cell" onClick={() => this.goToMessage(item.identifier)}>
              <div className="d-flex flex-row align-items-center">
                <ProfilePic user={item.toUser} size={40}/>
                <div className="ml-2 ">{item.toUser.username}</div>
              </div>
            </td>
          }
          <td className="messagesscreen-table-cell" onClick={() => this.goToMessage(item.identifier)}>
            <div className="">
          <span className={"" + (item.read_at === null && this.state.selectedNav !== MessagesScreen.NAV_SENT ? " font-weight-bolder" : null)}>
         {item.subject}
          </span>
            </div>
          </td>
          <td className="messagesscreen-table-cell" onClick={() => this.goToMessage(item.identifier)}>
            {moment(item.created_at).format("MMMM Do, YYYY")}
          </td>
        </tr>
      )
    });


    return(
      <div className="messagesscreen">
        <div>
          <div className="messagesscreen-left-nav">
            <div className="messagesscreen-left-nav-items epiqueue-darkblue-text">
              <div className={"messagesscreen-left-nav-item" + (this.props.messageBox === MessagesScreen.NAV_INBOX ? " messagesscreen-left-nav-item-selected" : "")} onClick={() => this.selectNav(MessagesScreen.NAV_INBOX)}>Inbox</div>
              <div className={"messagesscreen-left-nav-item" + (this.props.messageBox === MessagesScreen.NAV_UNREAD ? " messagesscreen-left-nav-item-selected" : "")} onClick={() => this.selectNav(MessagesScreen.NAV_UNREAD)}>Unread</div>
              <div className={"messagesscreen-left-nav-item" + (this.props.messageBox === MessagesScreen.NAV_SENT ? " messagesscreen-left-nav-item-selected" : "")} onClick={() => this.selectNav(MessagesScreen.NAV_SENT)}>Sent</div>
              <div className={"messagesscreen-left-nav-item" + (this.props.messageBox === MessagesScreen.NAV_ARCHIVED ? " messagesscreen-left-nav-item-selected" : "")} onClick={() => this.selectNav(MessagesScreen.NAV_ARCHIVED)}>Archived</div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column w-100">
          <div className="messagesscreen-action-box-area">
            <div className="messagesscreen-action-box messagesscreen-action-box-checkbox">
              <input type="checkbox" onClick={this.selectAll}/>
            </div>
            <div className="d-flex flex-row">
              <div className="d-flex flex-column flex-lg-row">
                <div className={"btn btn-outline-dark messagesscreen-action-box mb-1 mb-lg-0 " + (noneSelected ? ' disabled' : '')} onClick={() => this.messageAction('mark_as_read')}>Mark&nbsp;as&nbsp;Read</div>
                <div className={"btn btn-outline-dark messagesscreen-action-box" + (noneSelected ? ' disabled' : '')} onClick={() => this.messageAction('mark_as_unread')}>Mark&nbsp;as&nbsp;Unread</div>
              </div>
              <div className="d-flex flex-column flex-lg-row">
                { this.props.messageBox === MessagesScreen.NAV_INBOX ||  this.props.messageBox === MessagesScreen.NAV_UNREAD ?
                  <div className={"btn btn-outline-dark messagesscreen-action-box mb-1 mb-lg-0 " + (noneSelected ? ' disabled' : '')} onClick={() => this.messageAction('archive')}>Archive</div>
                  : null }
                { this.props.messageBox === MessagesScreen.NAV_ARCHIVED ?
                <div className={"btn btn-outline-dark messagesscreen-action-box" + (noneSelected ? ' disabled' : '')} onClick={() => this.messageAction('unarchive')}>Un&#8209;Archive</div>
                  : null }
              </div>
            </div>
          </div>
          <div className="messagesscreen-action-messages-area">
            <table className="table table-striped table-hover messagesscreen-action-table">
              <tbody className="bg-white">
              {messages}
              </tbody>
            </table>
          </div>
          <div className="d-flex flex-row justify-content-between epiqueue-darkblue-text">
            <div className="mt-1">Page {this.state.page} of {this.state.numPages} ({this.state.messages.length} messages)</div>
            <div>{this.renderPageLinks()}</div>
          </div>
        </div>
      </div>
    )
  }

  renderContent() {

    logger.debug('enderContent');

    if (!this.props.loggedInUser) {
      return <ErrorMessage errorMessage={"You are not logged in"}/>
    }


      if (this.state.loading) {
      return <Loading/>
    }

    if (this.state.message) {
      return (
        <>
          <ErrorMessage errorMessage={this.state.errorMessage}/>
          {this.renderMessage()}
        </>
      );
    }

    return (
      <>
        <ErrorMessage errorMessage={this.state.errorMessage}/>
        {this.renderMessages()}
      </>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser,
    unreadMessagesCount: state.notifications.unreadMessagesCount,
    messageBox: state.messages.messageBox,
  }
};

export default withRouter(connect(
  mapStateToProps,
  {setUnreadMessagesCount, setMessageBox}
)(MessagesScreen));
