import React from 'react';
import {API, Logger} from "aws-amplify";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import {addUserToCache} from "../../redux/actions";
import Queue from "../../models/Queue";
import Seller from "../../models/Seller";
import Loading from "../../components/Loading";
import RootScreen from "../RootScreen";
import CreateQueueModal from "../../components/modals/CreateQueueModal";
import ErrorMessage from "../../components/ErrorMessage";
import NexusSelector from "../../components/NexusSelector";

import "../../css/DashboardScreen.css";
import Overview from "../../components/dashboard/Overview";
import CurrentStats from "../../components/dashboard/CurrentStats";
import DashboardQueue from "../../components/dashboard/DashboardQueue";

const logger = new Logger('DashboardScreen');

class DashboardScreen extends RootScreen {

  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'DashboardScreen'
    });

    logger.debug('DashboardScreen::constructor');

    this.state = {
      queues: [],
      queuesLoaded: false,
      errorMessage: null,
      successMessage: null,

      seller: null,
      max_queues: 3,

      overviewData: [],

      company: "",
      selling: "",
    }

    this.updateCompany = this.updateCompany.bind(this);
    this.updateSelling = this.updateSelling.bind(this);
    this.addToList = this.addToList.bind(this);
  }


  loadQueues = async () => {
    let apiName = 'epiqueueapi';
    let path = '/dashboard';
    API.get(apiName, path).then((response) => {

      if (response.success) {
        let queues = [];
        for (var i in response.queues) {
          queues.push(new Queue(response.queues[i]));
        }
        let seller = response.seller ? new Seller(response.seller) : null;
        this.setState({
          queues: queues,
          overviewData: response.overview,
          queuesLoaded: true,
          seller: seller,
          max_queues: seller ? seller.max_queues : 3,
        });
      } else {
        this.setState({
          queues: [],
          errorMessage: response.errorMessage,
          queuesLoaded: true,
        });
      }
    }).catch(error => {
      this.setState({
        errorMessage: "Exception:"  + error.message,
        queuesLoaded: true,
      });
      console.log(error.message);
    });

  };

  componentDidMount() {

    if (this.props.loggedInUser) {
      this.loadQueues();
    }
  }

  createQueue = async (queueName) => {
    if (this.state.queues.length >= this.state.max_queues) {
      this.setState({
        errorMessage: "You can only create a maximum of 3 queues under the free plan."
      });
      return;
    }


    this.setState({
      errorMessage: null
    });

    let {loggedInUser} = this.props;
    if (!loggedInUser) {
      this.setState({
        errorMessage: "Can not create the queue right now."
      });
      return;
    }

    console.log("_createQueue");
    try {
      let apiName = 'epiqueueapi';
      let path = '/queue';
      let putData = {
        body: {
          name: queueName,
        }
      };
      var response = await API.put(apiName, path, putData);
      console.log("response: " + JSON.stringify(response));

      if (!response.success) {
        this.setState({
          errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred"
        });
      } else {
        let queue = response.queue;
        this.props.history.replace("/dashboard/edit/" + queue.id);
      }

    } catch (error) {
      console.log(error.message);
      this.setState({
        errorMessage: error.message
      });
    }
  };


  updateCompany(event) {
    this.setState({company: event.target.value});

  }

  updateSelling(event) {
    this.setState({selling: event.target.value});
  }


  addToList() {
    logger.debug("addToList");
    logger.debug(this.state.company);
    logger.debug(this.state.selling);


    try {
      let apiName = 'epiqueueapi';
      let path = '/user/seller_interest';
      let postData = {
        body: {
          company: this.state.company,
          selling: this.state.selling,
        }
      };
      API.post(apiName, path, postData).then(response => {
        if (!response.success) {
          this.setState({
            errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred"
          });
        } else {
          this.setState({
            successMessage: "Awesome, well be in touch!"
          });
        }
      });
    } catch (error) {
      console.log(error.message);
      this.setState({
        errorMessage: error.message
      });
    }

  }

  renderLoading() {
    return (
      <Loading/>
    );
  }

  renderSeller() {
    if (!this.props.loggedInUser) {
      return null;
    }


    if (this.state.seller === null) {
      return null;
    }


    let {seller, queues} = this.state;
    let {loggedInUser} = this.props;
    let maxQueues = seller.max_queues;
    let rake = seller.rake;
    let currentNumQueues = queues.length;



    if (loggedInUser.email.toLowerCase().lastIndexOf('@epiqueue.com') === -1) {
      return (
        <>
          <div className="font-150 mt-4">
            <div className="text-center">
              <strong>We have ended the seller's program</strong>
            </div>
            <p/>
            Thank you for your interest in the sellers program. We have decided to end the seller's program in order to
            focus on building the platform. We hope to re-open the program in the future.
            <p/>
            Please feel free to contact us at: <a target="_blank" rel="noopener noreferrer"
                                                  href="mailto:sellers@epiqueue.com">sellers@epiqueue.com</a>
          </div>
        </>
      );
    }



    return (
      <>

        <div className="d-flex flex-column w-100">
          <ErrorMessage errorMessage={this.state.errorMessage}/>
          <ErrorMessage type="success" errorMessage={this.state.successMessage}/>
        </div>

        <Overview/>
        <CurrentStats/>

        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <div className="dashboard-screen-title">Settings</div>
        </div>


        <div className="dashboard-settings-card">
          <div className="mb-1">
            <strong>Number of Queues</strong>: You are using {currentNumQueues} of your {maxQueues} queues.
          </div>
          <div className="mb-1">
            <strong>Charge per sale</strong>: {rake}% + Credit Card Fees (2.9% + 30 cents).
          </div>
          <div className="">
            <strong>Nexus States</strong>: States where you have a business presence, at least one is required for tax calculations
          </div>
          <div>
            <NexusSelector seller={this.state.seller}/>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row justify-content-sm-start justify-content-md-between align-items-center mb-3">
          <div className="dashboard-screen-title">Manage Your Queues</div>
          <div className="dashboard-screen-create-link">
            {(currentNumQueues < maxQueues) ?
              <div className="d-flex flex-column justify-content-end">
            <div className="btn btn-outline-primary epiqueue-darkblue-text"
                 data-toggle = "modal"
                 data-target = {"#create-queue-modal"}
            ><i className="fas fa-plus-square"></i> Create New Queue</div>
              </div>
              : <div>You are using {currentNumQueues} of your {maxQueues} queues.</div>}
          </div>
        </div>
        <div className="d-flex flex-column w-100">
          {this.state.queues && this.state.queues.length > 0 ?
            this.state.queues.map(item => {
              return (
                <DashboardQueue key={item.identifier} identifier={item.identifier}/>
              );
            })
            : <div>No Queues, See our <Link to="/help/creating-a-queue">Creating Queues Guide</Link> to get started</div>
          }

          <CreateQueueModal id={'create-queue-modal'} title={'Create a Queue'} okLabel={'OK'} cancelLabel={'Cancel'} okCallback={this.createQueue}/>
        </div>
      </>
    )
  }

  /**
   * TODO: move to its own screen
   *
   * @returns {null|*}
   */
  renderNotSellerSignUp() {
    if (!this.props.loggedInUser) {
      return null;
    }

    if (this.props.loggedInUser.is_seller) {
      return null;
    }

    return(
      <>
        <div className="font-150 mt-4">
          <div className="text-center">
          <strong>We have ended the seller's program</strong>
          </div>
          <p/>
          Thank you for your interest in the sellers program. We have decided to end the seller's program in order to focus on building the platform. We hope to re-open the program in the future.
          <p/>
          Please feel free to contact us at: <a target="_blank" rel="noopener noreferrer" href="mailto:sellers@epiqueue.com">sellers@epiqueue.com</a>
        </div>
      </>
    );

      // return(
      // <>
      //
      //
      //   <div className="dashboard-screen-heading ml-lg-5 mr-lg-5">
      //     We would love to have you as a seller on our platform.
      //     <p/>
      //     To get started, tell us a little about yourself and link to your Stripe account.
      //     <p/>
      //     Don't worry if you don't have a stripe account, it takes seconds to open one.
      //     <p/>
      //     We use stripe for the credit card transactions. When a transaction occurs, the money (minus the credit card fees and our fee) is transferred directly to your account. We never mix your money and our money.
      //     <p/>
      //     Get started for free.
      //   </div>
      //
      //
      //   <div className="card-deck dashboard-screen-card-deck ml-lg-5 mr-lg-5">
      //     <div className="card dashboard-screen-card">
      //       <div className="card-header text-center dashboard-screen-card-header">
      //         Free
      //       </div>
      //       <div className="card-body">
      //         <div className="dashboard-screen-card-pricing">
      //           No upfront costs, Pay as you go
      //         </div>
      //         <ul>
      //           <li>Up to 3 queues</li>
      //           <li>10% of sales + credit card transaction costs<br/>(2.9% + 30 cents per transaction)</li>
      //           <li>No listing fees</li>
      //           <li>Upgrade to premium at any time</li>
      //         </ul>
      //       </div>
      //       <div className="card-body text-center">
      //         <Link to={"/newaccount"}>
      //           <div className="btn btn-primary btn-epiqueue">Become a Seller Now</div>
      //         </Link>
      //       </div>
      //
      //     </div>
      //
      //
      //     <div className="card dashboard-screen-card">
      //       <div className="dashboard-ribbon">Available Soon</div>
      //       <div className="card-header text-center dashboard-screen-card-header">
      //         Premium
      //       </div>
      //       <div className="card-body">
      //         <div className="dashboard-screen-card-pricing">
      //           $29 per month
      //         </div>
      //         <ul>
      //           <li>Higher queue limit</li>
      //           <li>Reduced cut of sales</li>
      //           <li>No listing fees</li>
      //           <li>Details available soon (cost and features subject to change)</li>
      //         </ul>
      //       </div>
      //       <div className="card-footer text-center d-none">
      //           <div className="btn btn-primary btn-epiqueue disabled cursor-arrow" disabled>Become a Seller Now</div>
      //       </div>
      //
      //     </div>
      //
      //   </div>
      //   <div className="ml-lg-5 mr-lg-5 mt-2 text-center">
      //     *We are currently only accepting U.S. based sellers. We are actively working on adding more countries.
      //   </div>
      // </>
    // );
  }


    renderNotSellerEmailUs() {
    if (!this.props.loggedInUser) {
      return null;
    }

    if (this.props.loggedInUser.is_seller) {
      return null;
    }


    return(
      <>

        <div className="col-lg-8 offset-lg-2 col-12">
        <h5 className="mb-3">
          <h4>We would love to have you as a seller on our platform.</h4>
          <p></p>
          As we get up and running, We are currently working with just a few select sellers, but are trying to add more quickly. We will be happy to add you to the list of those interested.
          <p></p>
          Please fill out the form below and we'll let you know when we are ready to add additional sellers
        </h5>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-12">
          <div className="card">
            <div className="card-header text-center">
              Add me to the Epiqueue Seller Interest List!
            </div>
            <div className="card-body">
              <ErrorMessage errorMessage={this.state.errorMessage}/>
              <ErrorMessage type="success" errorMessage={this.state.successMessage}/>

              <div className="form-group">

              <div className="col-form-label">Company</div>
              <input className="form-control" type="text" id="company" value={this.state.company} onChange={this.updateCompany}/>

              <div className="col-form-label">Tell us a little about what you sell</div>
              <input className="form-control" type="text" id="selling" value={this.state.selling} onChange={this.updateSelling}/>

              <div className="d-flex mt-4 justify-content-end">
                <button className="btn btn-primary btn-epiqueue"  onClick={this.addToList}>Submit</button>
              </div>
            </div>
          </div>
          </div>
        </div>
        </div>

      </>
    );
  }



    renderNotSeller() {

      if (!this.props.loggedInUser) {
        return null;
      }

      if (this.props.loggedInUser.is_seller) {
        return null;
      }


      return(
        <>
          <div className="alert alert-info">
            We would love to have you as a seller on our platform. In order to to have your first projects go live, you must <Link to="/newaccount">create a sellers account</Link>.
          </div>

        </>
      );

  }

    renderNotLoggedIn() {
          return(<div>You must be logged in to use this page</div>);
    }


    renderContent() {

    if (!this.props.loggedInUser) {
      return this.renderNotLoggedIn();
    }

    let {queuesLoaded} = this.state;

    if (!queuesLoaded) {
      return <Loading/>
    }

    return (
      <>
        { (!this.props.loggedInUser.is_seller) ?
          this.renderNotSellerSignUp()
          : this.renderSeller()
        }
      </>
  );
}

}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
  {addUserToCache}
)(DashboardScreen));
