import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Queue from "../models/Queue";
import {withRouter, Link} from "react-router-dom";
import ImagePlaceholder from "../img/image-placeholder.png"

import "../css/QueueCard.css"
import "../css/corner-ribbon.css"
import ProfilePic from "./ProfilePic";

class QueueCard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      queue: this.props.queue
    };
  }


  toggleJoinState = (e) => {
    e.preventDefault();

    let {queue} = this.state;

    if (queue.is_member) {
      queue.is_member =false;
    } else {
      queue.is_member =true;
    }

    this.forceUpdate();

  }


  componentDidMount() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  render() {
    let {queue} = this.state;

    return (
    <Link to={queue.getUrl()} style={{textDecoration: 'none' }}>
      <div className="d-flex flex-column queue-card">
        {queue.live_product_count > 0 ?
          <div className="queue-card-corner-ribbon">Live</div>
          :
          null
        }

        {queue.testqueue === 1 ?
          <div className="queue-card-corner-ribbon-right">Test</div>
          :
          null
        }


        <div className="queue-card-image-container">
          {
            queue.primary_image ?
              <img className="queue-card-image" src={"https://cdn.epiqueue.com/public/" + queue.primary_image.url} width={190} height={190} alt={"Primary"}/>
              :
              <img src={ImagePlaceholder} width={190} height={190} alt={"Placeholder"}/>
          }
        </div>
        <div className="queue-card-body overflow-hidden">
          <div className="d-flex flex-row pt-1">
            <div className="pr-1 align-self-center">
              <ProfilePic user={queue.owner}/>
            </div>
            <div className="d-flex flex-column overflow-hidden">
              <div className="queue-card-queue-name oneline-ellipsis">
                {queue.name}
              </div>
              <div className="queue-card-owner-name oneline-ellipsis">
                {queue.owner ? queue.owner.username : null}
              </div>
            </div>
          </div>
        </div>
          <div className="queue-card-join-icon">
              {queue.is_member ?
                <button className="btn btn-sm btn-outline-secondary queue-card-join-icon-button" onClick={(e) => {this.toggleJoinState(e)}}  data-toggle="tooltip" data-placement="bottom" title="Click to leave queue">
                  <i className=" far fa-check-square"/>
                </button>
                :
                <button className="btn btn-sm btn-outline-secondary queue-card-join-icon-button" onClick={(e) => {this.toggleJoinState(e)}} data-toggle="tooltip" data-placement="bottom" title="Click to join queue">
                  <i className=" far fa-square" />
                </button>
              }
          </div>
      </div>
    </Link>
    );
  };
}

QueueCard.propTypes = {
  queue: PropTypes.instanceOf(Queue).isRequired
};

export default withRouter(QueueCard);

