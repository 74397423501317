import React, {} from 'react';
import {API, Logger} from "aws-amplify";
import Product from "../models/Product";
import {Link, withRouter} from "react-router-dom";
import RootScreen from "./RootScreen";
import ImageViewer from "../components/ImageViewer";
import ErrorMessage from "../components/ErrorMessage";

import "../css/ProductScreen.css";
import Loading from "../components/Loading";
import {connect} from "react-redux";
import OKCancelModal from "../components/modals/OKCancelModal";
import SelectAddressModal from "../components/modals/SelectAddressModal";
import Sale from "../models/Sale";
import StripeCustomer from "../models/StripeCustomer";
import ShippingAddress from "../models/ShippingAddress";
import ProductMember from "../models/ProductMember";
import TagContainer from "../components/TagContainer";
import BackLink from "../components/BackLink";
import AddCommentModal from "../components/modals/AddCommentModal";
import ReactMarkdown from "react-markdown/with-html";

const logger = new Logger('ProductScreen');

class ProductScreen extends RootScreen {

  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'ProductScreen'
    });

    const { match } = this.props;

    this.state = {
      id: match.params.id,
      product: null,
      queue: null,
      sale: null,
      productLoaded: false,
      queueLoaded: false,
      errorMessage: null,
      successMessage: null,
      selfMember: null,
      isOwner: false,
      isQueueMember: false,
      agreeToPurchaseCheckbox: false,
      enableYesButton: false,
      enableAgreementButton: false,
      shippingAddresses:null,
      selectedShipping:null,
      stripeCustomer: null,
      showAddressModal: false,

      goLiveErrors: [],
      goLiveWarnings: [],
      hasConflictingLive: false,

      joiningQueue: false,

      cloningProduct: false,
    };

    window.scrollTo(0, 0);

    this.sendOption = this.sendOption.bind(this);
  }

  componentDidMount = async() => {
    window.scrollTo(0, 0);

    this.loadProduct();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }


    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState({
        id: this.props.match.params.id,
        product: null,
        queue: null,
        sale: null,
        productLoaded: false,
        queueLoaded: false,
        errorMessage: null,
        successMessage: null,
        selfMember: null,
        isOwner: false,
        isQueueMember: false,
        agreeToPurchaseCheckbox: false,
        enableYesButton: false,
        enableAgreementButton: false,
        shippingAddresses:null,
        selectedShipping:null,
        stripeCustomer: null,
        showAddressModal: false,
      }, () => {
        this.loadProduct();
      });
    }
  }


  loadProduct = async () => {

    let {id} = this.state;

    try {
      let apiName = 'epiqueueapi';
      let path = '/product/' + id;
      var response = await API.get(apiName, path);
      if (!response.success) {
        this.setState({
          product: null,
          errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred",
          productLoaded: true,
          isOwner: false,
          isQueueMember: false,
        });
      } else {
        let product = new Product(response.product);
        let queue = product.queue;
        let isOwner = this.props.loggedInUser && product.queue.user_id === this.props.loggedInUser.id;
        let isQueueMember = response.isQueueMember;
        let selfMember = null;
        let enableAgreementButton = false;


        let stripeCustomer = null;
        if (response.stripeCustomer) {
          stripeCustomer = new StripeCustomer(response.stripeCustomer);
        }

        let goLiveErrors = [];
        let goLiveWarnings = [];
        let hasConflictingLive = false;
        if (response.notifications) {
          if (response.notifications.errors) {
            goLiveErrors = response.notifications.errors.map(item => {
              return item;
            });
          }
          if (response.notifications.warnings) {
            goLiveWarnings = response.notifications.warnings.map(item => {
              return item;
            });
          }
          if (response.notifications.has_live) {
            hasConflictingLive = response.notifications.has_live;
            logger.debug(response.notifications.has_live);
          }
        }


        let shippingAddresses = null;
        let selectedShipping = null;

        if (this.props.loggedInUser) {
          if (response.product_member) {
            selfMember = new ProductMember(response.product_member);
          }


          if (response.shippingAddresses && response.shippingAddresses.length > 0 && (selfMember && selfMember.shipping_address_id !== null)) {
            shippingAddresses = response.shippingAddresses.map(item => {
              return new ShippingAddress(item)
            });
            selectedShipping = response.shippingAddresses.find(item => {
              return  item.id === selfMember.shipping_address_id;
            });
          }

          if (selfMember && selfMember.shipping_address_id !== null && selectedShipping !== null && stripeCustomer) {
            enableAgreementButton = true;
          }
        }

        let sale = null;
        if (response.sale !== null) {
          sale = new Sale(response.sale);
        }

        this.setState({
          product: product,
          queue: queue,
          sale: sale,
          productLoaded: true,
          queueLoaded: queue !== null,
          isOwner: isOwner,
          isQueueMember: isQueueMember,
          selfMember: selfMember,
          stripeCustomer: stripeCustomer,
          shippingAddresses: shippingAddresses,
          selectedShipping: selectedShipping,
          enableAgreementButton: enableAgreementButton,
          goLiveErrors: goLiveErrors,
          goLiveWarnings: goLiveWarnings,
          hasConflictingLive: hasConflictingLive,

        });
      }
    } catch (error) {
      console.log(error.message);
      logger.error(error);
      logger.error(error);
      this.setState({
        errorMessage: error.message,
        productLoaded: true,
        isOwner: false,
        isQueueMember: false,
      });
    }
  };

  showAddressModal = (e) => {
    if (this.state.showAddressModal) {
      return;
    }

    logger.debug("show modal")
    this.setState({
      showAddressModal: true
    });
  };

  editProduct =  async() => {
    logger.debug("editProduct")

    this.props.history.push("/queue/" + this.state.product.queue_id + "/editproduct/" + this.state.product.id)
  };


  deleteProduct =  async() => {
    logger.debug("deleteProduct")

    let {product} = this.state;
   // let {queue} = product.queue;

    if (product.isLive()) {
      this.setState({
        errorMessage: "You can not delete a live product."
      });
      console.log("can't delete - is live");
      return;
    }

    if (product.isClosed()) {
      this.setState({
        errorMessage: "You can not delete a product that had been live."
      });
      return;
    }

    this.setState({
      errorMessage: null
    });

    try {
      let apiName = 'epiqueueapi';
      let path = '/product/' +  product.id;
      let deleteData = {
        body: {
        }
      };
      var response = await API.del(apiName, path, deleteData);

      if (!response.success) {
        this.setState({
          errorMessage: (response.error) ? response.error : "An error occurred"
        });
      } else {

        this.props.history.replace("/queue/" + product.queue_id);
      }
      return true;
    } catch (error) {
      console.log(error.message);
      this.setState({
        errorMessage: error.message
      });
    }
    return false;



  }

  endSaleEarly = async(e) => {
    e.stopPropagation();
    if (!this.state.product) {
      return null;
    }

    let {product} = this.state;

    logger.debug("end product")

    let apiName = 'epiqueueapi';
    let path = '/product/end';
    let postData = {
      body: {
        product_id: product.id,
      }
    };
    var response = await API.post(apiName, path, postData);
    logger.debug(JSON.stringify(response));
    if (!response.success) {
      this.setState({
        errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred"
      });
    } else {
      this.setState({
        product: new Product(response.product),
      });
    }

  };

  makeLive = async (e) => {
    e.stopPropagation();

    if (!this.state.product) {
      return null;
    }

    let {product} = this.state;

    let apiName = 'epiqueueapi';
    let path = '/product/makelive';
    let postData = {
      body: {
        product_id: product.id,
      }
    };
    var response = await API.post(apiName, path, postData);
    if (!response.success) {
      logger.debug(JSON.stringify(response));
      this.setState({
        errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred"
      });
    } else {
      this.setState({
        product: new Product(response.product),
      });
    }
  };


  cancelSale = async (e) => {
    logger.debug("cancel sale");


    e.stopPropagation();
    if (!this.state.product) {
      return null;
    }

    let {product} = this.state;

    logger.debug("cancel product sale")

    let apiName = 'epiqueueapi';
    let path = '/product/cancel';
    let postData = {
      body: {
        product_id: product.id,
      }
    };
    var response = await API.post(apiName, path, postData);
    logger.debug(JSON.stringify(response));
    if (!response.success) {
      this.setState({
        errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred"
      });
    } else {
      this.setState({
        product: new Product(response.product),
      });
    }


  };

  commitYes = (comment = null) => {
    logger.debug('yes');
    logger.debug(comment);
    this.sendOption(1, comment);
  }

  commitNo = (comment = null) => {
    logger.debug('no');
    logger.debug(comment);
    this.sendOption(0, comment);
  }

  sendOption = async (option, comment = null) => {

    logger.debug("sendOption: " + comment);

    let {product} = this.state;

    if (this.state.selfMember === null) {
      this.setState({
        errorMessage: "Sorry, you are not a member of this product's queue"
      });
      return;
    }

    let optionVal = (option === 0) ? 0 : 1;

    try {

      let apiName = 'epiqueueapi';
      let path = '/product/member/response';
      let postData = {
        body: {
          identifier: this.state.selfMember.identifier,
          response: optionVal,
          comment: comment,
        }
      };
      var response = await API.post(apiName, path, postData);
      if (!response.success) {
        throw new Error(response.errorMessage)
      }

      let selfMember = new ProductMember(response.member);
      logger.debug('selfMember: ' + selfMember.identifier)
      let members = product.product_members.map(function(item){
        if (item.id === selfMember.id) {
          return selfMember;
        }
        return item;
      });
      product.product_members = members;

      this.setState({
        selfMember: selfMember,
        product: product,
      });

    } catch (error) {
      window.scrollTo(0, 0);
      this.setState({
        errorMessage: error.message,
      });

    }


    logger.debug('sendOption: ' + option);
    logger.debug('sendOption: ' + this.state.selfMember.identifier);

  };


  joinQueue = async () => {

    logger.debug("joinQueue ENTER")

    let {queue} = this.state;

    if (!queue) {
      return;
    }

    await queue.join().then((result)=> {
      if (result.success) {
        this.loadProduct().then( () => {
        });
      } else {
        this.setState({
          errorMessage: result.errorMessage,
        });
      }
    }).catch(error => {
      this.setState({
        errorMessage: error.message,
      });
    });


  };


  updateShippingAddress = async(newId) => {

    let apiName = 'epiqueueapi';
    let path = '/product/member/update_shipping_address';
    let postData = {
      body: {
        shipping_address_id: newId,
        product_id: this.state.product.id,
      }
    };
    var response = await API.post(apiName, path, postData);
    logger.debug(JSON.stringify(response));
    if (response.success) {
      this.loadProduct();
    } else {
      this.setState({
        errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred"
      });
    }


  }

  cloneProduct = async () => {
    logger.debug("clone");
    this.setState({
      cloningProduct: true,
    });

    let apiName = 'epiqueueapi';
    let path = '/product/clone';
    let postData = {
      body: {
        product_id: this.state.product.id,
      }
    };
    var response = await API.post(apiName, path, postData);
    logger.debug(JSON.stringify(response));
    if (response.success) {

      await this.setState({
        cloningProduct: false,
      });

      window.scrollTo(0, 0);

      let newProduct = new Product(response.product);
      this.props.history.push(newProduct.getUrl());

    } else {
      window.scrollTo(0, 0);
      this.setState({
        errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred",
        cloningProduct: false,
      });
    }

  };


  uploadCallBack(product) {
    this.setState({
      product: product
    })
  }

  trashImage = async (id) => {
    logger.debug("trash: " + id);
  };

  handleAgreePurchaseChange = (event) => {
    this.setState({
      agreeToPurchaseCheckbox: event.target.checked,
      enableYesButton: event.target.checked,
    });

  }


  renderUpcomingBlock() {
    let {product, isOwner} = this.state;

    if (isOwner) {
      return (
        <div>
          <div className="alert alert-info alert-dismissible fade show">
            <div className="font-weight-bolder">This product is not yet available</div>
            {!product.visible ?
              <div className="mt-3 text-danger">This product is not visible to users</div>
              :
              <div className="mt-3 red">This product is visible to users</div>
            }

            {this.state.hasConflictingLive ?
              <div className="mt-1 text-danger">This product can go live once the current sale ends</div>
              :
              null
            }

            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          {this.state.goLiveErrors.length > 0 ?
            <div className="alert alert-danger alert-dismissible fade show">
              <div className="font-weight-bolder">Errors that must be fixed before going live</div>
              <ul>
              {this.state.goLiveErrors.map(item => {
                return <li>{item}</li>
              })}
              </ul>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="btn btn-info btn-sm" onClick={() => this.editProduct()}><i className="fas fa-edit"></i> Fix Product</div>
            </div>
            : null }

          {this.state.goLiveWarnings.length > 0 ?
            <div className="alert alert-warning alert-dismissible fade show">
              <div className="font-weight-bolder">Warnings that might effect your product sale.</div>
              <ul>
              {this.state.goLiveWarnings.map(item => {
                return <li>{item}</li>
              })}
              </ul>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            : null }

        </div>
      );


    }
    return <div className="alert alert-info alert-dismissible fade show">
      <div className="font-weight-bolder">This product is not yet available</div>
      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

  }

  renderStatus() {
    let {product, isOwner, isQueueMember, selfMember} = this.state;

    switch (product.status) {
      case Product.STATUS_LIVE:
        return (
          <div className="alert alert-success alert-dismissible fade show">

            {!isOwner && !isQueueMember ?
              <> This Product sale is live and ends on {product.getFormatedEndDate()} <p/><div className="btn btn-primary btn-block btn-epiqueue" onClick={this.joinQueue}>Join the queue to participate in this sale.</div></>
              : <div>
                  This Product sale is live and ends on {product.getFormatedEndDate()}
                {selfMember ? <div>You are {selfMember.order} in the queue</div> : null }
                </div>
            }
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        );
      case Product.STATUS_DELETED:
        return <div className="alert alert-warning">This Product sale has been deleted</div>
      case Product.STATUS_DISABLED:
        return <div className="alert alert-warning">This Product sale has been disabled</div>
      case Product.STATUS_CANCELLED:
      case Product.STATUS_PENDING_CANCEL:
        return <div className="alert alert-warning">This Product sale has been cancelled</div>
      case Product.STATUS_PENDING_CLOSE:
      case Product.STATUS_CLOSED:
        return <div className="alert alert-warning alert-dismissible fade show">This Product sale has ended
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      case Product.STATUS_UPCOMING:
        return this.renderUpcomingBlock();
      default:
        return <div className="alert alert-warning">This Product is in an unknown state ({product.status})</div>
    }
  }


  renderMembers() {

    let {product} = this.state;
    let {product_members} = product;

    if (product_members === null || product_members.count === 0) {
      return(<div>No members</div>);
    }

    let memberRows =  product_members.map( (item) => {
      return (
        <tr key={item.identifier}>
          <td>{item.place+1} </td>
          <td>{item.user.username}</td>
          <td>{item.response === null ? 'Not Responded' : (item.response === 1) ? 'Purchase' : 'Pass' }</td>
        </tr>
      );
    })


    return (
    <table className="table table-sm">
      <thead className="thead-light">
        <tr>
          <th>#</th>
          <th>Member</th>
          <th>Response</th>
        </tr>
      </thead>
      <tbody>
      {memberRows}
      </tbody>
    </table>
    )

  }


  renderImages() {
    let {product} = this.state;
    return <ImageViewer images={product.images} size={200}/>
  }

  renderSelfMember() {
    let {selfMember, sale, product, queue, stripeCustomer} = this.state;

    if (!selfMember) {
      return null;
    }

    let seller = queue.owner;


    if (this.state.product.isLive()) {
      return (
        <div className="alert alert-primary product-screen-alert-purchase">
          <h3>Don’t Worry—We’ll Hold Your Spot in Line</h3>
          <h6>Sale ends at {product.getFormatedEndDate()}</h6>
          <span className="mb-3">You’re currently #{selfMember.place+1} in the queue!</span>
          <div className="mt-1">
            <div>
              How the Queue Works:
              <ol>
                <li><strong>Wait for What Speaks to You</strong><br/>Once a product is listed, you’ll be asked whether you’d like to purchase or pass on that product. If you select ‘purchase,’ you commit to purchasing the product if eligible.</li>
                {!this.state.stripeCustomer  || (this.state.shippingAddresses === null || this.state.shippingAddresses.length === 0) ?
                  <li><strong>You must have a saved payment method and shipping address to be eligible to purchase.</strong>
                    <ul>
                      <li><Link to={"/settings"}><strong>You can set you payment and shipping defaults here</strong></Link></li>
                    </ul>
                  </li>
                  : null
                }
                <li><strong>Watch Your Number</strong><br/>Your payment will process <strong>only</strong> if you make it to the number one spot in the queue.</li>
                <li><strong>Bask in Curator Bliss</strong><br/>Once your purchase is confirmed, We will ship your product in the agreed upon time frame, typically 3-5 business days. </li>
              </ol>
            </div>

            <div className="mt-3">
              <div className="row">
                <div className="col-lg-4 col-sm-12 mb-3">
                  <div className="ml-3">
                    <div className="mb-2"><u>Amount to Be Charged</u></div>
                    <table className="table table-responsive table-sm">
                      <tbody>
                      <tr>
                        <td>Product:</td>
                        <td align="right">{product.getDisplayPrice()}</td>
                      </tr>
                      <tr className="">
                        <td>Estimated Shipping:</td>
                        <td align="right">{selfMember.getFormattedShipping()}</td>
                      </tr>
                      <tr className="border_bottom">
                        <td>Estimated Tax:</td>
                        <td align="right">{selfMember.getFormattedTax()}</td>
                      </tr>
                      <tr className="">
                        <td><strong>Total:</strong></td>
                        <td align="right"><strong>{selfMember.getTotalPrice(product)}</strong></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 mb-3">
                  <div className="mb-2"><u>Card to Be Charged:</u></div>
                  {
                    stripeCustomer ?
                      <div>
                          {stripeCustomer.getMaskedCreditCardNumber()}
                          <br/>
                          {stripeCustomer.expiration}
                        </div>
                      :
                      <Link to={"/settings"}> You must set a default card</Link>
                  }
                </div>
                <div className="col-lg-4 col-sm-12 mb-3">
                  <div className="mb-2"><u>To Be Shipped To:</u></div>
                  <div>
                    {this.state.selectedShipping ?
                      <div>
                        {this.state.selectedShipping.name}<br/>
                        {this.state.selectedShipping.address1}<br/>
                        {this.state.selectedShipping.address2 ?
                          this.state.selectedShipping.address2 + <br/>
                          : null
                        }
                        {this.state.selectedShipping.city}, {this.state.selectedShipping.state} {this.state.selectedShipping.zip}<br/>
                        {this.state.selectedShipping.country}
                        <p/>

                        <div
                          className = "btn btn-warning btn-sm"
                          data-toggle = "modal"
                          data-target = {"#select_address_modal"}
                        >Select a different shipping address
                        </div>
                        <SelectAddressModal id={'select_address_modal'} addresses={this.state.shippingAddresses} selected={this.state.selectedShipping.id} okCallback={this.updateShippingAddress}/>
                      </div>
                      :
                      <Link to={"/settings"}>You must set a shipping address</Link>}
                  </div>
                </div>
              </div>



              <div className="form-group">
                <div className="form-check">
                  <input type="checkbox" className={"form-check-input " + (this.state.enableAgreementButton ? "" : ' disabled')} id="agreeToPurchaseCheckbox" onChange={this.handleAgreePurchaseChange} checked={ this.state.agreeToPurchaseCheckbox } disabled={!this.state.enableAgreementButton}/>
                  <label className="form-check-label" htmlFor="agreeToPurchaseCheckbox">If I’m eligible, I agree to purchase for: {selfMember.getTotalPrice(product)}</label>
                </div>
                {!this.state.enableAgreementButton ? <small>You must have both a payment method and shipping address set in order to purchase</small> : null}
              </div>

              <button className={"btn btn-primary pl-5 pr-5" + (this.state.enableYesButton ? "" : ' disabled')}
                      data-toggle = "modal"
                      data-target = "#purchase_comment"
                      disabled={!this.state.enableYesButton}>Purchase</button>
              <button className="btn btn-danger ml-3 pl-5 pr-5"
                      data-toggle = "modal"
                      data-target = "#pass_comment"
              >Pass</button>
              <AddCommentModal id="purchase_comment"
                               submitCallback={this.commitYes}
                               message="Great! Add an optional message if there is anything we should know."
                               title="Complete Purchase"
              />
              <AddCommentModal id="pass_comment"
                               submitCallback={this.commitNo}
                               message="Add an optional message let the us know why you passed. This will help in planning future sales."
                               title="Complete Pass"
              />

            </div>
            <div className="mt-4">
              {
                selfMember.response === null ? 'You have not responded yet' : 'You have already responded: ' + (selfMember.response ? "Purchase" : "Pass")
              }
            </div>

            <div className="mt-1">
              {
                selfMember.comment ? "Comment: " + selfMember.comment : null
              }
            </div>

          </div>

        </div>
      )
    }

    if (this.state.product.isPrevious()) {

      let saleComplete = sale && sale.isComplete();
      let salePending = sale && sale.isOpen();

      let optedInLost = false;
      let optedInWon = false;
      let optedOut = selfMember.response === ProductMember.RESPONSE_OPT_OUT || selfMember.response === ProductMember.RESPONSE_DID_NOT_RESPOND;
      if (!optedOut) {
        optedInLost = selfMember.response === ProductMember.RESPONSE_OPT_IN && (selfMember.result === ProductMember.RESULT_LOST || selfMember.result === ProductMember.RESULT_DID_NOT_RESPOND);
      }
      if (!optedInLost && !optedOut) {
        optedInWon = selfMember.response === ProductMember.RESPONSE_OPT_IN && selfMember.result === ProductMember.RESULT_WON;
      }

      if (optedOut) {
        return (
          <div className="alert alert-primary">
            <h3>Passing Works Too</h3>
            <div>
                By passing on this product, you’re now #{selfMember.place+1} in line for {seller.username}’s next listing.
            </div>
          </div>
        );

      } else if (optedInLost) {
        return (
          <div className="alert alert-primary">
            <h3>All About That Next Listing</h3>
            <div>
              Even though you didn’t make it to the top of the queue for this product, being #{selfMember.place+1} in line for the next one has to feel good, right?!
            </div>
          </div>
        );
      } else if (optedInWon) {

        if (sale && sale.status === Sale.STATUS_PAYMENT_FAILED) {
          return(
            <div className="alert alert-primary">
              <h3>Well this is awkward...</h3>
              <div>

                <div className="mb-2">The Good new is you made it to the top of the queue. You can purchase the item.</div>

                <div className="mb-2">
                  The not so good news is we weren't able to charge your credit card.
                  <br/>Please update your payment details and we'll try it again.
                </div>

                <div className="mb-2">
                  Need some extra help, feel free to <Link className="text-dark" to={"/help/contact-us"}>reach out to our support team</Link>
                </div>
                <Link to={"/settings"}><div className="btn btn-primary btn-epiqueue">Update Payment Details</div></Link>
              </div>
            </div>
          )
        }

        return (
          <div className="alert alert-primary">
            <h3>Life at the Top of the Queue</h3>
            <div>
              {salePending ?
                <Link to={"/product/purchase/" + sale.identifier}>Complete Purchase</Link>
                : null
              }

              {saleComplete ?
                <>
                  <div>Feel all the good feels. {seller.username} is wrapping up your order as we speak.</div>
                  <Link to={"/orders/receipt/" + sale.identifier}><div className="btn btn-primary mt-3">View Receipt</div></Link>
                </>
                : null
              }

              { (!salePending && !saleComplete) ?
                <div>Unfortunately there was an error and we were not able to find your receipt</div>
                : null
              }
            </div>
          </div>
        );

      } else {
        // UNKNOWN STATE
        return (
          <div className="alert alert-danger">Oh Snap, an error occurred</div>
        );
      }
    }

  }

  renderProductActions = () => {
    let {product, isOwner, isQueueMember} = this.state;

    if (!isOwner) {

      if (!this.props.loggedInUser) {
        return (
          <div className="product-screen-nonmember-block">Log in to join this product's queue.</div>
        );
      }

      return (
        <>
          {isQueueMember ?
            <div className="product-screen-member-block">
              You are a member of this product's queue
            </div>
            :
            <div className="product-screen-nonmember-block">You are not a member of this product's queue.</div>
          }
        </>
        )
    }

    if (product.isLive()) {
      return (
        <div className="d-flex flex-column w-75 align-self-center">
          <div className="mb-2">
            <button
              type = "button"
              className="btn btn-sm btn-block  btn-warning mr-1"
              data-toggle = "modal"
              data-target = {"#endlive-product-" + product.id}
            ><i className="fas fa-stopwatch"></i> End Sale Early</button>
          </div>

          <div className="mb-2">
            <button className="btn btn-sm btn-block btn-primary mr-1"
                    data-toggle = "modal"
                    data-target = {"#cancellive-product-" + product.id}
            ><i className="fas fa-ban"></i> Cancel Sale</button>
          </div>

          <div className="mb-2">
            <button
              className="btn btn-sm btn-block btn-info mr-1"
              onClick={() => {this.editProduct()} }
            ><i className="fas fa-edit"></i> Edit Product</button>
          </div>


          <OKCancelModal id={"endlive-product-" + product.id} message={
            <div>
            Do you really want to end this sale early?
            <p/>
            <ul>
              <li>Burers will be notified of the end of sale.</li>
              <li>Any buyer who chose to purchase will have their card charged.</li>
            </ul>
          </div>
          } title={"End Product Sale"} okLabel={"Yep, End It"} cancelLabel={"Nope, cancel"} okCallback={this.endSaleEarly}/>
          <OKCancelModal id={"cancellive-product-" + product.id} message={
            <div>
              Do you really want to cancel this product sale?
              <p/>
              <ul>
                <li>The sale will be cancelled</li>
                <li>Buyers will be notified of cancellation</li>
              </ul>
            </div>
          } title={"Cancel Product Sale"} okLabel={"Yep, Cancel It"} cancelLabel={"Nope, Let It Run"} okCallback={this.cancelSale}/>
        </div>
      );
    }

    if (product.isUpcoming()) {
      return (
        <div className="d-flex flex-column w-75 align-self-center">

          {this.state.goLiveErrors.length === 0 && this.state.hasConflictingLive === false ?
          <div className="mb-2">
            <button className="btn btn-sm btn-block btn-primary mr-1"
                    data-toggle = "modal"
                    data-target = {"#makelive-product-" + product.id}
            ><i className="fas fa-monitor-heart-rate"></i> Make Live Now</button>
          </div>
          : null}

          <div className="mb-2">
            <button className="btn btn-sm btn-block btn-info mr-1" onClick={() => {this.editProduct()} }><i className="fas fa-edit"></i> Edit Product</button>
          </div>

          <div className="mb-2">
            <button
              type = "button"
              className = "btn btn-sm btn-block btn-danger"
              data-toggle = "modal"
              data-target = {"#delete-product-" + product.id}
            ><i className="fas fa-trash-alt"/> Delete Product
            </button>
          </div>
          <OKCancelModal id={"delete-product-" + product.id} message={<div>Do you really want to permanently delete this product?</div>} title={"Delete Product"} okLabel={"Yep, Delete away"} cancelLabel={"Nope, cancel"} okCallback={this.deleteProduct}/>
          <OKCancelModal id={"makelive-product-" + product.id} message={<div>Do you really want to make this product live?</div>} title={"Make Product Live"} okLabel={"Yep, Let's Go"} cancelLabel={"Nope, cancel"} okCallback={this.makeLive}/>
        </div>
      );
    }

    return (
      <div className="d-flex flex-column">
        <div className="d-flex flex-row justify-content-center w-100 product-screen-top-info-block mb-1">
          This product sale ended at {product.getFormatedEndDate()}
        </div>
        <div className="btn btn-primary btn-epiqueue" onClick={this.cloneProduct}>Clone This Product</div>
      </div>
    );

  }

  renderProductHeader() {
    let {product, selfMember} = this.state;

    let isLive = product.isLive();
    //let isClosed = product.isClosed();
    let isPrevious = product.isPrevious();

    return(
      <div className="d-flex flex-column align-content-stretch">
        {(isLive || isPrevious) && selfMember ?
          this.renderSelfMember()
          :
          this.renderStatus()
        }
      </div>
    );

  }

  renderProductBody() {
    let {product} = this.state;
    //let {queue} = product;

    return (
      <div className="d-flex flex-column flex-lg-row product-screen-product-block">

        <div className="col-sm-12 col-lg-5">
          <div className="d-flex flex-column mr-0 mb-2 ml-0 justify-content-center align-middle">
            <ImageViewer images={product.images}/>
            <div className="product-screen-action-button">
              {this.renderProductActions()}
            </div>
          </div>

        </div>

        <div className="col-sm-12 col-lg-7 product-screen-text">


          <div className="product-screen-title">{product.name}</div>
          <div className="product-screen-description">
            <div className="product-screen-description-label">About this product</div>
            <ReactMarkdown source={product.description} escapeHtml={false} />
          </div>

          <div className="product-screen-description">
            <div className="product-screen-description-label">Price</div>
            {product.taxable === Product.TAXABLE ?
              <div className="product-screen-description-text">{product.getDisplayPrice()} + {product.getFormattedShippingCharge()} Shipping (plus tax if applicable)</div>
              :
              <div className="product-screen-description-text">{product.getDisplayPrice()} + {product.getFormattedShippingCharge()} Shipping<br/>No tax will be charged on this item</div>
            }
          </div>

          <div className="product-screen-description">
            <div className="product-screen-description-label">Shipping Information</div>
            <div className="product-screen-description-text">{product.shipping_description ? product.shipping_description : "No shipping information provided"}</div>
          </div>

          <div className="product-screen-description">
            <div className="product-screen-description-label">Return Policy</div>
            <div className="product-screen-description-text">{product.return_policy ? product.return_policy : "No return policy provided"}</div>
          </div>

          <div className="product-screen-description">
            <div className="product-screen-description-label">Inventory Available</div>
            <div className="product-screen-description-text">{product.quantity}</div>
          </div>

          <div className="product-screen-description">
            <div className="product-screen-description-label">Tags</div>
            <div className="product-screen-description-text">{ (product.tags && product.tags.length > 0) ? <TagContainer editable={false} tags={product.tags}/> : "none" }</div>
          </div>


        </div>


      </div>
    );

  }


  renderContent() {
    let {product, productLoaded, queue} = this.state;
    if (!productLoaded) {
      return(
        <Loading/>
      );
    }

    if (!product) {
      return(
        <div className="alert alert-danger">{this.state.errorMessage}</div>
      );
    }

    // let isLive = product.isLive();
    // let isClosed = product.isClosed();
    // let isPrevious = product.isPrevious();

    return (
      <>
        <BackLink link={queue.getUrl()} text={"Back to " + queue.name} />

        <ErrorMessage errorMessage={this.state.errorMessage}/>
        {this.renderProductHeader()}
        {this.renderProductBody()}

        {product.product_members && product.product_members.length > 0 ?
          <div className="mt-3">
            {this.renderMembers()}
          </div>
          : null
        }
      </>
    )
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps
)(ProductScreen));
