
import React, { Component } from 'react';
import "../../css/HelpScreen.css"
import BlankBox from "../BlankBox";

class QueueHelp extends Component {
  static TITLE = "How Queues Work";

  content() {
    return (
      <ol>
        <li className="mb-3"><strong>Join Your Favorite Queues</strong><br/>
          By joining a queue, you’ll be the first to know when new products are listed.</li>
        <li className="mb-3"><strong>Wait for What Speaks to You</strong><br/>
          Once a product is listed in one of your queues, you’ll be asked whether you’d like to purchase or pass on that product. If you select ‘purchase,’ you commit to purchasing the product if eligible.</li>
        <li className="mb-3"><strong>Watch Your Number</strong><br/>
          Your payment will process only if you make it to the number one spot in that queue.</li>
        <li className="mb-3"><strong>Bask in Curator Bliss</strong><br/>
          Once your purchase is confirmed, We'll will ship your product in the agreed upon time frame, typically 3-5 business days.</li>
      </ol>
    )
  }

  render() {
    return (
      <BlankBox contentCallback={this.content} title="How Queues Work"/>
    )
  }
}

export default QueueHelp;