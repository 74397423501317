import React, { Component } from 'react';
import {API, Auth, Logger} from "aws-amplify";
import {withRouter, Redirect, Link} from "react-router-dom";
import {connect} from "react-redux";
import {setLoggedInUser} from "../../redux/actions";
import User from "../../models/User";
import AuthMessage from "../../models/AuthMessage";
import BusyModal from "../../components/modals/BusyModal";

import "../../css/AuthScreens.css";

const logger = new Logger('RegisterScreen');

class RegisterScreen extends Component {

  static ERR_CODE_UserNotConfirmedException = "UserNotConfirmedException";

  constructor(props) {
    super(props);

    this.state = {
      userNameFieldValue: "",
      emailFieldValue: "",
      passwordFieldValue: "",
      password2FieldValue: "",
      agreeToTermsFieldValue: false,
      verificationCodeFieldValue: "",
      waitingToVerify: false,
      resentVerificationCode: false,
      loggedIn: false,
      admin: false,
      user: null,
      registering: false,
      loggingIn: false,
      verificationError: null,
    };

    // This binding is necessary to make `this` work in the callback
    this.submitRegister = this.submitRegister.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePassword2Change = this.handlePassword2Change.bind(this);
    this.handleAgreeToTermsChange = this.handleAgreeToTermsChange.bind(this);
    this.verificationCodeChange = this.verificationCodeChange.bind(this);

  }

  componentDidMount = async() => {

    let waitingToVerify = localStorage.getItem('epiqueue.confirmation');
    if (waitingToVerify) {
      this.setState({
        waitingToVerify: true
      })
    }
  };


  submitRegister = async (e) => {
    e.preventDefault();

    this.setState({
      errorMessage: null,
      registering: true,
    });

    const email = this.state.emailFieldValue.trim();
    const password = this.state.passwordFieldValue.trim();
    const password2 = this.state.password2FieldValue.trim();
    const displayName = this.state.userNameFieldValue.trim();

    if (password !== password2) {
      this.setState({
        errorMessage: "passwords do not match",
        registering: false,
      });
      return;
    }

    if (email === '') {
      this.setState({
        errorMessage: "No email entered",
        registering: false,
      });
      return;
    }
    if (displayName === '') {
      this.setState({
        errorMessage: "No username entered",
        registering: false,
      });
      return;
    }

    try {

      let apiName = 'epiqueueapi';
      let path = '/user/check/available';
      let queryData = {
          'queryStringParameters': {
            'email': email,
            'username': displayName
          }
      };
      var response = await API.get(apiName, path, queryData);
      if (!response.success) {
        this.setState({
          errorMessage: response.errorMessage,
          registering: false,
        });
        return;
      }



      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          preferred_username: displayName,
        },
      });

      localStorage.setItem('epiqueue.confirmation', email);

      window.gtag('event', 'sign_up');

      await this.setState({
        waitingToVerify: true,
        registering: false,
      });

    } catch(error) {
      console.log(error);
      this.setState({
        errorMessage: error.message,
        registering: false,
      })
    }


  };

  handleUserNameChange(event) {
    this.setState({userNameFieldValue: event.target.value});
  }
  handleEmailChange(event) {
    this.setState({emailFieldValue: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({passwordFieldValue: event.target.value});
  }
  handlePassword2Change(event) {
    this.setState({password2FieldValue: event.target.value});
  }

  handleAgreeToTermsChange(event) {
    this.setState({agreeToTermsFieldValue: event.target.checked});
  }

  verificationCodeChange(event) {
    this.setState({verificationCodeFieldValue: event.target.value});
  }


  checkVerification = async() => {
    this.setState({
      messages: [],
      resentVerificationCode: false,
      verificationError: null,
    });

    let email = localStorage.getItem('epiqueue.confirmation')
    let verificationCode = this.state.verificationCodeFieldValue.trim()

    try {
      await Auth.confirmSignUp(email, verificationCode);
      localStorage.removeItem('epiqueue.confirmation');

      if (this.state.passwordFieldValue.trim() !== "") {
        this.loginAfterVerify();
      } else {
        this.props.history.replace("/login");
      }
    } catch(err) {
      let errorMessage = null;
      switch(err.code) {
        case 'InvalidParameterException':
          errorMessage = "Invalid verification code provided, please try again.";
          break;
        default:
          errorMessage = err.message;
          break;
      }
      this.setState({
        verificationError: errorMessage,
      });
    }
  }

  loginAfterVerify = async() => {

    this.setState({
      messages: [],
      loggingIn: true,
      resentVerificationCode: false,
    });

    let email = this.state.emailFieldValue.trim()
    let password = this.state.passwordFieldValue.trim()

    if (email === "") {
      let messages = this.state.messages;
      messages.push(new AuthMessage("You must enter a email address", AuthMessage.TYPE_DANGER));
      this.setState({
        messages: messages,
        loggingIn: false,
      });
      return;
    }



    if (password === "") {
      let messages = this.state.messages;
      messages.push(new AuthMessage("You must enter a password.", AuthMessage.TYPE_DANGER));
      this.setState({
        messages: messages,
        loggingIn: false,
      });
      return;
    }

    try {

      await Auth.signIn(email, password);
      const cognitoUser = await Auth.currentAuthenticatedUser();
      logger.debug(cognitoUser);

      window.gtag('event', 'login');

      // make sure user exists in database
      let apiName = 'epiqueueapi';
      let path = '/user/ensure';
      let postData = {
        body: {
          email: cognitoUser.attributes.email,
          identifier: cognitoUser.username,
          username: cognitoUser.attributes.preferred_username,
        }
      };
      await API.post(apiName, path, postData);

      let result = await User.load(cognitoUser);
      let user = result.user;


      // set identityId
      if (user && !user.identityId) {
        let credentials = await Auth.currentCredentials();
        user.identityId = credentials.identityId;
        await user.save();
        logger.debug("saved: " + JSON.stringify(user));
      }

      logger.debug(result.user);
      this.props.setLoggedInUser(user);
      this.props.history.replace("/");
    } catch(err) {
      if (err.code === RegisterScreen.ERR_CODE_UserNotConfirmedException) {
        logger.error("UserNotConfirmedException");
        logger.error(err);
        let messages = this.state.messages;
        messages.push(new AuthMessage("You have not yet confirmed your email. Please click on the link sent in the email.", AuthMessage.TYPE_DANGER, RegisterScreen.ERR_CODE_UserNotConfirmedException));
        this.setState({
          messages: messages,
          loggingIn: false,
        });

        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === 'PasswordResetRequiredException') {
        logger.error("PasswordResetRequiredException");
        logger.error(err);
        let messages = this.state.messages;
        messages.push(new AuthMessage("You are required to reset your password.", AuthMessage.TYPE_DANGER));
        this.setState({
          messages: messages,
          loggingIn: false,
        });
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else {
        logger.error(err);
        let messages = this.state.messages;
        messages.push(new AuthMessage(err.message, AuthMessage.TYPE_DANGER));
        this.setState({
          messages: messages,
          loggingIn: false,
        });
      }
    }
  }


  giveUp = () => {
    localStorage.removeItem('epiqueue.confirmation');
    this.props.history.replace('/');
  }

  resendVerification = async () => {
    let email = localStorage.getItem('epiqueue.confirmation');
    if (email === null) {
      return;
    }

    this.setState({
      resentVerificationCode: false,
      verificationError: null,
    });


    try {
      await Auth.resendSignUp(email);
      this.setState({
        resentVerificationCode: true,
      });
    } catch(err) {
      this.setState({
        verificationError: err.message,
      });
    }

  }


  renderMessages() {

    if (!this.state.messages || this.state.messages.length === 0) {
      return null;
    }

    return this.state.messages.map( (item, idx) =>  {
      return (<div key={item.getKey()} className={"alert " + item.getType() + " fade show"}>
          <div>
            {item.getMessage()}&nbsp;&nbsp;
            {
              (item.getErrorCode() === RegisterScreen.ERR_CODE_UserNotConfirmedException) ?
                <button className="btn btn-link alert-link pl-0" onClick={() => this.resendVerification()}>Click here to re-send the verification email</button>
                : null
            }
            {this.state.resentVerificationCode ? <div className="form">Sent</div> : null}

          </div>
        </div>
      );
    });
  }

  renderVerify() {
    return(
      <>
      <BusyModal id={"loggingin-busy-modal"} show={this.state.loggingIn} setRef={el => this.modalElement = el} message={"Attempting to log you in!"}/>
      <div className="flex-row justify-content-center align-items-center " style={{marginTop: 40}}>
        {this.renderMessages()}
        <div className='col-8 offset-2 mt-5'>
          <h3><span className="epiqueue-darkblue-text">Thanks for registering! We are glad you are here</span></h3>
          <h5 className="mt-4">We have sent an email to the address you used to sign up.<br/>Please enter the verification code below.</h5>

          <h6>{localStorage.getItem('epiqueue.confirmation')}</h6>
          <div className="form-group row">
            <div className="col-md-6">
              <input id="verification-code"
                     type="verification-code"
                     className="form-control"
                     name="verification-code"
                     value={this.state.verificationCodeFieldValue}
                     onChange={this.verificationCodeChange}
                     placeholder={"Verification Code"}
                     autoFocus/>
            </div>
            {this.state.verificationError ?
              <div className="registerscreen-feedback ml-4">{this.state.verificationError}</div>
              : null }
          </div>


          <div className="d-flex mt-2 mb-5">
            <div className="btn btn-primary" onClick={this.checkVerification}>Submit Verification Code</div>
          </div>

          <button className="btn btn-link alert-link p-0 epiqueue-link" onClick={() => this.resendVerification()}>Click here to re-send the verification email</button>

          {this.state.resentVerificationCode ?
            <div className="registerscreen-feedback">verification link resent</div>
            : null }

            <div className="justify-content-center mt-3">
              <div className="btn btn-link alert-link p-0 epiqueue-link" onClick={() => this.giveUp()}>Having trouble and want to start over?  No worries, click here</div>
            </div>

        </div>
      </div>
        </>
    );
  }

  renderSubmitButton() {
    if (this.state.registering) {
      return(
        <button className="btn btn-primary disabled" onClick={this.submitRegister} disabled>Register</button>
      );
    }

    if (!this.state.agreeToTermsFieldValue) {
      return(
        <button className="btn btn-primary disabled" onClick={this.submitRegister} disabled>Register</button>
      );
    }

    return(
      <button className="btn btn-primary" onClick={this.submitRegister}>Register</button>
    );

  }

  render() {
    if (this.props.loggedInUser) {
      return (
        <Redirect to="/"/>
      );
    }

    if (this.state.waitingToVerify) {
      return this.renderVerify();
    }


    return (
      <>
        <BusyModal id={"register-busy-modal"} show={this.state.registering} setRef={el => this.modalElement = el} message={"Registering your new account!"}/>

        {this.state.errorMessage ?
        <div className="alert alert-danger">{this.state.errorMessage}</div>
          : null}

        <div className="row justify-content-center" style={{marginTop: 40}}>
          <div className="col-md-8">
            <div className="card">
              <div className="authscreens-card-header">Register</div>

              <div className="card-body">
                <form method="POST" action="/login">

                  <div className="form-group row">
                    <label htmlFor="username" className="col-md-4 col-form-label text-md-right">User Name</label>

                    <div className="col-md-6">
                      <input id="username"
                             type="username"
                             className="form-control"
                             name="username"
                             value={this.state.userNameFieldValue}
                             onChange={this.handleUserNameChange}
                             required
                             autoFocus/>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="email" className="col-md-4 col-form-label text-md-right">Email</label>

                    <div className="col-md-6">
                      <input id="email"
                             type="email"
                             className="form-control"
                             name="email"
                             value={this.state.emailFieldValue}
                             onChange={this.handleEmailChange}
                             required
                             autoFocus/>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="password" className="col-md-4 col-form-label text-md-right">Password</label>

                    <div className="col-md-6">
                      <input id="password"
                             type="password"
                             className="form-control"
                             name="password"
                             onChange={this.handlePasswordChange}
                             autoComplete="new-password"
                             required/>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label htmlFor="password2" className="col-md-4 col-form-label text-md-right">Password Again</label>

                    <div className="col-md-6">
                      <input id="password2"
                             type="password"
                             className="form-control"
                             name="password2"
                             onChange={this.handlePassword2Change}
                             required/>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-md-6 offset-md-4">
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="agreeToTermsCheckbox" onChange={this.handleAgreeToTermsChange} checked={ this.state.agreeToTermsFieldValue }/>
                        <label className="form-check-label" htmlFor="agreeToTermsCheckbox">I have read and agree to the <Link to={"/terms"} target="_blank">Terms of Service</Link> and <Link to={"/privacy"} target="_blank">Privacy Policy</Link>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-0">
                    <div className="col-md-8 offset-md-4">
                      {this.renderSubmitButton()}
                      <p></p>

                      <button className="btn btn-link" type="button" onClick={() => this.props.history.push('/login')}>
                        Already Have an Account? Log in here.
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>


      </>
    );
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  logger.debug("Nav.mapStateToProps");
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
  {setLoggedInUser}
)(RegisterScreen));
