import React, { Component } from 'react';
import PropTypes from 'prop-types';
import User from "../models/User";
import SellerCard from "./SellerCard";
import {withRouter, Link} from "react-router-dom";
import "../css/ScrollingSellerList.css"

class ScrollingSellerList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sellers: this.props.sellers,
    }
  }

  // componentDidMount() {
  // }


  renderSellers() {
    if (!this.state.sellers || this.state.sellers.length === 0) {
      return (
        <div className="scrollingsellerlist-no-queues">
          <div className="" style={{fontSize:"medium"}}>{this.props.noResultsText}</div>
        </div>
      );
    }

    return this.state.sellers.map(item => {
      return (
        <div key={item.identifier} className="scrollingsellerlist-item">
          <SellerCard  seller={item} size={this.props.size}/>
        </div>
      )
    });
  }

  render() {
    return (
      <div className="d-flex flex-column scrollingsellerlist">
        <div className="d-flex flex-wrap justify-content-start justify-content-md-start justify-content-lg-start">
          <div className="d-flex scrollingsellerlist-title">

            {this.props.title}

            { this.props.seemore ? <div className="ml-5"><Link to={this.props.seemore['url']}>{this.props.seemore['label']}</Link></div> : null }

            {false && this.props.messageButton ?
              <div className="btn btn-sm btn-outline-primary scrollingsellerlist-messageButton epiqueue-darkblue-text" to={""}><i className="fas fa-envelope"></i></div>
              : null}
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center justify-content-md-start justify-content-lg-start mt-1">
          {this.renderSellers()}
        </div>
      </div>
    );
  };
}


ScrollingSellerList.defaultProps = {
  noResultsText: "No Members Yet",
  messageButton: false,
};

ScrollingSellerList.propTypes = {
  title: PropTypes.string,
  sellers: PropTypes.arrayOf(PropTypes.instanceOf(User)).isRequired,
  seemore: PropTypes.object,
  size: PropTypes.string,
  noResultsText: PropTypes.string,
  messageButton: PropTypes.bool,
};

export default withRouter(ScrollingSellerList);

