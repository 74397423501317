import { SET_MESSAGE_BOX } from "../actionTypes";
import {Logger} from "aws-amplify";
import MessagesScreen from "../../screens/MessagesScreen";
const logger = new Logger('redux.messages');

const initialState = {
  messageBox: MessagesScreen.NAV_INBOX,
};

export default function(state = initialState, action) {
  switch (action.type) {

    case SET_MESSAGE_BOX: {
      let { messageBox } = action.payload;
      logger.debug("SET_MESSAGE_BOX");
      return {...state, messageBox: messageBox}
    }
    default:
      return state;
  }



}
