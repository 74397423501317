import { SET_LOGGED_IN_USER, ADD_USER_TO_CACHE } from "../actionTypes";
import {Logger} from "aws-amplify";
const logger = new Logger('redux.users');

const initialState = {
  loggedInUser: null,
  cachedUsers: [],
  userLoading: true,
};

export default function(state = initialState, action) {
  switch (action.type) {

    case SET_LOGGED_IN_USER: {
      let { user } = action.payload;
      logger.debug("SET_LOGGED_IN_USER");
      return {...state, loggedInUser: user, userLoading: false}
    }
    case ADD_USER_TO_CACHE: {
      const { user } = action.payload;
      if (!user) {
        logger.debug("ADD_USER_TO_CACHE passed null user ????????");
        return state;
      }

      logger.debug("ADD_USER_TO_CACHE");

      let curCache = state.cachedUsers;
      curCache[user.id] = user;
      return {...state, cachedUsers: curCache}
    }
    default:
      return state;
  }



}
