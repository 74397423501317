import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';

import "../css/BlankBox.css"

class BlankBox extends Component {

  render() {
    return (
      <div className="blankbox">
        <div className="blankbox-title">{this.props.title}</div>
        <div className={"blankbox-content " + this.props.backgroundColor}>
          {this.props.contentCallback()}
        </div>
        {this.props.footer ?
          <div className="blankbox-footer">{this.props.footer}</div>
          : <div className="blankbox-footer p-3"></div>
        }
      </div>
    )
  };
}


BlankBox.defaultProps = {
  backgroundColor: 'blankbox-content-background-color'
};


BlankBox.propTypes = {
  title: PropTypes.string,
  footer: PropTypes.string,
  contentCallback: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
};

export default withRouter(BlankBox);

