import React, {} from 'react';
import RootScreen from "./RootScreen";
import Loading from "../components/Loading";
import Message from "../models/Message";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {API} from "aws-amplify";
import ErrorMessage from "../components/ErrorMessage";
import BackLink from "../components/BackLink";
import Util from "../Util";

import '../css/MessageScreen.css'
import SendMessageModal from "../components/modals/SendMessageModal";

class MessageScreen extends RootScreen {

  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'MessageScreen'
    });


    const { match } = this.props;


    this.state = {
      loading: true,
      errorMessage: null,
      message: null,
      identifier: match.params.identifier
    }

    window.scrollTo(0, 0);
  }

  componentDidMount = async() => {
    window.scrollTo(0, 0);

    let {identifier} = this.state;

    if (!identifier) {
      this.setState({
        errorMessage: "No message available",
        loading:false,
        message: null,
      });
      return;
    }

    let apiName = 'epiqueueapi';
    let path = '/messaging/message/' + this.state.identifier;
    API.get(apiName, path).then((response) => {

      if (response.success) {

        let message = new Message(response.message);

        this.setState({
          errorMessage: null,
          loading:false,
          message:message,
        })
      } else {
        this.setState({
          errorMessage: response.errorMessage,
          loading:false,
          message: null,
        })
      }
    }).catch(error => {
      this.setState({
        loading:false,
        errorMessage: error.message,
        message: null,
      })
    });

  };


  loadMessage(id) {
  }


  renderDirectMessage() {
    let {message} = this.state;

    return(
      <div className=" messagescreen-message-area ">
        <div className="alert alert-warning">
          This is a direct message from another Epiqueue user.
        </div>

        <div className="epiqueue-darkblue-border-top epiqueue-darkblue-border-left epiqueue-darkblue-border-right bg-white epiqueue-font-bold">
          <div className="d-flex flex-row justify-content-between flex-wrap p-2">

            <div>
              {message.subject}
            </div>
            <div>
              {Util.formatDate(message.created_at)}
            </div>
          </div>
        </div>
        <div className="epiqueue-darkblue-border">
          <div className="p-3">
            From: {message.fromUser.username}<br/>
            Date:  {Util.formatDate(message.created_at)}<br/>
            Subject:  {message.subject}<br/>
            <p/>
            {this.formatText()}
            <p/>
            {message.fromUser.id !== this.props.loggedInUser.id ?
              <>
            <button className="btn btn-primary"
                 data-toggle = "modal"
                 data-target = {"#send_message_modal"}
            >Reply to {message.fromUser.username}
            </button>
            <SendMessageModal toUser={message.fromUser} respondingTo={message} setRef={"send_message_modal"} id={"send_message_modal"}/>
            </>
              : null }
          </div>

        </div>
      </div>
    );
  }

  renderMessage() {

    if (!this.props.loggedInUser) {
      return (
        <h3>You must be logged in to view messages</h3>
      );
    }

    let {message} = this.state;

    if (message === null || message=== undefined) {
      return (
        null
      );
    }

    if (message.isDirectMessage()) {
      return this.renderDirectMessage();
    }


    return(
      <div className=" messagescreen-message-area ">
        <div className="epiqueue-darkblue-border-top epiqueue-darkblue-border-left epiqueue-darkblue-border-right bg-white epiqueue-font-bold">
          <div className="d-flex flex-row justify-content-between flex-wrap p-2">
            <div>
              {message.subject}
            </div>
            <div>
              {Util.formatDate(message.created_at)}
            </div>
          </div>
        </div>
          <div className="epiqueue-darkblue-border">
            <div className="" dangerouslySetInnerHTML={this.createMarkup()} />
        </div>
      </div>
    )
  }

  createMarkup() {
    let {message} = this.state;
    return {__html: message.message};
  }

  formatText() {
    return this.state.message.message;
  }


  renderContent() {

    if (this.state.loading) {
      return <Loading/>
    }


    return (
      <div className="epiqueue-darkblue-text">
        <BackLink link="/messages" text={"Back to Messages"}/>

        <ErrorMessage errorMessage={this.state.errorMessage}/>
          {this.renderMessage()}
      </div>
    );
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser,
  }
};

export default withRouter(connect(
  mapStateToProps,
)(MessageScreen));
