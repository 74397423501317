import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {CardElement, injectStripe} from "react-stripe-elements";
import {API, Logger} from "aws-amplify";
import User from "../models/User";
import ErrorMessage from "./ErrorMessage";
import Sale from "../models/Sale";

import "../css/StripeCreditCardForm.css"

const logger = new Logger('StripeCreditCardForm');

class StripeCreditCardForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      address_name: "",
      address_line1: "",
      address_line2: '',
      address_city: '',
      address_state: 'State...',
      address_zip: '',
      address_country: 'US',

      shipping_name: "",
      shipping_line1: "",
      shipping_line2: '',
      shipping_city: '',
      shipping_state: 'State...',
      shipping_zip: '',

      currency: 'usd',

      useShippingAddress: true,

      errorMessage: null,
      successMessage: null,

      invalidItems: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
  }


  updateTextField(field, event) {
    let alsoUpdateField =  null;

    logger.debug("field: " + field)

    if (this.state.useShippingAddress) {
      switch(field) {
        case 'shipping_name':
          alsoUpdateField = 'address_name';
          break;
        case 'shipping_line1':
          alsoUpdateField = 'address_line1';
          break;
        case 'shipping_line2':
          alsoUpdateField = 'address_line2';
          break;
        case 'shipping_city':
          alsoUpdateField = 'address_city';
          break;
        case 'shipping_state':
          alsoUpdateField = 'address_state';
          break;
        case 'shipping_zip':
          alsoUpdateField = 'address_zip';
          break;
        default:
          break;
      }
    }

    logger.debug("alsoUpdateField: " + alsoUpdateField)

    if (alsoUpdateField) {
      this.setState({
        [field]: event.target.value,
        [alsoUpdateField]: event.target.value,
        invalidItems: [],
        errorMessage: null,

      });
    } else {
      this.setState({
        [field]: event.target.value,
        invalidItems: [],
        errorMessage: null,
      });
    }

  }

  updateUseShippingAddressCheckBox(event) {
    if (event.target.checked) {
      this.setState({
        useShippingAddress: true,
        address_name: this.state.shipping_name,
        address_line1: this.state.shipping_line1,
        address_line2: this.state.shipping_line2,
        address_city: this.state.shipping_city,
        address_state: this.state.shipping_state,
        address_zip: this.state.shipping_zip,

      });
    } else {
      this.setState({
        useShippingAddress: false
      });
    }
  }


  invalidState(item) {
    if (item in this.state.invalidItems) {
      return 'is-invalid'
    }
  }

  validate() {
    let invalidItems = {};


    // check required fields
    if (this.state.shipping_name.trim() === '') {
      invalidItems['shipping_name'] = 'Name is a required field';
    }
    if (this.state.shipping_line1.trim() === '') {
      invalidItems['shipping_line1'] = 'Address is a required field';
    }
    if (this.state.shipping_city.trim() === '') {
      invalidItems['shipping_city'] = 'City is a required field';
    }
    if (this.state.shipping_state.trim() === '' || this.state.shipping_state.trim() === 'State...') {
      invalidItems['shipping_state'] = 'Required';
    }
    if (this.state.shipping_zip.trim() === '') {
      invalidItems['shipping_zip'] = 'Zip is a required field';
    }

    if (!this.state.useShippingAddress) {
      if (this.state.address_name.trim() === '') {
        invalidItems['address_name'] = 'Name is a required field';
      }
      if (this.state.address_line1.trim() === '') {
        invalidItems['address_line1'] = 'Address is a required field';
      }
      if (this.state.address_city.trim() === '') {
        invalidItems['address_city'] = 'City is a required field';
      }
      logger.debug("checking address_state")
      if (this.state.address_state.trim() === '' || this.state.address_state.trim() === 'State...') {
        logger.debug("yep")
        invalidItems['address_state'] = 'Required';
      } else {
        logger.debug("nope")

      }
      if (this.state.address_zip.trim() === '') {
        invalidItems['address_zip'] = 'Zip is a required field';
      }
    }

    if (Object.keys(invalidItems).length > 0) {
      this.setState({
        invalidItems: invalidItems,
        errorMessage: "Please correct the errors above",
      });
      return false;
    }

    return true;
  }

  async onSubmit(e) {
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    try {
      let {token} = await this.props.stripe.createToken(
        {
          name:            this.state.address_name,
          address_line1:   this.state.address_line1,
          address_line2:   this.state.address_line2,
          address_city:    this.state.address_city,
          address_state:   this.state.address_state,
          address_zip:     this.state.address_zip,
          address_country: this.state.address_country,
        });
      logger.debug(JSON.stringify(token));
      if (!token) {
        this.setState({
          errorMessage: "There was an error with the credit card information entered",
        });
        return;
      }

      if (token.error) {
        this.setState({
          errorMessage: token.error,
        });
        return;
      }

      let sale_identifier = this.props.sale.identifier;

      window.gtag('event', 'add_payment_info');

      let apiName = 'epiqueueapi';
      let path = '/payment/charge';

      let postData = {
        body: {
          stripe_token: token.id,
          sale_identifier: sale_identifier,
          amount: this.props.totalCharge,
          shipping_name: this.state.shipping_name,
          shipping_line1: this.state.shipping_line1,
          shipping_line2: this.state.shipping_line2,
          shipping_city: this.state.shipping_city,
          shipping_state: this.state.shipping_state,
          shipping_zip: this.state.shipping_zip,
        }
      };

      logger.debug("apiName: " + apiName);
      logger.debug("path: " + path);
      logger.debug("postData: " + JSON.stringify(postData));

      let response = await API.post(apiName, path, postData);
      this.props.onSubmit ({
        success: true,
        errorMessage: null,
        response: response
      });
      this.setState({
        errorMessage: JSON.stringify(response),
      });

      logger.debug("response: " + JSON.stringify(response));
    } catch(error) {
      logger.debug(error);
      logger.debug(error.message);
      this.setState({
        errorMessage: error.message,
      });
    };
  }

  render() {
    let {sale, totalCharge, loggedInUser} = this.props;

    if (!totalCharge) {
      return null;
    }

    if (!loggedInUser) {
      return null;
    }

    if (!sale) {
      return null;
    }


    return (
      <div className="container">
      <div className="row">
        <div className="card stripecreditcardform col-lg-8 offset-lg-2 offset-md-1 col-md-10 col-sm-12">
          <div className="card-body">
            <h5 className="card-title text-center">Checkout!</h5>
            <div className="d-flex flex-column">
              <div className="mb-4">
              Please fill out the information below to complete your purchase?
              </div>


              <div className="border-label"><h6>Shipping Address</h6></div>
              <div className="form-group border p-2" style={{backgroundColor: '#eeeeee'}}>
                <div className="form w-100">
                  <input className={"form-control " + this.invalidState('shipping_name')}
                         type="text"
                         id="shipping_name"
                         placeholder="Name"
                         onChange={(event) => this.updateTextField('shipping_name', event)}
                         value={this.state.shipping_name}
                  />
                  <div className="invalid-feedback">{this.state.invalidItems.shipping_name}</div>
                </div>
                <div className="mt-2">
                  <input className={"form-control " + this.invalidState('shipping_line1')}
                         type="text"
                         id="shipping_address1"
                         placeholder="Address1"
                         onChange={(event) => this.updateTextField('shipping_line1', event)}
                         value={this.state.shipping_line1}
                  />
                  <div className="invalid-feedback">{this.state.invalidItems.shipping_line1}</div>
                </div>
                <div className="mt-2">
                  <input className={"form-control " + this.invalidState('shipping_line2')}
                         type="text"
                         id="shipping_line2"
                         placeholder="Address2"
                         onChange={(event) => this.updateTextField('shipping_line2', event)}
                         value={this.state.shipping_line2}
                  />
                  <div className="invalid-feedback">{this.state.invalidItems.shipping_line2}</div>
                </div>
                <div className="form-inline">
                  <div className="mt-2 mr-lg-3 mr-md-2 mr-sm-0">
                    <input className={"form-control " + this.invalidState('shipping_city')}
                           type="text"
                           id="shipping_city"
                           placeholder="City"
                           onChange={(event) => this.updateTextField('shipping_city', event)}
                           value={this.state.shipping_city}
                    />
                    <div className="invalid-feedback">{this.state.invalidItems.shipping_city}</div>
                  </div>
                  <div className="mt-2 mr-lg-3 mr-md-2 mr-sm-0">
                    <select className={"form-control " + this.invalidState('shipping_state')}
                            id="shipping_state"
                            onChange={(event) => this.updateTextField('shipping_state', event)}
                            value={this.state.shipping_state}
                    >
                      <option>State...</option>
                      <option>AL</option>
                      <option>WA</option>
                    </select>
                      <div className="invalid-feedback">{this.state.invalidItems.shipping_state}</div>
                  </div>
                  <div className="mt-2">
                    <input className={"form-control " + this.invalidState('shipping_zip')}
                           type="text"
                           id="shipping_zip"
                           placeholder="Zip"
                           onChange={(event) => this.updateTextField('shipping_zip', event)}
                           value={this.state.shipping_zip}
                    />
                    <div className="invalid-feedback">{this.state.invalidItems.shipping_zip}</div>
                  </div>
                </div>
              </div>

              <div className="border-label"><h6>Credit Card Address</h6></div>
              <div className="form-group border border p-2 " style={{backgroundColor: '#eeeeee'}}>

                <div className="form-group form-check">
                  <input type="checkbox" className="form-check-input" id="useShippingAddress"
                         onChange={(event) => this.updateUseShippingAddressCheckBox(event)}
                         defaultChecked={true}
                         value={this.state.useShippingAddress}
                  />
                    <label className="form-check-label" htmlFor="useShippingAddress">Use Shipping Address</label>
                </div>
                <div className="form w-100">
                  <input className={"form-control " + this.invalidState('address_name')}
                         type="text"
                         id="address_name"
                         placeholder="Name"
                         onChange={(event) => this.updateTextField('address_name', event)}
                         value={this.state.address_name}
                         disabled={this.state.useShippingAddress}
                  />
                  <div className="invalid-feedback">{this.state.invalidItems.address_name}</div>
                </div>
                <div className="mt-2">
                  <input className={"form-control " + this.invalidState('address_line1')}
                         type="text"
                         id="address_line1"
                         placeholder="Address1"
                         onChange={(event) => this.updateTextField('address_line1', event)}
                         value={this.state.address_line1}
                         disabled={this.state.useShippingAddress}
                  />
                  <div className="invalid-feedback">{this.state.invalidItems.address_line1}</div>
                </div>
                <div className="mt-2">
                  <input className={"form-control " + this.invalidState('address_line2')}
                         type="text"
                         id="address_line2"
                         placeholder="Address2"
                         onChange={(event) => this.updateTextField('address_line2', event)}
                         value={this.state.address_line2}
                         disabled={this.state.useShippingAddress}
                  />
                  <div className="invalid-feedback">{this.state.invalidItems.address_line2}</div>
                </div>
                <div className="form-inline">
                  <div className="mt-2 mr-lg-3 mr-md-2 mr-sm-0">
                    <input className={"form-control " + this.invalidState('address_city')}
                           type="text"
                           id="address_city"
                           placeholder="City"
                           onChange={(event) => this.updateTextField('address_city', event)}
                           value={this.state.address_city}
                           disabled={this.state.useShippingAddress}
                    />
                    <div className="invalid-feedback">{this.state.invalidItems.address_city}</div>
                  </div>
                  <div className="mt-2 mr-lg-3 mr-md-2 mr-sm-0">
                    <select className={"form-control " + this.invalidState('address_state')}
                            id="address_state"
                            onChange={(event) => this.updateTextField('address_state', event)}
                            value={this.state.address_state}
                            disabled={this.state.useShippingAddress}
                    >
                      <option>State...</option>
                      <option>AL</option>
                      <option>WA</option>
                    </select>
                    <div className="invalid-feedback">{this.state.invalidItems.address_state}</div>
                  </div>
                  <div className="mt-2">
                    <input className={"form-control " + this.invalidState('address_zip')}
                           type="text"
                           id="shipping_zip"
                           placeholder="Zip"
                           onChange={(event) => this.updateTextField('address_zip', event)}
                           value={this.state.address_zip}
                           disabled={this.state.useShippingAddress}
                    />
                    <div className="invalid-feedback">{this.state.invalidItems.address_zip}</div>
                  </div>
                </div>
              </div>

              <div className="border-label"><h6>Credit Card Information</h6></div>
              <div className="form-group border p-2" style={{backgroundColor: '#eeeeee'}}>
                <div>
                  <div className="form-control">
                  <CardElement style={{base: {fontSize: '18px'}}} />
                  </div>
                </div>
              </div>

              <ErrorMessage errorMessage={this.state.errorMessage}/>

              <div className="align-self-end mt-3 w-100">
                <button className="btn btn-primary mt-0 w-100 epiqueue-darkblue" onClick={e => this.onSubmit(e)}>Pay ${this.props.totalCharge / 100}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  };
}



StripeCreditCardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loggedInUser: PropTypes.instanceOf(User).isRequired,
  totalCharge: PropTypes.number.isRequired,
  sale: PropTypes.instanceOf(Sale).isRequired,
};

export default injectStripe(StripeCreditCardForm);
