import React, {  } from 'react';
class RootScreen extends React.Component {

  /**
   * override me
   *
   * @returns {*}
   */
  renderContent() {
    return (<></>);
  }

  render() {
    return this.renderContent();
  }
}

export default RootScreen;