import React, {} from 'react';
import RootScreen from "./RootScreen";
import {API, Auth, Logger} from "aws-amplify";

import "../css/NewAccountScreen.css"
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import ErrorMessage from "../components/ErrorMessage";
import Seller from "../models/Seller";
import {setLoggedInUser} from "../redux/actions";
import User from "../models/User";

const logger = new Logger('NewAccountScreen');

class NewAccountScreen extends RootScreen {


  constructor(props) {
    super(props);

    this.state = {
      checkingLogin: true,
      loggedIn: false,
      admin: false,
      user: null,
      seller: null,
      signingUp: false,
      agreeToTermsFieldValue: false,
      signUpCodeFieldValue: '',
      errorMessage: null,

      stripeErrorMessage: null,
      stripeErrorCode: null,
      stripeScope: null,
      stripeCode: null,

      connectingToAccount: false,

      step: 1,
    }

    this.handleAgreeToTermsChange = this.handleAgreeToTermsChange.bind(this);
    this.submitAgree = this.submitAgree.bind(this);

  }


  componentDidMount = async () => {

    let {loggedInUser} = this.props;


    if (!loggedInUser) {
      await this.setState({
        loggedIn: false,
        checkingLogin: false,
      });
      return;
    }


    //double check that we are logged in
    try {
      let userInfo = await Auth.currentUserInfo();
      await this.setState({
        loggedIn: userInfo !== null,
      });
    } catch(error) {
      logger.debug("error: " + error.message);
      await this.setState({
        loggedIn: false,
        checkingLogin: false,
        errorMessage: error.message
      });
      return;
    }



    logger.debug(loggedInUser);

    // load seller data

    let apiName = 'epiqueueapi';
    let path = '/user/seller';

    let seller = null;

    try {
      let response = await API.get(apiName, path);
      if (response.success) {

        if (response.seller) {

          seller = new Seller(response.seller);

          await this.setState({
            checkingLogin: false,
            seller: seller,
            step: (seller.stripe_user_id === null) ? 2 : 3
          });
        } else {
          await this.setState({
            checkingLogin: false,
            seller: null,
            step: 1
          });

        }
      } else {
        this.setState({
          step: 1,
          seller: null,
          checkingLogin: false,
          errorMessage: response.errorMessage
        });
      }
    } catch(error) {
      await this.setState({
        step: 1,
        checkingLogin: false,
        seller: null,
        errorMessage: "There was an error while seller data"
      })
    }


    // check to see if we are connecting
    if (seller && seller.stripe_user_id === null) {

      // check for stripe messages

      let searchParams = new URLSearchParams(this.props.location.search);

      logger.debug(this.props.location.search)
      logger.debug(searchParams)

      let stripeScope = null;
      let stripeCode = null;
      let stripeErrorCode = null;
      let stripeErrorMessage = null;
      let hasStripeInfo = false;

      let connectToAccount = false;

      if (searchParams.has('scope')) {
        stripeScope = searchParams.get('scope');
        hasStripeInfo = true;
      }

      if (searchParams.has('code')) {
        stripeCode = searchParams.get('code');
        hasStripeInfo = true;
      }

      if (searchParams.has('error')) {
        stripeErrorCode = searchParams.get('error');
        hasStripeInfo = true;
      }

      if (searchParams.has('error_description')) {
        stripeErrorMessage = searchParams.get('error_description');
        if (stripeErrorCode === "access_denied") {
          stripeErrorMessage = "Connection to Stripe was cancelled";
        }
        hasStripeInfo = true;
      }

      if (hasStripeInfo) {
        if (stripeErrorCode || stripeErrorMessage) {
          this.setState({
            stripeErrorCode: stripeErrorCode,
            stripeErrorMessage: stripeErrorMessage,
          })

        } else if (stripeScope && stripeCode) {
          this.setState({
            connectingToAccount: true,
          });
          connectToAccount = true;
        }
      }

      if (connectToAccount) {
        let apiName = 'epiqueueapi';
        let path = '/stripe/connect';
        let postData = {
          body: {
            code: stripeCode,
            scope: stripeScope
          }
        };

        try {
          let result = await API.post(apiName, path, postData);

          if (result.success) {

            this.setState({
              connectingToAccount: false,
              stripeErrorMessage: null,
              step: this.state.step + 1,
            });

            let clonedUser = new User(loggedInUser)
            clonedUser.is_seller = 1;
            this.props.setLoggedInUser(clonedUser);

          } else {
            this.setState({
              connectingToAccount: false,
              stripeErrorMessage: result.errorMessage,
            })
          }
        } catch(error) {
          this.setState({
            connectingToAccount: false,
            stripeErrorMessage: error.message,
          })
        };
      }
    }
  };

  linkToStripe() {
  }

  handleSignupCodeChange = (event) => {
    let txt = event.target.value;
    this.setState({signUpCodeFieldValue: txt});
  };





  submitAgree = async () => {
    if (!this.state.agreeToTermsFieldValue) {
      return;
    }

    await this.setState({
      signingUp: true,
      errorMessage: null,
    });

    let signUpCode = this.state.signUpCodeFieldValue.trim();

    let apiName = 'epiqueueapi';
    let path = '/user/become_seller';
    let postData = {
      body: {
        signupcode: signUpCode,
      }
    };

    try {
      let response = await API.post(apiName, path, postData);
      if (response.success) {
        if (response.seller) {
          let seller = new Seller(response.seller);
          await this.setState({
            signingUp: false,
            seller: seller,
            step: 2,
          });
        } else {
          await this.setState({
            signingUp: false,
            seller: null,
            step: 1
          });
        }
      } else {
        await this.setState({
          signingUp: false,
          step: 1,
          seller: null,
          errorMessage: response.errorMessage
        });
      }
    } catch(error) {
      await this.setState({
        signingUp: false,
        step: 1,
        seller: null,
        errorMessage: "There was an error while creating your seller info: " + error.message
      })
    }

  };



  handleAgreeToTermsChange(event) {
    this.setState({agreeToTermsFieldValue: event.target.checked});
  }

  renderAgreeButton() {
    if (this.state.signingUp) {
      return(
        <button className="btn btn-primary btn-block disabled" onClick={null} disabled><i className="fas fa-circle-notch fa-spin"/></button>
      );
    }

    if (!this.state.agreeToTermsFieldValue) {
      return(
        <button className="btn btn-primary btn-block disabled" onClick={null} disabled>Next</button>
      );
    }

    return(
      <button className="btn btn-primary btn-block" onClick={this.submitAgree}>Next</button>
    );

  }


  renderContent() {

    if (this.state.checkingLogin) {
      return null;
    }


    if (!this.state.loggedIn) {
      return <div className="alert alert-danger">You must be logged in to use this page</div>;
    }

    // let disabledStr = "";
    // if (this.state.agreeToTermsFieldValue) {
    //   disabledStr = " disabled";
    // }

    // if (this.state.errorMessage) {
    //   return (
    //     <ErrorMessage errorMessage={this.state.errorMessage}/>
    //   );
    // }


    return (
      <div className="newaccountscreen-font">
        <ErrorMessage errorMessage={this.state.errorMessage}/>

        <div className="newaccountscreen-heading">
          You’re Almost There
        </div>

        <div className="mt-3 position-relative overflow-hidden">
          {this.state.step !== 1 ?
            <div className="newaccountscreen-cover">
              <div className="newaccountscreen-checkbox">
                <i className="fas fa-check-square fa-7x"></i>
              </div>
            </div>
            : null
          }
          <div className="newaccountscreen-step-card">
            <h4>
              Step 1: First, let’s take a look at the fine print.
            </h4>
            <h6>Cool with our <Link to={"/sellersterms"} target="_blank">seller's agreement</Link>?</h6>


            <div className="form-group row">
              <div className="offset-1">
                <small>Have a sign up code? Enter it below.</small><br/>
                <input type="text"
                       name="signupcode"
                       id={"signupcode"}
                       value={this.state.signUpCodeFieldValue}
                       onChange={this.handleSignupCodeChange}
                       placeholder={'Sign Up Code'}/>
              </div>
            </div>

            <div className="form-group row">
              <div className="offset-1">
                <div className="form-check">
                  <input type="checkbox" className={"form-check-input"} id="agreeToTermsCheckbox" onChange={this.handleAgreeToTermsChange} checked={ this.state.agreeToTermsFieldValue } />
                  <label className="form-check-label" htmlFor="agreeToTermsCheckbox">I have read and agree to the <Link to={"/sellersterms"} target="_blank">Seller's Terms of Service</Link>
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group row mt-4">
              <div className="offset-1 col-lg-3 col-md-6 col-sm-11">
                {this.renderAgreeButton()}
              </div>
            </div>

          </div>
        </div>

        <div className="mt-3 position-relative overflow-hidden">
          {this.state.step !== 2 ?
            <div className="newaccountscreen-cover">
              {this.state.step > 2 ?
                <div className="newaccountscreen-checkbox">
                <i className="fas fa-check-square fa-7x"></i>
              </div>
                : null }
            </div>
            : null
          }
          <div className="newaccountscreen-step-card" >
            <h4>Step 2, Then, you’ll probably want to get paid.</h4>
            <h6>Epiqueue connects with your Stripe account, but if you don’t have a stripe account—don’t worry—it only takes a minute to create one.</h6>


            <ErrorMessage errorMessage={this.state.stripeErrorMessage}/>

            {this.state.connectingToAccount ?
              <div className="d-flex justify-content-start align-items-center m-2">
                <div className="spinner-border epiqueue-darkblue-text mr-3" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <span className="text-warning">Connecting Your Account</span>
              </div>
              :
              <div className="form-group row mt-4">
                <div className="offset-1 col-lg-3 col-md-6 col-sm-11">
                  <a
                    href="https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_EHgB3hAdYVvxIPqNRQHxd7DYbPfTqb3V&scope=read_write">
                    <button className="btn btn-primary btn-block">Connect Stripe</button>
                  </a>
                </div>
              </div>
            }
          </div>
        </div>

        <div className="mt-3 position-relative overflow-hidden">
          {this.state.step !== 3 ?
            <div className="newaccountscreen-cover">
              {this.state.step > 3 ?
                <div className="newaccountscreen-checkbox">
                  <i className="fas fa-check-square fa-7x"></i>
                </div>
                : null
              }
            </div>
            : null
          }
          <div className="newaccountscreen-step-card" >
            <h4>Step 3: Now make like an artist.</h4>
            Before you pick up the paint brush or head to the shop, make sure you’ve created a customer queue for all your pieces.

            <div className="form-group row  mt-4">
              <div className="offset-1 col-lg-3 col-md-6 col-sm-11">
                <Link to={"/dashboard"}><button className="btn btn-primary btn-block">Create a Queue</button></Link>
              </div>
          </div>
        </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
  {setLoggedInUser}
)(NewAccountScreen));
