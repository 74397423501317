import React from 'react';
import {API, Logger} from "aws-amplify";
import {withRouter, Link} from "react-router-dom";
import {connect} from "react-redux";
import Loading from "../../components/Loading";
import RootScreen from "../RootScreen";
import Sale from "../../models/Sale";

import "../../css/OrderListScreen.css";
import Util from "../../Util";
import Product from "../../models/Product";
import BackLink from "../../components/BackLink";
import ShippingInfoModal from "../../components/modals/ShippingInfoModal";

const logger = new Logger('OrderListScreen');

class OrderListScreen extends RootScreen {

  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'OrderListScreen'
    });

    this.state = {
      errorMessage: null,
      orders: [],
      products: [],
      ordersLoaded: false,
      showModal: null,
      filter: null,
    }

  }


  componentDidMount() {
    if (this.props.loggedInUser) {
      this.loadOrders();
    }
  }



  loadOrders = async () => {
    let apiName = 'epiqueueapi';
    let path = '/dashboard/orders';
    let postData = {
      body: {
        filter: this.state.filter
      }
    };
    try {
      let response = await API.post(apiName, path, postData);
      if (response.success) {
        let orders = [];
        let products = [];
        for (var i in response.orders) {
          orders.push(new Sale(response.orders[i]));
        }
        for (var j in response.products) {
          products.push(new Product(response.products[j]));
        }
        this.setState({
          orders: orders,
          products: products,
          ordersLoaded: true,
        });
      } else {
        this.setState({
          orders: [],
          products: [],
          errorMessage: response.errorMessage,
          ordersLoaded: true,
        });
      }

    } catch(error) {
      this.setState({
        errorMessage: "Exception:"  + error.message,
        ordersLoaded: true,
      });
      console.log(error.message);
    }
  };

  updateFilter = async (event) => {
    let newFilter = event.target.value;

    if (newFilter === 'all') {
      newFilter = null;
    }

    if (newFilter === this.state.filter) {
      return;
    }

    await this.setState({
        filter: newFilter,
        // orders: [],
        // products: [],
    });

    this.loadOrders();
  };

  findProduct = (order) => {
    let product = this.state.products.find(item => {
      if (item.id === order.product_id) {
        return true;
      }
      return false
    });

    if (!product) {
      return null;
    }

    return product;
  }


  renderLoading() {
    return (
      <Loading/>
    );
  }


  renderAddress = (shippingAddress) => {
    return (
      <div className="">
        <div>{shippingAddress.name}</div>
        <div>{shippingAddress.address1}</div>
        <div>{shippingAddress.address2}</div>
        <div>{shippingAddress.city}, {shippingAddress.state} {shippingAddress.zip}</div>
        <div>{shippingAddress.country}</div>
      </div>
    )
  };

  renderProduct = (product_id) => {
    let product = this.state.products.find(item => {
      if (item.id === product_id) {
        return true;
      }
      return false
    });

    if (!product) {
      return null;
    }


    return (
      <div><Link className="epiqueue-darkblue-text" to={product.getUrl()}>{product.name}</Link></div>
    );


  };

  openShippingModal = (item) => {
    logger.debug("openShippingModal");
      this.setState({
        showModal: item
      });
  };


  renderOrder = () => {

    return this.state.orders.map((item, idx) => {
        return (
          <tr key={idx}>
            <td>
              {this.renderProduct(item.product_id)}
              <Link className="epiqueue-darkblue-text" to={"/dashboard/order/" + item.identifier}>{item.identifier}</Link>
              <br/>
              {Util.formatDate(item.created_at)}
            </td>
            <td>{item.quantity}</td>
            <td>{Util.formatMoney(item.amount)}</td>
            <td>{item.product_member ? <Link className="epiqueue-darkblue-text" to={item.product_member.user.getUrl()}>{item.product_member.user.username}</Link> : "No User"}</td>
            <td>{item.product_member.comment ? item.product_member.comment : null}</td>
            <td>{item.product_member.shipping_address ? this.renderAddress(item.product_member.shipping_address) : "No Shipping Address"}</td>
            <td className="text-right">
              <div>{item.status}</div>
              {item.readyForShipping() ? <div className="btn btn-primary btn-sm epiqueue-darkblue mt-2" onClick={() => this.openShippingModal(item)}>Ship Product</div>
                : null}
              {item.isShipped() ? <div className="btn btn-primary btn-sm epiqueue-darkblue mt-2" onClick={() => this.openShippingModal(item)}>Update Shipping</div>
                : null}
            </td>
          </tr>
        )
      });
  }


  renderSeller() {
    return (
      <div>
        <div className="d-flex flex-row justify-content-between">
          <div className="">
            <BackLink link="/dashboard" text="Back to Dashboard" />
          </div>
            <div className="mt-2 d-flex flex-row">
              <div className="mr-2">
                Filter:
              </div>
              <div>
                <select onChange={this.updateFilter} value={this.state.filter}>
                  <option value='all'>All</option>
                  <option value={Sale.STATUS_PAID}>{Sale.STATUS_PAID}</option>
                  <option value={Sale.STATUS_PAYMENT_FAILED}>{Sale.STATUS_PAYMENT_FAILED}</option>
                  <option value={Sale.STATUS_SHIPPED}>{Sale.STATUS_SHIPPED}</option>
                  <option value={Sale.STATUS_CANCELLED}>{Sale.STATUS_CANCELLED}</option>
                  <option value={Sale.STATUS_ABANDONED}>{Sale.STATUS_ABANDONED}</option>
                  <option value={Sale.STATUS_COMPLETED}>{Sale.STATUS_COMPLETED}</option>
                  <option value={Sale.STATUS_CREATED}>{Sale.STATUS_CREATED}</option>
                </select>
              </div>
          </div>
        </div>

        <table className="table table-striped orderlistscreen-table">
          <thead className="">
          <tr>
            <th>Order Details</th>
            <th>Qty</th>
            <th>Total</th>
            <th>Buyer</th>
            <th>Comment</th>
            <th>Shipping Address</th>
            <th className="text-right">Status</th>
          </tr>
          </thead>
          <tbody>
          {this.renderOrder()}
          </tbody>
        </table>
      </div>
    );
  }

  cancelModal = () => {
    this.setState({
      showModal: null,
    });
  }


  handleShippingModal = (order) => {
    logger.debug("handleShippingModal");
    // logger.debug(JSON.stringify(this.state.orders));

    // this.setState({
    //   showModal: null,
    // });

    let reorders = this.state.orders.map((item, idx) => {
      logger.debug(JSON.stringify(item));
      if (item.identifier === order.identifier) {
        logger.debug("return new order " + item.identifier + " / " + order.identifier);
        return order;
      } else {
        logger.debug("return old order");
        return item;
      }
    });

    logger.debug(JSON.stringify(reorders));


    this.setState({
      showModal: null,
      orders: reorders,
    });

  }


  renderNotSeller() {

      if (!this.props.loggedInUser) {
        return null;
      }

      if (this.props.loggedInUser.is_seller) {
        return null;
      }

      return(
        <>
          <div className="alert alert-danger">
            You must be a seller to view this page
          </div>

        </>
      );

  }

    renderNotLoggedIn() {
          return(
            <div className="alert alert-danger">
              You must be logged in to view this page
            </div>
          );
    }


    renderBusy() {
      return(
        <Loading/>
      );
    }

    renderContent() {

      if (!this.props.loggedInUser) {
        return this.renderNotLoggedIn();
      }

      let {ordersLoaded} = this.state;

      if (!ordersLoaded) {
        return this.renderBusy();
      }

      return (
        <>
        <div className="orderlistscreen">
          { (!this.props.loggedInUser.is_seller) ?
            this.renderNotSeller()
            : this.renderSeller()
          }
        </div>
          {this.state.showModal !== null ?
            <ShippingInfoModal order={this.state.showModal}
                               product={this.findProduct(this.state.showModal)}
                               cancelCallback={this.cancelModal}
                               okCallback={this.handleShippingModal}

            />
          : null}
          </>
    );
  }

}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
)(OrderListScreen));
