import React, {Component} from 'react';
import {Logger} from "aws-amplify";
import {Elements, StripeProvider} from 'react-stripe-elements';
import StripeCreditCardForm from "../../components/StripeCreditCardForm";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const logger = new Logger('CheckoutForm');

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stripe: null
    };

    this.onSubmit = this.onSubmit.bind(this);

  }

  componentDidMount() {
    if (window.Stripe) {
      this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_TOKEN)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_TOKEN)});
      });
    }
  }

  onSubmit(result) {

    logger.debug(JSON.stringify(result))

  }



  //   async onSubmit(result) {
  //   e.preventDefault();
  //
  //   try {
  //     //let {token} = await this.props.stripe.createToken({name: "Test Charge"});
  //
  //     let apiName = 'epiqueueapi';
  //     let path = '/payment/charge';
  //
  //     let postData = {
  //       body: {
  //         token: token.id,
  //       }
  //     };
  //
  //     console.log("apiName: " + apiName);
  //     console.log("path: " + path);
  //     console.log("postData: " + JSON.stringify(postData));
  //
  //     var response = await API.post(apiName, path, postData);
  //     console.log("response: " + JSON.stringify(response));
  //   } catch(error) {
  //     console.log(error);
  //     console.log(error.message);
  //   };
  // }

  render() {

    if (!this.props.loggedInUser) {
      return null;
    }

    // wait for stripe to load
    if (!this.state.stripe) {
      return null;
    }

    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <StripeCreditCardForm onSubmit={this.onSubmit} loggedInUser={this.props.loggedInUser}/>
        </Elements>
      </StripeProvider>

    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
)(CheckoutForm));
