
import React, { Component } from 'react';
import "../../css/HelpScreen.css"

class ManagingQueues extends Component {
  static TITLE = "Managing Queues";


  render() {
    return (
      <>
        <div className="helpscreen-title">{ManagingQueues.TITLE}</div>
      </>
    )
  }
}

export default ManagingQueues;