import React, { Component } from 'react';
import {Link, withRouter} from "react-router-dom";

import "../css/LoggedInIndexView.css"
import IndexQueueSeparator from "./IndexQueueSeparator";

class LoggedInIndexView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    }
  }


  expandInfo = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  }


  jumpTo(path) {
    this.props.history.push(path);
  }

  render() {

    return (
      <div className="d-flex flex-column logged-in-indexview-container">
        <div className="alert logged-in-indexview-top-message">
          <h4 className="alert-heading">Hi, we're new and a little different!</h4>
          On Epiqueue you join highly curated queues based on your interests. &nbsp;&nbsp;&nbsp;<a className={"epiqueue-darkblue-text epiqueue-font-bold" + (this.state.expanded ? " d-none" : '')} data-toggle="collapse" href="#about_epiqueue_text" role="button" aria-expanded="false" aria-controls="about_epiqueue_text" onClick={this.expandInfo}><i className="far fa-plus-square"></i>&nbsp;Find Out More</a>
          <div className="d-flex flex-column">
            <div className="collapse" id="about_epiqueue_text">
            <p/>Think of a queue as a mini-gallery where you queue up for the option to purchase the next creation. Join the queues that interest you and we will let you know
            when the next item is available, you will then have the option to purchase or pass based on your place in the queue.
            <p/>
            For more details on how Epiqueue works visit our <Link className="logged-in-indexview-top-message-link" to={"help/how-queues-work"}>How Queues Work</Link> page and our <Link className="logged-in-indexview-top-message-link" to={"help/faq"}>FAQ</Link>.
            <p/>
            We are just getting started, please let us know what you think through our <Link className="logged-in-indexview-top-message-link" to="/help/contact-us">contact page</Link> or by email at <a className="logged-in-indexview-top-message-link" target="_blank" rel="noopener noreferrer" href="mailto:support@epiqueue.com">support@epiqueue.com</a>.
            </div>
            <div className="text-center">
              <div className={"mt-3" + (!this.state.expanded ? " d-none" : '')}>
            <a className={"epiqueue-darkblue-text epiqueue-font-bold"} data-toggle="collapse" href="#about_epiqueue_text" role="button" aria-expanded="false" aria-controls="about_epiqueue_text" onClick={this.expandInfo}><i className="far fa-minus-square"></i>&nbsp;Show Less</a>
              </div>
              </div>
          </div>
        </div>

        <div className="logged-in-indexview-queue-block" onClick={() => this.jumpTo("/queue/35/limited-edition-prints")}>
          <div className="logged-in-indexview-section-title">
            Limited Edition Prints
          </div>
          <img className="logged-in-indexview-queue-image" src={"https://static.epiqueue.com/queues/limited-edition-prints/prints-cover.jpg"} alt={"limited edition prints"}/>
          <div className="logged-in-indexview-description">
            We scour the world looking for highly sought after limited edition prints. Join our Limited Edition Prints queue to be notified as new prints are available.
          </div>

        </div>

        <IndexQueueSeparator/>

        <div className="logged-in-indexview-queue-block" onClick={() => this.jumpTo("/queue/37/jewelry")}>
          <div className="logged-in-indexview-section-title">
            Jewelry
          </div>
          <img className="logged-in-indexview-queue-image" src={"https://static.epiqueue.com/queues/jewelry/jewelry3.jpg"} alt={"original artworks"}/>
          <div className="logged-in-indexview-description">
            Hand crafted, limited edition pieces of jewelry.
          </div>
        </div>

        <IndexQueueSeparator/>

        <div className="logged-in-indexview-queue-block" onClick={() => this.jumpTo("/queue/36/visual-art-originals")}>
          <div className="logged-in-indexview-section-title">
            Visual Artworks - Originals
          </div>
          <img className="logged-in-indexview-queue-image" src={"https://static.epiqueue.com/queues/visual-arts/visualartworks.jpg"} alt={"original artworks"}/>
          <div className="logged-in-indexview-description">
            We work hard to find original artwork that belongs on your walls. All items in this queue are one of a kind works and worthy of a collector such as yourself.
          </div>
        </div>



        <IndexQueueSeparator/>

        <div className="logged-in-indexview-section-title">
          More Queues Coming Soon!
        </div>

      </div>
    );
  }
}

export default withRouter(LoggedInIndexView);