import Model from "./models/Model";

export default class Util {

  /**
   *
   * creates the following eslint warning (suppressed)
   *
   *   Line 10:  Unexpected mix of '^' and '&'   no-mixed-operators
   *   Line 10:  Unexpected mix of '^' and '&'   no-mixed-operators
   *   Line 10:  Unexpected mix of '&' and '>>'  no-mixed-operators
   *   Line 10:  Unexpected mix of '&' and '>>'  no-mixed-operators
   *
   *
   *
   * @returns {*}
   */
  static createUUID() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      // eslint-disable-next-line
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }


  static formatDate(date, toLocal = false, includeTime = true) {

    if (date === null || date === '') {
      return null;
    }

    date = date.replace(/-/g, '/')

    if (toLocal) {
      date = date + ' UTC';
    }

    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = '' + d.getFullYear(),
      hour = '' + d.getHours(),
      minute = '' + d.getMinutes();


    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;

    let dateStr = '' + year + '-' + month + '-' + day;
    if (includeTime) {
      dateStr += ' ' +  hour + ':' + minute;
    }

    return dateStr;

    // if (includeTime) {
    //
    //   return '' + [year, month, day].join('-') + ' ' + [hour, minute].join(':');
    // } else {
    //   return '' + [year, month, day].join('-');
    // }
  }


  static formatMoney(amount) {
    return '$' +  Model.formatMoney(amount / 100);
  }


  static formatPercentage(perc) {
    return (perc * 100.0) + '%'
  }


  /**
   * add page breaks and such
   */
  static formatTextForDisplay(text) {

    text = text.replace(/\n\n/g, '<p>')
    text = text.replace(/\n/g, '<br>')

    return text;
  }

}