import React, {} from 'react';
import RootScreen from "./RootScreen";
import Loading from "../components/Loading";
import {API} from "aws-amplify";
import ErrorMessage from "../components/ErrorMessage";

import "../css/UnsubscribeScreen.css"
import {withRouter, Link} from "react-router-dom";
class UnsubscribeScreen extends RootScreen {


  constructor(props) {
    super(props);
    const { match } = this.props;

    this.state = {
      unsubscribing: true,
      unsubscribeCode: (match.params.code) ? match.params.code : null,
      errorMessage: null,
      email: null,
      username: null
    }

  }


  componentDidMount = async () => {

    if (this.state.unsubscribeCode === null) {
      this.setState({
        unsubscribing: false,
        errorMessage: "No unsubscribe code is present"
      });
      return;
    }


    let apiName = 'epiqueueapi';
    let path = '/messaging/unsubscribe/' + this.state.unsubscribeCode;

    try {
      let response = await API.get(apiName, path);
      if (response.success) {
        this.setState({
          unsubscribing: false,
          email: response.email,
          username: response.username
        });
      } else {
        this.setState({
          unsubscribing: false,
          errorMessage: "There was an error while unsubscribing you. (" + response.errorMessage + ")"
        });
      }
    } catch($error) {
      this.setState({
        unsubscribing: false,
        errorMessage: "There was an error while unsubscribing you. (" + $error.message + ")"
      })
    }


  }


  renderContent() {
    if (this.state.unsubscribing && this.state.unsubscribeCode) {
      return (
        <div className="mt-5 mb-5">
          <Loading/>
          <div className="text-center font-italic font-weight-bolder mt-2">Unsubscribing you</div>
        </div>
      );
    }

    if (this.state.unsubscribing) {
      return (
        <>
          <div className="mt-5 mb-5">
            <Loading/>
            <div className="text-center font-italic font-weight-bolder mt-2">Unsubscribing you</div>
          </div>
        </>
      );
    }


    if (this.state.errorMessage) {
      return(
        <ErrorMessage errorMessage={this.state.errorMessage}/>
      );
    }

    return (
      <>
        <div className="unsubscribescreen-title">
          Successfully unsubscribed <strong>{this.state.email}</strong> from future emails.
        </div>
        <div className="unsubscribescreen-text">
          You will no longer receive marketing emails and notifications of new sales.<p/>However you will continue to receive critical emails (such as notifications of purchases).
        </div>
        <div className="unsubscribescreen-text mb-5">
          In the future you can visit your <Link to={"/settings"}>account</Link> page to update your email preferences.
        </div>
      </>
    );
  }

}

export default withRouter(UnsubscribeScreen);
