// import { API } from 'aws-amplify';
//import aws_exports from '../aws-exports';
//Amplify.configure(aws_exports);

import Model from "./Model";
import Image from "./Image";
import Queue from "./Queue";
import ProductMember from "./ProductMember";
import Tag from "./Tag";
// import {now} from "moment";
// import {Logger} from "aws-amplify";

// const logger = new Logger('Project');

export default class Product extends Model {

  static NAME_CHAR_LIMIT = 128;

  static STATUS_DISABLED       = 'disabled';
  static STATUS_UPCOMING       = 'upcoming';
  static STATUS_LIVE           = 'live'; // open to people in the queue
  static STATUS_PENDING_CLOSE  = 'pending_close';
  static STATUS_CLOSED         = 'closed';
  static STATUS_OPEN           = 'open'; // open for immediate purchase
  static STATUS_CANCELLED      = 'cancelled';
  static STATUS_PENDING_CANCEL = 'pending_cancel';
  static STATUS_DELETED        = 'deleted';
  static VISIBLE        = 1;
  static NOT_VISIBLE    = 0;

  static TAX_FREE        = 0;
  static TAXABLE         = 1;

  constructor(jsonObject) {
    super();

    let {id, queue_id, name, description, shipping_description, return_policy, start_date,
      end_date, status, visible, taxable, price, shipping_charge, quantity,
      starting_inventory, deleted_at, created_at, updated_at,
      images, primary_image, queue, product_members, tags} = jsonObject;
    this.id = id;
    this.queue_id = queue_id;
    this.name = name;
    this.description = description;
    this.shipping_description = shipping_description;
    this.return_policy = return_policy;
    this.start_date = start_date;
    this.end_date = end_date;
    this.status = status;
    this.visible = visible;
    this.taxable = taxable;
    this.price = price;
    this.shipping_charge = shipping_charge;
    this.quantity = quantity;
    this.starting_inventory = starting_inventory;
    this.deleted_at = deleted_at;
    this.created_at = created_at;
    this.updated_at = updated_at;

    this.images = [];
    if (images) {
      this.images = images.map((item)=>{
        return new Image(item)
      });
    }
    this.primary_image = primary_image ? new Image(primary_image) : null;
    this.queue = queue ? new Queue(queue) : null;

    this.tags = [];
    if (tags) {
      this.tags = tags.map((item)=>{
        return new Tag(item)
      });
    }

    if (product_members && product_members.length > 0) {
      this.product_members = product_members.map((member) => {
        return new ProductMember(member);
      });
    } else {
      this.product_members = [];
    }
  }

  isLive() {
    if (this.status === Product.STATUS_LIVE) {
      return true;
    }
  }

  isUpcoming() {
    if (this.status === Product.STATUS_UPCOMING) {
      return true;
    }
    return false;
  }

  isPrevious() {
    switch(this.status) {
      case Product.STATUS_PENDING_CLOSE:
      case Product.STATUS_CLOSED:
      case Product.STATUS_CANCELLED:
      case Product.STATUS_PENDING_CANCEL:
      case Product.STATUS_DELETED:
      case Product.STATUS_DISABLED:
        return true;
      default:
        return false;
    }
  }

  isPendingClose() {
    if (this.status === Product.STATUS_PENDING_CLOSE) {
      return true;
    }
    return false;
  }


  isClosed() {
    switch(this.status) {
      case Product.STATUS_PENDING_CLOSE:
      case Product.STATUS_CLOSED:
      case Product.STATUS_CANCELLED:
      case Product.STATUS_PENDING_CANCEL:
      case Product.STATUS_DELETED:
      case Product.STATUS_DISABLED:
        return true;
      default:
        return false;
    }
  }

  isCancelled() {
    if (this.status === Product.STATUS_CANCELLED || this.status === Product.STATUS_PENDING_CANCEL) {
      return true;
    }
    return false;
  }

  isDisabled() {
    if (this.status === Product.STATUS_DISABLED) {
      return true;
    }
    return false;
  }

  isDeleted() {
    if (this.status === Product.STATUS_DELETED) {
      return true;
    }
    return false;
  }

  isVisible() {
    return this.visible === Product.VISIBLE;
  }

  getUrl() {
    return "/product/" + this.id + "/" + Model.urlify(this.name);
  }


  getFormattedShippingCharge() {
    return '$' +  Product.formatMoney(this.shipping_charge / 100);
  }

  getFormattedAmount() {
    return '$' +  Product.formatMoney(this.price / 100);
  }


  getDisplayPrice() {
    return this.getFormattedAmount();
  }

  getFormatedEndDate() {
    if (this.end_date === null) {
      return '';
    }

    let end_date = this.end_date.replace(/-/g, '/')

    let date = new Date(end_date + " UTC");

    return date.toLocaleString('en-US',  {'timeZoneName': 'short'});
  }
}

// +----------------------+---------------------+------+-----+-------------------+-----------------------------+
// | Field                | Type                | Null | Key | Default           | Extra                       |
// +----------------------+---------------------+------+-----+-------------------+-----------------------------+
// | id                   | bigint(20) unsigned | NO   | PRI | NULL              | auto_increment              |
// | identifier           | char(36)            | NO   | UNI | NULL              |                             |
// | queue_id             | bigint(20) unsigned | NO   | MUL | NULL              |                             |
// | name                 | varchar(255)        | NO   |     | NULL              |                             |
// | description          | text                | YES  |     | NULL              |                             |
// | shipping_description | text                | YES  |     | NULL              |                             |
// | return_policy        | text                | YES  |     | NULL              |                             |
// | start_date           | timestamp           | YES  |     | NULL              |                             |
// | end_date             | timestamp           | YES  |     | NULL              |                             |
// | status               | varchar(255)        | NO   |     | upcoming          |                             |
// | visible              | tinyint(1)          | NO   |     | 0                 |                             |
// | quantity             | int(10) unsigned    | NO   |     | 0                 |                             |
// | starting_inventory   | int(10) unsigned    | NO   |     | 0                 |                             |
// | price                | int(10) unsigned    | YES  |     | NULL              |                             |
// | shipping_charge      | int(10) unsigned    | YES  |     | NULL              |                             |
// | taxable              | tinyint(1)          | NO   |     | 1                 |                             |
// | currency             | varchar(255)        | NO   |     | usd               |                             |
// | deleted_at           | timestamp           | YES  |     | NULL              |                             |
// | created_at           | timestamp           | NO   |     | CURRENT_TIMESTAMP |                             |
// | updated_at           | timestamp           | NO   |     | CURRENT_TIMESTAMP | on update CURRENT_TIMESTAMP |
// +----------------------+---------------------+------+-----+-------------------+-----------------------------+
//   20 rows in set (0.08 sec)
