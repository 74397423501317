import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import {API, Logger, Storage} from "aws-amplify";
import Util from "../Util";
import Product from "../models/Product";
import ErrorMessage from "./ErrorMessage";
import Queue from "../models/Queue";

const logger = new Logger('ImageUploader');

class ImageUploader extends Component {

  static MAX_PROFILE_FILE_SIZE = 3;

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,

      size: '200px',
      product: this.props.product,
      queue: this.props.queue,

      dragging: false,
      itemDragging: null,
      draggingList: null,
    }
  }


  uploadImage = async (files) => {
    logger.debug("upload image");
    logger.debug(files);

    let {product, queue} = this.state;
    if (product === null && queue === null) {
      this.setState({
        errorMessage: "Not a queue or product",
      });
      return;
    }

   // let selectedFile = (files && files.length > 0) ? files[0] : null

    if (files.length === 0) {
      this.setState({
        errorMessage: "Please select a file before uploading",
      });
      return;
    }


    for (let i = 0; i < files.length; i++) {
      let selectedFile = files.item(i);

      let type = selectedFile.type;
      if (type !== 'image/jpeg' && type !== 'image/png') {
        this.setState({
          errorMessage: "the image type: \"" + type + "\" is not supported (" + selectedFile.name + ")",
        });
        return;
      }

      let fileSize = selectedFile.size;
      if (fileSize > ImageUploader.MAX_PROFILE_FILE_SIZE * 1024000) {
        fileSize /= 1024000;
        fileSize = fileSize.toFixed(2);
        this.setState({
          errorMessage: selectedFile.name + " is too large, please choose a file less than " + ImageUploader.MAX_PROFILE_FILE_SIZE + "MB. Your file was " + fileSize + " MBs",
        });
        logger.debug(selectedFile.name + " failed")
        return;
      }

      logger.debug(selectedFile.name + " passed")
    };



    for (let i = 0; i < files.length; i++) {
      let selectedFile = files.item(i);


      let type = selectedFile.type;
      let extension = '.jpg';
      switch(type) {
        case 'image/jpeg':
          extension = '.jpg';
          break;
        case 'image/png':
          extension = '.png';
          break;
        default:
          break;
      }

      try {
        await this.setState({
          //uploadingProfilePic: true,
          errorMessage: null,
        });

        let newKey = null;
        let tableName = null;
        let primaryKey = null;
        if (product != null) {
          newKey = 'product/' + product.id + '/images/' + Util.createUUID() + extension;
          tableName = 'products';
          primaryKey = product.id;
        } else if (queue != null) {
          newKey =   'queue/' + queue.id + '/images/' + Util.createUUID() + extension;
          tableName = 'queues'
          primaryKey = queue.id;
        }

        logger.debug("new key: " + newKey)
        let result = await Storage.put(newKey, selectedFile, {
          contentType: type,
          level: 'public'
        });


        let key = result.key

        let apiName = 'epiqueueapi';
        let path = '/image';
        let putData = {
          body: {
            table_name: tableName,
            primary_key: primaryKey,
            url: key,
            extension: type,
          }
        };

        var response = await API.put(apiName, path, putData);
        console.log("response: " + JSON.stringify(response));
        if (!response.success) {
          this.setState({
            errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred",
          });
        } else {
          if (product) {
            this.setState({
              product: new Product(response.product),
            });
          } else if (queue) {
            this.setState({
              queue: new Queue(response.queue),
            });
          }
        }

      } catch(error) {
        logger.error(error)
        this.setState({
          errorMessage: error.message,
        });
      }


    }



  };


  trashImage = async (identifier) => {
    let {product, queue} = this.state;
    if (product === null && queue === null) {
      this.setState({
        errorMessage: "Not a queue or product",
      });
      return;
    }

    try {

      let apiName = 'epiqueueapi';
      let path = '/image/' + identifier;
      var response = await API.del(apiName, path);
      console.log("response: " + JSON.stringify(response));
      if (!response.success) {
        this.setState({
          errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred",
        });
      } else {

        //TODO: nice remove animation
        if (response.product) {
          this.setState({
            product: new Product(response.product)
          });
        } else if (response.queue) {
          this.setState({
            queue: new Queue(response.queue)
          });
        }
      }
    } catch(error) {
      logger.error(error)
      this.setState({
        errorMessage: error.message,
      });
    }
  }


  doubleClickImage(image) {
    //todo: zoom image
    //logger.debug("doubleClickImage: " + image.identifier)
  }

  onDrag(event, image) {

    // logger.debug(event.currentTarget)
    //logger.debug(image.url)
  }

  onFileDrop(event) {
    event.preventDefault();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      logger.debug("files")
      logger.debug(event.dataTransfer.files)

      let file = event.dataTransfer.files.item(0);
      logger.debug(file)

      this.uploadImage(event.dataTransfer.files);

    } else {
      logger.debug("no files")
    }

  }

  onDrop = async(event, image) => {


    let {product, queue, itemDragging} = this.state;
    logger.debug(event.currentTarget)
    logger.debug(event.dataTransfer.files)

    let object = null;
    if (product) {
      object = product;
    } else {
      object = queue;
    }

    if (!itemDragging) {
      return;
    }

    logger.debug("onDrop: " + image.identifier);
    event.preventDefault();

    let droppedOn = image;

    logger.debug(itemDragging)


    if (itemDragging.identifier === undefined) {
      return;
    }

    if (droppedOn.identifier === itemDragging.identifier) {
      this.setState({
        dragging: false,
        itemDragging: null,
        draggingList: null,
      });
      return;
    }

    let newList = [];
    object.images.forEach(function(item) {
      if (item.identifier === droppedOn.identifier) {
        newList.push(itemDragging);
        newList.push(item);
      } else if (item.identifier === itemDragging.identifier) {
      } else {
        newList.push(item);
      }
    });

    object.images = newList;
    if (product) {
      this.setState({
        product: object,
        dragging: false,
        itemDragging: null,
        draggingList: null,
      });
    } else if (queue) {
      this.setState({
        queue: object,
        dragging: false,
        itemDragging: null,
        draggingList: null,
      });
    }

    // call api to actually update product
    let identifierList = newList.map(item => {
      return item.identifier;
    });

    // todo: see if it has changed - don't call if we don't need to

    try {

      let apiName = 'epiqueueapi';
      let path = '/image/reorder_images';
      let type = (product) ? 'products' : 'queues';
      let postData = {
        body: {
          'type': type,
          'id': object.id,
          'identifiers': identifierList
        }
      };
      var response = await API.post(apiName, path, postData);
      if (!response.success) {
        this.setState({
          errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred",
        });
      } else {
        //TODO: all good
      }
    } catch(error) {
      logger.error(error)
      this.setState({
        errorMessage: error.message,
      });
    }


  }

  onDragOver(event, image) {
    event.preventDefault()
    //logger.debug("onDragOver: " + image.identifier)
  }

  onDragEnd(event, image) {
   // event.preventDefault();
    logger.debug("onDragEnd: " + event)
    logger.debug("onDragEnd: " + image.identifier)
  }

  onDragEnter(event, image) {
    logger.debug("onDragEnter: " + image.identifier)
    event.dataTransfer.dropEffect = "move";
  }

  onDragExit(event, image) {
    logger.debug("onDragExit: " + event)
    logger.debug("onDragExit: " + image.identifier)
  }

  onDragStart(event, image) {
    let {product, queue} = this.state;
    let object = null;
    if (product) {
      object = product;
    } else {
      object = queue;
    }


    logger.debug("onDragStart: " + image.identifier)
    //event.dataTransfer.dropEffect = "move";

    let newList = object.images.map((item) => {
      return item;
    });

    this.setState({
      dragging: true,
      itemDragging: image,
      draggingList: newList,
    })


  }




  render() {

    let {product, queue} = this.state;
    let images = [];
    if (product) {
      images = product.images;
    } else if (queue) {
      images = queue.images;
    } else {
      return <ErrorMessage errorMessage={"No queue or product passed"}/>
    }

    if (images === null || images === undefined) {
      images = [];
    }


    let imagesDivs = images.map((item)=> {
      return (
        <div key={item.url} className="d-flex m-1 center-fit position-relative image-upload-cell"
             draggable={true}
             onDrag={(event) => this.onDrag(event, item)}
             onDrop={event => this.onDrop(event, item)}
             onDragOver={(event => this.onDragOver(event, item))}
             onDragEnd={(event => this.onDragEnd(event, item))}
             onDragStart={(event => this.onDragStart(event, item))}
             onDragEnter={(event => this.onDragEnter(event, item))}
             onDragExit={(event => this.onDragExit(event, item))}
             onDoubleClick={() => this.doubleClickImage(item)}
        >
          {this.props.isOwner ?
            <div className="d-flex position-absolute bg-light justify-content-center align-items-center image-upload-cell-trash">
              <div className="btn btn-link" onClick={(e) => this.trashImage(item.identifier)}><i className="fas fa-times-circle" /></div>
            </div>
            : null }

          <img className="mw-100 mh-100 image-uploader-image" draggable={false} src={"https://cdn.epiqueue.com/public/" + item.url} alt={"uploader item"}/>
        </div>
      )
    });

    return (
      <div className=''>
        <h4>Images</h4>
        <ErrorMessage errorMessage={this.state.errorMessage}/>
        <div className="small text-muted">There is a {ImageUploader.MAX_PROFILE_FILE_SIZE} MB size limit on uploaded files. Files must be a png, gif or jpg</div>
        <div className="d-flex flex-row flex-wrap justify-content-start align-items-start image-uploader">
          {imagesDivs}

            <div
              onDrop={event => this.onFileDrop(event)}
              onDragEnter={(event => event.preventDefault())}
              onDragOver={(event => this.onDragOver(event, null))}
              //draggable={true}
            >
            <label
                    htmlFor="image_upload"
                   onClick={() => {return false} }
            >
              <div key={'add_image'} className="d-flex flex-column justify-content-center align-items-center m-1 image-upload-cell-add cursor-pointer" htmlFor="profile">
                <div className="d-flex"><i className="fas fa-upload fa-3x" /></div>
                <h4 className="d-flex mt-2">Add Image</h4>
              </div>
            </label>
              <input className="profilePicInput hide" type='file' id='image_upload' name="image_upload" accept="image/gif, image/png, image/jpeg" multiple={true} onChange={(e) => this.uploadImage(e.target.files)}/>
            </div>
        </div>
        <div className="small text-muted ml-3">You can drag and drop images to reorder</div>
      </div>
    )
  };
}


ImageUploader.defaultProps = {
  images: [],
  isOwner: false,
};

ImageUploader.propTypes = {
  product: PropTypes.instanceOf(Product),
  queue: PropTypes.instanceOf(Queue),
  isOwner: PropTypes.bool.isRequired,
  uploadCallBack: PropTypes.func
};

export default withRouter(ImageUploader);

