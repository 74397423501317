import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import Tag from "../models/Tag";
import TagComponent from "./TagComponent";

class TagContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tagInput: '',
    };
  }


  deleteTag = (tag) => {
    if (this.props.deleteCallback) {
      this.props.deleteCallback(tag)
    }
  };

  addTag = (tag) => {
    if (this.props.addCallback) {
      this.props.addCallback(tag)
    }
  };

  handleTagInputChange = (event) => {
    this.setState({tagInput: event.target.value});
  };

  handleTagInputKeyCapture = (event) => {
    if(event.key === 'Enter') {

      let val = event.target.value.trim();
      if (val !== '') {
        this.addTag(new Tag({tag: event.target.value}));
      }
      this.setState({
        tagInput: '',
      });
    }
  };


  renderTags() {
    if (this.props.tags.length === 0) {
      return null
    }

    return (
      this.props.tags.map((item, idx) => {
        return <TagComponent key={idx} tag={item} editable={this.props.editable} deleteCallback={this.deleteTag}/>
      })
    );
  }


  render() {
    if (this.props.editable) {
      return (
        <div className="d-flex flex-row align-items-center flex-wrap">
          {this.renderTags()} <input className="ml-2" id='taginput' type="text" onChange={this.handleTagInputChange} onKeyUpCapture={this.handleTagInputKeyCapture} value={this.state.tagInput} placeholder="Add Tag"/>
        </div>
      );
    }

    return (
      <div className="d-flex flex-row align-items-center flex-wrap">
        {this.renderTags()}
      </div>
    );
  };
}

TagContainer.defaultProps = {
  editable: false
};

TagContainer.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.instanceOf(Tag)).isRequired,
  editable: PropTypes.bool,
  deleteCallback: PropTypes.func,
  addCallback: PropTypes.func,
};


export default withRouter(TagContainer);

