import { API, Logger } from 'aws-amplify';
// import aws_exports from '../aws-exports';
// Amplify.configure(aws_exports);

import Product from "./Product";
import Model from "./Model";
import User from "./User";
import Image from "./Image";
import Tag from "./Tag";

const logger = new Logger('QueueModel');


export default class Queue  extends Model {

  static NAME_CHAR_LIMIT = 128;

  static STATUS_AVAILABLE = 'available';
  static STATUS_ARCHIVED = 'archived';
  static STATUS_DELETED = 'deleted';

  static VISIBLE        = 1;
  static NOT_VISIBLE    = 0;

  static TEST_QUEUE     = 1;

  constructor(jsonObject) {
    super();

    let {id, identifier, user_id, name, description, status, visible, testqueue, deleted_at, created_at, updated_at, is_member, joined, product_count, live_product_count, closed_product_count, member_count, images, primary_image, owner, tags} = jsonObject;
    this.id = id;
    this.identifier = identifier;
    this.user_id = user_id;
    this.name = name;
    this.description = description;
    this.status = status;
    this.visible = visible;
    this.testqueue = testqueue;
    this.deleted_at = deleted_at;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.is_member = is_member || false;
    this.product_count = product_count || 0;
    this.live_product_count = live_product_count || 0;
    this.closed_product_count = closed_product_count || 0;
    this.member_count = member_count || 0;
    this.joined = joined || 0;

    this.images = [];
    if (images) {
      this.images = images.map((item)=>{
        return new Image(item)
      });
    }
    this.primary_image = primary_image ? new Image(primary_image) : null;

    this.owner = owner ? new User(owner) : null


    this.tags = [];
    if (tags) {
      this.tags = tags.map((item)=>{
        return new Tag(item)
      });
    }


    // calculated
    this.products = null;
    this.members = [];
  }


  loadProducts = async() => {
    logger.debug('Queue::loadProducts')
    let apiName = 'epiqueueapi';
    let path = '/product/queue/' + this.id;
    try {
      var response = await API.get(apiName, path);
      let products = [];
      for(var i in response.products) {
        products.push(new Product(response.products[i]));
      }
      this.products = products;
      this.product_count = products.length;
    } catch (error) {
      console.log("loadProducts error: " + error.message);
      this.products=[];
      this.product_count = 0;
    }
  };

  getDescription = () => {
      return (this.description) ? this.description : "Description coming soon";
  };

  getProducts = async(forceReload = false) => {
    logger.debug('Queue::getProducts')
    if (this.products === null || forceReload) {
      await this.loadProducts();
    }
    return this.products;
  };


  loadMembers = async(offset, limit) => {
    logger.debug('Queue::loadMembers')
    let apiName = 'epiqueueapi';
    let path = '/queue/' + this.id + "/members";
    // let postData = {
    //   body: {
    //     offset: offset,
    //     limit: limit,
    //   }
    // };
    try {
      var response = await API.get(apiName, path);
      for(var i in response.members) {
        this.members.push(new User(response.members[i]));
      }
      logger.debug("loaded");
      logger.debug(this.members);

    } catch (error) {
      logger.error(error);
    }
  };


  refreshMembers = async () => {
    this.members = [];
    await this.getMembers(true, 0, 20);

  }

  getMembers = async(forceReload = false, offset = 0, limit = 20) => {
    logger.debug("getMembers ENTER!!!!")

    let members = [];
    // a couple quick checks to avoid queries where they are not needed
    if (this.member_count === 0) {
      logger.debug("memabout to render membersber_count = 0")
      return members;
    }

    if (offset > this.member_count) {
      logger.debug("start > this.member_count")
      logger.debug("start = " + offset)
      logger.debug("this.member_count = " + this.member_count)
      return members;
    }


    // if previously, loaded, return that splice
    if (forceReload === false && this.members.length >= offset + (limit-1)) {
      logger.debug('get from cache');

      members =  this.members.slice(offset, offset+limit);
      logger.debug('got from caches: '+ members);
      return members;

    }

    logger.debug('go ahead and hit the db');

    try {
      await this.loadMembers(offset, limit);
      logger.debug('loaded, yeah');
      logger.debug(this.members);
    } catch(error) {
      logger.error(error);
    }

    members =  this.members.slice(offset, offset+limit);
    logger.debug('got from load: '+ members);
    return members;

  };



  join = async () => {
    let errorMessage = null;
    let success = true;

    console.log("join queue");
    let apiName = 'epiqueueapi';
    let path = '/queue/' + this.id + "/join";
    let postData = {
      body: {
        queue_id: this.id,
      }
    };

    try {
      var response = await API.post(apiName, path, postData);
      console.log("response: " + JSON.stringify(response));
      if (!response.success) {
        success = false;
        errorMessage = (response.error) ? response.error : "An error occurred"
      } else {
        this.is_member = true;
        await this.refreshMembers();
      }
    } catch(error) {
      console.log(error.message);
      success = false;
      errorMessage = error.message
    };

    console.log("returning join");
    return {success: success, error: errorMessage}
  };


  leave = async () => {
    let errorMessage = null;
    let success = true;

    let apiName = 'epiqueueapi';
    let path = '/queue/' + this.id + "/leave";
    let postData = {
      body: {
        queue_id: this.id,
      }
    };

    var response = await API.post(apiName, path, postData);
    try {
      console.log("response: " + JSON.stringify(response));
      if (!response.success) {
        success = false;
        errorMessage = (response.error) ? response.error : "An error occurred"
      } else {
        this.is_member = false;
        await this.refreshMembers();
      }
    } catch(error) {
      console.log(error.message);
      success = false;
      errorMessage = error.message
    };

    console.log("returning leave");
    return {success: success, error: errorMessage}
  }


  getOwner = async () => {
    if (this.owner !== null) {
      return this.owner;
    }

    let response = await User.loadByUserId(this.user_id);
    if (response.success) {
      this.owner = new User(response.userData);
      return this.owner;
    } else {
      return null;
    }
  }

  save = async () => {

    let queue = Object.assign({}, this);
    delete queue.created_at;
    delete queue.updated_at;

    logger.debug(JSON.stringify(queue));

    let apiName = 'epiqueueapi';
    let path = '/queue/';
    const params = {
      body: {
        queue: queue,
      }
    };

    try {
      var response = await API.post(apiName, path, params);
      logger.debug("API.post: " + JSON.stringify(response));
      return response;
    } catch (error) {
      logger.debug("API.post error: " + error.message)
      return {success: false, errorMessage: error.message};
    }
  }

  delete = async() => {
    try {
      let apiName = 'epiqueueapi';
      let path = '/queue/' + (this.id);
      let deleteData = {
        body: {
          queue_id: this.id,
        }
      };
      var response = await API.del(apiName, path, deleteData);
      console.log("response: " + JSON.stringify(response));
      if (response.success) {
        return {
          success: true,
          errorMessage: null
        };
      } else {
        return {
          success: false,
          errorMessage: response.errorMessage
        };
      }

    } catch (error) {
      return {
        success: false,
        errorMessage: error.message
      };
    }

  }

  getUrl = () => {
    return "/queue/" + this.id + "/" + Model.urlify(this.name);
  }

}

// +-------------+---------------------+------+-----+-------------------+-----------------------------+
// | Field       | Type                | Null | Key | Default           | Extra                       |
// +-------------+---------------------+------+-----+-------------------+-----------------------------+
// | id          | bigint(20) unsigned | NO   | PRI | NULL              | auto_increment              |
// | identifier  | char(36)            | NO   | UNI | NULL              |                             |
// | user_id     | bigint(20) unsigned | NO   | MUL | NULL              |                             |
// | name        | varchar(255)        | NO   |     | NULL              |                             |
// | description | text                | YES  |     | NULL              |                             |
// | status      | varchar(255)        | NO   |     | hidden            |                             |
// | visible     | tinyint(1)          | NO   |     | 0                 |                             |
// | testqueue   | tinyint(1)          | NO   |     | 0                 |                             |
// | deleted_at  | timestamp           | YES  |     | NULL              |                             |
// | created_at  | timestamp           | NO   |     | CURRENT_TIMESTAMP |                             |
// | updated_at  | timestamp           | NO   |     | CURRENT_TIMESTAMP | on update CURRENT_TIMESTAMP |
// +-------------+---------------------+------+-----+-------------------+-----------------------------+
//   11 rows in set (0.07 sec)
