import Model from "./Model";
// import {Logger} from "aws-amplify";
import User from "./User";
import ShippingAddress from "./ShippingAddress";

//const logger = new Logger('ProjectMember');

export default class ProductMember extends Model {


  static RESPONSE_DID_NOT_RESPOND = null;
  static RESPONSE_OPT_OUT = 0;
  static RESPONSE_OPT_IN = 1;

  static RESULT_NOT_YET_DECIDED = null;
  static RESULT_LOST = 0;
  static RESULT_WON = 1;
  static RESULT_DID_NOT_RESPOND = 2;
  static RESULT_OPTED_OUT = 4;
  static RESULT_PROJECT_CANCELLED = 4;

  constructor(jsonObject) {
    super();

    let {id, identifier, user_id, product_id, place, emailed_at, reminded_at, responded_at,
      response, shipping_address_id, estimated_tax, estimated_tax_rate, estimated_shipping, result,
      comment, deleted_at, created_at, updated_at, user, shipping_address} = jsonObject;
    this.id = id;
    this.identifier = identifier;
    this.user_id = user_id;
    this.product_id = product_id;
    this.place = place;
    this.emailed_at = emailed_at;
    this.reminded_at = reminded_at;
    this.responded_at = responded_at;

    this.response = response;
    this.result = result;

    this.shipping_address_id = shipping_address_id;
    this.estimated_tax = estimated_tax;
    this.estimated_tax_rate = estimated_tax_rate;
    this.estimated_shipping = estimated_shipping;

    this.comment = comment;
    this.deleted_at = deleted_at;

    this.created_at = created_at;
    this.updated_at = updated_at;

    this.user = (user) ? new User(user) : null;
    this.shipping_address = (shipping_address) ? new ShippingAddress(shipping_address) : null;
  }


  getTotalPrice(product = null) {
    if (product === null) {
      return "--";
    }

    let total = product.price + this.estimated_tax + this.estimated_shipping;

    let val = '$' +  ProductMember.formatMoney(total / 100);
    return val;
  }

  getFormattedTax() {
    if (this.estimated_tax === null) {
      return "--"
    }

    return '$' +  ProductMember.formatMoney(this.estimated_tax / 100);
  }

  getFormattedTaxRate() {
    if (this.estimated_tax_rate === null) {
      return ""
    }
    return " (" + (this.estimated_tax_rate * 100).toFixed(2) + '%)';
  }



  getFormattedShipping() {
    if (this.estimated_shipping === null) {
      return "--"
    }

    return '$' +  ProductMember.formatMoney(this.estimated_shipping / 100);
  }

}


/**
 +---------------------+-----------------------+------+-----+-------------------+-----------------------------+
 | Field               | Type                  | Null | Key | Default           | Extra                       |
 +---------------------+-----------------------+------+-----+-------------------+-----------------------------+
 | id                  | bigint(20) unsigned   | NO   | PRI | NULL              | auto_increment              |
 | identifier          | char(36)              | NO   | UNI | NULL              |                             |
 | user_id             | bigint(20) unsigned   | NO   | MUL | NULL              |                             |
 | product_id          | bigint(20) unsigned   | YES  | MUL | NULL              |                             |
 | place               | int(10) unsigned      | NO   |     | NULL              |                             |
 | emailed_at          | timestamp             | YES  |     | NULL              |                             |
 | reminded_at         | timestamp             | YES  |     | NULL              |                             |
 | responded_at        | timestamp             | YES  |     | NULL              |                             |
 | response            | tinyint(1)            | YES  |     | NULL              |                             |
 | result              | tinyint(1)            | YES  |     | NULL              |                             |
 | shipping_address_id | bigint(20) unsigned   | YES  |     | NULL              |                             |
 | estimated_tax       | int(10) unsigned      | YES  |     | NULL              |                             |
 | estimated_tax_rate  | decimal(8,2) unsigned | YES  |     | NULL              |                             |
 | estimated_shipping  | int(10) unsigned      | YES  |     | NULL              |                             |
 | comment             | text                  | YES  |     | NULL              |                             |
 | deleted_at          | timestamp             | YES  |     | NULL              |                             |
 | created_at          | timestamp             | NO   |     | CURRENT_TIMESTAMP |                             |
 | updated_at          | timestamp             | NO   |     | CURRENT_TIMESTAMP | on update CURRENT_TIMESTAMP |
 +---------------------+-----------------------+------+-----+-------------------+-----------------------------+
 18 rows in set (0.07 sec)
*/