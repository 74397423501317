import {
  ADD_USER_TO_CACHE,
  SET_LOGGED_IN_USER,
  SET_UNREAD_MESSAGES_COUNT,
  SET_PENDING_CART_ITEMS_COUNT,
  SET_MESSAGE_BOX,
} from "./actionTypes";


export function setLoggedInUser(user) {
  return {
    type: SET_LOGGED_IN_USER,
    payload: {
      user: user,
    }
  }
}

export function addUserToCache(user) {
  return {
    type: ADD_USER_TO_CACHE,
    payload: {
      user: user,
    }
  }
}


export function setUnreadMessagesCount(unreadMessagesCount) {
  return {
    type: SET_UNREAD_MESSAGES_COUNT,
    payload: {
      unreadMessagesCount: unreadMessagesCount,
    }
  }
}

export function setPendingCartItemsCount(pendingCartItemsCount) {
  return {
    type: SET_PENDING_CART_ITEMS_COUNT,
    payload: {
      pendingCartItemsCount: pendingCartItemsCount,
    }
  }
}


export function setMessageBox(messageBox) {
  return {
    type: SET_MESSAGE_BOX,
    payload: {
      messageBox: messageBox,
    }
  }
}
