import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {API, Logger} from "aws-amplify";
import StateDropdown from "./StateDropdown";

import "../css/NexusSelector.css";
import TagComponent from "./TagComponent";
import Tag from "../models/Tag";

const logger = new Logger('DropDownMultiSelector');

class NexusSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      selected: this.props.seller.nexus_states,
      options: StateDropdown.STATES_ABBREVIATIONS,
    };
  }

  updateNexus = async () => {
    try {
      let apiName = 'epiqueueapi';
      let path = '/user/seller_nexus';
      let putData = {
        body: {
          nexus_states: JSON.stringify(this.state.selected),
        }
      };
      var response = await API.post(apiName, path, putData);
      console.log("response: " + JSON.stringify(response));

      if (!response.success) {
        this.setState({
          errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred"
        });
      } else {
        // yea, nothing to do
      }

    } catch (error) {
      console.log(error.message);
      this.setState({
        errorMessage: error.message
      });
    }

  }


  itemAdded = async(e) => {
    logger.debug(e.target.value);
    let value = e.target.value;
    if (value === 'Select All') {
      await this.setState({
        selected: this.state.options,
      });
      this.updateNexus();
      return;
    }

    if (value === 'Select None') {
      await this.setState({
        selected: [],
      });
      this.updateNexus();
      return;
    }

    let selected = this.state.selected;
    if (selected == null) {
      selected = [];
    }
    if (selected.indexOf(value) === -1) {
      selected.push(value);
      await this.setState({
        selected: selected,
      });
      this.updateNexus();
    }

  };

  tagRemoved = (tag) => {
    this.itemRemoved(tag.tag)
  };

  itemRemoved = async(stateToDelete) => {
    logger.debug("itemRemoved: "  + stateToDelete)

    let selected = this.state.selected;
    if (selected === null) {
      return;
    }

    selected = selected.filter( item => {
      return item !== stateToDelete;
    });

    await this.setState({
      selected: selected,
    });
    this.updateNexus();

  };


  renderSelected = () => {
    if (this.state.selected === null) {
      return null;
    }

    return this.state.selected.map( (item, idx) => {
      return (
        <TagComponent key={idx} editable={true} deleteCallback={this.tagRemoved} tag={new Tag({tag: item})}/>
      )
    })
  };


  renderItems = () => {
    return this.state.options.map( (item) => {
      return <option key={item}>{item}</option>
    })
  };


  render() {
    return (
      <div className="d-flex flex-row">
        <div className="d-flex flex-row flex-wrap">
          {this.renderSelected()}
        </div>

        <div className="">
          <select className={"form-control"}
                  id="state"
                  onChange={(event) => this.itemAdded(event)}
                  value={'Select a State...'}
          >
            <option key={'default'}>Select a State...</option>
            <option key={'select_all'}>Select All</option>
            <option key={'select_none'}>Select None</option>
            {this.renderItems()}
          </select>
        </div>
      </div>
    )
  };
}

NexusSelector.defaultProps = {
};

NexusSelector.propTypes = {
  seller: PropTypes.object.isRequired,
};

export default NexusSelector;

