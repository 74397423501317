import React, {} from 'react';
import {API, Logger} from "aws-amplify";
import RootScreen from "./RootScreen";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

const logger = new Logger('ProductScreen');

class ProductCompleteScreen extends RootScreen {


  constructor(props) {
    super(props);
    const { match } = this.props;

    this.state = {
      identifier: match.params.identifier,
      loading: true,
      errorMessage: null,
    };



    window.scrollTo(0, 0);
  }

  componentDidMount = async() => {
    window.scrollTo(0, 0);


    if (this.state.identifier === null || this.state.identifier.trim() === '') {
      this.setState({
        errorMessage: "No identifier given",
        loading:false
      });
      return;
    }

    let {loggedInUser} = this.props;
    if (!loggedInUser) {
      this.setState({
        errorMessage: "This screen is only available for logged in users",
        loading:false
      });
      return;
    }


    try {

      let apiName = 'epiqueueapi';
      let path = '/product/member/' + this.state.identifier;
      var response = await API.get(apiName, path);
      console.log("response: " + JSON.stringify(response));
      if (!response.success) {
        throw new Error(response.errorMessage)
      }

      if (response.member.user_id !== loggedInUser.id) {
        throw new Error("Looks like that message was sent to someone else.  It may be that you are logged in as a different user, please log out and log in as the user to which the message was sent.")
      }

      this.props.history.replace('/product/' + response.member.product_id);

    } catch (error) {

      this.setState({
        errorMessage: error.message,
        loading:false
      });

    };



    logger.debug('ProductCompleteScreen::componentDidMount');

  }



  renderContent() {
    if (this.state.loading) {
      return null;
    }

    if (!this.state.errorMessage) {
      return null;
    }

   // let {loggedInUser} = this.props;

    return (
      <>
        <div className="alert alert-info">
          <div className="alert-heading text-dark mb-2"><span className="font-weight-bolder">Oh Snap!</span></div>
          <div className="">
          {this.state.errorMessage}
          </div>
        </div>



      </>
    );
}

}


const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps,
)(ProductCompleteScreen));

