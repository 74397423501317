import React from 'react';
import Queue from "../models/Queue";
import {API, Logger} from "aws-amplify";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ErrorMessage from "../components/ErrorMessage";
import ProfilePic from "../components/ProfilePic";
import OKCancelModal from "../components/modals/OKCancelModal";
import RootScreen from "./RootScreen";
import ImagePlaceholder from "../img/image-placeholder.png"

import "../css/QueueScreen.css";
import ScrollingProductList from "../components/ScrollingProductList";
import ScrollingSellerList from "../components/ScrollingSellerList";
import Loading from "../components/Loading";
import NoImage from "../img/image-placeholder.png";
import TagContainer from "../components/TagContainer";
import ReactMarkdown from "react-markdown/with-html";

const logger = new Logger('QueueScreen');

class QueueScreen extends RootScreen {


  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'QueueScreen'
    });

    const { match } = this.props;

    this.state = {
      id: match.params.id,
      queue: null,
      queueOwner: null,
      queueLoaded: false,
      errorMessage: null,
      isSelf: false,
      membersLoaded: false,
    };

    window.scrollTo(0, 0);

    this.loadQueue();

  }



  componentDidMount = async() => {
    window.scrollTo(0, 0);
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
      logger.debug("location changed")
    }


    if (prevProps.match.params.id !== this.props.match.params.id) {
      logger.debug("componentDidUpdate: id changed");

      this.setState({
        id: this.props.match.params.id,
        queueLoaded: false,
        queueOwner: null,
        errorMessage: null,
        isSelf: false,
        queue: null,
      }, () => {
        this.loadQueue();
      });
    }

    window.$('[data-toggle="tooltip"]').tooltip();

  }


  // componentDidUpdate() {
  //   window.$('[data-toggle="tooltip"]').tooltip();
  //
  // }

  joinQueue = async() => {

    logger.debug("joinQueue ENTER")

    let {queue} = this.state;


    await queue.join().then((result)=> {
      if (result.success) {
        queue.is_member = true;
        queue.member_count += 1;
        this.loadQueue().then( () => {
          this.setState({
            members:  queue.members,
            membersLoaded: false,
          });
        });

      } else {
        this.setState({
          errorMessage: result.errorMessage,
        });
      }
    }).catch(error => {
      this.setState({
        errorMessage: error.message,
      });
    });
  }


  leaveQueue = async() => {
    let {queue} = this.state;

    queue.leave().then((result)=> {
      if (result.success) {
        queue.is_member = false;
        queue.member_count -= 1;
        this.loadQueue().then( () => {
          this.setState({
            members: queue.members,
            membersLoaded: false,
          });
        });
      } else {
        this.setState({
          errorMessage: result.errorMessage,
        });
      }
    }).catch(error => {
      this.setState({
        errorMessage: error.message,
      });
    });
  }

  deleteQueue = async() => {
    logger.debug("deleteQueue");

    this.setState({
      errorMessage: null
    });

    let {queue} = this.state;

    let {loggedInUser} = this.props;
    if (!loggedInUser) {
      this.setState({
        errorMessage: "An error occurred and this queue was not able to be deleted."
      });
      return;
    }

    if (queue.user_id !== loggedInUser.id) {
      this.setState({
        errorMessage: "An error occurred and this queue was not able to be deleted."
      });
      return;
    }

    queue.delete().then( (response) => {
      logger.debug(response);
      if (response.success) {
        this.props.history.replace('/dashboard');
      } else {
        this.setState({
          errorMessage: response.errorMessage
        });
      }
    }).catch(error => {
      this.setState({
        errorMessage: error.message
      });
    });
  }


  loadQueue = async () => {
    logger.debug('QueueScreen::loadQueue');

    let {id} = this.state;

    if (id === undefined) {
      this.setState({
        queue: null,
        queueLoaded: true,
        errorMessage: "Error: No queue specified",
        isSelf: false,
      });
      return;
    }

    try {
      let apiName = 'epiqueueapi';
      let path = '/queue/' + id;
      var response = await API.get(apiName, path);
      logger.debug("response: " + JSON.stringify(response));
      if (!response.success) {
        this.setState({
          queue: null,
          errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred",
          queueLoaded: true,
          isSelf: false,
        });
      } else {
        let queue = new Queue(response.queue);
        await queue.getProducts();
        let queueOwner = await queue.getOwner();
        this.setState({
          queue: queue,
          queueLoaded: true,
          isSelf: this.props.loggedInUser && this.props.loggedInUser.id === queue.user_id,
          queueOwner: queueOwner,
        });
      }
    } catch (error) {
      logger.debug(error.message);
      logger.error(error);
      this.setState({
        errorMessage: error.message,
        queueLoaded: true,
        isSelf: false,
      });
    }
  };


  renderQueueActions = (queue) => {

    if (!this.props.loggedInUser) {
      return null;
    }

    if (this.state.isSelf) {
      return (
        <>
          <Link className="btn btn-sm btn-secondary btn-block mb-2" to={{pathname: "/queue/" + queue.id + "/createproduct"}}><i className="fas fa-plus-square"></i>&nbsp;New&nbsp;Product</Link>
          <div className="w-100 d-flex flex-row justify-content-between">
            <Link className="btn btn-sm btn-success flex-fill mr-1" to={{pathname: "/dashboard/edit/" + queue.id}}><i className="fas fa-edit"></i>&nbsp;Edit&nbsp;Queue</Link>
            <button className="btn btn-sm btn-danger flex-fill ml-1 mr-1" data-toggle = "modal" data-target = {"#delete-queue-" + queue.id}><i className="fas fa-trash-alt"></i>&nbsp;Delete&nbsp;Queue</button>
          </div>
          <OKCancelModal title={"Delete Queue?"}
                         message={
                           <div>
                             Are you sure you wish to permanently delete this queue?
                           </div>
                         }
                         id={"delete-queue-" + queue.id}
                         cancelLabel={"Cancel"}
                         okLabel={"Yes, Delete this queue"}
                         okCallback={() => this.deleteQueue()}
          />
        </>
      );
    }

    if (queue.is_member) {

      return (
        <>
          <button type = "button" className = "btn w-100 btn-outline-danger" data-toggle = "modal" data-target = {"#leave-queue-" + queue.id}>
            <i className="fas fa-sign-out-alt"></i>&nbsp;Leave&nbsp;Queue</button>
          <OKCancelModal title={"Leave Queue?"}
                         message={
                           <div>By leaving the queue, you will forfeit your place in line. You can re-join the queue at any time, but you will be placed at the end of the queue.
                             <p></p>
                             Are you sure you wish to leave?
                           </div>
                         }
                         id={"leave-queue-" + queue.id}
                         cancelLabel={"Cancel"}
                         okLabel={"Yes, Leave this queue"}
                         okCallback={() => this.leaveQueue()}
          />
        </>
      );

          } else {

      return (
          <button type = "button" className = "btn w-100 btn-success" onClick={() => this.joinQueue()}><i className="fas fa-sign-in-alt"></i>&nbsp;Join&nbsp;Queue</button>
      );

    }



  };


  renderQueueHeader() {
    let {queue, queueOwner} = this.state;

    let images = queue.images;

    let primaryImage  = null;
    if (images.length === 0) {
      primaryImage = <img className="queue-screen-viewer-image" src={NoImage} width='200' alt={"none"}/>
    } else {
      let image = images[0];
      primaryImage = <img className="queue-screen-viewer-image" src={"https://cdn.epiqueue.com/public/" + image.url} alt={"primary"}/>
    }

    return(
      <div className="queue-screen-header">

          <div className="queue-screen-header-image">
            {primaryImage}
          </div>
          <div className="queue-screen-header-info-area">

            <div className="queue-screen-header-info-queuename">
              {queue.name}
            </div>
            <div className="queue-screen-header-info-description">
              <ReactMarkdown source={queue.getDescription()}  escapeHtml={false} />
              <div className="queue-screen-header-tags">
                <TagContainer editable={false} tags={queue.tags}/>
              </div>
            </div>



            {queueOwner ?
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-row justify-content-start align-self-start mt-2">
                  <Link to={queueOwner.getProfileUrl()}><ProfilePic user={queueOwner} size={30}/><span className="ml-2 epiqueue-darkblue-text">{queueOwner.username}</span> </Link>
                </div>
              </div>

              : null}

            </div>
      </div>
    );
  }


  renderQueueBody() {
    let {queue} = this.state;
    //let owner = await queue.getOwner();

    return (
<>

    <div className="row">
      <div className="queue-screen-header-action-button col-12">
        {this.renderQueueActions(queue)}
      </div>
    </div>

  {this.renderLiveProducts()}

      <div className="row queue-screen">
        <div className="col-sm-12 col-lg-6">
          <div className="queue-screen-members-area">
            {this.renderFutureProducts()}
          </div>
        </div>

        <div className="col-sm-12 col-lg-6">
          <div className="queue-screen-members-area">
            {this.renderMembers()}
          </div>
        </div>
      </div>
  </>
    );

  }



  renderMembers ()  {

    let {queue, members, membersLoaded} = this.state;

    if (!membersLoaded) {
      queue.getMembers(false, 0, 20).then((members) => {
        logger.debug("getMembers returned: " + members)

        this.setState({
          members: members,
          membersLoaded: true,
        });
      }).catch(error => {
        logger.debug(error)
        this.setState({
          members: [],
          membersLoaded: true,
          errorMessage: error.message,
        });
      });

      return (
        <Loading/>
      );
    }

    return (
      <ScrollingSellerList sellers={members} title={"Members of this Queue"} size={'small'} messageButton={this.state.isSelf}/>
    )
  }

  gotoProduct = (product) => {
    this.props.history.push(product.getUrl());
  };

  renderLiveProducts = () => {
    let {queue} = this.state;
    let {products} = queue;

    let liveProducts = products.filter(item => {
      return item.isLive();
    });

    if (liveProducts.length === 0) {
      return null;
    }

    let liveProduct = liveProducts[0];

    return (
      <div className="pb-5">
      <div className="queue-screen-upcoming-product-sales-title d-flex flex-row justify-content-start">Currently Live Sale</div>
      <Link to={liveProduct.getUrl()} style={{ textDecoration: 'none' }}>
        <div className="row queue-screen-liveproduct">
          <div class="queue-screen-corner-ribbon">Live</div>
          <div className="queue-screen-liveproduct-image-area">
            {liveProduct.primary_image ?
              <img className="queue-screen-liveproduct-image" src={"https://cdn.epiqueue.com/public/" + liveProduct.primary_image.url}
                   alt={"Primary"}/>
              :
              <img className="queue-screen-liveproduct-image" src={ImagePlaceholder} alt={"Placeholder"}/>
            }
          </div>
          <div className="queue-screen-liveproduct-product-area">
              <div className="queue-screen-liveproduct-name">{liveProduct.name}</div>
            <ReactMarkdown source={liveProduct.description} escapeHtml={false} />
            <div><TagContainer editable={false} tags={liveProduct.tags}/></div>
              <div className="queue-screen-liveproduct-price">{liveProduct.getFormattedAmount()}</div>
            </div>
        </div>
      </Link>
      </div>
    )

  };

  renderFutureProducts = () => {
    let {queue} = this.state;
    let {products} = queue;

    let upcomingProducts = products.filter(item => {
      return item.isUpcoming();
    });

    let closedProducts = products.filter(item => {
      return item.isClosed();
    });

    return (
      <>
        <div className="mb-4 d-flex flex-column">
          <div>
            <div className="queue-screen-upcoming-product-sales-title d-flex flex-row justify-content-start">Upcoming Sales</div>
          </div>

          <ScrollingProductList title={null} products={upcomingProducts} shape={"vertical"} noResultsText={"No Upcoming Sales"}/>
        </div>
        <div className="mb-4 d-none">
          <ScrollingProductList title={"Previous Sales"} products={closedProducts} shape={"vertical"} noResultsText={"No Previous Sales"}/>
        </div>
      </>
    )

  };




  renderContent () {
    logger.debug('QueueScreen::renderContent');
    let {queue} = this.state;

    if (!this.state.queueLoaded) {
      return(
        <Loading/>
      );
    }

    if (!queue) {
      return(
        <div className="alert alert-danger">{this.state.errorMessage}</div>
      );
    }

    if (queue.deleted_at !== null) {
      return(
        <div className="alert alert-danger">This queue has been deleted</div>
      );
    }

    return (
    <>
      <ErrorMessage errorMessage={this.state.errorMessage}/>

      {this.renderQueueHeader()}
      {this.renderQueueBody()}
      </>
  );
  }

}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps
)(QueueScreen));
