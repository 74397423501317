import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';
import Tag from "../models/Tag";

import "../css/TagComponent.css";
import {Logger} from "aws-amplify";

const logger = new Logger('TagComponent');

class TagComponent extends Component {


  deleteTag = (event) => {
    logger.debug("delete tag")

    if (this.props.deleteCallback) {
      this.props.deleteCallback(this.props.tag)
    }
  };

  render() {
    let {tag} = this.props;

    if (this.props.editable) {
      return (
        <div className="tag tag-deletable">
          #{tag.tag}&nbsp;<i className="fas fa-times-circle cursor-pointer" onClick={this.deleteTag}></i>
        </div>
      );
    }

    return (
      <div className="tag">
        #{tag.tag}
      </div>
    );


  };
}

TagComponent.defaultProps = {
  editable: false
};

TagComponent.propTypes = {
  tag: PropTypes.instanceOf(Tag).isRequired,
  editable: PropTypes.bool,
  deleteCallback: PropTypes.func
};


export default withRouter(TagComponent);

