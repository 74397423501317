import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OKCancelModal extends Component {

  render() {
    return (
      <div className="modal fade" id={this.props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{this.props.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.props.message}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.props.cancelCallback}>{this.props.cancelLabel}</button>
              <button type="button" className="btn btn-primary epiqueue-darkblue" data-dismiss="modal" onClick={this.props.okCallback}>{this.props.okLabel}</button>
            </div>
          </div>
        </div>
      </div>
    )
  };
}

OKCancelModal.defaultProps = {
  okLabel: "OK",
  cancelLabel: "Cancel",
  okCallback: null,
  cancelCallback: null,
};

OKCancelModal.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  okLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  okCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
};

export default OKCancelModal;

