import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorMessage extends Component {

  render() {
    if (this.props.errorMessage === null || this.props.errorMessage === '' || this.props.errorMessage === undefined) {
      return null;
    }


    let message = this.props.errorMessage;
    if (message ===  'Request failed with status code 500') {
      message = "Oops, something went wrong back at headquarters. We've been notified. In the mean time, try again, sometimes these errors are temporary.";
    }


    return (
      <div className={"alert alert-" + this.props.type}>{message}</div>
    )
  };
}


ErrorMessage.defaultProps = {
  type: 'danger',
};

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
  type: PropTypes.string
};

export default ErrorMessage;

