import {Logger} from 'aws-amplify';
import Model from "./Model";

const logger = new Logger('TransactionModel');

export default class Transaction extends Model {

  static STATUS_CREATING = 'creating';
  static STATUS_SUCCESS = 'success';
  static STATUS_FAILED = 'failed';

  /**
   *
   * @param json
   */
  constructor(json) {
    super();

    logger.debug("Transaction constructor: " + JSON.stringify(json));

    let {
      id,
      identifier,
      sale_id,
      total_amount,
      platform_amount,
      seller_amount,
      stripe_fee,
      currency,
      status,
      comment,
      shipping_name,
      shipping_line1,
      shipping_line2,
      shipping_city,
      shipping_state,
      shipping_zip,
      shipping_country,
      created_at,
    } = json;

    // database fields
    this.id = id;
    this.identifier = identifier;
    this.total_amount = total_amount;
    this.platform_amount = platform_amount;
    this.seller_amount = seller_amount;
    this.stripe_fee = stripe_fee;
    this.sale_id = sale_id;
    this.currency = currency;
    this.status = status;
    this.comment = comment;
    this.shipping_name = shipping_name;
    this.shipping_line1 = shipping_line1;
    this.shipping_line2 = shipping_line2;
    this.shipping_city = shipping_city;
    this.shipping_state = shipping_state;
    this.shipping_zip = shipping_zip;
    this.shipping_country = shipping_country;
    this.created_at = created_at;
  }

  getFormattedTotalAmount() {
    return this.formatMoney(this.total_amount / 100);
  }

  formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  };

}

/**
 +-------------------------+---------------------+------+-----+-------------------+-----------------------------+
 | Field                   | Type                | Null | Key | Default           | Extra                       |
 +-------------------------+---------------------+------+-----+-------------------+-----------------------------+
 | id                      | bigint(20) unsigned | NO   | PRI | NULL              | auto_increment              |
 | identifier              | char(36)            | NO   |     | NULL              |                             |
 | sale_id                 | bigint(20) unsigned | NO   | MUL | NULL              |                             |
 | buyer_user_id           | bigint(20) unsigned | NO   | MUL | NULL              |                             |
 | seller_user_id          | bigint(20) unsigned | NO   | MUL | NULL              |                             |
 | total_amount            | int(10) unsigned    | NO   |     | NULL              |                             |
 | platform_amount         | int(10) unsigned    | NO   |     | NULL              |                             |
 | seller_amount           | int(10) unsigned    | NO   |     | NULL              |                             |
 | stripe_fee              | int(10) unsigned    | NO   |     | 0                 |                             |
 | currency                | varchar(255)        | NO   |     | USD               |                             |
 | type                    | varchar(255)        | NO   |     | sale              |                             |
 | status                  | varchar(255)        | NO   |     | creating          |                             |
 | comment                 | text                | YES  |     | NULL              |                             |
 | stripe_token            | varchar(255)        | YES  |     | NULL              |                             |
 | stripe_account          | varchar(255)        | YES  |     | NULL              |                             |
 | stripe_transfer_request | json                | YES  |     | NULL              |                             |
 | stripe_result           | json                | YES  |     | NULL              |                             |
 | shipping_name           | varchar(255)        | YES  |     | NULL              |                             |
 | shipping_line1          | varchar(255)        | YES  |     | NULL              |                             |
 | shipping_line2          | varchar(255)        | YES  |     | NULL              |                             |
 | shipping_city           | varchar(255)        | YES  |     | NULL              |                             |
 | shipping_state          | varchar(255)        | YES  |     | NULL              |                             |
 | shipping_zip            | varchar(255)        | YES  |     | NULL              |                             |
 | shipping_country        | varchar(255)        | YES  |     | NULL              |                             |
 | created_at              | timestamp           | NO   |     | CURRENT_TIMESTAMP |                             |
 | updated_at              | timestamp           | NO   |     | CURRENT_TIMESTAMP | on update CURRENT_TIMESTAMP |
 +-------------------------+---------------------+------+-----+-------------------+-----------------------------+
 26 rows in set (0.07 sec)
*/