import React, { Component } from 'react';
import {withRouter, Link} from "react-router-dom";

import "../css/Footer.css";

class Footer extends Component {


    render() {
    return (
      <div className="container">
        <div className="epiqueue-hr mt-5"/>
        <div className="footer-links">
          <a className="epiqueue-brand-link m-3" rel="noopener noreferrer" href="https://www.facebook.com/epiqueue" target="_blank"><i className="fab fa-facebook"></i></a>
          <a className="epiqueue-brand-link m-3" rel="noopener noreferrer" href="https://twitter.com/epiqueue" target="_blank"><i className="fab fa-twitter"></i></a>
          <a className="epiqueue-brand-link m-3" rel="noopener noreferrer" href="https://www.instagram.com/epiqueue" target="_blank"><i className="fab fa-instagram"></i></a>
          <a className="epiqueue-brand-link m-3" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCHj3Wd8iZvC9KF81z_w6x6g" target="_blank"><i className="fab fa-youtube"></i></a>
          <a className="epiqueue-brand-link m-3" rel="noopener noreferrer" href="https://www.pinterest.com/epiqueue" target="_blank"><i className="fab fa-pinterest"></i></a>
          <a className="epiqueue-brand-link m-3 d-none" rel="noopener noreferrer" href="https://www.linkedin.com/company/epiqueue" target="_blank"><i className="fab fa-linkedin"></i></a>
          <a className="epiqueue-brand-link m-3 d-none" rel="noopener noreferrer" href="https://github.com/epiqueue" target="_blank"><i className="fab fa-github"></i></a>
        </div>
        <div className="footer-links">
          <Link className="btn btn-link epiqueue-link" to={'/about'}>About</Link>
          <Link className="btn btn-link epiqueue-link" to={('/privacy')}>Privacy</Link>
          <Link className="btn btn-link epiqueue-link" to={('/terms')}>Terms</Link>
        </div>
        <div className="footer-copyright mb-4 epiqueue-darkblue-text">
          &copy;{new Date().getFullYear()} Epiqueue, Inc.
        </div>
      </div>
    )
  };
}

export default withRouter(Footer);

