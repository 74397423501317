import React, { Component } from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {API, Logger} from "aws-amplify";
import Queue from "../../models/Queue";
import PropTypes from 'prop-types';
import QueueCard from "../QueueCard";
import TagContainer from "../TagContainer";

import "../../css/DashboardQueue.css"
import ErrorMessage from "../ErrorMessage";
import Util from "../../Util";

const logger = new Logger('DashboardQueue');

class DashboardQueue extends Component {


  constructor(props) {
    super(props);
    logger.debug('DashboardScreen::constructor');

    this.state = {
      queueData: null,
      queue: null,
      dataLoaded: false,
    }

  }


  componentDidMount() {
    if (this.props.loggedInUser) {
      this.loadQueue();
    }
  }


  loadQueue = async() => {

    if (!this.props.identifier) {
      this.setState({
        errorMessage: "No identifier",
        queueData: null,
        queue: null,
        dataLoaded: true,
      });
      return;
    }

    try {
      let apiName = 'epiqueueapi';
      let path = '/dashboard/queue';
      let postData = {
        body: {
          identifier: this.props.identifier
        }
      };
      let response = await API.post(apiName, path, postData);
      if (response.success) {
        this.setState({
          queueData: response.queuedata,
          queue: new Queue(response.queue),
          dataLoaded: true,
        });
      } else {
        this.setState({
          queueData: null,
          queue: null,
          errorMessage: response.errorMessage,
          dataLoaded: true,
        });
      }
    } catch(error) {

      this.setState({
        errorMessage: error.message,
        queueData: null,
        queue: null,
        dataLoaded: true,
      });
    };

  };

  render() {
    if (!this.props.loggedInUser) {
      return null;
    }

    if (this.state.errorMessage) {
      return <ErrorMessage errorMessage={this.state.errorMessage}/>
    }

    let {queue} = this.state;
    if (!queue) {
      return null;
    }


    return (
      <div key={queue.identifier} className="dashboard-screen-queueinfo">
        <div className="dashboard-queue-screen-card p-2">
          <QueueCard queue={queue}/>
        </div>
        <div className="flex-lg-grow-1 ml-3">
          <div className="dashboard-screen-table-area">
            <table className="table table-sm table-borderless table-responsive dashboard-screen-table">
              <tbody>
              <tr>
                <td>Members</td>
                <td className="text-right">{this.state.queueData ? this.state.queueData.member_count : "..."}</td>
              </tr>
              <tr>
                <td>Products</td>
                <td className="text-right">{this.state.queueData ? this.state.queueData.product_count : "..."}</td>
              </tr>
              <tr>
                <td>Products Sold</td>
                <td className="text-right">{this.state.queueData ? this.state.queueData.total_sales_count : "..."}</td>
              </tr>
              <tr>
                <td>Total Sales</td>
                <td className="text-right">{this.state.queueData ? Util.formatMoney(this.state.queueData.total_sales_value) : "..."}</td>
              </tr>
              </tbody>
            </table>
            <div className="d-flex flex-row">
              <div className="mr-2">Tags:</div>
              <div>
                <TagContainer tags={queue.tags} editable={false} />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-lg-column flex-sm-row mt-1 align-items-lg-end align-items-sm-center justify-content-lg-start justify-content-around mr-1 p-1">
          <div className="w-100 m-1"><Link to={queue.getUrl()}><div className="btn btn-primary btn-sm epiqueue-darkblue w-100 text-left"><i className="fas fa-binoculars"></i>&nbsp;View&nbsp;Queue</div></Link></div>
          <div className="w-100 m-1"><Link to={"/dashboard/edit/" + queue.id}> <div className="btn btn-primary btn-sm epiqueue-darkblue w-100 text-left"><i className="fas fa-edit"></i>&nbsp;Edit&nbsp;Queue</div></Link></div>
          <div className="w-100 m-1"><Link to={"/queue/" + queue.id + "/createproduct"}> <div className="btn btn-primary btn-sm epiqueue-darkblue w-100 text-left"><i className="fas fa-plus-square"></i>&nbsp;New&nbsp;Product</div></Link></div>
        </div>
      </div>
      )
  };
}



const mapStateToProps = (state,  ownProps) => {
  return {
    loggedInUser: state.users.loggedInUser,
  }
};

export default withRouter(connect(
  mapStateToProps,
)(DashboardQueue));


DashboardQueue.propTypes = {
  identifier: PropTypes.string.isRequired,
};
