import React, { Component } from 'react';

class IndexQueueSeparator extends Component {

  render() {
    return (
      <div className="d-flex justify-content-center w-100 mt-5 mb-4 epiqueue-darkblue-text">
        <i className="fal fa-paint-brush-alt"></i>&nbsp;&nbsp;&nbsp;<i className="fal fa-paint-brush-alt"></i>&nbsp;&nbsp;&nbsp;<i className="fal fa-paint-brush-alt"></i>&nbsp;&nbsp;&nbsp;<i className="fal fa-paint-brush-alt"></i>&nbsp;&nbsp;&nbsp;<i className="fal fa-paint-brush-alt"></i>
      </div>
    );
  }
}

export default IndexQueueSeparator;
