import React, { Component } from 'react';
import PropTypes from 'prop-types';
import User from "../models/User";
import {withRouter} from "react-router-dom";
import {API} from "aws-amplify";
import ErrorMessage from "./ErrorMessage";
import ShippingAddress from "../models/ShippingAddress";
import StateDropdown from "./StateDropdown";

class ShippingAddressForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      address_name : "",
      address_line1 : "",
      address_line2 : "",
      address_city:  "",
      address_state:  "State...",
      address_zip:  "",
      address_country:  "US",

      errorMessage: null,
      successMessage: null,

      isUpdate: false,

      invalidItems: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  updateTextField(field, event) {
      this.setState({
        [field]: event.target.value,
        invalidItems: [],
        errorMessage: null,
      });
  }


  validate() {
    let invalidItems = {};


    // check required fields

    if (this.state.address_name.trim() === '') {
      invalidItems['address_name'] = 'Name is a required field';
    }
    if (this.state.address_line1.trim() === '') {
      invalidItems['address_line1'] = 'Address is a required field';
    }
    if (this.state.address_city.trim() === '') {
      invalidItems['address_city'] = 'City is a required field';
    }
    if (this.state.address_state.trim() === '' || this.state.address_state.trim() === 'State...') {
      invalidItems['address_state'] = 'Required';
    } else {
    }
    if (this.state.address_zip.trim() === '') {
      invalidItems['address_zip'] = 'Zip is a required field';
    }

    if (Object.keys(invalidItems).length > 0) {
      this.setState({
        invalidItems: invalidItems,
        errorMessage: "Please correct the errors above",
      });
      return false;
    }

    return true;
  }



  async onCancel(e) {
    e.preventDefault();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  async onSubmit(e) {
    e.preventDefault();


    if (!this.validate()) {
      return;
    }

    // save
    let apiName = 'epiqueueapi';
    let path = '/user/shipping_address';

    let postData = {
      body: {
        name: this.state.address_name.trim(),
        address1: this.state.address_line1.trim(),
        address2: this.state.address_line2.trim(),
        city: this.state.address_city.trim(),
        state: this.state.address_state.trim(),
        zip: this.state.address_zip.trim(),
        country: this.state.address_country.trim(),
      }
    };

    try {
      let response = await API.post(apiName, path, postData);
      if (response.success) {
        let shippingAddress = new ShippingAddress(response.shippingAddress);
        if (this.props.onSubmit) {
          this.props.onSubmit(shippingAddress);
        }
      } else {
        await this.setState({
          errorMessage: response.errorMessage,
        });
      }
    } catch (error) {
        await this.setState({
          errorMessage: error.message,
        });
    }

  }

  invalidState(item) {
    if (item in this.state.invalidItems) {
      return 'is-invalid'
    }
  }

  render() {
    return (
    <>

      <div className="border-label"><h6>Add Shipping Address</h6></div>
      <div className="form-group border border p-2 " style={{backgroundColor: '#eeeeee'}}>


        <div className="form w-100">
          <input className={"form-control " + this.invalidState('address_name')}
                 type="text"
                 id="address_name"
                 placeholder="Name"
                 onChange={(event) => this.updateTextField('address_name', event)}
                 value={this.state.address_name}
          />
          <div className="invalid-feedback">{this.state.invalidItems.address_name}</div>
        </div>
        <div className="mt-2">
          <input className={"form-control " + this.invalidState('address_line1')}
                 type="text"
                 id="address_line1"
                 placeholder="Address1"
                 onChange={(event) => this.updateTextField('address_line1', event)}
                 value={this.state.address_line1}
          />
          <div className="invalid-feedback">{this.state.invalidItems.address_line1}</div>
        </div>
        <div className="mt-2">
          <input className={"form-control " + this.invalidState('address_line2')}
                 type="text"
                 id="address_line2"
                 placeholder="Address2"
                 onChange={(event) => this.updateTextField('address_line2', event)}
                 value={this.state.address_line2}
          />
          <div className="invalid-feedback">{this.state.invalidItems.address_line2}</div>
        </div>
        <div className="form-inline">
          <div className="mt-2 mr-lg-3 mr-md-2 mr-sm-0">
            <input className={"form-control " + this.invalidState('address_city')}
                   type="text"
                   id="address_city"
                   placeholder="City"
                   onChange={(event) => this.updateTextField('address_city', event)}
                   value={this.state.address_city}
            />
            <div className="invalid-feedback">{this.state.invalidItems.address_city}</div>
          </div>
          <div className="mt-2 mr-lg-3 mr-md-2 mr-sm-0">
            <select className={"form-control " + this.invalidState('address_state')}
                    id="address_state"
                    onChange={(event) => this.updateTextField('address_state', event)}
                    value={this.state.address_state}
            >
              <StateDropdown defaultItem="States..."/>
            </select>
            <div className="invalid-feedback">{this.state.invalidItems.address_state}</div>
          </div>
          <div className="mt-2">
            <input className={"form-control " + this.invalidState('address_zip')}
                   type="text"
                   id="address_zip"
                   placeholder="Zip"
                   onChange={(event) => this.updateTextField('address_zip', event)}
                   value={this.state.address_zip}
            />
            <div className="invalid-feedback">{this.state.invalidItems.address_zip}</div>
          </div>
        </div>


        <ErrorMessage errorMessage={this.state.errorMessage}/>

        <div className="mt-3 mr-5 mb-3 row justify-content-end">
          <div className="mr-3">
            <button className="btn btn-secondary" onClick={e => this.onCancel(e)}>Cancel</button>
          </div>
          <div className="">
            <button className="btn btn-primary" onClick={e => this.onSubmit(e)}>{this.state.isUpdate === true ? "Update" : "Save"} Shipping Address</button>
          </div>
        </div>


      </div>



      </>
    );
  };
}

ShippingAddressForm.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default withRouter(ShippingAddressForm);

