//import {Logger} from 'aws-amplify';

import Model from "./Model";

//const logger = new Logger('ImageModel');

export default class Image extends Model {


  /**
   *
   * @param json
   */
  constructor(json) {
    super();

    //logger.debug("Image constructor");

    let {
      id,
      identifier,
      table_name,
      primary_key,
      url,
      order,
      width,
      height,
      extension,
      created_at,
      updated_at,
    } = json;

    // database fields
    this.id = id;
    this.identifier = identifier;
    this.table_name = table_name;
    this.primary_key = primary_key;
    this.url = url;
    this.order = order;
    this.width = width;
    this.height = height;
    this.height = height;
    this.extension = extension;
    this.updated_at = updated_at;
    this.created_at = created_at;
  }

}

 // +-------------+---------------------+------+-----+---------------------+-------------------------------+
 // | Field       | Type                | Null | Key | Default             | Extra                         |
 // +-------------+---------------------+------+-----+---------------------+-------------------------------+
 // | id          | bigint(20) unsigned | NO   | PRI | NULL                | auto_increment                |
 // | table_name  | varchar(20)         | NO   | MUL | NULL                |                               |
 // | primary_key | bigint(20) unsigned | NO   |     | NULL                |                               |
 // | url         | varchar(2083)       | NO   |     | NULL                |                               |
 // | order       | int(10) unsigned    | YES  |     | NULL                |                               |
 // | width       | int(10) unsigned    | YES  |     | NULL                |                               |
 // | height      | int(10) unsigned    | YES  |     | NULL                |                               |
 // | extension   | varchar(255)        | YES  |     | NULL                |                               |
 // | created_at  | timestamp           | NO   |     | current_timestamp() |                               |
 // | updated_at  | timestamp           | NO   |     | current_timestamp() | on update current_timestamp() |
 // +-------------+---------------------+------+-----+---------------------+-------------------------------+
 // 10 rows in set (0.07 sec)
