import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from "react-router-dom";
import ProductCard from "./ProductCard";
import Product from "../models/Product";

import "../css/ScrollingProductList.css"
import Pager from "./Pager";

class ScrollingProductList extends Component {

  constructor(props) {
    super(props);

    let maxPages = this.props.products ? Math.ceil(this.props.products.length / this.props.maxVisible) : 1;
    if (maxPages < 1) {
      maxPages = 1;
    }


    this.state = {
      products: this.props.products ? this.props.products : [],
      page: 1,
      maxVisible: this.props.maxVisible,
      maxPages: maxPages,
    }
  }

  // componentDidMount() {
  // }



  renderProductsSquare() {
    if (!this.state.products || this.state.products.length === 0) {
      return (
        <div className="scrollingproductlist-no-queues">
          <div className="" style={{fontSize:"medium"}}>{this.props.noResultsText}</div>
        </div>
      );

    }

    let startingIdx = (this.state.page-1) * this.state.maxVisible;
    let endingIndx = startingIdx +  this.state.maxVisible;
    let filteredProducts = this.props.products.filter( (item, idx) => {
      if (idx >= startingIdx && idx < endingIndx) {
        return true;
      }
      return false;
    });

    return filteredProducts.map(item => {
      return (
        <div className="bg-light scrollingproductlist-item"  key={item.id}>
        <ProductCard product={item} shape={this.props.shape}/>
        </div>
      );
    });
  }

  renderProductsVertical() {
    if (!this.state.products || this.state.products.length === 0) {
      return (
        <div className="scrollingproductlist-no-queues">
          <div className="" style={{fontSize:"medium"}}>{this.props.noResultsText}</div>
        </div>
      );
    }

    return this.state.products.map(item => {
      return (
        <div key={item.id} className="w-100">
          <ProductCard key={item.id} product={item} shape={this.props.shape}/>
        </div>
      );
    });
  }

  setPage = (page) => {

    this.setState({
      page: page,
    });
  };

  renderSquare() {
    return (
      <div className="d-flex flex-column scrollingproductlist">
        <div className="d-flex flex-wrap justify-content-start justify-content-md-start justify-content-lg-start mb-2">
          <div className="d-flex scrollingproductlist-title">
            {this.props.title}
            { this.props.seemore ? <div className="ml-5"><Link to={this.props.seemore['url']}>{this.props.seemore['label']}</Link></div> : null }
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center justify-content-md-start justify-content-lg-start mt-1">
          {this.renderProductsSquare()}
        </div>
        <div className="d-flex flex-row justify-content-center justify-content-md-end align-items-center ">
          <Pager total={this.state.maxPages} selected={this.state.page} callback={this.setPage}/>
        </div>
      </div>
    );
  }

  renderVertical() {
    return (
      <div className="d-flex flex-column scrollingproductlist-vertical">
        <div className="d-flex flex-wrap justify-content-start">
          <div className="d-flex scrollingproductlist-title-vertical">
            {this.props.title}
            { this.props.seemore ? <div className="ml-5"><Link to={this.props.seemore['url']}>{this.props.seemore['label']}</Link></div> : null }
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
          {this.renderProductsVertical()}
        </div>
      </div>
    );
  }

  render() {
    switch(this.props.shape) {
      case 'square':
        return this.renderSquare();
      case 'vertical':
        return this.renderVertical();
      default:
        return null;
    }


  };
}

ScrollingProductList.defaultProps = {
  shape: 'square',
  noResultsText: "No Products available",
  maxVisible: 4,
};

ScrollingProductList.propTypes = {
  title: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.instanceOf(Product)).isRequired,
  seemore: PropTypes.object,
  shape: PropTypes.string.isRequired,
  noResultsText: PropTypes.string,
  maxVisible: PropTypes.number,
};

export default withRouter(ScrollingProductList);

