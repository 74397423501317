import {API, Auth, Logger} from 'aws-amplify';
// import Amplify, { API, Auth } from 'aws-amplify';
// import aws_exports from '../aws-exports';
// Amplify.configure(aws_exports);

import Model from "./Model";
import Queue from "./Queue";

const logger = new Logger('UserModel');


export default class User extends Model {

  static STATUS_ACTIVE = 'active';
  static STATUS_CANCELLED = 'cancelled';
  static STATUS_DELETED = 'deleted';
  static STATUS_REMOVED = 'removed';

  constructor(json) {
    super();

    let {
      id,
      identifier,
      identityId,
      username,
      email,
      description,
      profile_url,
      role,
      is_seller,
      status,
      last_login,
      created_at,
      updated_at,
      queues
    } = json;

    // database fields
    this.id = id;
    this.identifier = identifier;
    this.identityId = identityId;
    this.username = username;
    this.email = email;
    this.description = description;
    this.profile_url = profile_url;
    this.role = role;
    this.is_seller = is_seller;
    this.status = status;
    this.last_login = last_login;
    this.created_at = created_at;
    this.updated_at = updated_at;

    if (queues) {
      this.queues = queues.map(item => {
        return new Queue(item);
      })
    }

    // extras
    this.cognitoUser = null;
  }


  isAdmin() {
    return false;
  }

  /**
   * local id, not cognito id
   *
   * @param userId
   * @returns {Promise<void>}
   */
  static async loadByUserId(userId) {
    logger.debug("load by userId: " + userId);
    let errorMessage = null;
    let success = true;

    logger.debug("/user/id/" + userId);

    var userObj = null;
    if (userId) {
      let apiName = 'epiqueueapi';
      let path = '/user/id/' + userId;
      try {
        var response = await API.get(apiName, path);
        if (response.success) {
          success = true;
          userObj = new User(response.user);
          userObj.cognitoUser = null;
          errorMessage = null;

        } else {
          success = false;
          errorMessage = response.error;
        }
      } catch (error) {
        success = false;
        errorMessage = error.message;
        logger.debug(error.message)
      }
    } else {
      success = false;
      errorMessage = "error";
      logger.error("error")
    }


    return {success: success, error: errorMessage, userData: userObj}
  }


  /**
   *
   * @param cognitoUser
   * @returns {Promise<{userData: *, success: boolean, error: *}>}
   */
  static async load(cognitoUser) {
    let errorMessage = null;
    let success = true;

    logger.debug("/user/" + cognitoUser.attributes.sub);

    var userObj = null;
    if (cognitoUser) {
      let apiName = 'epiqueueapi';
      let path = '/user/' + cognitoUser.attributes.sub;
      let options = {
        headers: {
        }
      };
      try {
        var response = await API.get(apiName, path, options);
        if (response.success) {
          success = true;
          userObj = new User(response.user);
          userObj.cognitoUser = cognitoUser;
          errorMessage = null;
        } else {
          success = false;
          errorMessage = response.error;
        }
      } catch (error) {
        success = false;
        errorMessage = error.message;
        logger.debug("error: "  + error.message)
        logger.debug(error);
      }
    } else {
      success = false;
      errorMessage = "error";
      logger.debug("error")
    }


    return {success: success, error: errorMessage, user: userObj}
  }


  /**
   *
   * @returns {Promise<null>}
   */
  static async getAuthenticated() {
    let cognitoUser = null;
    try {
      cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: false
      });
    } catch(error) {
      return null;
    }

    if (cognitoUser) {
      try {
        let {success, user} = await User.load(cognitoUser);
        if (success) {
          return user;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  save = async () => {

    let user = Object.assign({}, this);
    delete user.cognitoUser;
    delete user.last_login;
    delete user.created_at;
    delete user.updated_at;

    let apiName = 'epiqueueapi';
    let path = '/user';
    const params = {
      body: {
        user: user,
      }
    };
    try {
      var response = await API.post(apiName, path, params);
      if (response.success) {
      } else {
        logger.error(response.errorMessage)
      }
    } catch (error) {
      logger.error("API.post error: " + error.message)
    }
  }

  getProfileUrl = () => {
    return "/profile/" + this.id + "/" + Model.urlify(this.username);
  }


  getUrl = () => {
    return this.getProfileUrl();
  }
}
