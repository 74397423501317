import React from 'react';
import {API, Logger} from "aws-amplify";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Queue from "../models/Queue";
import Product from "../models/Product";
import "react-datepicker/dist/react-datepicker.css";
import RootScreen from "./RootScreen";
import ImageUploader from "../components/ImageUploader";
import ErrorMessage from "../components/ErrorMessage";
import CurrencyInput from "../components/CurrencyInput";
import TagContainer from "../components/TagContainer";
import Loading from "../components/Loading";
import BackLink from "../components/BackLink";
import ReactMarkdown from "react-markdown/with-html";

const logger = new Logger('CreateProductScreen');

class CreateProductScreen extends RootScreen {

  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'CreateProductScreen'
    });


    const { match } = this.props;

    this.state = {
      queueId: match.params.id,
      productId: match.params.productid,

      productName: "",
      productDescription: "",
      shippingDescription: "",
      returnPolicy: "",
      productVisible: Product.NOT_VISIBLE,
      productTaxable: Product.TAXABLE,
      price: "",
      shipping_charge: "",
      quantity: 1,

      tags: [],

      queue: null,
      product: null,
      queueLoaded: false,
      creatingProduct: false,
      errorMessage: null,
      successMessage: null,

      previewDescription: false,
    };

    this.handleProductNameChange = this.handleProductNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
  }

  componentDidMount = async() => {
    window.scrollTo(0, 0);

    if (this.state.productId) {
      this.loadProduct();
    } else {
      this.loadQueue();
    }
  }

  handleQuantityChange = (event) => {
    this.setState({
      quantity: event.target.value
    });
  };

  loadProduct = async () => {
    let {productId} = this.state;

    try {
      let apiName = 'epiqueueapi';
      let path = '/product/' + productId;
      var response = await API.get(apiName, path);
      logger.debug("response: " + JSON.stringify(response));
      if (!response.success) {
        this.setState({
          queue: null,
          errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred",
          queueLoaded: true,
          isSelf: false,
        });
      } else {
        let product = new Product(response.product);
        let {queue} = product;

        this.setState({
          product: product,
          productName: product.name,
          productDescription: product.description ? product.description : "",
          shippingDescription: product.shipping_description ? product.shipping_description : "",
          returnPolicy: product.return_policy ? product.return_policy : "",
          price: product.price,
          shipping_charge: product.shipping_charge,
          quantity: product.quantity,
          productVisible: product.visible,
          productTaxable: product.taxable ? Product.TAXABLE : Product.TAX_FREE,
          tags: product.tags,
          queue: queue,
          queueLoaded: true,
          isSelf: this.props.loggedInUser && this.props.loggedInUser.id === queue.user_id,
        });
      }
    } catch (error) {
      logger.debug(error.message);
      logger.error(error);
      this.setState({
        errorMessage: error.message,
        queueLoaded: true,
        isSelf: false,
      });
    }

  }

  loadQueue = async () => {

    let {queueId} = this.state;

    if (queueId === undefined) {
      this.setState({
        queue: null,
        queueLoaded: true,
        errorMessage: "Error: No queue specified",
        isSelf: false,
      });
      return;
    }

    try {
      let apiName = 'epiqueueapi';
      let path = '/queue/' + queueId;
      var response = await API.get(apiName, path);
      logger.debug("response: " + JSON.stringify(response));
      if (!response.success) {
        this.setState({
          queue: null,
          errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred",
          queueLoaded: true,
          isSelf: false,
        });
      } else {
        let queue = new Queue(response.queue);
        this.setState({
          queue: queue,
          queueLoaded: true,
          isSelf: this.props.loggedInUser && this.props.loggedInUser.id === queue.user_id,
        });
      }
    } catch (error) {
      logger.debug(error.message);
      logger.error(error);
      this.setState({
        errorMessage: error.message,
        queueLoaded: true,
        isSelf: false,
      });
    }
  };


  validate = () => {
    if (this.state.productName.trim() === '') {
      this.setState({
        errorMessage: "You must provide a name.",
        successMessage: null
      });
      return false ;
    }
    return true;
  };

  updateProduct = async () => {
    logger.debug("update")


    let {loggedInUser} = this.props;
    if (!loggedInUser) {
      this.setState({
        errorMessage: "Can not update the product right now.",
        successMessage: null,
      });
      return;
    }

    if (!this.validate()) {
      return;
    }

    this.setState({
      errorMessage: null,
      successMessage: null
    });


    let product_id = this.state.product.id;
    let name = this.state.productName.trim();
    let description = this.state.productDescription ? this.state.productDescription.trim() : "";
    let shipping_description = this.state.shippingDescription;
    let return_policy = this.state.returnPolicy;
    let price = this.state.price;
    let shipping_charge = this.state.shipping_charge;
    let quantity = this.state.quantity;
    let visible = this.state.productVisible;
    let taxable = this.state.productTaxable;

    let tags = this.state.tags.map(item => {
      return {id: item.id, 'tag': item.tag}
    });

    //TODO: sanity checks

    try {
      let apiName = 'epiqueueapi';
      let path = '/product';
      let putData = {
        body: {
          product_id: product_id,
          name: name,
          description: description,
          shipping_description: shipping_description,
          return_policy: return_policy,
          price: price,
          shipping_charge: shipping_charge,
          quantity: quantity,
          visible: visible,
          tags: tags,
          taxable: taxable,
        }
      };
      var response = await API.put(apiName, path, putData);
      if (!response.success) {
        this.setState({
          errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred"
        });
      } else {
        let updatedProduct = new Product(response.product);
        this.setState({
          product: updatedProduct,
          queue: updatedProduct.queue,
          productName: updatedProduct.name,
          productDescription: updatedProduct.description,
          shippingDescription: updatedProduct.shipping_description,
          returnPolicy: updatedProduct.return_policy,
          productVisible: updatedProduct.visible,
          price: updatedProduct.price,
          shipping_charge: updatedProduct.shipping_charge,
          quantity: updatedProduct.quantity,
          tags: updatedProduct.tags,
          productTaxable: updatedProduct.taxable,
          successMessage: "Product saved",
        });
      }

    } catch (error) {
      console.log(error.message);
      this.setState({
        errorMessage: error.message
      });
    }
  };




  createProduct = async () => {

    let {queue} = this.state;

    if (!this.validate()) {
      return;
    }


    let name = this.state.productName;
    let description = this.state.productDescription;
    let shipping_description = this.state.shippingDescription;
    let return_policy = this.state.returnPolicy;
    let quantity = this.state.quantity;
    let price = this.state.price;
    let shipping_charge = this.state.shipping_charge;
    let visible = this.state.productVisible;
    let taxable = this.state.productTaxable;

    this.setState({
      errorMessage: null,
      successMessage: null
    });


    let {loggedInUser} = this.props;
    if (!loggedInUser) {
      this.setState({
        errorMessage: "Can not create the event right now."
      });
      return;
    }


    // todo: verify


    try {
      let apiName = 'epiqueueapi';
      let path = '/product';
      let putData = {
        body: {
          queue_id: queue.id,
          name: name,
          description: description,
          shipping_description: shipping_description,
          return_policy: return_policy,
          quantity: quantity,
          price: price,
          shipping_charge: shipping_charge,
          visible: visible,
          taxable: taxable,
        }
      };
      var response = await API.put(apiName, path, putData);
      console.log("response: " + JSON.stringify(response));
      if (!response.success) {
        this.setState({
          errorMessage: (response.error) ? response.error : "An error occurred"
        });
      } else {

        let createdProduct = new Product(response.product);
        this.setState({
          successMessage: "Product Created",
          product: createdProduct,
          productId: createdProduct.id,
        });
        this.props.history.replace("/queue/" + queue.id + "/editproduct/" + createdProduct.id);
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        errorMessage: error.message
      });
    }
  };


  handleProductNameChange = (event) => {
    let txt = event.target.value.substr(0, Product.NAME_CHAR_LIMIT);
    this.setState({productName: txt});
  }

  handleDescriptionChange = (event) => {
    this.setState({productDescription: event.target.value});
  }

  handleShippingDescriptionChange = (event) =>{
    this.setState({shippingDescription: event.target.value});
  }

  handleReturnPolicyChange = (event) => {
    this.setState({returnPolicy: event.target.value});
  }

  handlePriceChange = (newPrice) => {
    this.setState({price: newPrice});
  }

  handleShippingChange = (newPrice) => {
    this.setState({shipping_charge: newPrice});
  }


  handleVisibleChange = (event) => {
    logger.debug("handleVisibleChange");
    let newValue = event.target.checked ? Product.VISIBLE : Product.NOT_VISIBLE;
    this.setState({productVisible: newValue});
  }

  handleTaxFreeChange = (event) => {
    let newValue = event.target.checked ? Product.TAX_FREE : Product.TAXABLE;
    this.setState({productTaxable: newValue});
  }

  deleteTag = (tag) => {
    let curTags = this.state.tags.filter(item => {
      if (item !== tag) {
        return true;
      }
      return false;
    });

    this.setState({
      tags: curTags,
    });

  };

  addTag = (tag) => {
    if (!tag || tag.tag === '') {
      return;
    }

    let curTags = this.state.tags;
    curTags.push(tag);
    this.setState({
      tags: curTags,
    })
  };


  renderLoading() {
    return (
      <Loading/>
    );
  }

  renderContent() {
    if (!this.props.loggedInUser) {
      return <h4>You must be logged in to create products</h4>;
    }

    if (!this.state.queueLoaded) {
      return null;
    }

    if (!this.state.isSelf) {
      return <h4>You can not create products for other users</h4>;
    }

    if(!this.props.loggedInUser || !this.props.loggedInUser.is_seller) {
      return (
        <div className="alert alert-info">You must be a registered seller in order to create a queue</div>
      )
    }

    return (
      <>
        {this.state.product ?
          <BackLink text="Back to Your Product!" link={this.state.product.getUrl()}/>
          :
          <BackLink text="Back to Your Queue!" link={"/queue/" + this.state.queueId}/>
        }

        {this.state.errorMessage ? <div className="alert alert-danger">{this.state.errorMessage}</div> : null}

        <div className="d-flex flex-column border border-dark p-2 mt-4 epiqueue-faded-background">
          <h5>
            Details
          </h5>

          <form>
            <div className="form-group">
              <label htmlFor="productname">Product Name</label>
              <input type="text"
                     className="form-control"
                     id="productname"
                     value={this.state.productName}
                     onChange={this.handleProductNameChange}
                     aria-describedby="queuenameHelp"
                     placeholder="Product Name"
                     required
                     autoFocus
              />
              {this.state.productName.trim() === '' ?
                <small><span className="text-danger">Required. </span>Limit of {Product.NAME_CHAR_LIMIT} characters.</small>
                :
                <small id="queuenameHelp" className="form-text text-muted">Limit of {Product.NAME_CHAR_LIMIT} characters.</small>
              }
            </div>

            <div className="form-group">
              <div className="d-flex flex-row justify-content-between">
                <label htmlFor="productdescription">Description (markup and html allowed)</label>
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="preview-description" onChange={() => this.setState({previewDescription: !this.state.previewDescription})} checked={this.state.previewDescription}/>
                  <label className="custom-control-label" htmlFor="preview-description">Preview</label>
                </div>
              </div>

              {!this.state.previewDescription ?
                <textarea
                  className="form-control"
                  rows="10"
                  id="productdescription"
                  value={this.state.productDescription}
                  onChange={this.handleDescriptionChange}
                  placeholder="Description"
                />
                :
                <div className="border p-2">
                <ReactMarkdown source={this.state.productDescription} escapeHtml={false}/>
                </div>
              }
            </div>

            <div className="form-group">
              <label htmlFor="shippingDescription">Shipping Description</label>
              <textarea
                className="form-control"
                rows="3"
                id="shippingDescription"
                value={this.state.shippingDescription}
                onChange={this.handleShippingDescriptionChange}
                placeholder="Shipping Description (e.g. Ships in 3 to 5 days)"
              />
            </div>


            <div className="form-group">
              <label htmlFor="returnPolicy">Return Policy</label>
              <textarea
                className="form-control"
                rows="3"
                id="returnPolicy"
                value={this.state.returnPolicy}
                onChange={this.handleReturnPolicyChange}
                placeholder="Return Policy"
              />
            </div>


            <div className="form-row p-2">
              <div className="form-group mr-2 mr-lg-4">
                <label htmlFor="quantity">Inventory</label><br/>
                <input type="text"
                       className="form-control"
                       id="quantity"
                       value={this.state.quantity}
                       onChange={this.handleQuantityChange}
                       aria-describedby="quantityHelp"
                       placeholder="100.00"
                       required
                />
                <div className="invalid-feedback">
                  Value is not valid.
                </div>
              </div>

              <div className="form-group mr-2 mr-lg-4">
                <label htmlFor="pricePerItem">Price per Item</label><br/>
                  <CurrencyInput value={this.state.price} onChange={this.handlePriceChange}/>
                <small id="pricePerItemHelp" className="form-text text-muted">Currently only US Dollars supported.</small>
                <div className="invalid-feedback">
                  Value is not valid.
                </div>
              </div>


              <div className="form-group">
                <label htmlFor="pricePerItem">Shipping per Item</label><br/>
                <CurrencyInput value={this.state.shipping_charge ? this.state.shipping_charge : 0} onChange={this.handleShippingChange}/>
                <small id="pricePerItemHelp" className="form-text text-muted">Currently only US Dollars supported.</small>
                <div className="invalid-feedback">
                  Value is not valid.
                </div>
              </div>


            </div>

            <div className='d-flex flex-row align-items-center mb-3 flex-wrap'>
              <div className='mr-2'>Tags:</div><TagContainer tags={this.state.tags} editable={true} deleteCallback={this.deleteTag} addCallback={this.addTag}/>
            </div>


            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="product-taxfree" onChange={this.handleTaxFreeChange} checked={this.state.productTaxable === Product.TAX_FREE}/>
              <label className="custom-control-label" htmlFor="product-taxfree">This is a tax-free product, do not collect tax.</label>
            </div>

            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="product-visible" onChange={this.handleVisibleChange} checked={this.state.productVisible === Product.VISIBLE}/>
              <label className="custom-control-label" htmlFor="product-visible">Make this product visible to all</label>
            </div>


            {this.state.successMessage ?
              <div className="mt-3 mb-2">
              <ErrorMessage errorMessage={this.state.successMessage} type={'success'}/>
              </div>
            : null }

            <div className="d-flex flex-row justify-content-end mt-3 bg-warning">
            {this.state.productId ?
              <button type="button" className="btn btn-primary btn-epiqueue btn-block" onClick={() => this.updateProduct()}>Update Product</button>
              :
              <button type="button" className="btn btn-primary btn-epiqueue btn-block" onClick={() => this.createProduct()}>Create Product</button>
            }
            </div>
          </form>
        </div>

        {this.state.productId ?
          <div className="d-flex flex-column border border-dark p-2 mt-4">
            { this.state.product ?
              <ImageUploader isOwner={true} product={this.state.product} enabled={this.state.productId !== null}/>
              : null }
          </div>
        : null }


      </>
  );
}

}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps
)(CreateProductScreen));
