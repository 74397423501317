import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Product from "../models/Product";
import {withRouter, Link} from "react-router-dom";
import ImagePlaceholder from "../img/image-placeholder.png"
import ReactMarkdown from "react-markdown/with-html";

import "../css/ProductCard.css"
import QueuePic from "./QueuePic";

class ProductCard extends Component {

  renderSquare() {
    let {product} = this.props;

    return (
      <Link to={product.getUrl()} style={{textDecoration: 'none'}}>
        <div className="d-flex flex-column product-card">
          <div className="product-card-image-container">
            {
              product.primary_image ?
                <img className="product-card-image" src={"https://cdn.epiqueue.com/public/" + product.primary_image.url} width={190} height={190}
                     alt={"Primary"}/>
                :
                <img className="product-card-image" src={ImagePlaceholder} width={190} height={190} alt={"Placeholder"}/>
            }
          </div>

          <div className="product-card-body overflow-hidden">
            <div className="d-flex flex-row pt-1">
              <div className="pr-1 align-self-center">
                <QueuePic queue={product.queue} size={40}/>
              </div>
              <div className="d-flex flex-column overflow-hidden">
                <div className="product-card-product-name oneline-ellipsis">
                  {product.name}
                </div>
                <div className="product-card-owner-name oneline-ellipsis">
                  {product.queue ? product.queue.name : null}
                </div>
              </div>
            </div>
          </div>
        </div>

      </Link>
    );
  };

  renderVertical() {
    let {product} = this.props;

    return (
      <Link to={product.getUrl()} style={{textDecoration: 'none'}}>
        <div className="product-card-vertical">
          <div className="product-card-image-vertical-column">
            <div className="product-card-image-vertical">
              {
                product.primary_image ?
                  <img className="product-card-image" src={"https://cdn.epiqueue.com/public/" + product.primary_image.url}
                       alt={"Primary"}/>
                  :
                  <img className="product-card-image" src={ImagePlaceholder} alt={"Placeholder"}/>
              }
            </div>
          </div>

          <div className="product-card-body ml-2">
            <div className="d-flex flex-row pt-0">
              <div className="d-flex flex-column overflow-hidden">
                <div className="product-card-product-name-vertical">
                  {product.name}
                </div>
                <div className="product-card-product-description-vertical">
                  <ReactMarkdown source={product.description} escapeHtml={false} />
                </div>
                <div className="product-card-product-price-vertical">
                  {product.price ? "$" + product.price / 100 : <span className="text-danger">Price not yet set</span>}
                </div>
              </div>
            </div>
          </div>
        </div>

      </Link>
    );
  };



  limitText(text, maxLength = 100) {

    if (text.length <= maxLength) {
      return text;
    }
    return text.substr(0, text.lastIndexOf(' ', maxLength)) + '...';
  }


  render() {
    switch (this.props.shape) {
      case 'square':
        return this.renderSquare();
      case 'vertical':
        return this.renderVertical();
      default:
        return null;
    }
  }
}

ProductCard.defaultProps = {
  shape: 'square',
};


ProductCard.propTypes = {
  product: PropTypes.instanceOf(Product).isRequired,
  shape: PropTypes.string.isRequired
};

export default withRouter(ProductCard);

