
import React, { Component } from 'react';
import "../../css/HelpScreen.css"
import BackLink from "../BackLink";
import {Link, withRouter} from "react-router-dom";

class CreatingAQueue extends Component {
  static TITLE = "Creating A Queue";


  render() {
    return (
      <>
        <BackLink link="/help/seller-get-started" text="Back to Getting Started Guide"/>
        <div className="helpscreen-largetitle">Creating a Queue</div>

        <div className="helpscreen-subheader">
          Step 1: Name your queue
        </div>
        <div className="helpscreen-paragraph">
          Click on the "Create New Queue" button in the Seller's Dashboard and fill in the name of your queue. You can always edit the name later.
        </div>
        <div className="d-flex justify-content-center">
          <img className="border m-1 bg-dark" src="/help/createqueueue.png" alt="Creating A Queue"/>
        </div>



        <div className="helpscreen-subheader">
          Step 2: Fill in queue details
        </div>
        <div className="helpscreen-paragraph">
          Add a description and some tags. (Don't forget to save). You can also add an image. Queues only show one image. You can add more than one and re-arrange at any time, however <strong>only the first image is shown in a queue</strong>.
        </div>
        <div className="d-flex justify-content-center">
          <img className="border m-1 bg-dark" height="300" src="/help/queuedetails.png" alt="Queue Detaile"/>
        </div>



        <div className="helpscreen-subheader">
          Step 3: Decide when to make it visible
        </div>
        <div className="helpscreen-paragraph">
          A queue will not be seen by users until you decide to make it visible.
        </div>




        <div className="helpscreen-subheader">
          Step 3: Create Some Products
        </div>
        <div className="helpscreen-paragraph">
          Now that you have a queue, you will need some products in it to sell. For details, see our <Link to="/help/creating-a-product">Product Creation Guide</Link>
        </div>

      </>
    )
  }
}

export default withRouter(CreatingAQueue);