// import { API } from 'aws-amplify';
//import aws_exports from '../aws-exports';
//Amplify.configure(aws_exports);
//import {Link} from "react-router-dom";

import Util from '../Util';

/**
 * THIS IS NOT A DATABASE MODEL!
 */

class AuthMessage {

  static ERR_CODE_UserNotConfirmedException = "UserNotConfirmedException";

  static TYPE_PRIMARY = 'alert-primary';
  static TYPE_SECONDARY = 'alert-secondary';
  static TYPE_SUCCESS = 'alert-success';
  static TYPE_DANGER = 'alert-danger';
  static TYPE_WARNING = 'alert-warning';
  static TYPE_INFO = 'alert-info';
  static TYPE_LIGHT = 'alert-light';
  static TYPE_DARK = 'alert-dark';

  constructor(message, type, errorCode = null) {
    this.key = Util.createUUID();
    this.message = message;
    this.type = type;
    this.isShown = false;
    this.errorCode = errorCode;
  }

  getMessage() {
    return this.message;
  }

  getType() {
    return this.type;
  }

  getKey() {
    return this.key;
  }

  isShown() {
    return this.isShown;
  }

  getErrorCode() {
    return this.errorCode;
  }

}

export default AuthMessage;