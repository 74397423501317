import React from 'react';
import {API, Logger} from "aws-amplify";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Queue from "../models/Queue";
import RootScreen from "./RootScreen";
import ImageUploader from "../components/ImageUploader";
import TagContainer from "../components/TagContainer";
import ErrorMessage from "../components/ErrorMessage";
import Loading from "../components/Loading";
import BackLink from "../components/BackLink";
import ReactMarkdown from "react-markdown/with-html";

const logger = new Logger('CreateQueueScreen');

class CreateQueueScreen extends RootScreen {

  constructor(props) {
    super(props);
    window.gtag('event', 'screen_view', {
      'app_name' : 'website',
      'screen_name' : 'CreateQueueScreen'
    });

    logger.debug('CreateQueueScreen::constructor');
    const { match } = this.props;

    this.state = {
      queueId: match.params.id,
      queueName: "",
      queueDescription: "",
      queueVisible: "",

      creatingQueue: false,
      errorMessage: null,
      successMessage: null,

      tags: [],

      queueLoaded: match.params.id === undefined,
      originalQueue: null,

      previewDescription: false,

    };

    this.handleQueueNameChange = this.handleQueueNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
  }

  componentDidMount() {
    if (this.state.queueId) {
      this.loadQueue();
    }
  }


  loadQueue = async () => {

    if (!this.state.queueId) {
      return;
    }

    if (!this.props.loggedInUser) {
      return;
    }


    try {
      let apiName = 'epiqueueapi';
      let path = '/queue/' + this.state.queueId;
      var response = await API.get(apiName, path);
      logger.debug("response: " + JSON.stringify(response));
      if (!response.success) {
        this.setState({
          queue: null,
          errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred",
          queueLoaded: true,
        });
      } else {
        
        let queue = new Queue(response.queue);
        if (queue.user_id !== this.props.loggedInUser.id) {
          throw Error("You are not the owner of this queue");
        }

        this.setState({
          originalQueue: queue,
          queueName: queue.name,
          queueDescription: queue.description,
          queueVisible: queue.visible,
          queueLoaded: true,
          tags: queue.tags,
        });
      }
    } catch (error) {
      logger.debug(error.message);
      logger.error(error);
      this.setState({
        errorMessage: error.message,
        queueLoaded: true,
      });
    }

  }

  updateQueue = async () => {
    logger.debug("updateQueue");

    logger.debug("createQueue");

    let {originalQueue} = this.state;
    if (!originalQueue) {
      this.setState({
        errorMessage: "There was an error trying to save this queue"
      });
      return;
    }


    let name = this.state.queueName.trim();
    let description = this.state.queueDescription;
    let visible = this.state.queueVisible;

    let tags = this.state.tags.map(item => {
      return {id: item.id, 'tag': item.tag}
    });


    if (name === '') {
      this.setState({
        errorMessage: "You must supply a name for the queue."
      });
      return;
    }



    this.setState({
      errorMessage: null
    });


    let {loggedInUser} = this.props;
    if (!loggedInUser) {
      this.setState({
        errorMessage: "Can not create the queue right now."
      });
      return;
    }

    console.log("_createQueue");
    try {
      let apiName = 'epiqueueapi';
      let path = '/queue';
      let putData = {
        body: {
          queue_id: originalQueue.id,
          name: name,
          description: description,
          visible: visible,
          tags: tags,
        }
      };
      var response = await API.put(apiName, path, putData);
      console.log("response: " + JSON.stringify(response));

      if (!response.success) {
        this.setState({
          errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred"
        });
      } else {
        let updatedQueue = new Queue(response.queue);
        this.setState({
          originalQueue: updatedQueue,
          queueName: updatedQueue.name,
          queueDescription: updatedQueue.description,
          successMessage: "Queue saved",
          tags: updatedQueue.tags,

        });
      }

    } catch (error) {
      console.log(error.message);
      this.setState({
        errorMessage: error.message
      });
    }

  }

  // createQueue = async () => {
  //   logger.debug("createQueue");
  //
  //   let name = this.state.queueName;
  //   let description = this.state.queueDescription;
  //
  //   this.setState({
  //     errorMessage: null
  //   });
  //
  //
  //   let {loggedInUser} = this.props;
  //   if (!loggedInUser) {
  //     this.setState({
  //       errorMessage: "Can not create the queue right now."
  //     });
  //     return;
  //   }
  //
  //   console.log("_createQueue");
  //   try {
  //     let apiName = 'epiqueueapi';
  //     let path = '/queue';
  //     let putData = {
  //       body: {
  //         queue_id: null,
  //         user_id: loggedInUser.id,
  //         name: name,
  //         description: description
  //       }
  //     };
  //     var response = await API.put(apiName, path, putData);
  //     console.log("response: " + JSON.stringify(response));
  //
  //     if (!response.success) {
  //       this.setState({
  //         errorMessage: (response.errorMessage) ? response.errorMessage : "An error occurred"
  //       });
  //     } else {
  //       this.props.history.replace("/dashboard");
  //     }
  //
  //   } catch (error) {
  //     console.log(error.message);
  //     this.setState({
  //       errorMessage: error.message
  //     });
  //   }
  //
  // };


  handleQueueNameChange(event) {
    let txt = event.target.value;
    if (txt.length > Queue.NAME_CHAR_LIMIT) {
      txt = txt.substr(0, Queue.NAME_CHAR_LIMIT);
    }
    this.setState({queueName: txt});
  }

  handleDescriptionChange(event) {
    this.setState({queueDescription: event.target.value});
  }

  handleVisibleChange(event) {
    logger.debug(event.target.checked);
    let newValue = event.target.checked ? Queue.VISIBLE : Queue.NOT_VISIBLE
    this.setState({queueVisible: newValue});
  }


  uploadCallback() {

  }

  deleteTag = (tag) => {
    let curTags = this.state.tags.filter(item => {
      if (item !== tag) {
        return true;
      }
      return false;
    });

    this.setState({
      tags: curTags,
    });

  };

  addTag = (tag) => {
    if (!tag || tag.tag === '') {
      return;
    }

    let curTags = this.state.tags;
    curTags.push(tag);
    this.setState({
      tags: curTags,
    })
  };

  renderLoading() {
    return (
      <Loading/>
    );
  }

  renderContent() {
    if(!this.props.loggedInUser || !this.props.loggedInUser.is_seller) {
      return (
        <div className="alert alert-info">You must be a registered seller in order to create a queue</div>
      )
    }

    if (!this.state.queueLoaded) {
      return this.renderLoading()
    }

    if (!this.state.originalQueue) {
      return (
        <>
          {this.state.errorMessage ?
            <div className="alert alert-danger">{this.state.errorMessage}</div>
            : null}
        </>
      )
    }

    return (
      <>

        <BackLink link={"/queue/" + this.state.queueId} text="Back to Your Queue"/>


        <div className="d-flex flex-column border border-dark p-2 mt-4 epiqueue-faded-background">
          <h5>
            Details
          </h5>

          <div className="form">
            <div className="form-group">
              <label htmlFor="queuename">Queue Name</label>
              <input type="text"
                     className="form-control"
                     id="queuename"
                     value={this.state.queueName}
                     onChange={this.handleQueueNameChange}
                     aria-describedby="queuenameHelp"
                     placeholder="Queue Name"
                     required
                     autoFocus
              />
                <small id="queuenameHelp" className="form-text text-muted">Required, Limit of {Queue.NAME_CHAR_LIMIT} characters.</small>
            </div>
            <div className="form-group">
              <div className="d-flex flex-row justify-content-between">
                <label htmlFor="queuedescription">Description (markup and html allowed)</label>
                <div className="custom-control custom-switch">
                  <input type="checkbox" className="custom-control-input" id="preview-description" onChange={() => this.setState({previewDescription: !this.state.previewDescription})} checked={this.state.previewDescription}/>
                  <label className="custom-control-label" htmlFor="preview-description">Preview</label>
                </div>
              </div>

              {!this.state.previewDescription ?
              <textarea
                className="form-control"
                rows="5"
                id="queuedescription"
                value={this.state.queueDescription}
                onChange={this.handleDescriptionChange}
                placeholder="Description"
              />
              :
                <div className="border p-2">
                  <ReactMarkdown source={this.state.queueDescription} escapeHtml={false}/>
                </div>
              }
            </div>


            <div className='d-flex flex-row align-items-center mb-3 flex-wrap'>
              <div className='mr-2'>Tags:</div><TagContainer tags={this.state.tags} editable={true} deleteCallback={this.deleteTag} addCallback={this.addTag}/>
            </div>

            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="visible" onChange={this.handleVisibleChange} checked={this.state.queueVisible === Queue.VISIBLE}/>
                <label className="custom-control-label" htmlFor="visible">Make this queue visible to all</label>
            </div>

            <ErrorMessage errorMessage={this.state.errorMessage}/>
            <ErrorMessage errorMessage={this.state.successMessage} type={'success'}/>

            <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-primary btn-epiqueue mr-3" onClick={() => this.updateQueue()}>Update Details</button>
            </div>
          </div>

        </div>

        <div className="d-flex flex-column border border-dark p-2 mt-4 epiqueue-faded-background">
        { this.state.originalQueue ?
          <ImageUploader isOwner={true} queue={this.state.originalQueue} />
          : null }
        </div>


      </>
  );
}

}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loggedInUser: state.users.loggedInUser
  }
};

export default withRouter(connect(
  mapStateToProps
)(CreateQueueScreen));
