import React, { Component } from 'react';
import {withRouter, Link} from "react-router-dom";
import SearchBar from "./SearchBar";

import "../css/CategoryBar.css"
import {connect} from "react-redux";

class CategoryBar extends Component {


  render() {
    if (!this.props.loggedInUser) {
      return null;
    }

    return (
      <div className="container">
      <div className="d-flex flex-column justify-content-center align-content-center">
        <div className="pt-2 pb-2 justify-content-center align-self-center w-100">
          <div className="categorybar-content">
            <div className="categorybar-tags">
              <Link className="epiqueue-link categorybar-tag" to="/?tag=jewlery"><i className="fas fa-gem"></i> Jewelery & Accessories</Link><br/>
              <Link className="epiqueue-link categorybar-tag" to="/?tag=art"><i className="fas fa-paint-brush"></i> Art & Collectables</Link>
              <Link className="epiqueue-link categorybar-tag" to="/?tag=clothing"><i className="fas fa-tshirt"></i> Clothing</Link><br/>
              <Link className="epiqueue-link categorybar-tag" to="/?tag=vintage"><i className="fas fa-scroll-old"></i> Vintage</Link>
            </div>
            <div className="categorybar-search-item">
              <div className="d-flex justify-content-center align-self-center">
                <SearchBar searchResultsFunc={this.searchResults} startSearchFunc={this.startSearch} updateSearchTextFunc={this.updateSearchText}/>
              </div>
            </div>
          </div>
        </div>
        <div className="epiqueue-hr"/>
      </div>
      </div>
    );

  };
}

const mapStateToProps = (state,  ownProps) => {
  return {
    loggedInUser: state.users.loggedInUser,
  }
};

export default withRouter(connect(
  mapStateToProps,
)(CategoryBar));


