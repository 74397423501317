
import Model from "./Model";
import Product from "./Product";
import Transaction from "./Transaction";
import ProductMember from "./ProductMember";
import User from "./User";

export default class Sale extends Model {

  static STATUS_CREATED = 'created';
  static STATUS_AWAITING_PAYMENT = 'awaiting_payment';
  static STATUS_PAID = 'paid';
  static STATUS_PAYMENT_FAILED = 'payment_failed';
  static STATUS_SHIPPED = 'shipped';
  static STATUS_COMPLETED = 'completed';
  static STATUS_CANCELLED = 'cancelled';
  static STATUS_ABANDONED = 'abandoned';

  /**
   *
   * @param json
   */
  constructor(json) {
    super();

    let {
      id,
      identifier,
      user_id,
      product_id,
      amount,
      product_price,
      quantity,
      shipping_amount,
      tax_percentage,
      tax_amount,
      status,
      carrier,
      tracking_number,
      shipping_comment,
      shipped_at,
      created_at,
      updated_at,
      product,
      transactions,
      product_member,
      user
    } = json;

    // database fields
    this.id = id;
    this.identifier = identifier;
    this.user_id = user_id;
    this.product_id = product_id;
    this.amount = amount;
    this.product_price = product_price;
    this.quantity = quantity;
    this.shipping_amount = shipping_amount;
    this.tax_percentage = tax_percentage;
    this.tax_amount = tax_amount;
    this.status = status;
    this.carrier = carrier;
    this.tracking_number = tracking_number;
    this.shipping_comment = shipping_comment;
    this.shipped_at = shipped_at;
    this.created_at = created_at;
    this.updated_at = updated_at;
    if (product) {
      this.product = new Product(product);
    }
    if (user) {
      this.user = new User(user);
    }
    if (product_member) {
      this.product_member = new ProductMember(product_member);
    }
    if (transactions) {
      this.transactions = transactions.map(item => {
        return new Transaction(item);
      })
    }
  }

  isOpen()  {
    return this.status === Sale.STATUS_CREATED;
  }

  isComplete() {
    return this.status === Sale.STATUS_COMPLETED ||
      this.status === Sale.STATUS_PAID ||
      this.status === Sale.STATUS_SHIPPED;
  }


  isClosed() {
    return this.status !== Sale.STATUS_CREATED;
  }

  isShipped() {
    return this.status === Sale.STATUS_SHIPPED;
  }

  readyForShipping() {
    return this.status === Sale.STATUS_PAID;
  }

  calculateTotal() {
    return this.amount + this.tax_amount + this.shipping_amount;
  }


  getFormattedAmount() {
    return '$' +  this.formatMoney(this.amount / 100);
  }

  getFormattedShippingAmount() {
    return '$' + this.formatMoney(this.shipping_amount / 100);
  }

  getFormattedTaxAmount() {
    return '$' + this.formatMoney(this.tax_amount / 100);
  }

  formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  };

}

// +------------------+-----------------------+------+-----+-------------------+-----------------------------+
// | Field            | Type                  | Null | Key | Default           | Extra                       |
// +------------------+-----------------------+------+-----+-------------------+-----------------------------+
// | id               | bigint(20) unsigned   | NO   | PRI | NULL              | auto_increment              |
// | identifier       | char(36)              | NO   | UNI | NULL              |                             |
// | user_id          | bigint(20) unsigned   | NO   | MUL | NULL              |                             |
// | product_id       | bigint(20) unsigned   | YES  | MUL | NULL              |                             |
// | amount           | int(10) unsigned      | NO   |     | NULL              |                             |
// | product_price    | int(10) unsigned      | NO   |     | NULL              |                             |
// | quantity         | int(10) unsigned      | NO   |     | 1                 |                             |
// | shipping_amount  | int(10) unsigned      | NO   |     | NULL              |                             |
// | tax_percentage   | decimal(8,4) unsigned | YES  |     | NULL              |                             |
// | tax_amount       | int(10) unsigned      | YES  |     | NULL              |                             |
// | status           | varchar(255)          | NO   |     | created           |                             |
// | carrier          | varchar(255)          | YES  |     | NULL              |                             |
// | tracking_number  | varchar(255)          | YES  |     | NULL              |                             |
// | shipping_comment | text                  | YES  |     | NULL              |                             |
// | shipped_at       | timestamp             | YES  |     | NULL              |                             |
// | created_at       | timestamp             | NO   |     | CURRENT_TIMESTAMP |                             |
// | updated_at       | timestamp             | NO   |     | CURRENT_TIMESTAMP | on update CURRENT_TIMESTAMP |
// +------------------+-----------------------+------+-----+-------------------+-----------------------------+
//   17 rows in set (0.09 sec)
